import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, createStyles, FormHelperText, Grid, IconButton, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormMode } from '../../../../../../utils/Enums';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useFormContext } from 'react-hook-form';
import { SuccessFactorStatus, FundamentalPrimeMeasurement } from '../../../../../../apollo/generated/types/globalTypes';
import { useSuccessFactorForm } from '../../../../../Providers/SuccessFactorFormProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '15px',
        },
        accordion: {
            flex: 1,
        },
        removeSection: {
            width: '50px',
        },
        tooltip: {
            padding: theme.spacing(2),
        },
        alignRight: {
            textAlign: 'right',
        },
        weight: {
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
        },
    }),
);

const SuccessFactorItem: React.FC<SuccessFactorItemProps> = ({
    mode,
    index,
    topicIndex,
    id,
    fpmCategory,
    name,
    weight,
    definition,
    imported,
    importedLocked,
    remove,
}) => {
    const classes = useStyles();
    const { register, errors, setValue, watch } = useFormContext();
    const { loading, librarySuccessFactors } = useSuccessFactorForm();

    const hasError = (field: string): boolean => {
        return !!errors && !!errors[`topics[${topicIndex}].successFactors[${index}].${field}`];
    };

    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors[`topics[${topicIndex}].successFactors[${index}].${field}`]?.message : '';
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //if they empty the weight value, allow that
        if (e.target.value == '') {
            setValue(e.target.name, e.target.value);
            return;
        }

        //for improved user experience, if they type in a new number that would be in the range, we set the value of the weight to that
        let remainder = +e.target.value % 10;

        //if new number would exceed our range anyways, set its bounds
        if (remainder > 5) {
            remainder = 5;
        } else if (remainder < 1) {
            remainder = 1;
        }

        remainder = +remainder.toFixed(1);

        setValue(e.target.name, remainder);
    };

    const handleAutofill = (event: React.ChangeEvent<unknown>, value: string | null) => {
        console.log(event);

        //don't do anything on empty field(onChange triggers when textbox is emptied)
        if (value === null || value.length < 1) {
            return;
        }

        const librarySF = librarySuccessFactors.find((x) => x.fpmCategory === fpmCategory && x.name === value);

        setValue(`topics[${topicIndex}].successFactors[${index}].definition`, librarySF?.definition);
    };

    const getHelperText = (field?: number): string => {
        if (field === undefined) {
            return '';
        } else if (field == 5) {
            return '5 - Absolutely Necessary';
        } else if (field >= 4) {
            return '4 - Important';
        } else if (field >= 3) {
            return '3 - A good to have';
        } else if (field >= 2) {
            return '2 - A minor contribution';
        } else if (field >= 1) {
            return '1 - Never Needed';
        } else return '';
    };

    const weightWatch = watch(`topics[${topicIndex}].successFactors[${index}].weight`);
    const status = watch('status');
    const required = status !== SuccessFactorStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View || (importedLocked && imported);
    return (
        <div className={classes.root}>
            <Accordion elevation={0} className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container justify="space-between">
                        <FormControl
                            draggable
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            {id || id === 0 ? (
                                <input name={`topics[${topicIndex}].successFactors[${index}].id`} type="hidden" ref={register()} value={id} />
                            ) : null}
                            <input
                                name={`topics[${topicIndex}].successFactors[${index}].fpmCategory`}
                                type="hidden"
                                ref={register({ valueAsNumber: false })}
                                value={fpmCategory}
                            />
                            <Checkbox
                                name={`topics[${topicIndex}].successFactors[${index}].imported`}
                                style={{ visibility: 'hidden' }}
                                inputRef={register()}
                                disabled={true}
                                checked={imported}
                            />
                            {loading ? (
                                <TextField
                                    name={`topics[${topicIndex}].successFactors[${index}].name`}
                                    placeholder={`Success Factor Name${requiredAsterisk}`}
                                    inputRef={register()}
                                    onClick={(e) => e.stopPropagation()}
                                    error={hasError('name')}
                                    InputProps={{ style: { width: '300px' }, readOnly: readOnly }}
                                />
                            ) : (
                                <Autocomplete
                                    freeSolo
                                    onClick={(e) => e.stopPropagation()}
                                    ListboxProps={{ style: { display: readOnly ? 'none' : '' } }}
                                    onChange={handleAutofill}
                                    disableClearable={readOnly}
                                    defaultValue={name || ''}
                                    options={
                                        librarySuccessFactors
                                            ? librarySuccessFactors?.filter((x) => x.fpmCategory === fpmCategory).map((option) => option.name)
                                            : ([] as string[])
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name={`topics[${topicIndex}].successFactors[${index}].name`}
                                            placeholder={`Success Factor Name${requiredAsterisk}`}
                                            inputRef={register()}
                                            onClick={(e) => e.stopPropagation()}
                                            error={hasError('name')}
                                            InputProps={{ ...params.InputProps, style: { width: '300px' }, readOnly: readOnly }}
                                        />
                                    )}
                                />
                            )}
                            {hasError('name') ? <FormHelperText error={true}>{getErrorMessage('name')}</FormHelperText> : null}
                        </FormControl>
                        <FormControl
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            <TextField
                                className={classes.weight}
                                name={`topics[${topicIndex}].successFactors[${index}].weight`}
                                placeholder={`Weight (1-5)${requiredAsterisk}`}
                                type="number"
                                inputRef={register()}
                                InputProps={{ inputProps: { min: 1, max: 5, step: 0.1, style: { width: '135px' } }, readOnly: readOnly }}
                                defaultValue={weight || ''}
                                onClick={(e) => e.stopPropagation()}
                                onChange={handleChange}
                                error={hasError('weight')}
                            />
                            <FormHelperText error={hasError('weight')}>
                                {hasError('weight') ? getErrorMessage('weight') : getHelperText(weightWatch)}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label={`Definition${requiredAsterisk}`}
                                name={`topics[${topicIndex}].successFactors[${index}].definition`}
                                inputRef={register()}
                                InputProps={{ readOnly: readOnly }}
                                fullWidth
                                defaultValue={definition}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={hasError('definition')}
                                helperText={getErrorMessage('definition')}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {!readOnly && (
                <div className={classes.removeSection}>
                    <IconButton aria-label="remove" onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                </div>
            )}
        </div>
    );
};

export interface SuccessFactorItemProps {
    mode: FormMode;
    index: number;
    topicIndex: number;
    fpmCategory: FundamentalPrimeMeasurement;
    id?: number | null;
    name?: string;
    weight?: number;
    definition?: string;
    imported?: boolean;
    remove: (index: number) => void;
    importedLocked: boolean;
}

export default SuccessFactorItem;
