import { StepIconProps } from '@material-ui/core';
import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import EventIcon from '@material-ui/icons/Event';
import InfoIcon from '@material-ui/icons/Info';
import CategoryIcon from '@material-ui/icons/Category';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStepIconStyles = makeStyles({
    root: {
        background: '#1F8BF3',
        zIndex: 1,
        color: '#fff',
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        background: '#1F8BF3',
    },
    completed: {
        background: '#1976D2',
    },
});

export const WPStepIcon = (props: StepIconProps): JSX.Element => {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <DescriptionIcon />,
        2: <InfoIcon />,
        3: <CategoryIcon />,
        4: <AssignmentTurnedInIcon />,
        5: <EventIcon />,
        6: <CategoryIcon />,
        7: <PermContactCalendarIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}>
            {icons[String(props.icon)]}
        </div>
    );
};
