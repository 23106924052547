import React, { useEffect } from 'react';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PageWrapper, PageHeader, PageBody, ScreenMessage, LoadingMessage, ErrorMessage, usePermissions } from '../components';
import FileIcon from '../images/FileIcon';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { NavLink } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GetCycles } from '../apollo/generated/types/GetCycles';
import { GET_CYCLES } from '../apollo/queries';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
    },
});

const DashboardPage: React.FC = () => {
    const classes = useStyles();
    const { hasPermission } = usePermissions();
    const [getCycles, { loading, error, data }] = useLazyQuery<GetCycles>(GET_CYCLES, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (hasPermission('create:cycles')) {
            getCycles();
        }
    }, [getCycles, hasPermission]);

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <Typography variant="h1" className={classes.title}>
                        Dashboard
                    </Typography>
                </Toolbar>
            </PageHeader>
            <PageBody>
                {loading ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : hasPermission('create:cycles') && (!data || !data?.cycles || !data?.cycles?.items || data.cycles.items.length === 0) ? (
                    <ScreenMessage icon={<FileIcon />} header="Welcome to your dashboard." subHeader="Start by creating an Acquisition Cycle.">
                        <Button startIcon={<AddCircleIcon />} component={NavLink} to="/acquisition-cycle/create" variant="contained">
                            Create Acquisition Cycle
                        </Button>
                    </ScreenMessage>
                ) : (
                    <ScreenMessage icon={<FileIcon />} header="Welcome to your dashboard." />
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default DashboardPage;
