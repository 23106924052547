import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from '@material-ui/core';
import { LoadingButton } from '../../index';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    dialog: {
        '& .MuiDialog-paper': {
            padding: '52px 24px 34px 24px',
        },
    },
    title: {
        marginTop: '30px',
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogDescription: {
        color: '#1A2A3D',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
        marginBottom: '24px',
    },
    secondaryButton: {
        marginLeft: '0!important',
        marginTop: '10px',
    },
});

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open,
    icon,
    title,
    message,
    primaryBusy,
    showPrimary = true,
    showSecondary = true,
    primaryText = 'Confirm',
    secondaryText = 'Cancel',
    onPrimaryClick,
    onSecondaryClick,
    onClose,
    ...dialogProps
}) => {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose} className={classes.dialog} {...dialogProps}>
            <DialogTitle>
                <div className={classes.centerItems}>
                    {icon}
                    {title ? (
                        <Typography variant="h1" className={classes.title}>
                            {title}
                        </Typography>
                    ) : null}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.dialogDescription}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.centerItems}>
                {showPrimary ? (
                    <LoadingButton pending={primaryBusy} variant="contained" onClick={onPrimaryClick}>
                        {primaryText}
                    </LoadingButton>
                ) : null}
                {showSecondary ? (
                    <Button onClick={onSecondaryClick} variant="text" className={classes.secondaryButton}>
                        {secondaryText}
                    </Button>
                ) : null}
            </DialogActions>
        </Dialog>
    );
};

export interface ConfirmDialogProps extends Omit<DialogProps, 'title'> {
    open: boolean;
    icon?: React.ReactNode;
    title?: React.ReactNode;
    message?: React.ReactNode;
    primaryBusy?: boolean;
    showPrimary?: boolean;
    primaryText?: string;
    onPrimaryClick?: () => void;
    showSecondary?: boolean;
    secondaryText?: string;
    onSecondaryClick?: () => void;
}

export default ConfirmDialog;
