import gql from 'graphql-tag';

export const SubCycleFragment = gql`
    fragment SubCycleFields on SubCycleType {
        id
        name
        formType
        status
        order
        archiveDate
        currentClosingDate
        lastUpdatedDate
        originalArchiveDate
        originalClosingDate
        postedDate
        successFactorStatus
        responses {
            id
        }
    }
`;

export const SubCycleWithEvaluatorsFragment = gql`
    fragment SubCycleWithEvaluatorsFields on SubCycleType {
        ...SubCycleFields
        canBeSetActive
        assignedEvaluators {
            id
            user {
                firstName
                lastName
            }
        }
    }
    ${SubCycleFragment}
`;

export const CycleFundingFragment = gql`
    fragment CycleFundingFields on CycleFundingType {
        id
        fundingAmount
        fundingTypeId
        timeframe
    }
`;

export const CycleFragment = gql`
    fragment CycleFields on CycleType {
        id
        status
        title
        estimatedCloseDate
        externalId
        primaryContactId
        departmentId
        serviceId
        subServiceId
        eligibleResponders
        distributionStatement
        templateId
        subCycles {
            ...SubCycleFields
        }
        cycleFundings {
            ...CycleFundingFields
        }
    }
    ${SubCycleFragment}
    ${CycleFundingFragment}
`;

export const SubServiceFragment = gql`
    fragment SubServiceFields on SubServiceType {
        id
        name
        acronym
    }
`;

export const FundingTypeFragment = gql`
    fragment FundingTypeFields on FundingTypeType {
        id
        name
    }
`;

export const CurrentUserFragment = gql`
    fragment CurrentUserFields on UserType {
        id
        email
        fullName
        phoneNumber
        externalRoleName
        isProfileComplete
        cycleAssignments {
            cycleId
            cycleRole {
                roleType
            }
        }
    }
`;

export const UserFragment = gql`
    fragment UserDisplayFields on UserType {
        id
        email
        fullName
        phoneNumber
        externalRoleName
        isProfileComplete
    }
`;

export const EvaluatorFragment = gql`
    fragment EvaluatorFields on UserType {
        id
        fullName
        externalRoleName
        cycleAssignments {
            id
        }
        notAvailable
        userAvailabilities {
            day
            startTime
            endTime
        }
    }
`;

export const TemplateFragment = gql`
    fragment TemplateFields on TemplateType {
        id
        name
        templateSubCycles {
            id
            name
            formType
            order
        }
    }
`;

export const TemplateNameFragment = gql`
    fragment TemplateNameFields on TemplateType {
        id
        name
    }
`;

export const ProfileFragment = gql`
    fragment ProfileFields on UserType {
        id
        firstName
        lastName
        countryOfCitizenship
        email
        secondaryEmail
        phoneNumber
        employer
        workAddress
        jobTitle
        independentFreelancer
        notAvailable
        cycleAssignments {
            id
            cycle {
                id
                title
            }
            cycleRole {
                id
                name
            }
        }
        userAvailabilities {
            id
            day
            endTime
            startTime
        }
        isProfileComplete
    }
`;

export const TopicFragment = gql`
    fragment TopicFields on TopicType {
        id
        name
        description
        categories {
            id
        }
    }
`;

export const CategoryFragment = gql`
    fragment CategoryFields on CategoryType {
        id
        name
        description
    }
`;

export const LibrarySuccessFactorFragment = gql`
    fragment LibrarySuccessFactorFields on LibrarySuccessFactorType {
        name
        definition
        fpmCategory
    }
`;

export const RequestForInformationFormFragment = gql`
    fragment RequestForInformationFormFields on RequestForInformationFormType {
        subCycleId
        title
        status
        additionalInformation
        archiveDate
        backgroundOverview
        briefResponseDescription
        currentClosingDate
        deadlineDateTime
        deadlineTimezone
        eligibleResponderTypeId
        expectedAwards
        externalId
        fundingSourceId
        hasBriefResponse
        instructions
        isAcceptingQuestionSubmission
        isCostSharing
        lastUpdatedDate
        naicscode
        opportunityDescription
        originalArchiveDate
        originalClosingDate
        postedDate
        responseInstructions
        responseInstructionsFileId
        securityClassificationId
        submissionOfQuestions
        timeHorizonId
        totalBudget
        locationPlaceOfPerformance
        researchDevelopmentCategories {
            id
            categoryId
            fundingRange
            technologyReadinessLevelId
        }
        technologyKeywords {
            id
        }
        pointOfContacts {
            id
            firstName
            lastName
            phone
            email
            jobTitle
            organizationId
        }
        topics {
            ...TopicFields
        }
        referenceFiles {
            id
        }
    }
    ${TopicFragment}
`;

export const RequestForWhitePaperFormFragment = gql`
    fragment RequestForWhitePaperFormFields on RequestForWhitePaperFormType {
        additionalTermsAndConditions
        basePeriod
        basisForSelectionEvaluationApproach
        buildingNumberMailstop
        contractOfficeAddress
        contractorEvaluationAdministratorSupport
        costSharingDescription
        descriptionOfRequirement
        exportControl
        externalId
        followOn
        hasAcceptingQuestionSubmission
        hasBasisForSelectionEvaluationApproach
        hasContractorEvaluationAdministratorSupport
        hasCostSharing
        hasDescriptionOfRequirement
        hasExportControl
        hasFollowOn
        hasLegallyResponsibleEntity
        hasNontraditionalDefenseContractorDefinition
        hasStatementOfNeed
        hasSystemForAwardManagement
        instructions
        legallyResponsibleEntity
        locationPlaceOfPerformance
        naicsCode
        nontraditionalDefenseContractorDefinition
        originalInactiveDateTime
        originalInactiveTimeZone
        originalPublishedDateTime
        originalPublishedTimeZone
        originalResponseDateTime
        originalResponseTimeZone
        pointOfContacts {
            id
            firstName
            lastName
            phone
            email
            jobTitle
            organizationId
        }
        researchDevelopmentCategories {
            id
            categoryId
            fundingRange
            technologyReadinessLevelId
        }
        securityClassificationId
        statementOfNeed
        statementOfNeedFileId
        status
        subCycleId
        submissionOfQuestions
        systemForAwardManagement
        technologyObjective
        title
        topics {
            id
            name
            description
            categories {
                id
            }
        }
        updatedInactiveDateTime
        updatedInactiveTimeZone
        updatedPublishedDateTime
        updatedPublishedTimeZone
        updatedResponseDateTime
        updatedResponseTimeZone
    }
`;

export const RequestForProposalFormFragment = gql`
    fragment RequestForProposalFormFields on RequestForProposalFormType {
        awarding
        basePeriod
        basisForProposalReview
        buildingNumberMailstop
        costSharingDescription
        contractOfficeAddress
        eligibleResponderTypeId
        exportControl
        externalId
        followOnProduction
        generalInformation
        governmentInterfacing
        hasAwarding
        hasBasisForProposalReview
        hasCostSharing
        hasExportControl
        hasFollowOnProduction
        hasGeneralInformation
        hasGovernmentInterfacing
        hasIntroduction
        hasLicensingRights
        hasMandatoryGovernmentTerms
        hasNonDisclosureStatement
        hasPriceInformation
        hasProposalPreparationInstructions
        hasStatementOfWork
        hasSubmissionInstructions
        hasTechnicalProposalInstructions
        introduction
        issuingOrganizationId
        licensingRights
        locationPlaceOfPerformance
        mandatoryGovernmentTerms
        naicsCode
        nonDisclosureStatement
        originalInactiveDateTime
        originalInactiveTimeZone
        originalPublishedDateTime
        originalPublishedTimeZone
        originalResponseDateTime
        originalResponseTimeZone
        pointOfContacts {
            id
            firstName
            lastName
            phone
            email
            jobTitle
            organizationId
        }
        priceInformation
        program
        proposalPreparationInstructions
        referenceFiles {
            id
        }
        researchDevelopmentCategories {
            id
            categoryId
            fundingRange
            technologyReadinessLevelId
        }
        securityClassificationId
        statementOfWork
        status
        subCycleId
        submissionInstructions
        technicalProposalInstructions
        title
        topics {
            id
            name
            description
            categories {
                id
            }
        }
        updatedInactiveDateTime
        updatedInactiveTimeZone
        updatedPublishedDateTime
        updatedPublishedTimeZone
        updatedResponseDateTime
        updatedResponseTimeZone
    }
`;

export const SubCycleUserFragment = gql`
    fragment SubCycleUserFields on SubCycleUserType {
        user {
            id
        }
        subCycle {
            id
        }
    }
`;

export const ActiveSubCycleFormFragment = gql`
    fragment ActiveSubCycleFormFields on SubCycleType {
        form {
            ... on RequestForInformationFormType {
                topics {
                    id
                    name
                    description
                }
                researchDevelopmentCategories {
                    category {
                        id
                        name
                    }
                }
            }
            ... on RequestForWhitePaperFormType {
                topics {
                    id
                    name
                    description
                }
                researchDevelopmentCategories {
                    category {
                        id
                        name
                    }
                }
            }
            ... on RequestForProposalFormType {
                topics {
                    id
                    name
                    description
                }
                researchDevelopmentCategories {
                    category {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const ResponseUserFragment = gql`
    fragment ResponseUserFields on ResponseUserType {
        user {
            id
        }
        response {
            id
        }
    }
`;

export const OptionFragment = gql`
    fragment OptionFields on FormFieldValueType {
        id
        name
    }
`;

export const OptionWithParentFragment = gql`
    fragment OptionWithParentFields on FormFieldValueType {
        id
        name
        parents {
            id
        }
    }
`;

export const ResponderTypeFragment = gql`
    fragment ResponderTypeFields on ResponderTypeType {
        id
        name
    }
`;

export const SecurityClassificationFragment = gql`
    fragment SecurityClassificationFields on SecurityClassificationType {
        id
        name
    }
`;

export const FileFragment = gql`
    fragment FileFields on SubCycleFileType {
        id
        fileName
        fileSize
        fileType
    }
`;

export const SubCycleFormTypeFragment = gql`
    fragment SubCycleFormTypeFields on SubCycleFormTypeType {
        name
        shortName
        type
    }
`;

export const SuccessFactorsFragment = gql`
    fragment SuccessFactorsFields on SuccessFactorType {
        id
        name
        definition
        fpmCategory
        weight
        imported
    }
`;

export const TopicRiskFragment = gql`
    fragment TopicRiskFields on TopicRiskType {
        id
        riskId
        fpmCategory
        imported
    }
`;

export const TopicNameFragment = gql`
    fragment TopicNameFields on TopicType {
        id
        name
    }
`;

export const TopicWithSuccessFactorsFragment = gql`
    fragment TopicWithSuccessFactorsFields on TopicType {
        ...TopicNameFields
        categories {
            id
        }
        successFactors {
            ...SuccessFactorsFields
        }
        risks {
            ...TopicRiskFields
        }
    }
    ${TopicNameFragment}
    ${SuccessFactorsFragment}
    ${TopicRiskFragment}
`;

export const ResponseTableFragment = gql`
    fragment ResponseTableFields on ResponseType {
        id
        title
        pointOfContact
        researchCategoryId
        fundingRequested
        selectedStatus
        subCycleId
        organization {
            id
            name
        }
        topic {
            id
            name
            categories {
                name
            }
        }
        subCycle {
            id
            status
            formType
            name
            form {
                ... on RequestForInformationFormType {
                    researchDevelopmentCategories {
                        category {
                            id
                            name
                        }
                    }
                }
                ... on RequestForWhitePaperFormType {
                    researchDevelopmentCategories {
                        category {
                            id
                            name
                        }
                    }
                }
                ... on RequestForProposalFormType {
                    researchDevelopmentCategories {
                        category {
                            id
                            name
                        }
                    }
                }
            }
        }
        selfEvaluationFile {
            ...FileFields
        }
        responseFiles {
            ...FileFields
        }
        assignedEvaluators {
            id
            user {
                id
                firstName
                lastName
            }
            isTeamLead
            status
            unassigned
        }
        responderScores {
            successFactorId
            score
        }
        responderRisks {
            risk {
                name
            }
            fpmCategory
        }
    }
    ${FileFragment}
`;

export const ResponseUserScoreFragment = gql`
    fragment ResponseUserScoreFields on ResponseUserType {
        id
        userId
        user {
            fullName
        }
        response {
            title
            organization {
                name
            }
            topic {
                id
                subCycle {
                    id
                    form {
                        ... on RequestForInformationFormType {
                            hasBriefResponse
                        }
                    }
                }
                risks {
                    fpmCategory
                    risk {
                        name
                    }
                }
            }
            responseFiles {
                id
                fileName
            }
            responderScores {
                id
                successFactorId
                score
            }
            responderRisks {
                fpmCategory
                risk {
                    name
                }
            }
        }
        status
        isTeamLead
        reviewed
        evaluatorScores {
            id
            responseUserId
            successFactor {
                ...SuccessFactorsFields
            }
            score
            comment
        }
        responseUserFiles {
            ...FileFields
        }
    }
    ${SuccessFactorsFragment}
    ${FileFragment}
`;
