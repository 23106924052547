import { SelectionStatus } from '../../../apollo/generated/types/globalTypes';

export const getSelectionStatusEnum = (value: number): SelectionStatus | null => {
    switch (value) {
        case 1:
            return SelectionStatus.PENDING;
        case 2:
            return SelectionStatus.DOWN_SELECTION_SELECTED;
        case 3:
            return SelectionStatus.DOWN_SELECTION_REJECTED;
        case 4:
            return SelectionStatus.AWARD_SELECTED;
        case 5:
            return SelectionStatus.AWARD_REJECTED;
        default:
            return null;
    }
};

export const getSelectionStatusText = (value: SelectionStatus): string => {
    switch (value) {
        case SelectionStatus.PENDING:
            return 'Basket';
        case SelectionStatus.DOWN_SELECTION_SELECTED:
            return 'Selected';
        case SelectionStatus.AWARD_SELECTED:
            return 'Awarded';
        case SelectionStatus.DOWN_SELECTION_REJECTED:
        case SelectionStatus.AWARD_REJECTED:
            return 'Rejected';
    }
};
