import React from 'react';
import { StatusMenuOption } from '../index';
import { MenuItem } from '@material-ui/core';
import { CycleStatus } from '../../../apollo/generated/types/globalTypes';

const CycleMenuOption: React.FC<StatusMenuOption> = React.forwardRef(({ status, changeStatus }, ref) => {
    return (
        <>
            {status === CycleStatus.DRAFT ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(CycleStatus.PENDING_APPROVAL)}>
                    Submit for Approval
                </MenuItem>
            ) : null}
            {status === CycleStatus.PENDING_APPROVAL || status === CycleStatus.ACTIVE ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(CycleStatus.DRAFT)}>
                    Revert to Draft
                </MenuItem>
            ) : null}
            {status === CycleStatus.PENDING_APPROVAL ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(CycleStatus.ACTIVE)}>
                    Set as Approved
                </MenuItem>
            ) : null}
            {status === CycleStatus.ACTIVE ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(CycleStatus.PENDING_APPROVAL)}>
                    Revert to Pending Approval
                </MenuItem>
            ) : null}
        </>
    );
});
export default CycleMenuOption;
