import React, { useState } from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { TabPanelProps } from '../../TabPanel';
import { TabPanel } from '../../index';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage, LoadingMessage, ScreenMessage, TableActionMenu } from '../../../../index';
import { useHistory, useParams } from 'react-router-dom';
import { ScoreDisplay } from '../../../../ScoreDisplay';
import { ApolloError, useQuery } from '@apollo/client';
import {
    GetResponseScoreTable,
    GetResponseScoreTableVariables,
    GetResponseScoreTable_responses,
    GetResponseScoreTable_responses_assignedEvaluators_evaluatorScores,
} from '../../../../../apollo/generated/types/GetResponseScoreTable';
import { GET_RESPONSE_SCORE_TABLE } from '../../../../../apollo/queries';
import { FundamentalPrimeMeasurement, ResponseStatus } from '../../../../../apollo/generated/types/globalTypes';
import { Typography } from '@material-ui/core';
import { CycleSubTab, CycleTab } from '../../../../../utils/Enums/TabEnum';

interface URLParams {
    id: string; // Acquisition Cycle ID
}

interface ResponseTableItem {
    id: number;
    title: string;
    organization: string;
    appeal: number;
    value: number;
    reliability: number;
    people: number;
    planningProcess: number;
    finances: number;
    sizeScope: number;
    marketDemand: number;
    delivery: number;
}

const useStyles = makeStyles({
    centerHeader: {
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
});

export type FinalScoresCompleteTabPanelProps = TabPanelProps;
const FinalScoresCompleteTabPanel: React.FC<FinalScoresCompleteTabPanelProps> = ({ index, value, ...other }) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const cycleId = parseInt(id);
    const [responses, setResponses] = useState<ResponseTableItem[]>([]);

    const handleEvaluationsClick = (responseId: number) => {
        history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.FinalizedResponses}/${responseId}/evaluations`);
    };

    /**
     * Filters out scores within a particular Fundamental Prime Measurement category and then
     * calculates the weighted average of those scores.
     * @param scores List of evaluator scores from within the response
     * @param fpmCategory Fundamental Prime Measurement to filter by
     * @returns Weighted Average of the filtered scores
     */
    const filterAndAverage = (
        scores: GetResponseScoreTable_responses_assignedEvaluators_evaluatorScores[],
        fpmCategory: FundamentalPrimeMeasurement,
    ): number => {
        let score = 0;
        let weight = 0;
        scores
            .filter((scoreItem) => scoreItem.successFactor.fpmCategory === fpmCategory)
            .map((scoreItem) => {
                const weightVal = parseFloat(scoreItem.successFactor.weight);
                score = score + parseFloat(scoreItem.score) * weightVal;
                weight = weight + weightVal;
            });

        let weightedScoreAvg = parseFloat((score / weight).toFixed(1));
        weightedScoreAvg = isNaN(weightedScoreAvg) ? 0 : weightedScoreAvg;

        return weightedScoreAvg;
    };
    /**
     * Generates response score data for the Finalized Responses table.
     * @param finalizedResponses List of finalized responses
     * @returns Response items with weighted average scores from the Team Lead evaluation
     */
    const mapFinalizedResponses = (finalizedResponses: GetResponseScoreTable_responses[]): ResponseTableItem[] => {
        return finalizedResponses.map((response) => {
            const teamLeadScores = response.assignedEvaluators.find((ae) => ae.isTeamLead)?.evaluatorScores ?? [];

            return {
                id: response.id,
                title: response.title,
                organization: response.organization.name,
                appeal: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.APPEAL),
                value: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.VALUE),
                reliability: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.RELIABILITY),
                people: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.PERSONNEL),
                planningProcess: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.PROCESSES),
                finances: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.FINANCES),
                sizeScope: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.APPLICATION_SIZE),
                marketDemand: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.DEMAND),
                delivery: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.DELIVERY),
            } as ResponseTableItem;
        });
    };

    //// Loads currently Active Sub-Cycle and Responses ////
    const { loading: responseTableLoading, error: responseTableError } = useQuery<GetResponseScoreTable, GetResponseScoreTableVariables>(
        GET_RESPONSE_SCORE_TABLE,
        {
            variables: {
                cycleId: cycleId,
            },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data: GetResponseScoreTable) => {
                if (data && data.cycle && data.cycle.subCycles.length > 0) {
                    // Isolate Complete responses
                    const finalizedResponses = data?.responses?.filter(
                        (response) =>
                            response.subCycle.id === data.cycle?.subCycles[0].id &&
                            response.assignedEvaluators.some(
                                (evaluation) => evaluation.status === ResponseStatus.COMPLETE || evaluation.status === ResponseStatus.LOCKED,
                            ),
                    );
                    setResponses(mapFinalizedResponses(finalizedResponses ?? []));
                } else {
                    console.log('Error retrieving Response data');
                }
            },
            onError: (err: ApolloError) => {
                if (err.graphQLErrors) {
                    const hasAuthenticationError = err.graphQLErrors.some(
                        (e) => e.extensions?.code.toUpperCase() === 'AUTHORIZATION' || e.extensions?.code.toUpperCase() === 'AUTHENTICATION',
                    );
                    if (hasAuthenticationError) {
                        history.push('/acquisition-cycles');
                    }
                }
                console.error(err);
            },
        },
    );

    // Column definition
    const columns = [
        {
            name: 'id',
            label: 'Response ID',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'title',
            label: 'Response Title',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'organization',
            label: 'Organization',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'appeal',
            label: 'Appeal',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'value',
            label: 'Value',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'reliability',
            label: 'Reliability',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'people',
            label: 'People',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'planningProcess',
            label: 'Planning / Process',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'finances',
            label: 'Finances',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'sizeScope',
            label: 'Size / Scope',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'marketDemand',
            label: 'Market Demand',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'delivery',
            label: 'Delivery (Market Access)',

            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                display: true,
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex: number, _rowIndex: number) => {
                    if (dataIndex < responses.length) {
                        const row = responses[dataIndex];
                        return <TableActionMenu id={row.id} index={dataIndex} showView={true} onViewClick={handleEvaluationsClick} />;
                    }

                    return null;
                },
            },
        },
    ];
    // Table options definition
    const options = {
        enableNestedDataAccess: '.',
        responsive: 'simple',
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRowsHeader: false,
        selectableRows: 'none',
        onRowsDelete: () => false,
        selectToolbarPlacement: 'above',
        filter: false,
        search: false,
        pagination: false,
    } as MUIDataTableOptions;

    return (
        <TabPanel index={index} value={value} {...other}>
            {responseTableLoading ? (
                <LoadingMessage />
            ) : responseTableError ? (
                <ErrorMessage error={responseTableError} />
            ) : responses ? (
                <MUIDataTable title="" columns={columns} data={responses} options={options} />
            ) : (
                <ScreenMessage header="Welcome!" subHeader="It seems there is no data for Response Management to load">
                    <Typography> Please contact an admin </Typography>
                </ScreenMessage>
            )}
        </TabPanel>
    );
};

export default FinalScoresCompleteTabPanel;
