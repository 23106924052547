import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLazyQuery, useMutation } from '@apollo/client';
import { REQUEST_UPLOAD_TOKEN } from '../../apollo/mutations';
import { RequestUploadToken, RequestUploadTokenVariables } from '../../apollo/generated/types/RequestUploadToken';
import { downloadBlobFromStorage, uploadBlobsToStorage } from '../../utils/file-storage-util';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { GET_FILE_DOWNLOAD_LINK, GET_SUB_CYCLE_FILE, GET_TOKEN_DOWNLOAD_LINK } from '../../apollo/queries';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { GetSubCycleFile, GetSubCycleFileVariables } from '../../apollo/generated/types/GetSubCycleFile';
import { FileFields } from '../../apollo/generated/types/FileFields';
import ModalDeleteConfirmDialog from './ModalDeleteConfirmDialog';
import { GetTokenDownloadLink, GetTokenDownloadLinkVariables } from '../../apollo/generated/types/GetTokenDownloadLink';
import { GetFileDownloadLink, GetFileDownloadLinkVariables } from '../../apollo/generated/types/GetFileDownloadLink';
import { FileUploadInputType } from '../../apollo/generated/types/globalTypes';

const useStyles = makeStyles({
    base: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    altStyle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#1A2A3D',
        fontWeight: 'bold',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    active: {
        borderColor: '#2196f3',
    },
    accept: {
        borderColor: '#00e676',
    },
    reject: {
        borderColor: '#ff1744',
    },
});

const SingleFileDropZone: React.FC<SingleFileDropZoneProps> = ({
    id,
    name,
    rules,
    onChange,
    hidden,
    label,
    altStyle,
    browseBtn,
    fileType,
    fileListHeader,
    readOnly,
    onFileUpload = null,
}) => {
    const classes = useStyles();
    const { control, setValue, watch } = useFormContext();
    const [confirm, setConfirm] = useState(false);
    const [uploadedFile, setUploadedFile] = useState<FileInfo | undefined>();
    const [existingFile, setExistingFile] = useState<FileFields | undefined>();

    const [getFile, { loading, called }] = useLazyQuery<GetSubCycleFile, GetSubCycleFileVariables>(GET_SUB_CYCLE_FILE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data && data.subCycleFile) {
                setExistingFile(data.subCycleFile);
            } else {
                setExistingFile(undefined);
            }
        },
        onError: (error) => {
            setExistingFile(undefined);
            console.log(error);
        },
    });
    const [requestUploadToken, { error }] = useMutation<RequestUploadToken, RequestUploadTokenVariables>(REQUEST_UPLOAD_TOKEN);
    const [getTokenDownloadLink] = useLazyQuery<GetTokenDownloadLink, GetTokenDownloadLinkVariables>(GET_TOKEN_DOWNLOAD_LINK, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (result) => {
            if (result.uploadTokenURL && uploadedFile) {
                downloadBlobFromStorage(uploadedFile.fileName, result.uploadTokenURL.sasUri);
            }
        },
        onError: (error) => {
            console.log(error.message);
        },
    });
    const [getFileDownloadLink] = useLazyQuery<GetFileDownloadLink, GetFileDownloadLinkVariables>(GET_FILE_DOWNLOAD_LINK, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (result) => {
            if (result.subCycleFile) {
                downloadBlobFromStorage(result.subCycleFile.fileName, result.subCycleFile.url);
            }
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const onDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            try {
                const file = acceptedFiles[0];
                const tokenResult = await requestUploadToken({
                    variables: {
                        input: {
                            subCycleId: id,
                            fileName: file.name,
                            fileSize: file.size,
                        },
                    },
                });

                const uploadTokenRequest = tokenResult.data?.uploadTokenRequest;
                if (uploadTokenRequest) {
                    await uploadBlobsToStorage(file, uploadTokenRequest.sasUri);
                    setUploadedFile({ fileName: file.name, fileSize: file.size, token: uploadTokenRequest.token });
                    setValue(name, { uploadToken: uploadTokenRequest.token });

                    // If file was uploaded correctly, call onFileUpload function
                    if (onFileUpload) onFileUpload(file);
                } else {
                    setUploadedFile(undefined);
                    setValue(name, {});
                    console.error('Invalid uploadTokenRequest');
                }
            } catch (e) {
                setUploadedFile(undefined);
                setValue(name, {});
                console.error(e);
            }
        }
    };
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        maxFiles: 1,
        onDrop: onDrop,
        noClick: browseBtn,
        accept: fileType ? fileType : undefined,
        disabled: readOnly,
    });

    /**
     * Watch for file id to change
     */
    const fileInput = watch(name);
    useEffect(() => {
        // Form was reset
        if (fileInput && fileInput.id) {
            setUploadedFile(undefined);
            getFile({
                variables: {
                    id: Number(fileInput.id),
                },
            });
        } else if (fileInput && fileInput.uploadToken) {
            setExistingFile(undefined);
        } else {
            setUploadedFile(undefined);
            setExistingFile(undefined);
        }
        if (onChange) {
            onChange(fileInput);
        }
    }, [fileInput, getFile, onChange]);

    const onDelete = () => {
        setValue(name, {});
        setUploadedFile(undefined);
        setExistingFile(undefined);
        setConfirm(false);
    };

    const downloadFile = (id: number) => {
        getFileDownloadLink({
            variables: {
                id: id,
            },
        });
    };

    const downloadTempFile = (uploadToken: string) => {
        getTokenDownloadLink({
            variables: {
                token: uploadToken,
            },
        });
    };

    return (
        <Controller
            render={({ value }) =>
                hidden ? (
                    <></>
                ) : (
                    <>
                        {(value.id || value.uploadToken) && fileListHeader}
                        {value.id && (
                            <List dense={true} disablePadding={true}>
                                <ListItem button onClick={() => downloadFile(value.id)}>
                                    <ListItemIcon>{loading || !called ? <CircularProgress /> : <AttachFileIcon />}</ListItemIcon>
                                    <ListItemText primary={loading || !called ? 'Getting file information...' : existingFile?.fileName} />
                                    {!loading && called && (
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)}>
                                                <DeleteIcon />
                                            </IconButton>
                                            <ModalDeleteConfirmDialog
                                                open={confirm}
                                                fileName={existingFile?.fileName || ''}
                                                onPrimaryClick={onDelete}
                                                onSecondaryClick={() => setConfirm(false)}
                                                onClose={() => setConfirm(false)}
                                            />
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            </List>
                        )}
                        {value.uploadToken && (
                            <List dense={true} disablePadding={true}>
                                <ListItem button onClick={() => downloadTempFile(value.uploadToken)}>
                                    <ListItemIcon>
                                        <AttachFileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={uploadedFile?.fileName} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <ModalDeleteConfirmDialog
                                            open={confirm}
                                            fileName={uploadedFile?.fileName || ''}
                                            onPrimaryClick={onDelete}
                                            onSecondaryClick={() => setConfirm(false)}
                                            onClose={() => setConfirm(false)}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        )}
                        {!value.id && !value.uploadToken && (
                            <div
                                {...getRootProps({
                                    className: `${altStyle ? classes.altStyle : classes.base} ${isDragActive ? classes.active : ''} ${
                                        isDragAccept ? classes.accept : ''
                                    } ${isDragReject ? classes.reject : ''}`,
                                })}>
                                <input {...getInputProps()} />
                                <p>{label ? label : "Drag 'n' drop some files here, or click to select files"}</p>
                                {browseBtn && (
                                    <Button
                                        style={{ background: '#f5f5f5', color: '#1A2A3D', fontWeight: 'bold' }}
                                        variant="contained"
                                        type="button"
                                        onClick={open}>
                                        <p style={{ fontSize: '13px' }} className="btn">
                                            Browse files
                                        </p>
                                    </Button>
                                )}
                                {error ? (
                                    <Grid container xs={12}>
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                <AlertTitle>File Upload Failed</AlertTitle>
                                                {error?.message}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </div>
                        )}
                    </>
                )
            }
            name={name}
            control={control}
            defaultValue={{}}
            rules={rules}
        />
    );
};

interface FileInfo {
    fileName: string;
    fileSize: number;
    token: string;
}

export interface SingleFileDropZoneProps {
    id: number;
    name: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    onChange?: (value: FileUploadInputType) => void;
    hidden?: boolean;
    label?: string;
    altStyle?: boolean;
    browseBtn?: boolean;
    fileType?: string;
    fileListHeader?: JSX.Element;
    onFileUpload?: (fileData: File) => void;
    readOnly?: boolean;
}

export default SingleFileDropZone;
