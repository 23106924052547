import * as React from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_RFI_FORM_OPTIONS } from '../../../apollo/queries';
import { GetRFIFormOptions } from '../../../apollo/generated/types/GetRFIFormOptions';
import { OptionFields } from '../../../apollo/generated/types/OptionFields';
import { CREATE_FUNDING_SOURCE, CREATE_TECHNOLOGY_KEYWORD, CREATE_TIME_HORIZON } from '../../../apollo/mutations';
import { CreateTimeHorizon, CreateTimeHorizonVariables } from '../../../apollo/generated/types/CreateTimeHorizon';
import { CreateTechnologyKeyword, CreateTechnologyKeywordVariables } from '../../../apollo/generated/types/CreateTechnologyKeyword';
import { CreateFundingSource, CreateFundingSourceVariables } from '../../../apollo/generated/types/CreateFundingSource';

interface RFIFormContextProps {
    loading: boolean;
    error?: ApolloError;
    fundingSources?: OptionFields[];
    timeHorizons?: OptionFields[];
    technologyKeywords?: OptionFields[];

    addTimeHorizon: (name: string) => Promise<OptionFields | null>;
    addTechnologyKeyword: (name: string) => Promise<OptionFields | null>;
    addFundingSource: (name: string) => Promise<OptionFields | null>;
}

const RFIFormContext = createContext<RFIFormContextProps | undefined>(undefined);

const RFIFormProvider: React.FC = ({ children }) => {
    // Allows Manual Entry
    const [timeHorizons, setTimeHorizons] = useState<Array<OptionFields> | undefined>();
    const [technologyKeywords, setTechnologyKeywords] = useState<Array<OptionFields> | undefined>();
    const [fundingSources, setFundingSources] = useState<Array<OptionFields> | undefined>();

    const [createTimeHorizon] = useMutation<CreateTimeHorizon, CreateTimeHorizonVariables>(CREATE_TIME_HORIZON);
    const [createTechnologyKeyword] = useMutation<CreateTechnologyKeyword, CreateTechnologyKeywordVariables>(CREATE_TECHNOLOGY_KEYWORD);
    const [createFundingSource] = useMutation<CreateFundingSource, CreateFundingSourceVariables>(CREATE_FUNDING_SOURCE);

    const { loading, error, data } = useQuery<GetRFIFormOptions>(GET_RFI_FORM_OPTIONS, {
        fetchPolicy: 'cache-and-network',
    });

    // No Manual Entry

    useEffect(() => {
        setTimeHorizons(data?.timeHorizons as OptionFields[]);
        setTechnologyKeywords(data?.technologyKeywords as OptionFields[]);
        setFundingSources(data?.fundingSources as OptionFields[]);
    }, [data]);

    const addTimeHorizon = useCallback(
        async (name: string) => {
            const result = await createTimeHorizon({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createTimeHorizon) {
                const timeHorizon = result.data.createTimeHorizon;
                setTimeHorizons((currentList) => [...(currentList || []), timeHorizon as OptionFields]);
                return timeHorizon as OptionFields;
            }
            return null;
        },
        [createTimeHorizon],
    );

    const addTechnologyKeyword = useCallback(
        async (name: string) => {
            const result = await createTechnologyKeyword({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createTechnologyKeyword) {
                const technologyKeyword = result.data.createTechnologyKeyword;
                setTechnologyKeywords((currentList) => [...(currentList || []), technologyKeyword as OptionFields]);
                return technologyKeyword as OptionFields;
            }
            return null;
        },
        [createTechnologyKeyword],
    );

    const addFundingSource = useCallback(
        async (name: string) => {
            const result = await createFundingSource({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createFundingSource) {
                const fundingSource = result.data.createFundingSource;
                setFundingSources((currentList) => [...(currentList || []), fundingSource as OptionFields]);
                return fundingSource as OptionFields;
            }
            return null;
        },
        [createFundingSource],
    );

    return (
        <RFIFormContext.Provider
            value={{
                loading,
                error,
                fundingSources,
                timeHorizons,
                technologyKeywords,
                addTimeHorizon,
                addTechnologyKeyword,
                addFundingSource,
            }}>
            {children}
        </RFIFormContext.Provider>
    );
};

const useRFIForm = (): RFIFormContextProps => {
    const context = React.useContext(RFIFormContext);
    if (context === undefined) {
        throw new Error('useRFIForm must be used within a RFIFormProvider');
    }
    return context;
};

export { RFIFormProvider, useRFIForm };
