import React, { DetailedHTMLProps } from 'react';

export interface TabPanelProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    index: number | string;
    value: number | string;
}

const TabPanel: React.FC<TabPanelProps> = ({ index, value, children, ...other }) => {
    return (
        <div key={index} role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tabpanel-${index}`} {...other}>
            {value === index ? children : null}
        </div>
    );
};

export default TabPanel;
