import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import { GET_SERVICES } from '../../../../apollo/queries';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { GetServices, GetServicesVariables } from '../../../../apollo/generated/types/GetServices';

const ServiceSelect: React.FC<ServiceSelectProps> = ({ readOnly, required }) => {
    const { control, watch, getValues, errors } = useFormContext();
    const [departmentId, setDepartmentId] = useState<number>(getValues('departmentId'));
    const [getServices, { called, loading, error, data }] = useLazyQuery<GetServices, GetServicesVariables>(GET_SERVICES, {
        fetchPolicy: 'cache-and-network',
    });
    const watchDepartmentId = watch('departmentId');

    useEffect(() => {
        if (watchDepartmentId && watchDepartmentId !== departmentId) {
            setDepartmentId(watchDepartmentId);
        }
    }, [departmentId, watchDepartmentId]);

    useEffect(() => {
        if (departmentId) {
            getServices({ variables: { departmentId: departmentId } });
        }
    }, [called, departmentId, getServices]);

    const requiredAsterisk = required ? '*' : '';
    return (
        <ReactHookFormSelect
            fullWidth
            control={control}
            label={`Department Level II${requiredAsterisk}`}
            name="serviceId"
            defaultValue={getValues('serviceId')}
            disabled={!!error || !called}
            readOnly={readOnly}
            error={!!error || !!errors.serviceId}
            loading={loading || !called}
            helperText={error ? 'Unable to retrieve data' : errors.serviceId?.message}
            rules={{ required: required ? 'Department Level II is required' : false }}>
            {!data || !data.services || data.services.length <= 0 ? (
                <MenuItem disabled>No Data Available. Select another Department</MenuItem>
            ) : (
                data.services.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                        {service.name}
                    </MenuItem>
                ))
            )}
        </ReactHookFormSelect>
    );
};

export interface ServiceSelectProps {
    required: boolean;
    readOnly: boolean;
}

export default ServiceSelect;
