import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ConfirmDialog, LoadingButton, useTemplate } from '../../../index';
import { ApolloError, useMutation } from '@apollo/client';
import { CreateTemplate, CreateTemplateVariables } from '../../../../apollo/generated/types/CreateTemplate';
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from '../../../../apollo/mutations';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { SubCycleInputType, TemplateSubCycleInputType } from '../../../../apollo/generated/types/globalTypes';
import { UpdateTemplate, UpdateTemplateVariables } from '../../../../apollo/generated/types/UpdateTemplate';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles({
    saveTemplateButton: {
        marginRight: '12px',
        height: '34px',
        padding: '8px 16px',
    },
});

const SaveTemplateButton: React.FC = () => {
    const classes = useStyles();
    const { getValues, setValue } = useFormContext();
    const { add } = useTemplate();

    const [showError, setShowError] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const [createError, setCreateError] = useState<ApolloError | null>(null);
    const [updateError, setUpdateError] = useState<ApolloError | null>(null);
    const [dialogValue, setDialogValue] = useState({ name: '' });
    const [createTemplate, { loading: createLoading }] = useMutation<CreateTemplate, CreateTemplateVariables>(CREATE_TEMPLATE);
    const [updateTemplate, { loading: updateLoading }] = useMutation<UpdateTemplate, UpdateTemplateVariables>(UPDATE_TEMPLATE);

    const OnSaveTemplate = () => {
        const templateId = getValues('templateId');
        if (templateId) {
            saveTemplateChanges(templateId);
        } else {
            setOpen(true);
        }
    };

    const saveTemplateChanges = async (id: number) => {
        try {
            const cycle = getValues();
            await updateTemplate({
                variables: {
                    template: {
                        id: id,
                        templateSubCycles: cycle.subCycles.map((subCycle: SubCycleInputType) => {
                            return {
                                name: subCycle.name,
                                order: subCycle.order,
                                formType: subCycle.formType,
                            } as TemplateSubCycleInputType;
                        }),
                    },
                },
            });
            setShowSuccess(true);
        } catch (e) {
            setUpdateError(e);
            setShowError(true);
        }
    };

    /**
     * Handle Close of Add Organization Popup
     */
    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccessClose = () => {
        setShowSuccess(false);
    };

    /**
     * Handle Submit of Add Organization Popup
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const cycle = getValues();
            const result = await createTemplate({
                variables: {
                    template: {
                        name: dialogValue.name,
                        templateSubCycles: cycle.subCycles.map((subCycle: SubCycleInputType) => {
                            return {
                                name: subCycle.name,
                                order: subCycle.order,
                                formType: subCycle.formType,
                            } as TemplateSubCycleInputType;
                        }),
                    },
                },
            });
            if (result.data?.createTemplate) {
                add(result.data?.createTemplate);
                setValue('templateId', result.data?.createTemplate.id);
            }
            setOpen(false);
        } catch (e) {
            setCreateError(e);
        }
    };

    return (
        <>
            <LoadingButton
                startIcon={<SaveIcon style={{ color: '#1976D2' }} />}
                pending={updateLoading}
                className={classes.saveTemplateButton}
                onClick={OnSaveTemplate}>
                Save as Template
            </LoadingButton>
            <ConfirmDialog
                open={showError}
                icon={<ErrorIcon style={{ color: '#F63832', fontSize: 91 }} />}
                title="Error Saving Template"
                message={<Alert severity="error">{updateError?.message}</Alert>}
                primaryText="OK"
                showSecondary={false}
                onPrimaryClick={() => setShowError(false)}
                onClose={() => setShowError(false)}
                fullWidth={true}
                maxWidth="sm"
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth="sm">
                <form id="form-create-template" onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h1">Save as New Template</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="name"
                                    fullWidth
                                    value={dialogValue.name}
                                    onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                                    label="Name"
                                    type="text"
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {createError ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {createError?.message}
                                    </Alert>
                                </Grid>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="text">
                            Cancel
                        </Button>
                        <LoadingButton pending={createLoading} form="form-create-template" type="submit" variant="contained">
                            Add
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar open={showSuccess} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={handleSuccessClose} autoHideDuration={5000}>
                <Alert onClose={handleSuccessClose} severity="success" elevation={3}>
                    Template Updated!
                </Alert>
            </Snackbar>
        </>
    );
};

export default SaveTemplateButton;
