import * as React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import DepartmentSelect from './DepartmentSelect';
import ServiceSelect from './ServiceSelect';
import SubServiceSelect from './SubServiceSelect';
import { useFormContext } from 'react-hook-form';
import ContactSelect from './ContactSelect';
import { ReactHookFormDate, ReactHookFormTextArea } from '../../../ReactHookForm';
import { FormMode } from '../../../../utils/Enums';
import EligibleRespondersSelect from './EligibleRespondersSelect';
import { CycleStatus, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const GeneralForm: React.FC<GeneralFormProps> = ({ mode }) => {
    const { register, control, getValues, watch, errors } = useFormContext();
    const status = watch('status', CycleStatus.DRAFT);

    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">General</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <TextField
                        label={`Acquisition Cycle Title${requiredAsterisk}`}
                        name="title"
                        inputRef={register({ required: 'Title is required' })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={`Acquisition Cycle ID${requiredAsterisk}`}
                        name="externalId"
                        inputRef={register({ required: required ? 'Acquisition Cycle ID is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.externalId}
                        helperText={errors.externalId?.message}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ContactSelect required={required} readOnly={readOnly} />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label={`Estimated Closing Date${requiredAsterisk}`}
                        name="estimatedCloseDate"
                        readOnly={readOnly}
                        error={!!errors.estimatedCloseDate}
                        helperText={errors.estimatedCloseDate?.message}
                        defaultValue={getValues('estimatedCloseDate')}
                        rules={{ required: required ? 'Estimated Closing Date is required' : false }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DepartmentSelect required={required} readOnly={readOnly} />
                </Grid>
                <Grid item xs={6}>
                    <ServiceSelect required={required} readOnly={readOnly} />
                </Grid>
                <Grid item xs={6}>
                    <SubServiceSelect required={required} readOnly={readOnly} />
                </Grid>
                <Grid item xs={6}>
                    <EligibleRespondersSelect readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label={`Distribution Statement${requiredAsterisk}`}
                        name="distributionStatement"
                        defaultValue="<p>DISTRIBUTION STATEMENT D. Distribution authorized to Department of Defense and U.S. DoD contractors only; Operational Use; November 2020. Other requests for this document shall be referred to the DOTC Program Director at (973) 724-4485.</p>"
                        error={!!errors.distributionStatement}
                        helperText={errors.distributionStatement?.message}
                        readOnly={readOnly}
                        rules={{
                            required: required ? 'Distribution Statement must have some content.' : false,
                            validate: (value) => {
                                return !required || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Distribution Statement must have some content.';
                            },
                        }}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface GeneralFormProps {
    mode: FormMode;
}

export default GeneralForm;
