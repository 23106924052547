/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Typography, Grid, Paper, Tabs, Tab } from '@material-ui/core';
import { LoadingButton } from '../../../Buttons';
import FormDivider from '../../../Form/FormDivider';
import { TabPanel } from '../../index';
import TabPanels from './TabPanels';
import TransitionModal from './TransitionModal';
import { SubCycleForm, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import { UPDATE_SUB_CYCLE_STATUS } from '../../../../apollo/mutations';
import { UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables } from '../../../../apollo/generated/types/UpdateSubCycleFormStatus';
import { useDownSelection } from '../../../Providers';
import { usePermissions } from '../../../Providers/PermissionProvider';
import { LoadingMessage } from '../../..';
import { useCycleData } from '../../../Providers/CycleDataProvider';

const DownSelectionTabPanel: React.FC<any> = ({ index, value, ...other }) => {
    const { items, responsesLoading, tabs, researchDevelopmentCategories, pending, selected, rejected, subCycleId, cycleId } = useDownSelection();
    const { isTeamLead, isSystemProgramManagement, isEvaluator } = usePermissions();
    const { readOnly: cycleReadOnly } = useCycleData();

    const [modal, setModal] = useState(false);
    let finalized = 0;
    let downSelected = 0;
    if (items) {
        finalized = selected + pending + rejected;
        downSelected = selected + rejected;
    }

    const readOnly = cycleReadOnly || !(isSystemProgramManagement() || isTeamLead(cycleId));

    const [categoryTab, setCategoryTab] = useState(0);

    const handleChange = (_, newValue: number) => {
        setCategoryTab(newValue);
    };

    const handleModalClose = (complete: boolean) => {
        setModal(false);
        if (complete) {
            updateSubCycleStatus({
                variables: {
                    id: subCycleId,
                    status: SubCycleStatus.COMPLETE,
                },
            });
        }
    };

    const [updateSubCycleStatus, { loading: updateLoading }] = useMutation<UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables>(
        UPDATE_SUB_CYCLE_STATUS,
    );

    return (
        <TabPanel index={index} value={value} {...other}>
            {!items ? (
                <LoadingMessage />
            ) : (
                <>
                    <Paper>
                        <Grid container spacing={2} style={{ padding: '20px' }}>
                            <Grid item xs={10} style={{ margin: 'auto' }}>
                                <Typography variant="h2">Down-Selection</Typography>
                                <Grid style={{ marginTop: 'auto', color: '#A0A0A0' }}>
                                    <Typography variant="body2">{`Finalized Responses: ${finalized} / ${items.length} `}</Typography>
                                </Grid>
                                <Grid style={{ marginTop: 'auto', color: '#A0A0A0' }}>
                                    <Typography variant="body2">{`Down-Selected Responses: ${downSelected} / ${items.length}`}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{ textAlign: 'right' }}>
                                <LoadingButton
                                    variant="contained"
                                    disabled={cycleReadOnly || items.length === 0 || downSelected !== items.length || isEvaluator(cycleId)}
                                    pending={updateLoading}
                                    onClick={() => setModal(true)}>
                                    Move to Next Sub-Cycle
                                </LoadingButton>
                            </Grid>
                        </Grid>
                        <Tabs value={categoryTab} aria-label="Down Selection Tabs" onChange={handleChange} style={{ marginBottom: '-2px' }}>
                            {!responsesLoading &&
                                researchDevelopmentCategories &&
                                researchDevelopmentCategories
                                    ?.filter((i) => tabs?.some((j) => j === i.category.id))
                                    .map((i) => (
                                        <Tab
                                            label={i.category.name}
                                            id={`down-select-tab-${index}`}
                                            aria-controls={`down-select-tab-panel-${index}`}
                                            key={i.name}
                                        />
                                    ))}
                        </Tabs>
                        <FormDivider />
                        {tabs
                            ?.filter((i) => items.some((j) => j.researchCategoryId === i))
                            .map((i, index) => {
                                const tabData = items.filter((j) => j.researchCategoryId === i);
                                return (
                                    <TabPanels
                                        data={tabData}
                                        index={index}
                                        value={categoryTab}
                                        id={`down-selection-tab-panel-${index}`}
                                        aria-labelledby={`down-selection-tab-${index}`}
                                        key={index}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                    </Paper>
                    {items && items.length > 0 && (
                        <TransitionModal
                            open={modal}
                            onClose={handleModalClose}
                            formType={items[0].topic.subCycle.formType as SubCycleForm}
                            downSelected={selected}
                        />
                    )}{' '}
                </>
            )}
        </TabPanel>
    );
};

export default DownSelectionTabPanel;
