import React from 'react';
import { Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PageWrapper, PageHeader, PageBody } from '../components';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
    },
});

const RespondersPage: React.FC = () => {
    const classes = useStyles();
    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <Typography variant="h1" className={classes.title}>
                        Responders
                    </Typography>
                </Toolbar>
            </PageHeader>
            <PageBody>
                <div>This is the list of Responders</div>
            </PageBody>
        </PageWrapper>
    );
};

export default RespondersPage;
