import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { ReactHookFormCheckbox, ReactHookFormTextArea, ReactHookFormDateTime, ReactHookFormTimezone } from '../../../ReactHookForm';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const DatesAndBudgetsForm: React.FC<DatesAndBudgetsFormProps> = ({ mode }) => {
    const { control, errors, watch } = useFormContext();
    const hasCostSharing = watch('hasCostSharing', true);
    const status = watch('status');

    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Dates & Budgets</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ opacity: '0.9' }}>
                        Publish
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label="Updated Published Date & Time"
                        name="updatedPublishedDateTime"
                        defaultValue={null}
                        error={!!errors.updatedPublishedDateTime}
                        helperText={errors.updatedPublishedDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label="Updated Published Timezone"
                        name="updatedPublishedTimeZone"
                        error={!!errors.updatedPublishedTimeZone}
                        helperText={errors.updatedPublishedTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label={`Original Published Date & Time${requiredAsterisk}`}
                        name="originalPublishedDateTime"
                        defaultValue={null}
                        rules={{ required: required ? 'Original Published Date & Time is required' : false }}
                        error={!!errors.originalPublishedDateTime}
                        helperText={errors.originalPublishedDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label={`Original Published Timezone${requiredAsterisk}`}
                        name="originalPublishedTimeZone"
                        rules={{ required: required ? 'Original Published Timezone is required' : false }}
                        error={!!errors.originalPublishedTimeZone}
                        helperText={errors.originalPublishedTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ opacity: '0.9' }}>
                        Response
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label="Updated Response Date & Time"
                        name="updatedResponseDateTime"
                        defaultValue={null}
                        error={!!errors.updatedResponseDateTime}
                        helperText={errors.updatedResponseDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label="Updated Response Timezone"
                        name="updatedResponseTimeZone"
                        error={!!errors.updatedResponseTimeZone}
                        helperText={errors.updatedResponseTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label={`Original Response Date & Time${requiredAsterisk}`}
                        name="originalResponseDateTime"
                        defaultValue={null}
                        rules={{ required: required ? 'Original Response Date & Time is required' : false }}
                        error={!!errors.originalResponseDateTime}
                        helperText={errors.originalResponseDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label={`Original Response Timezone${requiredAsterisk}`}
                        name="originalResponseTimeZone"
                        rules={{ required: required ? 'Original Response Timezone is required' : false }}
                        error={!!errors.originalResponseTimeZone}
                        helperText={errors.originalResponseTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ opacity: '0.9' }}>
                        Inactive
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label="Updated inactive Date & Time"
                        name="updatedInactiveDateTime"
                        defaultValue={null}
                        error={!!errors.updatedInactiveDateTime}
                        helperText={errors.updatedInactiveDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label="Updated inactive Timezone"
                        name="updatedInactiveTimeZone"
                        error={!!errors.updatedInactiveTimeZone}
                        helperText={errors.updatedInactiveTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label={`Original inactive Date & Time${requiredAsterisk}`}
                        name="originalInactiveDateTime"
                        defaultValue={null}
                        rules={{ required: required ? 'Original inactive Date & Time is required' : false }}
                        error={!!errors.originalInactiveDateTime}
                        helperText={errors.originalInactiveDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        label={`Original inactive Timezone${requiredAsterisk}`}
                        name="originalInactiveTimeZone"
                        rules={{ required: required ? 'Original inactive Timezone is required' : false }}
                        error={!!errors.originalInactiveTimeZone}
                        helperText={errors.originalInactiveTimeZone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasCostSharing"
                        label="Cost Share / Matching Funds"
                        defaultValue={true}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label={`Cost Sharing Description${requiredAsterisk}`}
                        name="costSharingDescription"
                        defaultValue=""
                        rules={{
                            required: required && hasCostSharing ? 'Cost Sharing Description must have some content.' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !hasCostSharing ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Cost Sharing Description must have some content.'
                                );
                            },
                        }}
                        error={!!errors.costSharingDescription}
                        helperText={errors.costSharingDescription?.message}
                        readOnly={readOnly}
                        hidden={!hasCostSharing}
                    />
                </Grid>
            </FormSection>
        </>
    );
};
export interface DatesAndBudgetsFormProps {
    mode: FormMode;
}
export default DatesAndBudgetsForm;
