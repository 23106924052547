import * as React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    section: {
        padding: '26px 30px',
    },
});

const FormSection: React.FC<GridProps> = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.section}>
            <Grid container spacing={2} {...props}>
                {children}
            </Grid>
        </div>
    );
};

export default FormSection;
