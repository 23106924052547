import { SubCycleStatus } from '../../../apollo/generated/types/globalTypes';

export const getSubCycleStatusDisplayName = (value: string): string => {
    switch (value) {
        case SubCycleStatus.DRAFT:
            return 'Draft';
        case SubCycleStatus.PENDING_APPROVAL:
            return 'Pending Approval';
        case SubCycleStatus.APPROVED:
            return 'Approved';
        case SubCycleStatus.OUT_FOR_RESPONSE:
            return 'Out for Response';
        case SubCycleStatus.COMPLETE:
            return 'Completed';
        default:
            return '';
    }
};
