import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormCheckbox } from '../../../..';

const SubCycleOption: React.FC<SubCycleOptionProps> = ({ id, name, selected, index }) => {
    const { register, control } = useFormContext();

    return (
        <>
            {id ? <input name={`selectedSubCycles[${index}].subCycleId`} type="hidden" ref={register()} defaultValue={id} /> : null}
            {name ? <input name={`selectedSubCycles[${index}].subCycleName`} type="hidden" ref={register()} defaultValue={name} /> : null}
            <ReactHookFormCheckbox name={`selectedSubCycles[${index}].selected`} label={name ?? ''} control={control} defaultValue={selected ?? false} />
        </>
    );
};

export interface SubCycleOptionProps {
    id: number | undefined;
    name: string | null | undefined;
    selected: boolean | undefined;
    index: number;
}

export default SubCycleOption;
