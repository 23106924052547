import React, { useState } from 'react';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalDeleteConfirmDialog from '../ModalDeleteConfirmDialog';
import { useLazyQuery } from '@apollo/client';
import { GET_TOKEN_DOWNLOAD_LINK } from '../../../apollo/queries';
import { downloadBlobFromStorage } from '../../../utils/file-storage-util';
import { GetTokenDownloadLink, GetTokenDownloadLinkVariables } from '../../../apollo/generated/types/GetTokenDownloadLink';

const TempFileDisplay: React.FC<TempFileDisplayProps> = ({ index, uploadToken, name, fileName, onDelete, openFile = false }) => {
    const { register } = useFormContext();
    const [confirm, setConfirm] = useState(false);

    const [getTokenDownloadLink] = useLazyQuery<GetTokenDownloadLink, GetTokenDownloadLinkVariables>(GET_TOKEN_DOWNLOAD_LINK, {
        fetchPolicy: 'cache-and-network',
        variables: {
            token: uploadToken,
        },
        onCompleted: (result) => {
            if (result.uploadTokenURL) {
                downloadBlobFromStorage(fileName, result.uploadTokenURL.sasUri, openFile);
            }
        },
        onError: (error) => {
            console.log(error.message);
        },
    });

    const handleConfirm = () => {
        setConfirm(false);
        onDelete(index);
    };

    const download = () => {
        getTokenDownloadLink();
    };

    return (
        <ListItem button onClick={download}>
            <ListItemIcon>
                <AttachFileIcon />
            </ListItemIcon>
            <ListItemText primary={fileName} />
            <ListItemSecondaryAction>
                <input name={`${name}[${index}].uploadToken`} ref={register()} type="hidden" value={uploadToken} />
                <IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)}>
                    <DeleteIcon />
                </IconButton>
                <ModalDeleteConfirmDialog
                    open={confirm}
                    fileName={fileName}
                    onPrimaryClick={handleConfirm}
                    onSecondaryClick={() => setConfirm(false)}
                    onClose={() => setConfirm(false)}
                />
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export interface TempFileDisplayProps {
    index: number;
    uploadToken: string;
    fileName: string;
    name: string;
    onDelete: (index: number) => void;
    openFile?: boolean;
}

export default TempFileDisplay;
