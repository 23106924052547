import { CycleStatus } from '../../../apollo/generated/types/globalTypes';

export const getCycleStatusDisplayName = (value: string): string => {
    switch (value) {
        case CycleStatus.DRAFT:
            return 'Draft';
        case CycleStatus.PENDING_APPROVAL:
            return 'Pending Approval';
        case CycleStatus.ACTIVE:
            return 'Active';
        case CycleStatus.ARCHIVED:
            return 'Archived';
        case CycleStatus.COMPLETED:
            return 'Completed';
        default:
            return '';
    }
};
