import React, { useCallback, useState } from 'react';
import { Button, createStyles, Grid, IconButton, Paper, Popover, Theme, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import SubCycleItem from './SubCycleItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TemplateProvider } from '../../../index';
import { SubCycleInputType, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';
import SaveTemplateButton from './SaveTemplateButton';
import TemplateSelect from './TemplateSelect';
import { TemplateFields } from '../../../../apollo/generated/types/TemplateFields';
import { SubCycleFields } from '../../../../apollo/generated/types/SubCycleFields';
import ConfirmDialog from '../../../Dialogs/ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            padding: theme.spacing(2),
        },
        popover: {
            pointerEvents: 'none',
        },
        saveTemplateButton: {
            marginRight: '12px',
            height: '34px',
            padding: '8px 16px',
        },
        expandButton: {
            marginRight: '12px',
        },
        template: {
            display: 'flex',
        },
        templateButton: {
            width: '100px',
        },
        centerItems: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
        },
        dialogDescription: {
            color: '#1A2A3D',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
        },
    }),
);

enum ActionType {
    None = 0,
    Remove = 1,
    Order = 2,
}

interface ConfirmModalProps {
    index: number;
    startIndex: number;
    endIndex: number;
    action: ActionType;
    open: boolean;
}

const SubCyclesForm: React.FC<SubCyclesFormProps> = ({ mode, subCyclesData }) => {
    const classes = useStyles();
    const readOnly = mode === FormMode.View;
    const { getValues, setValue, control, trigger, formState } = useFormContext();
    const { isSubmitted } = formState;
    const [confirmModal, setConfirmModal] = useState<ConfirmModalProps>({ index: 0, startIndex: 0, endIndex: 0, open: false, action: ActionType.None });
    const [warningIndex, setWarningIndex] = useState<number | null>(null);
    const [startMoveIndex, setStartMoveIndex] = useState<number | null>(null);
    const [expand, setExpand] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [maxError, setMaxError] = useState<boolean>(false);
    const { fields: subCycles, append, move, remove } = useFieldArray<SubCycleInputType, 'key'>({
        control,
        name: 'subCycles', // unique name for your Field Array
        keyName: 'key',
    });

    const expandAll = () => {
        setExpand(true);
    };

    const collapseAll = () => {
        setExpand(false);
    };

    const addSubCycle = () => {
        if (!readOnly) {
            // Max of 10 SubCycles
            if (subCycles.length < 10) {
                append({
                    postedDate: new Date(),
                    lastUpdatedDate: new Date(),
                    originalClosingDate: new Date(),
                    currentClosingDate: new Date(),
                    originalArchiveDate: new Date(),
                    archiveDate: new Date(),
                });
            } else {
                setMaxError(true);
            }
        }
    };

    /**
     * Remove Sub Cycle
     */
    const removeSubCycle = useCallback(
        (index: number) => {
            if (!readOnly && subCycles.length > index) {
                const formSubCycle = subCycles[index];
                if (formSubCycle && formSubCycle.id) {
                    const subCycle = subCyclesData?.find((subCycle) => subCycle.id === formSubCycle.id);
                    if (subCycle) {
                        if (subCycle.status === SubCycleStatus.COMPLETE) {
                            return;
                        } else if (subCycle.status === SubCycleStatus.OUT_FOR_RESPONSE) {
                            // This Sub Cycle is Active so notify the user before removing
                            setConfirmModal({ index: index, startIndex: 0, endIndex: 0, open: true, action: ActionType.Remove });
                        } else {
                            remove(index);
                        }
                    } else {
                        remove(index);
                    }
                } else {
                    remove(index);
                }
            }
        },
        [readOnly, subCycles, subCyclesData, remove],
    );

    /**
     * Handle Click event to continue with  reordering or Removing sub-cycle
     */
    const handleConfirmSubmit = useCallback(() => {
        const { action, index } = confirmModal;
        if (action === ActionType.Remove) {
            // Confirm remove the item
            remove(index);
        }
        setConfirmModal({ index: 0, startIndex: 0, endIndex: 0, open: false, action: ActionType.None });
    }, [confirmModal, remove]);

    /**
     * User canceled or closed the confirmation modal on re-ordering or removing sub-cycle
     */
    const handleConfirmClose = useCallback(() => {
        const { action, startIndex, endIndex } = confirmModal;
        if (action === ActionType.Order) {
            // Move the item back
            move(endIndex, startIndex);
        }

        setConfirmModal({ index: 0, startIndex: 0, endIndex: 0, open: false, action: ActionType.None });
    }, [confirmModal, move]);

    const isLocked = useCallback(
        (id: number | undefined) => {
            if (!id) {
                return false;
            }
            const subCycle = subCyclesData?.find((subCycleData) => subCycleData.id === id);
            if (!subCycle) {
                return false;
            }
            return subCycle.status === SubCycleStatus.COMPLETE || subCycle.status === SubCycleStatus.OUT_FOR_RESPONSE;
        },
        [subCyclesData],
    );

    const canRemove = useCallback(
        (id: number | undefined) => {
            if (subCycles.length === 1) {
                return false;
            }
            if (!id) {
                return true;
            }
            const subCycle = subCyclesData?.find((subCycleData) => subCycleData.id === id);
            if (!subCycle) {
                return true;
            }
            return subCycle.status !== SubCycleStatus.COMPLETE;
        },
        [subCycles.length, subCyclesData],
    );

    const closeWarning = () => {
        setWarningIndex(null);
    };

    /**
     * User re ordering sub-cycles
     */
    const moveSubCycle = useCallback(
        (startIndex: number, endIndex: number) => {
            if (!readOnly) {
                if (!subCyclesData || endIndex === startIndex) {
                    return false;
                }

                if (startMoveIndex === null) setStartMoveIndex(startIndex);

                const movedUp = endIndex < startIndex;

                let canMove = true;
                for (let x = 0; x < subCycles.length; x++) {
                    const subCycle = subCycles[x];
                    const order = subCycle.order || 0;
                    const moved = movedUp ? order >= endIndex && order <= startIndex : order <= endIndex && order >= startIndex;
                    if (moved) {
                        const subCycleData = subCyclesData.find((subCycleData) => subCycleData.id === subCycle.id);
                        if (subCycleData) {
                            if (subCycleData.status === SubCycleStatus.OUT_FOR_RESPONSE || subCycleData.status === SubCycleStatus.COMPLETE) {
                                setWarningIndex(x);
                                canMove = false;
                                break;
                            }
                        }
                    }
                }
                if (canMove) {
                    move(startIndex, endIndex);
                    setWarningIndex(null);
                    return true;
                }
            }

            return false;
        },
        [readOnly, subCyclesData, startMoveIndex, subCycles, move],
    );

    const finalizeMoveSubCycle = useCallback(
        (startIndex: number) => {
            if (!readOnly) {
                if (!subCyclesData || warningIndex === null) {
                    setStartMoveIndex(null);
                    return -1;
                }

                //if there is a warning showing, then the user is attempting to commit an invalid move and we reset to the orignal order
                if (warningIndex !== null && startMoveIndex !== null) {
                    move(startIndex, startMoveIndex);
                    setStartMoveIndex(null);
                    setWarningIndex(null);
                    return startMoveIndex;
                }
            }

            return -1;
        },
        [readOnly, subCyclesData, warningIndex, startMoveIndex, move],
    );

    const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handlePopoverClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleMaxErrorClose = useCallback(() => {
        setMaxError(false);
    }, []);

    const handleTemplateChanged = useCallback(
        (template: TemplateFields) => {
            const currentLength = subCycles.length;
            const newLength = template.templateSubCycles.length;
            const removeArray = [] as number[];
            const addArray = [] as SubCycleInputType[];

            for (let i = 0; i < Math.max(currentLength, newLength); i++) {
                if (i > newLength - 1) {
                    // Need to remove SubCycle
                    removeArray.push(i);
                } else if (i > currentLength - 1) {
                    // Need to add SubCycle
                    addArray.push({
                        name: template.templateSubCycles[i].name,
                        formType: template.templateSubCycles[i].formType,
                        postedDate: new Date(),
                        lastUpdatedDate: new Date(),
                        originalClosingDate: new Date(),
                        currentClosingDate: new Date(),
                        originalArchiveDate: new Date(),
                        archiveDate: new Date(),
                    } as SubCycleInputType);
                } else {
                    setValue(`subCycles[${i}].name`, template.templateSubCycles[i].name);
                    setValue(`subCycles[${i}].formType`, template.templateSubCycles[i].formType);
                }
            }

            // remove and append can only be called once per render
            if (currentLength > newLength) {
                // Need to remove SubCycle
                remove(removeArray);
            } else if (currentLength < newLength) {
                // Need to add SubCycle
                append(addArray);
            }
        },
        [append, remove, setValue, subCycles.length],
    );

    const isUniqueName = useCallback(
        (index: number, name: string): boolean => {
            const cycle = getValues();
            return (
                cycle.subCycles.filter((subCycle: SubCycleInputType, i: number) => i !== index && subCycle.name?.toUpperCase() === name?.toUpperCase())
                    .length === 0
            );
        },
        [getValues],
    );

    const onNameChanged = useCallback(
        (index: number) => {
            if (isSubmitted) {
                const cycle = getValues();
                cycle.subCycles.map((_: SubCycleInputType, i: number) => (i !== index ? trigger(`subCycles[${i}].name`) : null));
            }
        },
        [isSubmitted, getValues, trigger],
    );

    const open = Boolean(anchorEl);
    return (
        <TemplateProvider>
            <FormSection justify="space-between">
                <Grid item>
                    <Typography variant="h1">
                        Sub-Cycles
                        <IconButton
                            aria-owns={open ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseLeave={handlePopoverClose}
                            onMouseEnter={handlePopoverOpen}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Typography>
                    <Popover
                        id="mouse-over-popover"
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        className={classes.popover}
                        disableRestoreFocus>
                        <Paper className={classes.tooltip}>
                            <Typography>This section allows you to define the major steps of your acquisition cycle.</Typography>
                        </Paper>
                    </Popover>
                </Grid>
                <Grid item>
                    {!readOnly ? <SaveTemplateButton /> : null}
                    <Button variant="contained" color="secondary" className={classes.expandButton} onClick={expandAll}>
                        Expand All
                    </Button>
                    <Button variant="contained" color="secondary" onClick={collapseAll}>
                        Collapse All
                    </Button>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <TemplateSelect mode={mode} onTemplateChange={handleTemplateChanged} />
                </Grid>
                <Grid item xs={12}>
                    {subCycles.map((subCycle, index) => (
                        <SubCycleItem
                            key={subCycle.key}
                            {...subCycle}
                            order={index}
                            index={index}
                            closeWarning={closeWarning}
                            lockedWarning={warningIndex === index}
                            locked={isLocked(subCycle?.id || undefined)}
                            move={moveSubCycle}
                            finalizeMove={finalizeMoveSubCycle}
                            expand={expand}
                            showRemove={canRemove(subCycle?.id || undefined)}
                            remove={removeSubCycle}
                            mode={mode}
                            isUniqueName={isUniqueName}
                            onNameChanged={onNameChanged}
                        />
                    ))}
                </Grid>
                {!readOnly ? (
                    <Grid item xs={12}>
                        <Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addSubCycle}>
                            Create Sub-Cycle
                        </Button>
                    </Grid>
                ) : null}
            </FormSection>
            <ConfirmDialog
                open={maxError}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />}
                title="Sub-Cycles Limit"
                message="You do not have the ability to create an additional sub-cycle."
                showPrimary={true}
                primaryText="OK"
                onPrimaryClick={handleMaxErrorClose}
                showSecondary={false}
                onClose={handleMaxErrorClose}
            />
            <ConfirmDialog
                open={confirmModal.open}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />}
                title="Change Out for Response Sub-Cycle?"
                message="You are applying a change to a sub-cycle that is Out for Response. Are you sure you want to make this change?"
                showPrimary={true}
                primaryText="Submit Change"
                onPrimaryClick={handleConfirmSubmit}
                showSecondary={true}
                secondaryText="Cancel"
                onSecondaryClick={handleConfirmClose}
                onClose={handleConfirmClose}
            />
        </TemplateProvider>
    );
};

export interface SubCyclesFormProps {
    mode: FormMode;
    subCyclesData?: SubCycleFields[];
}

export default SubCyclesForm;
