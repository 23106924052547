import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Typography } from '@material-ui/core';
import { FormMode } from '../../../../utils/Enums';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactHookFormSelect, ReactHookFormCurrency, useCommonForm } from '../../../index';
import { useFormContext } from 'react-hook-form';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const RDCategoryItem: React.FC<RDCategoryItemProps> = ({ name, index, mode, categoryId, id, technologyReadinessLevelId, fundingRange }) => {
    const { control, register, errors, watch } = useFormContext();
    const { technologyReadinessLevels, loading } = useCommonForm();
    const status = watch('status');

    const hasError = (field: string): boolean => {
        return (
            !!errors &&
            !!errors.researchDevelopmentCategories &&
            !!errors.researchDevelopmentCategories[index] &&
            !!errors.researchDevelopmentCategories[index][field]
        );
    };

    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.researchDevelopmentCategories[index][field]?.message : '';
    };

    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <Accordion elevation={0} defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="h3">{name}</Typography>
                {id ? <input name={`researchDevelopmentCategories[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} value={id} /> : null}
                <input
                    name={`researchDevelopmentCategories[${index}].categoryId`}
                    type="hidden"
                    ref={register({ valueAsNumber: true })}
                    defaultValue={categoryId}
                />
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ReactHookFormCurrency
                            control={control}
                            label={`Funding Range${requiredAsterisk}`}
                            name={`researchDevelopmentCategories[${index}].fundingRange`}
                            defaultValue={fundingRange}
                            readOnly={readOnly}
                            rules={{
                                required: required ? 'Funding Range is required' : false,
                                min: {
                                    value: 0,
                                    message: 'Funding Range is a dollar amount and has a minimum of 0 dollars.',
                                },
                                max: {
                                    value: 1000000000000,
                                    message: 'Funding Range is a dollar amount and has a maximum of $1,000,000,000,000.00 dollars.',
                                },
                                valueAsNumber: true,
                            }}
                            error={hasError('fundingRange')}
                            helperText={getErrorMessage('fundingRange')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ReactHookFormSelect
                            fullWidth
                            control={control}
                            label={`Technology Readiness Level${requiredAsterisk}`}
                            name={`researchDevelopmentCategories[${index}].technologyReadinessLevelId`}
                            defaultValue={technologyReadinessLevelId || ''}
                            readOnly={readOnly}
                            rules={{
                                required: required ? 'Technology Readiness Level is required' : false,
                            }}
                            loading={loading}
                            error={hasError('technologyReadinessLevelId')}
                            helperText={getErrorMessage('technologyReadinessLevelId')}>
                            {technologyReadinessLevels
                                ?.filter((level) => level.parents && level.parents.map((parent) => parent.id).indexOf(Number(categoryId)) > -1)
                                .map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </ReactHookFormSelect>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export interface RDCategoryItemProps {
    index: number;
    mode: FormMode;
    name: string;
    id?: number | null;
    categoryId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fundingRange?: any;
    technologyReadinessLevelId?: number | null;
}

export default RDCategoryItem;
