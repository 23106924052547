import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { PointOfContactInputType } from '../../../../apollo/generated/types/globalTypes';
import { ReactHookFormAutocomplete, ReactHookFormPhone, useCommonForm } from '../../../index';
import { useState } from 'react';
import { EmailRegex } from '../../../../utils/patterns';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';

const useStyles = makeStyles({
    table: {
        '& .MuiTableCell-head:last-child': {
            width: '50px',
        },
    },
});
const PointsOfContactSection: React.FC<PointsOfContactSectionProps> = ({ mode }) => {
    const classes = useStyles();
    const { control, register } = useFormContext();
    const { organizations, addOrganization } = useCommonForm();
    const { fields: contacts, append, remove } = useFieldArray<PointOfContactInputType, 'key'>({
        control,
        name: 'pointOfContacts', // unique name for your Field Array
        keyName: 'key',
    });
    const [open, toggleOpen] = useState(false);
    const pocForm = useForm<PointOfContactInputType>();
    const readOnly = mode === FormMode.View;

    const handleAddClick = () => {
        pocForm.reset({
            firstName: '',
            lastName: '',
            jobTitle: '',
            phone: '',
            email: '',
            organizationId: undefined,
        });
        toggleOpen(true);
    };

    /**
     * Handle Close of Add Option Popup
     */
    const handleClose = () => {
        toggleOpen(false);
    };

    /**
     * Handle Submit of Add Option Popup
     */
    const addContact = (contact: PointOfContactInputType) => {
        append({
            ...contact,
        });
        handleClose();
    };

    const options = {
        download: false,
        print: false,
        sort: false,
        filter: false,
        search: false,
        viewColumns: false,
        pagination: false,
        elevation: 0,
        selectableRows: 'none' as const,
    };

    const columns = [
        {
            name: 'key',
            label: 'Name',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: string) => {
                    const contact = contacts.find((contact) => contact.key === value);
                    return contact ? `${contact.firstName} ${contact.lastName}` : '';
                },
            },
        },
        {
            name: 'organizationId',
            label: 'Organization',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: number) => {
                    if (!organizations || organizations.length <= 0) {
                        return '';
                    }
                    const organization = organizations.find((o) => o.id === value);
                    return organization?.name || '';
                },
            },
        },
        {
            name: 'jobTitle',
            label: 'Job Title',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (_value: number, tableMeta: MUIDataTableMeta) => (
                    <IconButton aria-label="remove" onClick={() => remove(tableMeta.rowIndex)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                ),
            },
        },
    ];

    return (
        <>
            <FormSection justify="space-between">
                <Grid item>
                    <Typography variant="h1">Program Points of Contact</Typography>
                </Grid>
                <Grid item>
                    <Button startIcon={<AddCircleIcon />} variant="contained" onClick={handleAddClick} disabled={readOnly}>
                        Add New Contact
                    </Button>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12} className={classes.table}>
                    <MUIDataTable title="" data={contacts} columns={columns} options={options} />
                    {contacts.map((contact, index) => (
                        <div key={contact.key}>
                            {contact.id ? (
                                <input name={`pointOfContacts[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} defaultValue={contact.id} />
                            ) : null}
                            <input name={`pointOfContacts[${index}].firstName`} type="hidden" ref={register()} defaultValue={contact.firstName} />
                            <input name={`pointOfContacts[${index}].lastName`} type="hidden" ref={register()} defaultValue={contact.lastName} />
                            <input name={`pointOfContacts[${index}].phone`} type="hidden" ref={register()} defaultValue={contact.phone} />
                            <input name={`pointOfContacts[${index}].email`} type="hidden" ref={register()} defaultValue={contact.email} />
                            <input name={`pointOfContacts[${index}].jobTitle`} type="hidden" ref={register()} defaultValue={contact.jobTitle} />
                            <input
                                name={`pointOfContacts[${index}].organizationId`}
                                type="hidden"
                                ref={register({ valueAsNumber: true })}
                                defaultValue={contact.organizationId}
                            />
                        </div>
                    ))}
                </Grid>
            </FormSection>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-create-contact-title">
                <FormProvider {...pocForm}>
                    <form id="form-create-contact" onSubmit={pocForm.handleSubmit(addContact)}>
                        <DialogTitle id={`form-create-${name}-title`}>
                            <Typography variant="h1">Add a new Point of Contact</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        label="First Name*"
                                        inputRef={pocForm.register({
                                            required: 'First Name is required',
                                        })}
                                        error={!!pocForm.errors.firstName}
                                        helperText={pocForm.errors.firstName?.message}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name*"
                                        inputRef={pocForm.register({
                                            required: 'Last Name is required',
                                        })}
                                        error={!!pocForm.errors.lastName}
                                        helperText={pocForm.errors.lastName?.message}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactHookFormAutocomplete
                                        label="Organization*"
                                        name="organizationId"
                                        rules={{ required: 'Organization is required.' }}
                                        error={!!pocForm.errors.organizationId}
                                        helperText={pocForm.errors.organizationId?.message}
                                        options={organizations}
                                        addOption={addOrganization}
                                        getOptionSelected={(option: OptionFields, value?: number | OptionFields) => {
                                            if (!value) {
                                                return false;
                                            }
                                            if (typeof value === 'number') {
                                                return option.id === value;
                                            } else {
                                                return option.id === value.id;
                                            }
                                        }}
                                        getOptionValue={(option: OptionFields | null) => (option ? option?.id : null)}
                                        getOptionLabel={(option: OptionFields) => option?.name || ''}
                                        renderOption={(option: OptionFields) => option?.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="jobTitle"
                                        name="jobTitle"
                                        label="Job Title*"
                                        inputRef={pocForm.register({
                                            required: 'Job Title is required',
                                        })}
                                        error={!!pocForm.errors.jobTitle}
                                        helperText={pocForm.errors.jobTitle?.message}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReactHookFormPhone
                                        control={pocForm.control}
                                        name="phone"
                                        label="Phone*"
                                        onlyCountries={['us']}
                                        error={!!pocForm.errors.phone}
                                        helperText={pocForm.errors.phone?.message}
                                        rules={{
                                            required: 'Phone Number is required',
                                            pattern: {
                                                value: /^\+1 \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                                                message: 'Phone Number is not valid',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email*"
                                        inputRef={pocForm.register({
                                            required: 'Email is required',
                                            pattern: {
                                                value: EmailRegex,
                                                message: 'Invalid email address',
                                            },
                                        })}
                                        error={!!pocForm.errors.email}
                                        helperText={pocForm.errors.email?.message}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="text">
                                Cancel
                            </Button>
                            <Button form="form-create-contact" type="submit" variant="contained">
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
};

export interface PointsOfContactSectionProps {
    mode: FormMode;
}

export default PointsOfContactSection;
