import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_DEPARTMENTS } from '../../../../apollo/queries';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { GetDepartments } from '../../../../apollo/generated/types/GetDepartments';

const DepartmentSelect: React.FC<DepartmentSelectProps> = ({ required, readOnly }) => {
    const { control, getValues, errors } = useFormContext();
    const { loading, error, data } = useQuery<GetDepartments>(GET_DEPARTMENTS, {
        fetchPolicy: 'cache-and-network',
    });

    const requiredAsterisk = required ? '*' : '';
    return (
        <ReactHookFormSelect
            fullWidth
            control={control}
            label={`Selected Department${requiredAsterisk}`}
            name="departmentId"
            defaultValue={getValues('departmentId')}
            disabled={!!error}
            readOnly={readOnly}
            loading={loading}
            error={!!error || !!errors.departmentId}
            helperText={error ? 'Unable to retrieve data' : errors.departmentId?.message}
            rules={{ required: required ? 'Selected Department is required' : false }}>
            {!data || !data.departments || data.departments.length <= 0 ? (
                <MenuItem disabled>No Data Available</MenuItem>
            ) : (
                data.departments.map((department) => (
                    <MenuItem key={department.id} value={department.id} disabled={department.servicesCount <= 0}>
                        {department.name}
                    </MenuItem>
                ))
            )}
        </ReactHookFormSelect>
    );
};

export interface DepartmentSelectProps {
    required: boolean;
    readOnly: boolean;
}

export default DepartmentSelect;
