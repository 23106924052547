import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { REQUEST_UPLOAD_TOKEN } from '../../../apollo/mutations';
import { RequestUploadToken, RequestUploadTokenVariables } from '../../../apollo/generated/types/RequestUploadToken';
import { uploadBlobsToStorage } from '../../../utils/file-storage-util';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, List } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FileUploadInputType } from '../../../apollo/generated/types/globalTypes';
import FileDisplay from './FileDisplay';
import TempFileDisplay from './TempFileDisplay';

const useStyles = makeStyles({
    base: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    altStyle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#1A2A3D',
        fontWeight: 'bold',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    active: {
        borderColor: '#2196f3',
    },
    accept: {
        borderColor: '#00e676',
    },
    reject: {
        borderColor: '#ff1744',
    },
    listContainer: {
        '& .MuiTypography-body1': {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
});

interface MultiFileInput extends FileUploadInputType {
    fileName?: string;
    url?: string;
}

const MultipleFileDropZone: React.FC<MultipleFileDropZoneProps> = ({
    subCycleId,
    name,
    label,
    altStyle,
    browseBtn,
    fileListHeader,
    openFiles = false,
    readOnly = false,
}) => {
    const classes = useStyles();
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray<MultiFileInput, 'key'>({
        control,
        name: name, // unique name for your Field Array
        keyName: 'key',
    });

    const [requestUploadToken, { error }] = useMutation<RequestUploadToken, RequestUploadTokenVariables>(REQUEST_UPLOAD_TOKEN);

    const onDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const uploadedFiles = [] as MultiFileInput[];
            for (let index = 0; index < acceptedFiles.length; index++) {
                try {
                    const file = acceptedFiles[index];
                    const tokenResult = await requestUploadToken({
                        variables: {
                            input: {
                                subCycleId: subCycleId,
                                fileName: file.name,
                                fileSize: file.size,
                            },
                        },
                    });

                    const uploadTokenRequest = tokenResult.data?.uploadTokenRequest;
                    if (uploadTokenRequest) {
                        await uploadBlobsToStorage(file, uploadTokenRequest.sasUri);
                        uploadedFiles.push({ uploadToken: uploadTokenRequest.token, fileName: file.name, url: uploadTokenRequest.sasUri });
                    } else {
                        console.error('Invalid uploadTokenRequest');
                    }
                } catch (e) {
                    console.error(e);
                }
            }
            if (uploadedFiles.length > 0) {
                append(uploadedFiles);
            }
        }
    };
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        onDrop: onDrop,
        noClick: browseBtn,
        disabled: readOnly,
    });

    return (
        <>
            <div
                {...getRootProps({
                    className: `${altStyle ? classes.altStyle : classes.base} ${isDragActive ? classes.active : ''} ${isDragAccept ? classes.accept : ''} ${
                        isDragReject ? classes.reject : ''
                    }`,
                })}>
                <input {...getInputProps()} />
                <p>{label ? label : "Drag 'n' drop some files here, or click to select files"}</p>
                {browseBtn && (
                    <Button style={{ background: '#f5f5f5', color: '#1A2A3D', fontWeight: 'bold' }} variant="contained" type="button" onClick={open}>
                        <p style={{ fontSize: '13px' }} className="btn">
                            Browse files
                        </p>
                    </Button>
                )}
                {error ? (
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                <AlertTitle>File Upload Failed</AlertTitle>
                                {error?.message}
                            </Alert>
                        </Grid>
                    </Grid>
                ) : null}
            </div>
            {fields.length > 0 && fileListHeader}
            <List className={classes.listContainer} disablePadding={true}>
                {fields.map(({ key, id, uploadToken, fileName }, index) =>
                    id ? (
                        <FileDisplay key={key} id={id} index={index} onDelete={remove} name={name} openFile={openFiles} />
                    ) : (
                        <TempFileDisplay
                            key={key}
                            index={index}
                            uploadToken={uploadToken || ''}
                            fileName={fileName || ''}
                            onDelete={remove}
                            name={name}
                            openFile={openFiles}
                        />
                    ),
                )}
            </List>
        </>
    );
};

export interface MultipleFileDropZoneProps {
    subCycleId: number;
    name: string;
    label?: string;
    altStyle?: boolean;
    browseBtn?: boolean;
    fileListHeader?: JSX.Element;
    openFiles?: boolean;
    readOnly?: boolean;
}

export default MultipleFileDropZone;
