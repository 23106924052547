import * as React from 'react';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { EmailRegex } from '../../../utils/patterns';
import { ReactHookFormPhone, ReactHookFormSelect } from '../../ReactHookForm';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFormContext } from 'react-hook-form';
import { ApolloError, useQuery } from '@apollo/client';
import FormSection from '../FormSection';
import { GetAuth0Roles, GetAuth0Roles_auth0Roles } from '../../../apollo/generated/types/GetAuth0Roles';
import { GET_AUTH0_ROLES } from '../../../apollo/queries';
import { useEffect, useState } from 'react';
import { UserInputType } from '../../../apollo/generated/types/globalTypes';

const UserForm: React.FC<UserFormProps> = ({ apiError, onSubmit }) => {
    const { handleSubmit, register, errors, control, watch, setValue, getValues } = useFormContext();
    const id = watch('id');
    const externalRoleId = watch('externalRoleId');
    const [roles, setRoles] = useState<Array<GetAuth0Roles_auth0Roles>>([]);

    const { loading, error } = useQuery<GetAuth0Roles>(GET_AUTH0_ROLES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data.auth0Roles) {
                setRoles(data.auth0Roles);
                const existingRole = getValues('externalRoleId');
                if (!existingRole || existingRole === '') {
                    const defaultRole = data.auth0Roles.find((role) => role.name.toUpperCase() === 'EVALUATOR');
                    if (defaultRole) {
                        setValue('externalRoleName', defaultRole.name);
                        setValue('externalRoleId', defaultRole.id);
                    }
                }
            }
        },
    });

    // externalRoleId was reset so set it back to the default
    useEffect(() => {
        if ((roles && roles.length > 0 && !externalRoleId) || externalRoleId === '') {
            const defaultRole = roles.find((role) => role.name.toUpperCase() === 'EVALUATOR');
            if (defaultRole) {
                setValue('externalRoleName', defaultRole.name);
                setValue('externalRoleId', defaultRole.id);
            }
        }
    }, [externalRoleId, roles, setValue]);

    return (
        <form id="user-form" onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">User Details</Typography>
                </Grid>
                {id ? (
                    <Grid item xs={12}>
                        <input id="id" name="id" type="hidden" ref={register()} value={id} />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email*"
                        placeholder="Enter Email"
                        inputRef={register({
                            required: 'Email is required',
                            pattern: {
                                value: EmailRegex,
                                message: 'Invalid email address',
                            },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name*"
                        placeholder="Enter First Name"
                        inputRef={register({
                            required: 'First Name is required',
                        })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name*"
                        placeholder="Enter Last Name"
                        inputRef={register({
                            required: 'Last Name is required',
                        })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormPhone
                        control={control}
                        name="phoneNumber"
                        label="Phone Number*"
                        onlyCountries={['us']}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        rules={{
                            required: 'Phone Number is required',
                            pattern: {
                                value: /^\+1 \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                                message: 'Phone Number is not valid',
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <input id="id" name="externalRoleName" type="hidden" ref={register()} />
                    <ReactHookFormSelect
                        control={control}
                        fullWidth
                        label="Role*"
                        name="externalRoleId"
                        disabled={loading}
                        onSelectChange={(e) => {
                            const selectedRole = roles.find((role) => role.id === e.target.value);
                            setValue('externalRoleId', selectedRole?.id);
                            setValue('externalRoleName', selectedRole?.name);
                        }}
                        error={!!errors.externalRoleId || !!error}
                        rules={{ required: 'Role is required' }}
                        helperText={errors.externalRoleId?.message || (error ? 'Unable to retrieve data' : null)}>
                        {roles?.map((role: GetAuth0Roles_auth0Roles, index: number) => (
                            <MenuItem key={index} value={role.id}>
                                {role.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                {apiError ? (
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {apiError?.message}
                        </Alert>
                    </Grid>
                ) : null}
            </FormSection>
        </form>
    );
};

export interface UserFormProps {
    apiError?: ApolloError | null;
    onSubmit: (data: UserInputType) => void;
}

export default UserForm;
