import React from 'react';

const FileIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
            <g transform="translate(-1 -1)">
                <path fill="#f6b445" d="M39,13H22V7H38a.979.979,0,0,1,1,1Z" />
                <path fill="#fed049" d="M22,7v6H43a4,4,0,0,1,4,4V43a4,4,0,0,1-4,4H5a4,4,0,0,1-4-4V5S1,1,5,1H18s4,0,4,4Z" />
            </g>
        </svg>
    );
};

export default FileIcon;
