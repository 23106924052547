import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface ScreenMessageProps {
    icon?: React.ReactNode;
    header?: string;
    subHeader?: string;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    header: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    subHeader: {
        marginTop: '10px',
        marginBottom: '20px',
    },
});

const ScreenMessage: React.FC<ScreenMessageProps> = ({ icon, header, subHeader, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {icon ? icon : null}
            {header ? (
                <Typography variant="h1" align="center" className={classes.header}>
                    {header}
                </Typography>
            ) : null}
            {subHeader ? (
                <Typography variant="h3" align="center" className={classes.subHeader}>
                    {subHeader}
                </Typography>
            ) : null}
            {children}
        </div>
    );
};

export default ScreenMessage;
