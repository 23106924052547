import { gql } from '@apollo/client';
import {
    CycleFragment,
    FundingTypeFragment,
    RequestForInformationFormFragment,
    RequestForWhitePaperFormFragment,
    RequestForProposalFormFragment,
    SubServiceFragment,
    TemplateNameFragment,
    ProfileFragment,
    OptionFragment,
    SubCycleUserFragment,
    SubCycleWithEvaluatorsFragment,
    TopicWithSuccessFactorsFragment,
    ResponseUserFragment,
    CurrentUserFragment,
    ResponseUserScoreFragment,
} from './fragments';

export const REGISTER_USER = gql`
    mutation RegisterUser($auth0Id: String!, $email: String!) {
        register(auth0Id: $auth0Id, email: $email) {
            ...CurrentUserFields
        }
    }
    ${CurrentUserFragment}
`;

export const CREATE_USER = gql`
    mutation CreateUser($user: UserInputType!) {
        createUser(user: $user) {
            id
            email
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($user: UserInputType!) {
        updateUser(user: $user) {
            id
            email
        }
    }
`;

export const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile($userProfile: UserProfileInputType!) {
        updateUserProfile(user: $userProfile) {
            ...ProfileFields
        }
    }
    ${ProfileFragment}
`;

export const DELETE_USER = gql`
    mutation DeleteUser($userId: Int!) {
        deleteUser(id: $userId)
    }
`;

export const CREATE_ACQUISITION_CYCLE = gql`
    mutation CreateAcquisitionCycle($cycle: CycleInputType!) {
        createAcquisitionCycle(cycle: $cycle) {
            ...CycleFields
        }
    }
    ${CycleFragment}
`;

export const CREATE_SUB_SERVICE = gql`
    mutation CreateSubService($subService: SubServiceInputType!) {
        createSubService(subService: $subService) {
            ...SubServiceFields
        }
    }
    ${SubServiceFragment}
`;

export const CREATE_FUNDING_TYPE = gql`
    mutation CreateFundingType($fundingType: FundingTypeInputType!) {
        createFundingType(fundingType: $fundingType) {
            ...FundingTypeFields
        }
    }
    ${FundingTypeFragment}
`;

export const ARCHIVE_CYCLE = gql`
    mutation ArchiveCycle($cycleId: Int!) {
        archiveCycle(cycleId: $cycleId) {
            id
            status
        }
    }
`;

export const COMPLETE_CYCLE = gql`
    mutation CompleteCycle($cycleId: Int!) {
        completeCycle(cycleId: $cycleId) {
            id
            status
        }
    }
`;

export const CREATE_TEMPLATE = gql`
    mutation CreateTemplate($template: TemplateInputType!) {
        createTemplate(template: $template) {
            ...TemplateNameFields
        }
    }
    ${TemplateNameFragment}
`;

export const UPDATE_TEMPLATE = gql`
    mutation UpdateTemplate($template: TemplateInputType!) {
        updateTemplate(template: $template) {
            ...TemplateNameFields
        }
    }
    ${TemplateNameFragment}
`;

export const REQUEST_UPLOAD_TOKEN = gql`
    mutation RequestUploadToken($input: UploadTokenRequestType!) {
        uploadTokenRequest(input: $input) {
            sasUri
            token
        }
    }
`;

export const SAVE_FILE = gql`
    mutation SaveFile($token: String!) {
        saveFile(token: $token) {
            id
            subCycleId
            fileName
        }
    }
`;

export const ADD_CYCLE_USERS = gql`
    mutation AddCycleUsers($cycleId: Int!, $users: [CycleUserInputType!]) {
        addCycleUsers(cycleId: $cycleId, users: $users) {
            id
            userId
        }
    }
`;

export const REMOVE_CYCLE_USERS = gql`
    mutation RemoveCycleUsers($cycleId: Int!, $users: [CycleUserInputType!]!) {
        removeCycleUsers(cycleId: $cycleId, users: $users) {
            id
            userId
        }
    }
`;

export const UPDATE_REQUEST_FOR_INFORMATION_FORM = gql`
    mutation UpdateRequestForInformationForm($input: RequestForInformationFormInputType!) {
        updateRequestForInformationForm(input: $input) {
            ...RequestForInformationFormFields
        }
    }
    ${RequestForInformationFormFragment}
`;

export const UPDATE_REQUEST_FOR_WHITEPAPER_FORM = gql`
    mutation UpdateRequestForWhitePaperForm($input: RequestForWhitePaperFormInputType!) {
        updateRequestForWhitePaperForm(input: $input) {
            ...RequestForWhitePaperFormFields
        }
    }
    ${RequestForWhitePaperFormFragment}
`;

export const UPDATE_REQUEST_FOR_PROPOSAL_FORM = gql`
    mutation UpdateRequestForProposalForm($input: RequestForProposalFormInputType!) {
        updateRequestForProposalForm(input: $input) {
            ...RequestForProposalFormFields
        }
    }
    ${RequestForProposalFormFragment}
`;

export const ADD_SUB_CYCLE_USERS = gql`
    mutation AddSubCycleUsers($subCycleUsers: [SubCycleUserInputType!]) {
        addSubCycleUsers(subCycleUsers: $subCycleUsers) {
            ...SubCycleUserFields
        }
    }
    ${SubCycleUserFragment}
`;

export const REMOVE_SUB_CYCLE_USERS = gql`
    mutation RemoveSubCycleUsers($subCycleUsers: [SubCycleUserInputType!]) {
        removeSubCycleUsers(subCycleUsers: $subCycleUsers) {
            ...SubCycleUserFields
        }
    }
    ${SubCycleUserFragment}
`;

export const ADD_RESPONSE_USERS = gql`
    mutation AddResponseUsers($responseUsers: [ResponseUserInputType!]) {
        addResponseUsers(responseUsers: $responseUsers) {
            ...ResponseUserFields
        }
    }
    ${ResponseUserFragment}
`;

export const REMOVE_RESPONSE_USERS = gql`
    mutation RemoveResponseUsers($responseUsers: [ResponseUserInputType!]) {
        removeResponseUsers(responseUsers: $responseUsers) {
            ...ResponseUserFields
        }
    }
    ${ResponseUserFragment}
`;

export const CREATE_TIME_HORIZON = gql`
    mutation CreateTimeHorizon($name: String!) {
        createTimeHorizon(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const CREATE_TECHNOLOGY_KEYWORD = gql`
    mutation CreateTechnologyKeyword($name: String!) {
        createTechnologyKeyword(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const CREATE_FUNDING_SOURCE = gql`
    mutation CreateFundingSource($name: String!) {
        createFundingSource(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const CREATE_RESPONSE_ORGANIZATION = gql`
    mutation CreateResponseOrganization($name: String!) {
        createResponseOrganization(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const CREATE_RISK = gql`
    mutation CreateRisk($name: String!) {
        createRisk(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const CREATE_POINT_OF_CONTACT_ORGANIZATION = gql`
    mutation CreatePointOfContactOrganization($name: String!) {
        createPointOfContactOrganization(name: $name) {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const UPDATE_SUB_CYCLE_SUCCESS_FACTOR_FORM = gql`
    mutation UpdateSubCycleSuccessFactorForm($input: SubCycleSuccessFactorFormInputType!) {
        updateSubCycleSuccessFactorForm(input: $input) {
            id
            successFactorStatus
            topics {
                ...TopicWithSuccessFactorsFields
            }
        }
    }
    ${TopicWithSuccessFactorsFragment}
`;

export const UPDATE_SUB_CYCLE_STATUS = gql`
    mutation UpdateSubCycleFormStatus($id: Int!, $status: SubCycleStatus!) {
        updateSubCycleFormStatus(id: $id, status: $status) {
            ...SubCycleWithEvaluatorsFields
        }
    }
    ${SubCycleWithEvaluatorsFragment}
`;

export const Export_Form_PDF = gql`
    mutation ExportFormPDF($id: Int!) {
        exportFormPDF(id: $id) {
            formPdf
            instructionsDoc
        }
    }
`;

export const Export_SuccessFactors_PDF = gql`
    mutation ExportSuccessFactorsPDF($input: ExportSuccessFactorsPdfInputType!) {
        exportSuccessFactors(input: $input)
    }
`;

export const ADD_UPDATE_RESPONSE = gql`
    mutation AddUpdateResponse($response: ResponseInputType!) {
        updateResponse(response: $response) {
            id
        }
    }
`;

export const UPDATE_EVALUATOR_SCORES = gql`
    mutation UpdateEvaluatorScores($responseUser: ResponseUserScoreInputType!) {
        updateEvaluatorScores(responseUser: $responseUser) {
            ...ResponseUserScoreFields
        }
    }
    ${ResponseUserScoreFragment}
`;

export const ADD_TEAM_LEAD = gql`
    mutation AddTeamLead($responseUser: ResponseUserInputType!) {
        addResponseUsers(responseUsers: [$responseUser]) {
            ...ResponseUserScoreFields
        }
    }
    ${ResponseUserScoreFragment}
`;

export const IMPORT_SELECTED_RESPONSES = gql`
    mutation ImportSelectedResponses($cycleId: Int!) {
        importResponses(cycleId: $cycleId) {
            id
        }
    }
`;

export const UPDATE_RESPONSE_SELECTION = gql`
    mutation UpdateResponseSelection($responseId: Int!, $selectedStatus: SelectionStatus!) {
        updateResponseSelection(responseId: $responseId, selectedStatus: $selectedStatus) {
            id
            selectedStatus
        }
    }
`;

export const UPDATE_RDCATEGORY_COMPLETED = gql`
    mutation UpdateRDCategoryCompleted($categoryId: Int!, $selectionCompleted: Boolean!) {
        updateRDCategoryCompleted(categoryId: $categoryId, selectionCompleted: $selectionCompleted) {
            id
            selectionCompleted
        }
    }
`;
