/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Grid, makeStyles, createStyles, Typography, GridSize, FormHelperText, GridProps } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            '&:first-child': {
                borderRadius: '5px 0px 0px 5px',
            },
            '&:last-child': {
                borderRadius: '0px 5px 5px 0px',
                textAlign: 'center',
            },
        },
        firstCard: {
            borderRadius: '5px 0px 0px 5px',
        },
        lastCard: {
            borderRadius: '0px 5px 5px 0px',
            textAlign: 'center',
        },
        error: {
            background: theme.palette.error.light,
        },
        warning: {
            background: theme.palette.warning.light,
        },
        success: {
            background: theme.palette.success.light,
        },
        errorLight: {
            background: theme.palette.error.main,
        },
        warningLight: {
            background: theme.palette.warning.main,
        },
        successLight: {
            background: theme.palette.success.main,
        },
        title: {
            fontWeight: 'bold',
        },
    }),
);

export interface ScoreTitleProps {
    gridSize: GridSize;
    gridProps?: GridProps;
    label?: any;
    score: number;
    dark?: boolean;
    variant?: Variant;
    firstCard?: boolean;
    lastCard?: boolean;
    hasError?: boolean;
    errorMessage?: string;
}

const ScoreTitleDisplay: React.FC<ScoreTitleProps> = ({
    score,
    dark = false,
    label,
    gridSize,
    variant = 'h3',
    firstCard,
    lastCard,
    hasError,
    errorMessage,
    gridProps,
}) => {
    const classes = useStyles();

    const colorLevel = (score: any, light: boolean) => {
        if (score >= 8) {
            return light ? classes.successLight : classes.success;
        } else if (score < 8 && score >= 7) {
            return light ? classes.warningLight : classes.warning;
        } else if (score < 7) {
            return light ? classes.errorLight : classes.error;
        }
    };

    const nthCard = firstCard ? classes.firstCard : lastCard ? classes.lastCard : '';

    return (
        <Grid item xs={gridSize} className={`${colorLevel(score, dark)} ${classes.card} ${nthCard}`} {...gridProps}>
            <Typography variant={variant} className={classes.title}>
                {label}
            </Typography>
            {hasError ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
        </Grid>
    );
};

export default ScoreTitleDisplay;
