import Tooltip from '@material-ui/core/Tooltip';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AddIcon from '@material-ui/icons/Add';
import { LoadingIconButton } from '../../../Buttons';
import React from 'react';

export interface CustomToolBarProps {
    openModal: () => void;
    importResponses: () => void;
    importDisabled: boolean;
    importSelectedDisabled: boolean;
    loading: boolean;
}

const CustomToolBar: React.FC<CustomToolBarProps> = ({ openModal, importResponses, importDisabled, importSelectedDisabled, loading }) => {
    return (
        <>
            <Tooltip title={'Import Response'}>
                <LoadingIconButton onClick={openModal} disabled={importDisabled} pending={loading}>
                    <AddIcon />
                </LoadingIconButton>
            </Tooltip>
            <Tooltip title={'Import Selected Responses'}>
                <LoadingIconButton onClick={importResponses} disabled={importSelectedDisabled} pending={loading}>
                    <SaveAltIcon />
                </LoadingIconButton>
            </Tooltip>
        </>
    );
};

export default CustomToolBar;
