import * as React from 'react';
import { IconButton, Grid, Typography, Chip } from '@material-ui/core';
import FormSection from '../../../../FormSection';
import FormDivider from '../../../../FormDivider';
import { FormMode } from '../../../../../../utils/Enums';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Alert } from '@material-ui/lab';
import { useFieldArray, useFormContext, ArrayField } from 'react-hook-form';
import {
    FundamentalPrimeMeasurement,
    SubCycleForm,
    SuccessFactorInputType,
    TopicRiskCategoryInputType,
    FpmCategory,
} from '../../../../../../apollo/generated/types/globalTypes';
import { useCallback, useEffect } from 'react';
import SuccessFactorCategorySection from './SuccessFactorCategorySection';
import { useSuccessFactorForm } from '../../../../../Providers/SuccessFactorFormProvider';
import { ReactHookFormAutocomplete, SelectPlaceholder } from '../../../../../index';
import { OptionFields } from '../../../../../../apollo/generated/types/OptionFields';
import { TopicWithSuccessFactorsFields_risks } from '../../../../../../apollo/generated/types/TopicWithSuccessFactorsFields';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600,
    },
});

const getSectionLabels = () => ['Product / Technology', 'Organization', 'Application'];
const getFPMLabels = () => [
    ['Appeal', 'Value', 'Reliability'],
    ['People', 'Planning / Processes', 'Finances'],
    ['Size / Scope', 'Market Demand', 'Delivery (Market Access)'],
];
const getFPMOrder = () => [
    FundamentalPrimeMeasurement.APPEAL,
    FundamentalPrimeMeasurement.VALUE,
    FundamentalPrimeMeasurement.RELIABILITY,
    FundamentalPrimeMeasurement.PERSONNEL,
    FundamentalPrimeMeasurement.PROCESSES,
    FundamentalPrimeMeasurement.FINANCES,
    FundamentalPrimeMeasurement.APPLICATION_SIZE,
    FundamentalPrimeMeasurement.DEMAND,
    FundamentalPrimeMeasurement.DELIVERY,
];

const FpmCategories = [FpmCategory.PRODUCT_TECHNOLOGY, FpmCategory.ORGANIZATION, FpmCategory.APPLICATION];

const infoBoxText = "2 Page RFI's should focus on the Product/Technology metrics. Higher page counts can allow all 9 fundamental metrics";

const FPMSuccessFactorForm: React.FC<FPMSuccessFactorFormProps> = ({
    mode,
    sectionNumber,
    topicIndex,
    topicRisks,
    formType,
    successFactors,
    append,
    remove,
    previousFormType,
    subCycleOrder,
}) => {
    const classes = useStyles();
    const { control, trigger, formState, errors, register } = useFormContext();
    const { loading, risks: options, addRisk } = useSuccessFactorForm();
    const { isSubmitted } = formState;
    const readOnly = mode === FormMode.View;

    const { fields: riskCategories } = useFieldArray<TopicRiskCategoryInputType, 'key'>({
        control,
        name: `topics[${topicIndex}].riskCategories`, // unique name for your Field Array
        keyName: 'key',
    });

    const removeSuccessFactor = useCallback(
        (index: number) => {
            if (successFactors.length > 1) {
                remove(index);
            }
        },
        [remove, successFactors.length],
    );

    useEffect(() => {
        if (isSubmitted) {
            trigger(`topics[${topicIndex}].category`);
        }
    }, [successFactors.length, topicIndex, trigger, isSubmitted]);

    const importedLocked = formType === SubCycleForm.REQUEST_FOR_PROPOSAL && previousFormType !== SubCycleForm.REQUEST_FOR_INFORMATION;
    const additionLocked = subCycleOrder > 0 && formType == SubCycleForm.REQUEST_FOR_PROPOSAL && previousFormType === SubCycleForm.REQUEST_FOR_PROPOSAL;

    return (
        <>
            <FormSection justify="space-between">
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {getSectionLabels()[sectionNumber]}
                        <IconButton>
                            <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                {formType === SubCycleForm.REQUEST_FOR_INFORMATION && sectionNumber == 0 && (
                    <Grid item xs={12}>
                        <Alert color="success" severity="info">
                            {infoBoxText}
                        </Alert>
                    </Grid>
                )}
                <Grid container justify="space-between">
                    <Typography variant="subtitle2" align="left" className={classes.bold}>
                        9-Hi™ Categories
                    </Typography>
                    <Typography variant="subtitle2" align="right" className={classes.bold} style={{ paddingRight: '120px' }}>
                        Weights
                    </Typography>
                </Grid>
            </FormSection>
            <FormSection>
                <Grid container spacing={4}>
                    {getFPMLabels()[sectionNumber].map((category, fpmIndex) => (
                        <SuccessFactorCategorySection
                            key={category}
                            mode={mode}
                            category={category}
                            successFactors={successFactors}
                            topicIndex={topicIndex}
                            fpmCategory={getFPMOrder()[sectionNumber * 3 + fpmIndex]}
                            append={append}
                            remove={removeSuccessFactor}
                            importedLocked={importedLocked}
                            additionLocked={additionLocked}
                        />
                    ))}
                </Grid>
                {riskCategories &&
                    riskCategories
                        .filter((x) => x.fpmCategory === FpmCategories[sectionNumber])
                        .map((riskCat) => (
                            <Grid item xs={12} key={riskCat.key}>
                                <input
                                    name={`topics[${topicIndex}].riskCategories[${sectionNumber}].fpmCategory`}
                                    type="hidden"
                                    ref={register()}
                                    value={riskCat.fpmCategory}
                                />
                                {loading || options === undefined ? (
                                    <SelectPlaceholder label="Risks" />
                                ) : (
                                    <ReactHookFormAutocomplete
                                        readOnly={readOnly}
                                        multiple={true}
                                        label="Risks"
                                        name={`topics.${topicIndex}.riskCategories.${sectionNumber}.risks`}
                                        defaultValue={riskCat.risks}
                                        rules={{
                                            required: false,
                                        }}
                                        error={!!errors.risks}
                                        helperText={errors.risks?.message}
                                        options={options}
                                        addOption={addRisk}
                                        getOptionSelected={(option: OptionFields, value?: number[] | OptionFields) => {
                                            if (!value) {
                                                return false;
                                            }
                                            if (Array.isArray(value)) {
                                                return value.includes(option.id);
                                            } else {
                                                return value.id === option.id;
                                            }
                                        }}
                                        getOptionValue={(options: OptionFields[]) => options.map((option: OptionFields) => option.id)}
                                        getOptionLabel={(option: OptionFields) => {
                                            return option.name;
                                        }}
                                        renderTags={(values: OptionFields[], getTagProps) =>
                                            values.map((option: OptionFields, index: number) => {
                                                return (
                                                    <Chip
                                                        label={option.name}
                                                        {...getTagProps({ index })}
                                                        disabled={
                                                            readOnly ||
                                                            topicRisks.filter((x) => x.riskId === option.id && x.imported && importedLocked).length > 0
                                                        }
                                                    />
                                                );
                                            })
                                        }
                                        fixedOptions={options.filter(
                                            (x) => topicRisks.filter((y) => y.imported && y.riskId === x.id && importedLocked).length > 0,
                                        )}
                                    />
                                )}
                            </Grid>
                        ))}
            </FormSection>
        </>
    );
};

export interface FPMSuccessFactorFormProps {
    mode: FormMode;
    sectionNumber: number;
    topicIndex: number;
    formType?: SubCycleForm;
    successFactors: Partial<ArrayField<SuccessFactorInputType, 'key'>>[];
    append: (value: Partial<SuccessFactorInputType> | Partial<SuccessFactorInputType>[]) => void;
    remove: (index: number) => void;
    previousFormType: SubCycleForm | null;
    subCycleOrder: number;
    topicRisks: TopicWithSuccessFactorsFields_risks[];
}

export default FPMSuccessFactorForm;
