import React, { useEffect, useRef, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Popper,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormDate, ReactHookFormSelect } from '../../../ReactHookForm';
import { CycleStatus, SubCycleForm, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';
import { useCycleForm } from '../../../Providers';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        marginBottom: '15px',
    },
    removeSection: {
        width: '50px',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    headerIcon: {
        height: '100%',
    },
    space: {
        margin: '0 10px',
    },
});

interface DragItem {
    index: number;
    type: string;
}

const SubCycleItem: React.FC<SubCycleFormProps> = ({
    index,
    mode,
    expand,
    closeWarning,
    lockedWarning,
    locked,
    move,
    finalizeMove,
    showRemove,
    remove,
    name,
    id,
    order,
    formType,
    postedDate,
    lastUpdatedDate,
    originalClosingDate,
    currentClosingDate,
    originalArchiveDate,
    archiveDate,
    isUniqueName,
    onNameChanged,
}) => {
    const classes = useStyles();
    const { register, control, errors, watch } = useFormContext();
    const { formTypes, loading } = useCycleForm();
    const anchorEl = useRef(null);
    const [localExpand, setLocalExpand] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const status = watch('status', CycleStatus.DRAFT);

    const [, drop] = useDrop({
        accept: 'subCycle',
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            if (move(dragIndex, hoverIndex)) {
                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.index = hoverIndex;
            }
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: 'subCycle', index },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !readOnly && !locked,
        end(item: { type: string; index: number } | undefined) {
            if (item === undefined) {
                return;
            }
            const result = finalizeMove(item?.index);

            if (result !== -1) {
                //         // Note: we're mutating the monitor item here!
                //         // Generally it's better to avoid mutations,
                //         // but it's good here for the sake of performance
                //         // to avoid expensive index searches.
                item.index = result;
            }
        },
    });

    useEffect(() => {
        setLocalExpand(expand);
    }, [expand]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental, @typescript-eslint/no-explicit-any
    const handleChange = (event: any, isExpanded: boolean) => {
        if (!isDragging) {
            setLocalExpand(isExpanded);
        }
    };

    const hasError = (field: string): boolean => {
        return !!errors && !!errors.subCycles && !!errors.subCycles[index] && !!errors.subCycles[index][field];
    };

    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.subCycles[index][field]?.message : '';
    };

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <div className={classes.root}>
            <Accordion elevation={0} style={{ opacity }} expanded={isDragging ? false : localExpand} onChange={handleChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} ref={ref}>
                    <div className={classes.header}>
                        {locked ? <LockIcon className={classes.headerIcon} /> : <DragHandleIcon className={classes.headerIcon} />}
                        <Divider orientation="vertical" className={classes.space} ref={anchorEl} />
                        <Popper
                            style={{ marginLeft: '5px' }}
                            open={lockedWarning}
                            anchorEl={anchorEl.current}
                            placement="right"
                            onClick={(e) => e.stopPropagation()}>
                            <Alert severity="warning" onClose={closeWarning} onClick={(e) => e.stopPropagation()}>
                                The current sub-cycle is locked and cannot be moved.
                            </Alert>
                        </Popper>
                        <FormControl
                            fullWidth
                            error={hasError('name')}
                            draggable
                            onDragStart={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                            <InputBase
                                placeholder={`Sub-Cycle Name${requiredAsterisk}`}
                                name={`subCycles[${index}].name`}
                                inputRef={register({
                                    required: required ? 'Sub-Cycle Name is required' : false,
                                    validate: (value) =>
                                        isUniqueName(index, value) || 'You have an existing sub-cycle with this name. Please enter a unique name.',
                                })}
                                onChange={() => onNameChanged(index)}
                                defaultValue={name}
                                readOnly={readOnly}
                                onClick={(e) => e.stopPropagation()}
                            />
                            {hasError('name') ? <FormHelperText>{getErrorMessage('name')}</FormHelperText> : null}
                        </FormControl>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {id ? <input name={`subCycles[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} defaultValue={id} /> : null}
                            <input name={`subCycles[${index}].order`} type="hidden" ref={register({ valueAsNumber: true })} value={order} />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                fullWidth
                                control={control}
                                label="Form Type*"
                                name={`subCycles[${index}].formType`}
                                defaultValue={formType}
                                loading={loading}
                                rules={{ required: 'Form Type is required' }}
                                error={hasError('formType')}
                                helperText={getErrorMessage('formType')}
                                readOnly={readOnly}>
                                {formTypes &&
                                    formTypes.map((subCycleFormType) => (
                                        <MenuItem key={subCycleFormType.type} value={subCycleFormType.type}>
                                            {`${subCycleFormType.name} (${subCycleFormType.shortName})`}
                                        </MenuItem>
                                    ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label={`Posted Date${requiredAsterisk}`}
                                name={`subCycles[${index}].postedDate`}
                                readOnly={readOnly}
                                error={hasError('postedDate')}
                                helperText={getErrorMessage('postedDate')}
                                defaultValue={postedDate}
                                rules={{ required: required ? 'Posted Date is required' : false }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label="Last Updated Date"
                                name={`subCycles[${index}].lastUpdatedDate`}
                                readOnly={readOnly}
                                error={hasError('lastUpdatedDate')}
                                helperText={getErrorMessage('lastUpdatedDate')}
                                defaultValue={lastUpdatedDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label={`Original Closing Date${requiredAsterisk}`}
                                name={`subCycles[${index}].originalClosingDate`}
                                readOnly={readOnly}
                                error={hasError('originalClosingDate')}
                                helperText={getErrorMessage('originalClosingDate')}
                                defaultValue={originalClosingDate}
                                rules={{ required: required ? 'Original Closing Date is required' : false }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label="Current Closing Date"
                                name={`subCycles[${index}].currentClosingDate`}
                                readOnly={readOnly}
                                error={hasError('currentClosingDate')}
                                helperText={getErrorMessage('currentClosingDate')}
                                defaultValue={currentClosingDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label="Original Archive Date"
                                name={`subCycles[${index}].originalArchiveDate`}
                                readOnly={readOnly}
                                error={hasError('originalArchiveDate')}
                                helperText={getErrorMessage('originalArchiveDate')}
                                defaultValue={originalArchiveDate}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormDate
                                control={control}
                                format="MM/dd/yyyy"
                                label="Archive Date"
                                name={`subCycles[${index}].archiveDate`}
                                readOnly={readOnly}
                                error={hasError('archiveDate')}
                                helperText={getErrorMessage('archiveDate')}
                                defaultValue={archiveDate}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {!readOnly && showRemove ? (
                <div className={classes.removeSection}>
                    <IconButton aria-label="remove" onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                </div>
            ) : null}
        </div>
    );
};

export interface SubCycleFormProps {
    index: number;
    mode: FormMode;
    closeWarning: () => void;
    lockedWarning: boolean;
    locked: boolean;
    move: (dragIndex: number, hoverIndex: number) => boolean;
    finalizeMove: (dragIndex: number) => number;
    showRemove: boolean;
    remove: (index: number) => void;
    expand: boolean;
    isUniqueName: (index: number, value: string) => boolean;
    onNameChanged: (index: number) => void;

    id?: number | null;
    formType?: SubCycleForm;
    name?: string | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    postedDate?: any | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastUpdatedDate?: any | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalClosingDate?: any | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentClosingDate?: any | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalArchiveDate?: any | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    archiveDate?: any | null;
    order: number;
}

export default SubCycleItem;
