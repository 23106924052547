import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Step, StepButton, StepLabel, Stepper } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { CycleStepIcon, StepperConnector } from '../../Stepper';
import GeneralForm from './GeneralForm';
import SubCyclesForm from './SubCyclesForm';
import FundingsForm from './FundingsForm';
import { ApolloError } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormMode } from '../../../utils/Enums';
import { CycleFormProvider } from '../../Providers';
import { StatusDropdown } from '../../index';
import { SubCycleFields } from '../../../apollo/generated/types/SubCycleFields';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftSide: {
        width: '320px',
        paddingLeft: '60px',
        paddingRight: '60px',
    },
    stepperLabel: {
        '& .MuiStepLabel-label': {
            color: '#1A2A3D',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: '#1A2A3D',
        },
    },
    sticky: {
        position: 'sticky',
        top: '1rem',
    },
    rightSide: {
        flex: 1,
        maxWidth: '1000px',
    },
});

const getSteps = () => ['General', 'Sub-Cycles', 'Funding'];

const AcquisitionCycleForm: React.FC<AcquisitionCycleFormProps> = ({ mode, apiError, subCyclesData }) => {
    const classes = useStyles();
    const { register, watch } = useFormContext();
    const [activeStep, setActiveStep] = useState<number>(0);
    const generalSectionRef = useRef<null | HTMLDivElement>(null);
    const subCyclesSectionRef = useRef<null | HTMLDivElement>(null);
    const fundingSectionRef = useRef<null | HTMLDivElement>(null);
    const steps = getSteps();
    const id = watch('id');
    const readOnly = mode === FormMode.View;

    const handleStep = (step: number) => () => {
        setActiveStep(step);
        switch (step) {
            case 0:
                if (generalSectionRef && generalSectionRef.current) {
                    generalSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 1:
                if (subCyclesSectionRef && subCyclesSectionRef.current) {
                    subCyclesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
            case 2:
                if (fundingSectionRef && fundingSectionRef.current) {
                    fundingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }
                break;
        }
    };

    return (
        <CycleFormProvider>
            <form id="acquisition-form">
                <div className={classes.root}>
                    <div className={classes.leftSide}>
                        <Grid container spacing={2} className={classes.sticky}>
                            <Grid item xs={12}>
                                {id ? <input name="id" type="hidden" ref={register({ valueAsNumber: true })} defaultValue={id} /> : null}
                                <StatusDropdown readOnly={readOnly} name="status" type="Cycle" />
                            </Grid>
                            <Grid item xs={12}>
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                    nonLinear
                                    connector={<StepperConnector />}
                                    style={{ background: 'transparent' }}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton onClick={handleStep(index)}>
                                                <StepLabel className={classes.stepperLabel} StepIconComponent={CycleStepIcon}>
                                                    {label}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.rightSide}>
                        <Grid container spacing={2}>
                            <Grid ref={generalSectionRef} item xs={12}>
                                <Paper elevation={0}>
                                    <GeneralForm mode={mode} />
                                </Paper>
                            </Grid>
                            <Grid ref={subCyclesSectionRef} item xs={12}>
                                <Paper elevation={0}>
                                    <SubCyclesForm mode={mode} subCyclesData={subCyclesData} />
                                </Paper>
                            </Grid>
                            <Grid ref={fundingSectionRef} item xs={12}>
                                <Paper elevation={0}>
                                    <FundingsForm mode={mode} />
                                </Paper>
                            </Grid>
                            {apiError ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {apiError?.message}
                                    </Alert>
                                </Grid>
                            ) : null}
                        </Grid>
                    </div>
                </div>
            </form>
        </CycleFormProvider>
    );
};

export default AcquisitionCycleForm;

export interface AcquisitionCycleFormProps {
    mode: FormMode;
    apiError?: ApolloError | null;
    subCyclesData?: SubCycleFields[];
}
