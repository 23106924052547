import { SuccessFactorStatus } from '../../../apollo/generated/types/globalTypes';

export const getSuccessFactorStatusDisplayName = (value: string): string => {
    switch (value) {
        case SuccessFactorStatus.DRAFT:
            return 'Draft';
        case SuccessFactorStatus.PENDING_APPROVAL:
            return 'Pending Approval';
        case SuccessFactorStatus.APPROVED:
            return 'Approved';
        default:
            return '';
    }
};
