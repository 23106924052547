/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, makeStyles } from '@material-ui/core';
import AwardManagementItem from './AwardManagementItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { SelectionStatus } from '../../../../../apollo/generated/types/globalTypes';

interface AwardManagementContainerProps {
    title: string;
    finishedResponses: any;
    status: SelectionStatus;
    setTabItems: (x: any) => void;
    count: number;
    awardContainer?: boolean;
    setSelectedItems: any;
    selectedItems: any;
    readOnly: boolean;
}

const useStyles = makeStyles({
    item: {
        margin: 'auto 0',
        width: '13.3%',
    },
    itemLabel: {
        width: '7.5%',
    },
});

const AwardManagementContainer: React.FC<AwardManagementContainerProps> = ({
    title,
    finishedResponses,
    setTabItems,
    status,
    count,
    awardContainer,
    setSelectedItems,
    selectedItems,
    readOnly,
}) => {
    const classes = useStyles();

    // when item dropped targetSection is returned as getDropResult which is utilized in AwardManagementItem useDrag()
    const handleDrop = () => {
        return { targetSection: status };
    };

    // checks if drop zone is viable
    const handleCanDrop = (item) => {
        const currentColumnName = item;
        return (
            currentColumnName.sectionStatus === SelectionStatus.AWARD_SELECTED ||
            currentColumnName.sectionStatus === SelectionStatus.AWARD_REJECTED ||
            currentColumnName.sectionStatus === SelectionStatus.PENDING
        );
    };

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'response',
        drop: handleDrop,
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item: any) => handleCanDrop(item),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = '#ffffff';
    if (isActive) {
        backgroundColor = 'lightgreen';
    } else if (canDrop) {
        backgroundColor = 'lightblue';
    }

    const moveItemHandler = (dragIndex, hoverIndex) => {
        const dragItem = finishedResponses[dragIndex];
        if (dragItem) {
            setTabItems((prevState) => {
                const coppiedStateArray = [...prevState];
                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
                // remove item by "dragIndex" and put "prevItem" instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
                return coppiedStateArray;
            });
        }
    };

    return (
        <Accordion ref={drop}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: '#ffffff', flexDirection: 'row-reverse' }}>
                <Grid container>
                    <Grid item>
                        <Typography style={{ fontWeight: isOver ? 'bold' : 'normal', color: isOver || canDrop ? `${backgroundColor}` : 'black' }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px', marginTop: 'auto', color: '#A0A0A0' }}>
                        <Typography variant="body2">{`${count} records`}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ borderRadius: '8px', background: '#f5f5f5f5', width: '100%', padding: '10px' }}>
                    <div>
                        <Grid container spacing={2} style={{ padding: '10px', textAlign: 'center' }}>
                            {awardContainer ? (
                                <>
                                    <Grid item className={classes.item}>
                                        Response Title
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Response ID#
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Organization
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Technology
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Team
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Application
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        Net Cost
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={1}>
                                        Response Title
                                    </Grid>
                                    <Grid item xs={1}>
                                        Response ID#
                                    </Grid>
                                    <Grid item xs={1}>
                                        Organization
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Appeal
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Value
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Reliability
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        People
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Planning/Process
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Finances
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Size/Scope
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Market Demand
                                    </Grid>
                                    <Grid item className={classes.itemLabel}>
                                        Delivery (Market Access)
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </div>
                    <div>
                        {finishedResponses
                            .filter((i) => i.selectedStatus === status)
                            .map((i) => {
                                const index = finishedResponses.findIndex((j) => j.id == i.id);
                                return (
                                    <AwardManagementItem
                                        key={i.id}
                                        response={i}
                                        sectionStatus={status}
                                        setTabItems={setTabItems}
                                        index={index}
                                        moveItemHandler={moveItemHandler}
                                        backgroundColor={backgroundColor}
                                        awardItem={i.selectedStatus === SelectionStatus.AWARD_SELECTED}
                                        handleSetMulti={setSelectedItems}
                                        selectedItems={selectedItems}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default AwardManagementContainer;
