import { usePermissions } from '../Providers';

export interface HasPermissionProps {
    permission: string;
    children: JSX.Element;
}

const HasPermission = ({ permission, children }: HasPermissionProps): null | JSX.Element => {
    const { hasPermission } = usePermissions();
    if (hasPermission(permission)) {
        return children;
    } else {
        return null;
    }
};

export default HasPermission;
