import React from 'react';
import FormSection from '../../FormSection';
import { Grid, Paper, Typography } from '@material-ui/core';
import FormDivider from '../../FormDivider';
import { GetProfile_user_cycleAssignments } from '../../../../apollo/generated/types/GetProfile';
import MUIDataTable from 'mui-datatables';

const TeamAssignments: React.FC<TeamAssignmentsProps> = ({ teamAssignments }) => {
    const options = {
        download: false,
        print: false,
        sort: false,
        filter: false,
        search: false,
        viewColumns: false,
        pagination: false,
        elevation: 0,
        selectableRows: 'none' as const,
        enableNestedDataAccess: '.',
        textLabels: {
            body: {
                noMatch: 'No Team Assignments',
            },
        },
    };

    const columns = [
        {
            name: 'cycle.title',
            label: 'Acquisition Cycle',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'cycleRole.name',
            label: 'Role',
            options: {
                filter: false,
                sort: false,
            },
        },
    ];
    return (
        <Grid item xs={12}>
            <Paper elevation={0}>
                <FormSection>
                    <Grid item xs={12}>
                        <Typography variant="h1">Team Assignments</Typography>
                    </Grid>
                </FormSection>
                <FormDivider />
                <FormSection>
                    <Grid item xs={12}>
                        <MUIDataTable title="" data={teamAssignments || []} columns={columns} options={options} />
                    </Grid>
                </FormSection>
            </Paper>
        </Grid>
    );
};

export interface TeamAssignmentsProps {
    teamAssignments?: GetProfile_user_cycleAssignments[] | null;
}

export default TeamAssignments;
