import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormProvider, useForm } from 'react-hook-form';
import { PageWrapper, PageHeader, PageBody, FormSaveButtons, AcquisitionCycleForm } from '../../components';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_ACQUISITION_CYCLE } from '../../apollo/mutations';
import { CycleInputType, CycleStatus, SubCycleForm } from '../../apollo/generated/types/globalTypes';
import { FormMode } from '../../utils/Enums';
import { CreateAcquisitionCycle, CreateAcquisitionCycleVariables } from '../../apollo/generated/types/CreateAcquisitionCycle';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    backButton: {
        background: '#F0F0F0',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    ButtonDivider: {
        marginRight: '25px',
        height: '54px',
    },
});

const CreateAcquisitionCyclePage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [apiError, setAPIError] = useState<ApolloError | null>();
    const methods = useForm<CycleInputType>({
        defaultValues: {
            status: CycleStatus.DRAFT,
            title: '',
            externalId: '',
            estimatedCloseDate: new Date(),
            primaryContactId: null,
            departmentId: null,
            serviceId: null,
            subServiceId: null,
            distributionStatement:
                '<p>DISTRIBUTION STATEMENT D. Distribution authorized to Department of Defense and U.S. DoD contractors only; Operational Use; November 2020. Other requests for this document shall be referred to the DOTC Program Director at (973) 724-4485.</p>',
            templateId: null,
            subCycles: [
                {
                    id: null,
                    name: '',
                    formType: SubCycleForm.REQUEST_FOR_INFORMATION,
                    postedDate: new Date(),
                    lastUpdatedDate: new Date(),
                    originalClosingDate: new Date(),
                    currentClosingDate: new Date(),
                    originalArchiveDate: new Date(),
                    archiveDate: new Date(),
                    order: 0,
                },
            ],
            cycleFundings: [
                {
                    id: null,
                    fundingTypeId: null,
                    timeframe: new Date(),
                    fundingAmount: 0,
                },
            ],
        },
    });

    const [createCycle] = useMutation<CreateAcquisitionCycle, CreateAcquisitionCycleVariables>(CREATE_ACQUISITION_CYCLE);

    const onFormSubmit = async (data: CycleInputType, close: boolean) => {
        try {
            setAPIError(null);
            const result = await createCycle({
                variables: {
                    cycle: data,
                },
            });

            // Update Form with new Ids
            if (result && result.data && result.data.createAcquisitionCycle) {
                const cycle = result.data.createAcquisitionCycle;
                methods.reset(cycle);
                if (!close) {
                    history.push(`/acquisition-cycle/${cycle.id}/edit`);
                    enqueueSnackbar('Form Saved!', {
                        variant: 'success',
                    });
                }
                return cycle.subCycles.length > 0 ? `/acquisition-cycle/${cycle.id}/sub-cycle/${cycle.subCycles[0].id}/form` : false;
            }
            enqueueSnackbar('Error Saving Form!', {
                variant: 'error',
            });
            return false;
        } catch (error) {
            if (error) {
                setAPIError(error);
            }
            enqueueSnackbar('Error Saving Form!', {
                variant: 'error',
            });
            return false;
        }
    };

    return (
        <FormProvider {...methods}>
            <PageWrapper permission={'create:cycles'}>
                <PageHeader>
                    <Toolbar className={classes.header}>
                        <IconButton component={NavLink} to="/acquisition-cycles" className={classes.backButton}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h1" className={classes.title}>
                            Creating an Acquisition Cycle
                        </Typography>

                        <FormSaveButtons onSubmit={onFormSubmit} />
                    </Toolbar>
                </PageHeader>
                <PageBody>
                    <AcquisitionCycleForm mode={FormMode.Create} apiError={apiError} />
                </PageBody>
            </PageWrapper>
        </FormProvider>
    );
};

export default CreateAcquisitionCyclePage;
