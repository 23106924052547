import * as React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { ReactHookFormAutocomplete, ReactHookFormDate } from '../../../ReactHookForm';
import ReactHookFormCurrency from '../../../ReactHookForm/ReactHookFormCurrency';
import { CycleFundingInputType } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';
import { FundingTypeFields } from '../../../../apollo/generated/types/FundingTypeFields';
import { useCycleForm } from '../../../Providers/CycleFormProvider';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        marginBottom: '15px',
    },
    removeSection: {
        width: '50px',
    },
});

const FundingItem: React.FC<FundingItemProps> = ({ mode, index, remove, id, fundingTypeId, timeframe, fundingAmount }) => {
    const classes = useStyles();
    const { control, register } = useFormContext();
    const { fundingTypes, addFundingType, loading } = useCycleForm();
    const readOnly = mode === FormMode.View;

    return (
        <div className={classes.root}>
            {id ? <input name={`cycleFundings[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} defaultValue={id} /> : null}
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ReactHookFormAutocomplete
                        label="Funding Instrument Type"
                        name={`cycleFundings[${index}].fundingTypeId`}
                        helperText="Type to filter or add new value"
                        options={loading ? [{ id: fundingTypeId, name: 'Loading...' } as FundingTypeFields] : fundingTypes}
                        addOption={addFundingType}
                        defaultValue={fundingTypeId}
                        getOptionSelected={(option: FundingTypeFields, value?: number | FundingTypeFields) => {
                            if (!value) {
                                return false;
                            }
                            if (typeof value === 'number') {
                                return option.id === value;
                            } else {
                                return option.id === value.id;
                            }
                        }}
                        getOptionValue={(option: FundingTypeFields | null) => (option ? option?.id : null)}
                        getOptionLabel={(option: FundingTypeFields) => option?.name || ''}
                        renderOption={(option: FundingTypeFields) => option?.name}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        views={['year', 'month']}
                        label="Funding Timeframe"
                        name={`cycleFundings[${index}].timeframe`}
                        defaultValue={timeframe}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormCurrency
                        control={control}
                        label="Identified Funding Amount"
                        name={`cycleFundings[${index}].fundingAmount`}
                        readOnly={readOnly}
                        defaultValue={fundingAmount}
                    />
                </Grid>
            </Grid>
            {mode !== FormMode.View ? (
                <div className={classes.removeSection}>
                    <IconButton aria-label="remove" onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                </div>
            ) : null}
        </div>
    );
};

export interface FundingItemProps extends CycleFundingInputType {
    mode: FormMode;
    index: number;
    remove: (index: number) => void;
}

export default FundingItem;
