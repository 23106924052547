import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Paper, IconButton, Toolbar, Typography, Button, DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions } from '@material-ui/core';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER } from '../../apollo/mutations';
import { GET_USER } from '../../apollo/queries';
import { LoadingButton, UserForm, PageWrapper, PageHeader, PageBody, LoadingMessage, ErrorMessage, usePermissions } from '../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { GetUser, GetUserVariables } from '../../apollo/generated/types/GetUser';
import { UpdateUser, UpdateUserVariables } from '../../apollo/generated/types/UpdateUser';
import { UserInputType } from '../../apollo/generated/types/globalTypes';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        flex: 1,
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogCreated: {
        textDecoration: 'underline',
        fontWeight: 900,
    },
    dialogDescription: {
        color: '#1A2A3D',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
    },
});

interface URLParams {
    id: string;
}

const EditUserPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const [email, setEmail] = useState<string>();
    const methods = useForm<UserInputType>();
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [apiError, setAPIError] = useState<ApolloError | null>();
    const { updateUserInfo } = usePermissions();

    const { loading, error } = useQuery<GetUser, GetUserVariables>(GET_USER, {
        variables: {
            userId: Number(id),
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            methods.reset({ ...data.user });
        },
    });

    const [updateUser, { loading: mutationLoading }] = useMutation<UpdateUser, UpdateUserVariables>(UPDATE_USER, {
        onCompleted: (data: UpdateUser) => {
            setEmail(data.updateUser?.email);
            setAPIError(null);
            updateUserInfo();
            setOpenSuccess(true);
        },
        onError: (error: ApolloError) => {
            console.error(error.message);
            setAPIError(error);
        },
    });

    const handleClose = () => {
        setOpenSuccess(false);
    };

    const handleDialogViewUsers = () => {
        setOpenSuccess(false);
        history.push('/users');
    };

    const onSubmitUser = (data: UserInputType) => {
        updateUser({
            variables: {
                user: data,
            },
        });
    };

    return (
        <PageWrapper permission={'update:users'}>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <IconButton onClick={history.goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1" className={classes.title}>
                        Edit a User
                    </Typography>
                    {!loading || !error ? (
                        <LoadingButton pending={mutationLoading} form="user-form" type="submit" variant="contained">
                            Update User
                        </LoadingButton>
                    ) : null}
                </Toolbar>
            </PageHeader>
            <PageBody>
                {loading ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : (
                    <div className={classes.root}>
                        <Paper className={classes.paper} elevation={0}>
                            <FormProvider {...methods}>
                                <UserForm onSubmit={onSubmitUser} apiError={apiError} />
                            </FormProvider>
                        </Paper>

                        <Dialog
                            open={openSuccess}
                            disableBackdropClick
                            disableEscapeKeyDown
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">
                                <div className={classes.centerItems}>
                                    <CheckCircleIcon style={{ color: '#0EAE79', fontSize: 80 }} />
                                    <Typography variant="h1">
                                        You've successfully updated <span className={classes.dialogCreated}>{email}</span>
                                    </Typography>
                                </div>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description" className={classes.dialogDescription}>
                                    We can take you back to view users, or you can continue editing.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions className={classes.centerItems}>
                                <Button onClick={handleDialogViewUsers} autoFocus variant="contained">
                                    View Users
                                </Button>
                                <Button onClick={handleClose} variant="text">
                                    Continue editing
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default EditUserPage;
