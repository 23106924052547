import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

// Create custom MaterialUI theme
export const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#91A0BA',
            main: '#222A43',
        },
        secondary: {
            main: '#FFFFFF',
        },
        warning: {
            main: '#EDAC43',
            light: 'rgba(237, 172, 67, 0.4)',
        },
        success: {
            main: '#0EAE79',
            light: 'rgba(14, 174, 121, 0.4)',
        },
        error: {
            main: '#ED5531',
            light: 'rgba(237, 85, 49, 0.4)',
        },
    },
    typography: {
        fontFamily: ['Lato', 'sans-serif'].join(','),
        h1: {
            fontSize: '22px',
            lineHeight: '27px',
            letterSpacing: '-0.33px',
            fontWeight: 600,
            color: '#1A2A3D',
        },
        h2: {
            fontSize: '20px',
            lineHeight: '24px',
            letterSpacing: '0',
            fontWeight: 600,
            color: '#1A2A3D',
        },
        h3: {
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0',
            fontWeight: 400,
            color: '#1A2A3D',
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
    },
});

Object.assign(theme, {
    overrides: {
        MuiChip: {
            root: {
                'color': 'white',
                'backgroundColor': '#1976D2',
                '&:hover': {
                    backgroundColor: '#1F8BF3',
                },
            },
        },
        MuiToolbar: {
            gutters: {
                [defaultTheme.breakpoints.up('sm')]: {
                    paddingLeft: '30px',
                },
                paddingLeft: '30px',
            },
        },
        MuiButton: {
            text: {
                color: '#1A2A3D',
                height: '45px',
                padding: '14px 20px',
            },
            contained: {
                'color': 'white',
                'backgroundColor': '#1976D2',
                'borderRadius': '2px',
                'height': '45px',
                'padding': '14px 20px',
                '&:hover': {
                    backgroundColor: '#1F8BF3',
                },
            },
            containedSecondary: {
                'color': '#222A43',
                'backgroundColor': '#E4E5E8',
                'borderRadius': '2px',
                'height': '45px',
                'padding': '14px 20px',
                '&:hover': {
                    backgroundColor: '#C3DAF2',
                },
            },
            label: {
                fontSize: '15px',
                lineHeight: '18px',
                letterSpacing: '0.23px',
                textTransform: 'none',
            },
        },
        MuiInputLabel: {
            shrink: {
                fontSize: '12px',
                lineHeight: '15px',
                letterSpacing: '0.12px',
                fontWeight: 500,
                color: '#808080',
                transform: 'translate(0, 1.5px) scale(1)',
            },
        },
        MuiAccordionSummary: {
            root: {
                background: '#F2F4F4',
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: '2px',
            },
        },
        MuiCircularProgress: {
            colorPrimary: {
                color: '#1976D2',
            },
        },
        MuiTab: {
            root: {
                '&$selected': { color: '#1976D2' },
                'maxWidth': '265px',
            },
            wrapper: {
                flexDirection: 'row',
                textTransform: 'none',
                fontSize: '16px',
                lineHeight: '18px',
                letterSpacing: '-0.24px',
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#1976D2',
            },
        },
        MuiBreadcrumbs: {
            root: {
                fontSize: '20px',
                lineHeight: '24px',
                letterSpacing: '-0.3px',
                fontWeight: 600,
            },
        },
        MuiTableCell: {
            head: {
                fontWeight: 600,
            },
        },
        MUIDataTableFilter: {
            title: {
                verticalAlign: 'middle',
            },
            resetLink: {
                '&.MuiButton-root .MuiButton-label': {
                    fontSize: '14px',
                },
            },
        },
        MuiTableRow: {
            root: {
                '&$selected': {
                    backgroundColor: 'rgba(14, 174, 121, 0.15) !important',
                },
            },
        },
        MUIRichTextEditor: {
            container: {
                margin: 0,
            },
            root: {
                border: '1px solid #D5D5D5',
                borderRadius: '3px',
                marginTop: '30px',
            },
            editor: {
                padding: '10px',
                height: '200px',
                minHeight: '200px',
                maxHeight: '200px',
                overflow: 'auto',
            },
            toolbar: {
                borderBottom: '1px solid #D5D5D5',
                padding: '6px 12px',
            },
            placeHolder: {
                paddingLeft: 10,
                paddingTop: 10,
                width: '100%',
                height: '190px',
            },
        },
        MuiAvatar: {
            colorDefault: {
                color: 'rgb(25, 118, 210)',
                backgroundColor: 'rgba(25, 118, 210, 0.5)',
            },
        },
        MuiAlert: {
            standardWarning: {
                'color': '#1A2A3D',
                'backgroundColor': '#f8deb4',
                '& .MuiAlert-icon': {
                    color: '#1A2A3D',
                },
            },
        },
    },
});
