import * as React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import FundingItem from './FundingItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCallback } from 'react';
import { CycleFundingInputType } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';

const FundingsForm: React.FC<FundingsFormProps> = ({ mode }) => {
    const { control } = useFormContext();
    const { fields: fundings, append, remove } = useFieldArray<CycleFundingInputType, 'key'>({
        control,
        name: 'cycleFundings',
        keyName: 'key',
    });
    const readOnly = mode === FormMode.View;

    const addFunding = () => {
        append({
            timeframe: null,
            fundingAmount: null,
        });
    };

    const removeFunding = useCallback(
        (index: number) => {
            remove(index);
        },
        [remove],
    );

    return (
        <>
            <FormSection justify="space-between">
                <Grid item>
                    <Typography variant="h1">Funding</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    {readOnly && (!fundings || fundings.length === 0) ? <div>No Data</div> : null}
                    {fundings.map((funding, index) => (
                        <FundingItem key={funding.key} {...funding} index={index} remove={removeFunding} mode={mode} />
                    ))}
                </Grid>
                {!readOnly ? (
                    <Grid item xs={12}>
                        <Button startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />} onClick={addFunding}>
                            Add Funding Source
                        </Button>
                    </Grid>
                ) : null}
            </FormSection>
        </>
    );
};

export interface FundingsFormProps {
    mode: FormMode;
}

export default FundingsForm;
