import * as React from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '../../../../index';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SubCycleUserInputType } from '../../../../../apollo/generated/types/globalTypes';
import { GET_SUB_CYCLES_FULL } from '../../../../../apollo/queries';
import { GetSubCyclesFull, GetSubCyclesFull_subCycles } from '../../../../../apollo/generated/types/GetSubCyclesFull';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ADD_SUB_CYCLE_USERS, REMOVE_SUB_CYCLE_USERS } from '../../../../../apollo/mutations';
import { AddSubCycleUsers } from '../../../../../apollo/generated/types/AddSubCycleUsers';
import { RemoveSubCycleUsers } from '../../../../../apollo/generated/types/RemoveSubCycleUsers';
import { GetCycleEvaluatorsFull_cycleUsers } from '../../../../../apollo/generated/types/GetCycleEvaluatorsFull';
import FormDivider from '../../../../Form/FormDivider';
import SubCycleOption from './SubCycleOption';

const useStyles = makeStyles({
    evaluators: {
        marginBottom: '10px',
    },
});

interface FormInput {
    selectedSubCycles: Array<SelectSubCycle>;
}

interface SelectSubCycle {
    subCycleId: number;
    subCycleName: string | null;
    order: number;
    selected: boolean;
}

const SubCyclesModal: React.FC<SubCyclesModalProps> = ({ assign, selectedEvaluators, cycleId, open, onClose }) => {
    const classes = useStyles();
    const [modalError, setModalError] = useState<ApolloError | null>(null);
    // compile a list of the Sub-Cycles currently assigned to the user(s)
    const assignedSubCycles = new Set<number>();
    selectedEvaluators.forEach((evaluator) => {
        if (evaluator.user && evaluator.user.subCycleAssignments) {
            evaluator.user.subCycleAssignments.forEach((subCycleAssignment) => {
                if (subCycleAssignment.subCycle) assignedSubCycles.add(subCycleAssignment.subCycle.id);
            });
        }
    });

    const methods = useForm<FormInput>({ defaultValues: { selectedSubCycles: [] } });
    const { handleSubmit, reset, watch } = methods;
    const observedSubCycles = watch('selectedSubCycles', []);
    const [subCycleOptions, setSubCycleOptions] = useState<Array<SelectSubCycle>>([]);

    const [addSubCycleUsers, { loading: addLoading }] = useMutation<AddSubCycleUsers>(ADD_SUB_CYCLE_USERS);
    const [removeSubCycleUsers, { loading: removeLoading }] = useMutation<RemoveSubCycleUsers>(REMOVE_SUB_CYCLE_USERS);
    const [getSubCycleData] = useLazyQuery<GetSubCyclesFull>(GET_SUB_CYCLES_FULL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: cycleId,
        },
        onCompleted: (data) => {
            const subCycles = new Array<SelectSubCycle>();
            // Filter out subcycles
            if (data && data.subCycles) {
                let filteredSubCycles;
                // If the user is assigning sub-cycles, allow all as options (duplicates will be ignored on the backend)
                if (assign) filteredSubCycles = data.subCycles;
                // If the user is removing sub-cycles, only display the ones already assigned to the selected evaluator
                else filteredSubCycles = data.subCycles.filter((subCycle) => assignedSubCycles.has(subCycle.id));

                filteredSubCycles.forEach((subCycle: GetSubCyclesFull_subCycles) =>
                    subCycles.push({ subCycleId: subCycle.id, subCycleName: subCycle.name, order: subCycle.order, selected: false } as SelectSubCycle),
                );
            }

            // Generate Sub-Cycle option components
            setSubCycleOptions(subCycles);

            // Reset Form
            reset({
                selectedSubCycles: subCycles,
            });
        },
    });

    useEffect(() => {
        setModalError(null);
        if (open) {
            getSubCycleData();
        }
    }, [getSubCycleData, open]);

    const onFormSubmit = async (data: FormInput) => {
        try {
            // Filter out unselected sub-cycles
            const selectedSubCycles = data.selectedSubCycles.filter((user) => user.selected);
            // For every evaluator, generate SubCycleUserInput objects
            const subCycleUsers = new Array<SubCycleUserInputType>();
            selectedEvaluators.forEach((evaluator) => {
                if (evaluator.user) {
                    subCycleUsers.push(
                        ...selectedSubCycles.map(
                            (subCycle) =>
                                ({
                                    userId: evaluator.user?.id,
                                    subCycleId: subCycle.subCycleId,
                                } as SubCycleUserInputType),
                        ),
                    );
                }
            });

            // Determine submission action
            if (assign) await onAssign(subCycleUsers);
            else await onRemove(subCycleUsers);
            setModalError(null);
            onClose();
        } catch (e) {
            setModalError(e);
        }
    };
    const onAssign = async (addUsers: SubCycleUserInputType[]) => {
        await addSubCycleUsers({
            variables: {
                subCycleUsers: addUsers,
            },
        });
    };
    const onRemove = async (removeUsers: SubCycleUserInputType[]) => {
        await removeSubCycleUsers({
            variables: {
                subCycleUsers: removeUsers,
            },
        });
    };

    const actionStr = assign ? 'Assign' : 'Remove';
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true} disableBackdropClick={true}>
            <DialogTitle id="customized-dialog-title">{`${actionStr} Sub-Cycles`}</DialogTitle>
            <DialogContent dividers>
                <div>
                    <form>
                        <FormProvider {...methods}>
                            {/* List of selected evaluators */}
                            <div className={classes.evaluators}>
                                <Typography variant="h3">Selected Evaluator(s):</Typography>
                                {selectedEvaluators
                                    ? selectedEvaluators.map((evaluator) => (
                                          <Chip size="small" label={evaluator.user?.fullName} color="primary" key={evaluator.user?.id} />
                                      ))
                                    : null}
                            </div>
                            {/* List of possible sub-cycles to assign/remove */}
                            {subCycleOptions && subCycleOptions.length > 0 ? (
                                <>
                                    <Typography variant="h3">Sub-Cycles</Typography>
                                    <Grid container>
                                        {subCycleOptions
                                            .sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
                                            .map((subCycleOption, index) => (
                                                <Grid item xs={4} key={subCycleOption.subCycleId ?? index}>
                                                    <SubCycleOption
                                                        id={subCycleOption.subCycleId}
                                                        name={subCycleOption.subCycleName}
                                                        selected={subCycleOption.selected}
                                                        index={index}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                </>
                            ) : null}
                            {modalError ? (
                                <>
                                    <FormDivider />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Alert severity="error">
                                                <AlertTitle>Error</AlertTitle>
                                                {modalError?.message}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}
                        </FormProvider>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    pending={addLoading || removeLoading}
                    variant="contained"
                    onClick={handleSubmit(onFormSubmit)}
                    disabled={!observedSubCycles.some((sc) => sc.selected)}>
                    {actionStr}
                </LoadingButton>
                <Button variant="contained" color="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export interface SubCyclesModalProps {
    assign: boolean;
    selectedEvaluators: Array<GetCycleEvaluatorsFull_cycleUsers>;
    cycleId: number;
    open: boolean;
    onClose: () => void;
}

export default SubCyclesModal;
