import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_CYCLE } from '../../../apollo/queries';
import { GetCycle, GetCycleVariables } from '../../../apollo/generated/types/GetCycle';
import { CycleStatus } from '../../../apollo/generated/types/globalTypes';

interface CycleDataProviderProps {
    id?: number;
}

interface CycleDataContextProps {
    loading: boolean;
    error?: ApolloError;
    readOnly: boolean;
}

const CycleDataContext = createContext<CycleDataContextProps | undefined>(undefined);

const CycleDataProvider: React.FC<CycleDataProviderProps> = ({ id, children }) => {
    const [loading, setLoading] = useState(true);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const { data: cycleData, loading: cycleLoading, error } = useQuery<GetCycle, GetCycleVariables>(GET_CYCLE, {
        skip: !id,
        variables: {
            id: Number(id),
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        setLoading(cycleLoading);
        if (cycleData) {
            const cycle = cycleData.cycle;
            if (cycle) {
                setReadOnly(cycle.status === CycleStatus.COMPLETED || cycle.status === CycleStatus.ARCHIVED);
            }
        } else {
            setReadOnly(true);
        }
    }, [cycleData, cycleLoading]);

    return (
        <CycleDataContext.Provider
            value={{
                loading,
                error,
                readOnly,
            }}>
            {children}
        </CycleDataContext.Provider>
    );
};

const useCycleData = (): CycleDataContextProps => {
    const context = React.useContext(CycleDataContext);
    if (context === undefined) {
        throw new Error('useCycleData must be used within a CycleDataProvider');
    }
    return context;
};

export { CycleDataProvider, useCycleData };
