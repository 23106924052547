import * as React from 'react';

import { Grid, Typography, Paper } from '@material-ui/core';
import { FormMode } from '../../../../../utils/Enums';
import FPMSuccessFactorForm from './FPMSuccessFactorForm';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { SubCycleForm, SuccessFactorInputType, FpmCategory } from '../../../../../apollo/generated/types/globalTypes';
import { GetSubCycleSuccessFactorForm_subCycle_form } from '../../../../../apollo/generated/types/GetSubCycleSuccessFactorForm';
import { TopicWithSuccessFactorsFields } from '../../../../../apollo/generated/types/TopicWithSuccessFactorsFields';

import CommentBoxTemp from '../../../../CommentBox/CommentBoxTemp';

const TopicFPMContainerForm: React.FC<TopicFPMContainerFormProps> = ({
    mode,
    formType,
    form,
    topicIndex,
    organizationSectionRef,
    applicationSectionRef,
    collaborationSectionRef,
    previousFormType,
    subCycleOrder,
    topicInfo,
}) => {
    const { control } = useFormContext();

    let hasBriefResponse = false;
    if (form && formType === SubCycleForm.REQUEST_FOR_INFORMATION && form.__typename === 'RequestForInformationFormType') {
        hasBriefResponse = form.hasBriefResponse;
    }

    const { fields: successFactors, append, remove } = useFieldArray<SuccessFactorInputType, 'key'>({
        control,
        name: `topics[${topicIndex}].successFactors`, // unique name for your Field Array
        keyName: 'key',
    });

    return (
        <>
            <Grid item xs={12}>
                <Paper elevation={0}>
                    <FPMSuccessFactorForm
                        mode={mode}
                        sectionNumber={0}
                        topicIndex={topicIndex}
                        formType={formType}
                        successFactors={successFactors}
                        append={append}
                        remove={remove}
                        previousFormType={previousFormType}
                        subCycleOrder={subCycleOrder}
                        topicRisks={topicInfo.risks.filter((x) => x.fpmCategory === FpmCategory.PRODUCT_TECHNOLOGY)}
                    />
                </Paper>
            </Grid>
            {!hasBriefResponse ? (
                <>
                    <Grid item xs={12} ref={organizationSectionRef}>
                        <Paper elevation={0}>
                            <FPMSuccessFactorForm
                                mode={mode}
                                sectionNumber={1}
                                topicIndex={topicIndex}
                                formType={formType}
                                successFactors={successFactors}
                                append={append}
                                remove={remove}
                                previousFormType={previousFormType}
                                subCycleOrder={subCycleOrder}
                                topicRisks={topicInfo.risks.filter((x) => x.fpmCategory === FpmCategory.ORGANIZATION)}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} ref={applicationSectionRef}>
                        <Paper elevation={0}>
                            <FPMSuccessFactorForm
                                mode={mode}
                                sectionNumber={2}
                                topicIndex={topicIndex}
                                formType={formType}
                                successFactors={successFactors}
                                append={append}
                                remove={remove}
                                previousFormType={previousFormType}
                                subCycleOrder={subCycleOrder}
                                topicRisks={topicInfo.risks.filter((x) => x.fpmCategory === FpmCategory.APPLICATION)}
                            />
                        </Paper>
                    </Grid>
                </>
            ) : null}
            <Grid container item xs={12} ref={collaborationSectionRef}>
                <Grid item xs={12}>
                    <Typography variant="h2">SF Collaboration</Typography>
                    <CommentBoxTemp></CommentBoxTemp>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ height: '100%' }}></div>
                </Grid>
            </Grid>
        </>
    );
};

export interface TopicFPMContainerFormProps {
    mode: FormMode;
    formType?: SubCycleForm;
    form?: GetSubCycleSuccessFactorForm_subCycle_form | null;
    topicIndex: number;
    organizationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    applicationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    collaborationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    previousFormType: SubCycleForm | null;
    subCycleOrder: number;
    topicInfo: TopicWithSuccessFactorsFields;
}

export default TopicFPMContainerForm;
