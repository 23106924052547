import React from 'react';
import { makeStyles } from '@material-ui/core';

interface FundingBarProps {
    fundingAwardedItemsTotal: number;
    fundingRange: number;
}

const useStyles = makeStyles({
    containerStyles: { contain: 'content', height: 40, backgroundColor: 'rgba(14, 174, 121, .4)', borderRadius: 5, margin: '5px 20px 20px' },
    fillerStyles: {
        height: '100%',
        backgroundColor: '#0EAE79 ',
        borderRadius: 'inherit',
        textAlign: 'left',
        transition: 'width 1s',
    },
    labelStyles: {
        padding: '0px 5px',
        color: 'white',
        fontWeight: 'bold',
        margin: 'auto',
        lineHeight: '42px',
        zIndex: 1,
        position: 'absolute',
        height: '100%',
    },
});

const FundingBar: React.FC<FundingBarProps> = ({ fundingAwardedItemsTotal, fundingRange }) => {
    const classes = useStyles();
    const completed = (1 - fundingAwardedItemsTotal / fundingRange) * 100;
    function convertToInternationalCurrencySystem(labelValue) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + 'B'
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
            ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
            : // Three Zeroes for Thousands
            Math.abs(Number(labelValue)) >= 1.0e3
            ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + 'K'
            : Math.abs(Number(labelValue));
    }

    return (
        <div className={classes.containerStyles} style={{ position: 'relative' }}>
            <h3 className={classes.labelStyles}>
                {convertToInternationalCurrencySystem(fundingRange - fundingAwardedItemsTotal)} / {convertToInternationalCurrencySystem(fundingRange)}
            </h3>
            <div className={classes.fillerStyles} style={{ width: `${completed}%` }}></div>
        </div>
    );
};

export default FundingBar;
