import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_PRIMARY_CONTACTS } from '../../../../apollo/queries';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { UserDisplayFields } from '../../../../apollo/generated/types/UserDisplayFields';
import { GetPrimaryContacts } from '../../../../apollo/generated/types/GetPrimaryContacts';

const ContactSelect: React.FC<ContactSelectProps> = ({ readOnly, required }) => {
    const { control, getValues, errors } = useFormContext();
    const { loading, error, data } = useQuery<GetPrimaryContacts>(GET_PRIMARY_CONTACTS, {
        fetchPolicy: 'cache-and-network',
    });

    const requiredAsterisk = required ? '*' : '';
    return (
        <ReactHookFormSelect
            fullWidth
            control={control}
            label={`Primary Contact${requiredAsterisk}`}
            name="primaryContactId"
            defaultValue={getValues('primaryContactId')}
            disabled={loading || !!error}
            loading={loading}
            readOnly={readOnly}
            error={!!error || !!errors.primaryContactId}
            helperText={error ? 'Unable to retrieve data' : errors.primaryContactId?.message}
            rules={{ required: required ? 'Primary Contact is required' : false }}>
            {!data || !data.primaryContacts || data.primaryContacts.length <= 0 ? (
                <MenuItem disabled>No Data Available</MenuItem>
            ) : (
                data.primaryContacts.map((user: UserDisplayFields) => (
                    <MenuItem key={user.id} value={user.id}>
                        {user.fullName}
                    </MenuItem>
                ))
            )}
        </ReactHookFormSelect>
    );
};

export interface ContactSelectProps {
    required: boolean;
    readOnly: boolean;
}

export default ContactSelect;
