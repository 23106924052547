import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { RegisterOptions, Controller } from 'react-hook-form';
import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const ReactHookFormTextArea: React.FC<ReactHookFormTextAreaProps> = ({
    name,
    label,
    defaultValue,
    control,
    error,
    helperText,
    rules,
    placeHolder,
    subHeading,
    infoBoxText,
    readOnly,
    hidden,
    ...formProps
}) => {
    const useStyles = makeStyles(() => ({
        textEditor: {
            'marginTop': subHeading || infoBoxText ? '15px' : '30px',
            'height': '200px',
            'display': 'flex',
            'flexDirection': 'column',
            '&>.ql-toolbar': {
                borderRadius: '2px 2px 0 0',
            },
            '&>.ql-container': {
                borderRadius: '0 0 2px 2px',
                flex: 1,
                maxHeight: '157px',
            },
        },
        informationBox: {
            marginTop: '30px',
        },
        subHeading: {
            color: '#808080',
            fontSize: '12px',
            transform: 'translate(0, 10px) scale(1)',
            fontWeight: 500,
            lineHeight: '15px',
            letterSpacing: '0.12px',
            transformOrigin: 'top left',
            marginTop: '15px',
            fontStyle: 'italic',
        },
    }));

    const classes = useStyles();

    return (
        <FormControl {...formProps} style={{ display: hidden ? 'none' : 'inline-flex' }}>
            <InputLabel shrink error={error}>
                {label}
            </InputLabel>
            {subHeading ? (
                <div className={classes.subHeading}>{subHeading}</div>
            ) : infoBoxText ? (
                <Alert className={classes.informationBox} severity="info">
                    {infoBoxText}
                </Alert>
            ) : (
                ''
            )}
            <Controller
                name={name}
                control={control}
                rules={rules}
                theme="snow"
                defaultValue={defaultValue}
                render={({ ref, onChange, value }) => {
                    return (
                        <ReactQuill
                            onChange={(content: string) => {
                                onChange(content);
                            }}
                            value={value}
                            ref={ref}
                            className={classes.textEditor}
                            readOnly={readOnly}
                            modules={{
                                toolbar: [
                                    [{ header: '1' }, { header: '2' }, { font: [] }],
                                    [{ size: [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ color: [] }, { background: [] }],
                                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                    ['link'],
                                    ['clean'],
                                ],
                                clipboard: {
                                    // toggle to add extra line breaks when pasting HTML:
                                    matchVisual: false,
                                },
                            }}
                            formats={[
                                'header',
                                'font',
                                'size',
                                'bold',
                                'italic',
                                'underline',
                                'color',
                                'background',
                                'strike',
                                'blockquote',
                                'list',
                                'bullet',
                                'indent',
                                'link',
                            ]}
                            placeholder={placeHolder}
                            id="body"
                        />
                    );
                }}
            />
            {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};
export interface ReactHookFormTextAreaProps extends FormControlProps {
    name: string;
    label?: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: string;
    helperText?: string;
    placeHolder?: string;
    subHeading?: React.ReactNode;
    infoBoxText?: string;
    readOnly?: boolean;
    hidden?: boolean;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormTextArea;
