import React, { useCallback, useEffect } from 'react';
import { createContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
    GetResponseUsers,
    GetResponseUsers_responseUsers,
    GetResponseUsers_responseUsers_evaluatorScores,
} from '../../../apollo/generated/types/GetResponseUsers';
import { GET_RESPONSE_USERS, GET_TOPIC_SUCCESS_FACTORS } from '../../../apollo/queries';
import { GetTopicSuccessFactors } from '../../../apollo/generated/types/GetTopicSuccessFactors';
import { AddTeamLead, AddTeamLeadVariables } from '../../../apollo/generated/types/AddTeamLead';
import { ResponseUserInputType } from '../../../apollo/generated/types/globalTypes';
import { ADD_TEAM_LEAD } from '../../../apollo/mutations';

interface TeamLeadScoreProviderProps {
    responseId?: number;
    userId?: number;
    isTeamLead?: boolean;
}

interface TeamLeadScoreContextProps {
    teamLeadEvaluation?: GetResponseUsers_responseUsers;
    evaluations: GetResponseUsers_responseUsers[];
    loading: boolean;
    evaluationIndex: number;
    hasBriefResponse: boolean;
    handleNextEvaluation: () => void;
    handlePreviousEvaluation: () => void;
    handleSelectEvaluation: (i: number) => void;
}

const TeamLeadScoreContext = createContext<TeamLeadScoreContextProps | undefined>(undefined);

const TeamLeadScoreProvider: React.FC<TeamLeadScoreProviderProps> = ({ responseId, userId, isTeamLead, children }) => {
    const [teamLeadEvaluation, setTeamLeadEvaluation] = useState<GetResponseUsers_responseUsers>();
    const [evaluations, setEvaluations] = useState<GetResponseUsers_responseUsers[]>([]);
    const [evaluationIndex, setEvaluationIndex] = useState<number>(0);
    const [hasBriefResponse, setHasBriefResponse] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    // Query for fetching current evaluations
    const { data: responseUserData } = useQuery<GetResponseUsers>(GET_RESPONSE_USERS, {
        skip: !responseId || !userId,
        variables: {
            responseId: responseId,
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    // Query for fetching success factor data
    const { data: successFactorData } = useQuery<GetTopicSuccessFactors>(GET_TOPIC_SUCCESS_FACTORS, {
        fetchPolicy: 'no-cache',
        skip: !responseUserData?.responseUsers || responseUserData?.responseUsers.length === 0,
        variables: {
            topicId: responseUserData?.responseUsers ? responseUserData?.responseUsers[0].response.topic?.id : -1,
        },
    });

    const [addTeamLead] = useMutation<AddTeamLead, AddTeamLeadVariables>(ADD_TEAM_LEAD);

    /**
     * Averages scores for each Success Factor to populate new Team Lead SF Score fields
     * @param teamLeadSFs Empty Team Lead scores
     * @param evaluations Current user evaluations
     * @returns List of Team Lead SF objects with averaged evaluator scores
     */
    const addAverageSFScores = (teamLeadSFs: GetResponseUsers_responseUsers_evaluatorScores[], evaluations: GetResponseUsers_responseUsers[]) => {
        const sfMap = evaluations.reduce((sfObj, evaluation) => {
            (evaluation.evaluatorScores ?? []).forEach((score) => {
                const sfId = `${score.successFactor.id ?? ''}`;
                // add object to keep track of total score for average
                if (!Object.keys(sfObj).includes(sfId)) {
                    sfObj[sfId] = { scoreTotal: 0, count: 0 };
                }

                // sum scores and keep track of # of scores entered for each 
                if (score.score) {
                    const sf = sfObj[sfId];
                    const newTotal = sf.scoreTotal + parseFloat(score.score);
                    const newCount = sf.count + 1;
                    sfObj[sfId] = { scoreTotal: newTotal, count: newCount };
                }
            });

            return sfObj;
        }, {});

        const newTeamLeadSFs: GetResponseUsers_responseUsers_evaluatorScores[] = [];
        teamLeadSFs.forEach((teamLeadSF) => {
            const sfId = `${teamLeadSF.successFactor.id ?? ''}`;
            let avg: number | null = null;
            // calc average
            if (Object.keys(sfMap).includes(sfId)) {
                const sf = sfMap[sfId];
                avg = parseFloat((sf.scoreTotal / sf.count).toFixed(1));
                avg = isNaN(avg) ? null : avg;
            }

            newTeamLeadSFs.push({
                ...teamLeadSF,
                score: teamLeadSF.score ? teamLeadSF.score : avg,
            } as GetResponseUsers_responseUsers_evaluatorScores);
        });

        return newTeamLeadSFs;
    };

    //  Extracts response user info from query data
    const setEvaluatorData = useCallback(async () => {
        if (responseUserData?.responseUsers && successFactorData?.successFactors) {
            const successFactors = successFactorData.successFactors;
            const responseUsers = responseUserData.responseUsers;
            // Isolate Non-team leads
            const currEvaluations = responseUsers.filter((user) => !user.isTeamLead);

            // Isolate Team Lead (if there is one)
            const teamLeadOptions = responseUsers.filter((user) => user.isTeamLead);
            let teamLead = teamLeadOptions.length === 1 ? teamLeadOptions[0] : undefined;
            let scores = teamLead?.evaluatorScores ?? [];
            if (!teamLead && isTeamLead) {
                // Create a new ResponseUser for the Team Lead evaluation
                const teamLeadData = (
                    await addTeamLead({
                        fetchPolicy: 'no-cache',
                        variables: {
                            responseUser: { userId: userId, responseId: responseId, isTeamLead: true } as ResponseUserInputType,
                        },
                    })
                ).data?.addResponseUsers;

                if (teamLeadData && teamLeadData.length > 0) {
                    teamLead = teamLeadData[0];
                }
            }

            if (teamLead) {
                // Add new scores for Success Factors
                scores.push(
                    ...successFactors
                        .filter((sFactor) => !scores?.some((score) => score.successFactor.id === sFactor.id))
                        .map(
                            (successFactor) =>
                                ({
                                    responseUserId: teamLead?.id ?? -1,
                                    successFactor: {
                                        id: successFactor.id,
                                        fpmCategory: successFactor.fpmCategory,
                                        name: successFactor.name,
                                        definition: successFactor.definition,
                                        weight: successFactor.weight,
                                    },
                                } as GetResponseUsers_responseUsers_evaluatorScores),
                        ),
                );

                scores = addAverageSFScores(scores, currEvaluations);

                setTeamLeadEvaluation({
                    id: teamLead.id,
                    userId: teamLead.userId,
                    user: teamLead.user,
                    response: teamLead.response,
                    status: teamLead.status,
                    evaluatorScores: scores,
                } as GetResponseUsers_responseUsers);
            }

            setHasBriefResponse(
                currEvaluations.some((evaluation) => {
                    const form = evaluation.response.topic?.subCycle.form;
                    return form?.__typename === 'RequestForInformationFormType' && form.hasBriefResponse;
                }),
            );
            setEvaluations(currEvaluations);
            setLoading(false);
        }
    }, [responseUserData, successFactorData, addTeamLead, userId, responseId, isTeamLead]);

    // Evaluation Navigation functions
    const handleNextEvaluation = () => {
        if (evaluationIndex >= evaluations.length - 1) {
            setEvaluationIndex(0);
        } else {
            setEvaluationIndex(evaluationIndex + 1);
        }
    };

    const handlePreviousEvaluation = () => {
        if (evaluationIndex <= 0) {
            setEvaluationIndex(evaluations.length - 1);
        } else {
            setEvaluationIndex(evaluationIndex - 1);
        }
    };

    const handleSelectEvaluation = (id: number) => {
        const newEvaluationIndex = evaluations.findIndex((evaluation) => evaluation.id === id);

        if (newEvaluationIndex >= 0) {
            setEvaluationIndex(newEvaluationIndex);
        } else {
            console.log('NOT A VALID ID');
            setEvaluationIndex(0);
        }
    };

    useEffect(() => {
        if (responseUserData && successFactorData) setEvaluatorData();
    }, [responseUserData, successFactorData, setEvaluatorData]);

    return (
        <TeamLeadScoreContext.Provider
            value={{
                teamLeadEvaluation,
                evaluations,
                loading,
                evaluationIndex,
                hasBriefResponse,
                handleNextEvaluation,
                handlePreviousEvaluation,
                handleSelectEvaluation,
            }}>
            {children}
        </TeamLeadScoreContext.Provider>
    );
};

const useTeamLeadScore = (): TeamLeadScoreContextProps => {
    const context = React.useContext(TeamLeadScoreContext);
    if (context === undefined) {
        throw new Error('useTeamLeadScore must be used within a TeamLeadScore');
    }
    return context;
};

export { TeamLeadScoreProvider, useTeamLeadScore };
