/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CycleStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  COMPLETED = "COMPLETED",
  DRAFT = "DRAFT",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export enum DayOfWeek {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum FpmCategory {
  APPLICATION = "APPLICATION",
  ORGANIZATION = "ORGANIZATION",
  PRODUCT_TECHNOLOGY = "PRODUCT_TECHNOLOGY",
}

export enum FundamentalPrimeMeasurement {
  APPEAL = "APPEAL",
  APPLICATION_SIZE = "APPLICATION_SIZE",
  DELIVERY = "DELIVERY",
  DEMAND = "DEMAND",
  FINANCES = "FINANCES",
  PERSONNEL = "PERSONNEL",
  PROCESSES = "PROCESSES",
  RELIABILITY = "RELIABILITY",
  VALUE = "VALUE",
}

export enum ResponseStatus {
  COMPLETE = "COMPLETE",
  IN_PROGRESS = "IN_PROGRESS",
  LOCKED = "LOCKED",
  NOT_STARTED = "NOT_STARTED",
}

export enum Role {
  CO_LEAD = "CO_LEAD",
  EVALUATOR = "EVALUATOR",
  TEAM_LEAD = "TEAM_LEAD",
}

export enum SelectionStatus {
  AWARD_REJECTED = "AWARD_REJECTED",
  AWARD_SELECTED = "AWARD_SELECTED",
  DOWN_SELECTION_REJECTED = "DOWN_SELECTION_REJECTED",
  DOWN_SELECTION_SELECTED = "DOWN_SELECTION_SELECTED",
  PENDING = "PENDING",
}

export enum SubCycleForm {
  REQUEST_FOR_INFORMATION = "REQUEST_FOR_INFORMATION",
  REQUEST_FOR_PROPOSAL = "REQUEST_FOR_PROPOSAL",
  REQUEST_FOR_WHITE_PAPER = "REQUEST_FOR_WHITE_PAPER",
}

export enum SubCycleStatus {
  APPROVED = "APPROVED",
  COMPLETE = "COMPLETE",
  DRAFT = "DRAFT",
  OUT_FOR_RESPONSE = "OUT_FOR_RESPONSE",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export enum SuccessFactorStatus {
  APPROVED = "APPROVED",
  DRAFT = "DRAFT",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export interface CycleFundingInputType {
  id?: number | null;
  fundingTypeId?: number | null;
  timeframe?: any | null;
  fundingAmount?: any | null;
}

export interface CycleInputType {
  id?: number | null;
  status: CycleStatus;
  title: string;
  externalId?: string | null;
  primaryContactId?: number | null;
  estimatedCloseDate?: any | null;
  departmentId?: number | null;
  serviceId?: number | null;
  subServiceId?: number | null;
  templateId?: number | null;
  eligibleResponders?: number | null;
  distributionStatement?: string | null;
  subCycles?: (SubCycleInputType | null)[] | null;
  cycleFundings?: (CycleFundingInputType | null)[] | null;
}

export interface CycleUserInputType {
  userId: number;
  cycleRoleId: number;
}

export interface EvaluatorScoreInputType {
  id?: number | null;
  responseUserId: number;
  successFactorId: number;
  score?: any | null;
  comment?: string | null;
}

export interface ExportSuccessFactorsPdfInputType {
  subCycleId: number;
  topics: number[];
  showApplicationRisk: boolean;
  showOrganizationRisk: boolean;
  showProductTechnologyRisk: boolean;
}

export interface FileUploadInputType {
  id?: number | null;
  uploadToken?: string | null;
}

export interface FormFieldValueInputType {
  id?: number | null;
  name: string;
}

export interface FundingTypeInputType {
  name: string;
}

export interface PointOfContactInputType {
  id?: number | null;
  email: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  organizationId: number;
}

export interface RequestForInformationFormInputType {
  subCycleId: number;
  title?: string | null;
  status: SubCycleStatus;
  eligibleResponderTypeId?: number | null;
  totalBudget?: string | null;
  securityClassificationId?: number | null;
  externalId?: string | null;
  expectedAwards?: number | null;
  naicscode?: string | null;
  opportunityDescription?: string | null;
  backgroundOverview?: string | null;
  additionalInformation?: string | null;
  postedDate?: any | null;
  lastUpdatedDate?: any | null;
  originalClosingDate?: any | null;
  currentClosingDate?: any | null;
  originalArchiveDate?: any | null;
  archiveDate?: any | null;
  deadlineDateTime?: any | null;
  deadlineTimezone?: string | null;
  instructions?: string | null;
  responseInstructions?: string | null;
  hasBriefResponse: boolean;
  responseInstructionsFile?: FileUploadInputType | null;
  briefResponseDescription?: string | null;
  isAcceptingQuestionSubmission: boolean;
  submissionOfQuestions?: string | null;
  locationPlaceOfPerformance?: string | null;
  isCostSharing: boolean;
  timeHorizonId?: number | null;
  fundingSourceId?: number | null;
  technologyKeywords?: number[] | null;
  topics?: TopicInputType[] | null;
  pointOfContacts?: PointOfContactInputType[] | null;
  researchDevelopmentCategories?: ResearchDevelopmentCategoryInputType[] | null;
  referenceFiles?: FileUploadInputType[] | null;
}

export interface RequestForProposalFormInputType {
  subCycleId: number;
  title?: string | null;
  status: SubCycleStatus;
  eligibleResponderTypeId?: number | null;
  contractOfficeAddress?: string | null;
  basePeriod?: number | null;
  buildingNumberMailstop?: string | null;
  issuingOrganizationId?: number | null;
  securityClassificationId?: number | null;
  externalId?: string | null;
  naicsCode?: string | null;
  hasAwarding?: boolean | null;
  hasBasisForProposalReview: boolean;
  hasCostSharing: boolean;
  hasExportControl: boolean;
  hasFollowOnProduction: boolean;
  hasGeneralInformation: boolean;
  hasGovernmentInterfacing: boolean;
  hasIntroduction: boolean;
  hasLicensingRights: boolean;
  hasMandatoryGovernmentTerms: boolean;
  hasNonDisclosureStatement: boolean;
  hasPriceInformation: boolean;
  hasProposalPreparationInstructions: boolean;
  hasStatementOfWork: boolean;
  hasSubmissionInstructions: boolean;
  hasTechnicalProposalInstructions: boolean;
  awarding?: string | null;
  basisForProposalReview?: string | null;
  costSharingDescription?: string | null;
  exportControl?: string | null;
  followOnProduction?: string | null;
  generalInformation?: string | null;
  governmentInterfacing?: string | null;
  introduction?: string | null;
  licensingRights?: string | null;
  mandatoryGovernmentTerms?: string | null;
  nonDisclosureStatement?: string | null;
  priceInformation?: string | null;
  program?: string | null;
  proposalPreparationInstructions?: string | null;
  statementOfWork?: string | null;
  submissionInstructions?: string | null;
  technicalProposalInstructions?: string | null;
  updatedPublishedDateTime?: any | null;
  updatedPublishedTimeZone?: string | null;
  originalPublishedDateTime?: any | null;
  originalPublishedTimeZone?: string | null;
  updatedResponseDateTime?: any | null;
  updatedResponseTimeZone?: string | null;
  originalResponseDateTime?: any | null;
  originalResponseTimeZone?: string | null;
  updatedInactiveDateTime?: any | null;
  updatedInactiveTimeZone?: string | null;
  originalInactiveDateTime?: any | null;
  originalInactiveTimeZone?: string | null;
  locationPlaceOfPerformance?: string | null;
  topics?: TopicInputType[] | null;
  pointOfContacts?: PointOfContactInputType[] | null;
  researchDevelopmentCategories?: ResearchDevelopmentCategoryInputType[] | null;
  referenceFiles?: FileUploadInputType[] | null;
}

export interface RequestForWhitePaperFormInputType {
  subCycleId: number;
  status: SubCycleStatus;
  title?: string | null;
  externalId?: string | null;
  technologyObjective?: string | null;
  contractOfficeAddress?: string | null;
  buildingNumberMailstop?: string | null;
  securityClassificationId?: number | null;
  naicsCode?: string | null;
  hasDescriptionOfRequirement: boolean;
  descriptionOfRequirement?: string | null;
  hasStatementOfNeed: boolean;
  statementOfNeed?: string | null;
  statementOfNeedFile?: FileUploadInputType | null;
  hasBasisForSelectionEvaluationApproach: boolean;
  basisForSelectionEvaluationApproach?: string | null;
  hasContractorEvaluationAdministratorSupport: boolean;
  contractorEvaluationAdministratorSupport?: string | null;
  hasNontraditionalDefenseContractorDefinition: boolean;
  nontraditionalDefenseContractorDefinition?: string | null;
  hasExportControl: boolean;
  exportControl?: string | null;
  hasLegallyResponsibleEntity: boolean;
  legallyResponsibleEntity?: string | null;
  hasSystemForAwardManagement: boolean;
  systemForAwardManagement?: string | null;
  hasFollowOn: boolean;
  followOn?: string | null;
  instructions?: string | null;
  hasAcceptingQuestionSubmission: boolean;
  submissionOfQuestions?: string | null;
  additionalTermsAndConditions?: string | null;
  updatedPublishedDateTime?: any | null;
  updatedPublishedTimeZone?: string | null;
  originalPublishedDateTime?: any | null;
  originalPublishedTimeZone?: string | null;
  updatedResponseDateTime?: any | null;
  updatedResponseTimeZone?: string | null;
  originalResponseDateTime?: any | null;
  originalResponseTimeZone?: string | null;
  updatedInactiveDateTime?: any | null;
  updatedInactiveTimeZone?: string | null;
  originalInactiveDateTime?: any | null;
  originalInactiveTimeZone?: string | null;
  hasCostSharing: boolean;
  costSharingDescription?: string | null;
  locationPlaceOfPerformance?: string | null;
  basePeriod?: number | null;
  topics?: TopicInputType[] | null;
  pointOfContacts?: PointOfContactInputType[] | null;
  researchDevelopmentCategories?: ResearchDevelopmentCategoryInputType[] | null;
}

export interface ResearchDevelopmentCategoryInputType {
  id?: number | null;
  categoryId: number;
  fundingRange?: any | null;
  technologyReadinessLevelId?: number | null;
}

export interface ResponderRiskInputType {
  risk: FormFieldValueInputType;
  fpmCategory: FpmCategory;
}

export interface ResponderScoreInputType {
  successFactorId: number;
  score?: any | null;
}

export interface ResponseInputType {
  id?: number | null;
  topicId: number;
  organizationId: number;
  title?: string | null;
  pointOfContact: string;
  researchCategoryId?: number | null;
  fundingRequested?: number | null;
  selectedStatus: SelectionStatus;
  selfEvaluationFile: FileUploadInputType;
  responseFiles: FileUploadInputType[];
  responderScores: ResponderScoreInputType[];
  responderRisks: ResponderRiskInputType[];
}

export interface ResponseUserInputType {
  userId: number;
  responseId: number;
  isTeamLead?: boolean | null;
}

export interface ResponseUserScoreInputType {
  id: number;
  userId: number;
  responseId: number;
  reviewed?: boolean | null;
  statusId?: ResponseStatus | null;
  evaluatorScores: EvaluatorScoreInputType[];
  responseUserFiles?: FileUploadInputType[] | null;
}

export interface SubCycleInputType {
  cycleId?: number | null;
  id?: number | null;
  formType: SubCycleForm;
  name?: string | null;
  postedDate?: any | null;
  lastUpdatedDate?: any | null;
  originalClosingDate?: any | null;
  currentClosingDate?: any | null;
  originalArchiveDate?: any | null;
  archiveDate?: any | null;
  order: number;
}

export interface SubCycleSuccessFactorFormInputType {
  subCycleId: number;
  status: SuccessFactorStatus;
  topics: TopicSuccessFactorsInputType[];
}

export interface SubCycleUserInputType {
  userId: number;
  subCycleId: number;
}

export interface SubServiceInputType {
  serviceId: number;
  name: string;
  acronym: string;
}

export interface SuccessFactorInputType {
  id?: number | null;
  name: string;
  definition: string;
  fpmCategory: FundamentalPrimeMeasurement;
  weight?: any | null;
  imported: boolean;
}

export interface TemplateInputType {
  id?: number | null;
  name?: string | null;
  templateSubCycles?: (TemplateSubCycleInputType | null)[] | null;
}

export interface TemplateSubCycleInputType {
  id?: number | null;
  name: string;
  formType?: SubCycleForm | null;
  order: number;
}

export interface TopicInputType {
  id?: number | null;
  name: string;
  categories?: number[] | null;
  description: string;
  importedID?: number | null;
}

export interface TopicRiskCategoryInputType {
  fpmCategory: FpmCategory;
  risks?: number[] | null;
}

export interface TopicSuccessFactorsInputType {
  id: number;
  successFactors?: SuccessFactorInputType[] | null;
  riskCategories?: TopicRiskCategoryInputType[] | null;
}

export interface UploadTokenRequestType {
  subCycleId: number;
  fileName: string;
  fileSize: any;
}

export interface UserAvailabilityInputType {
  id?: number | null;
  userId?: number | null;
  day: DayOfWeek;
  startTime: any;
  endTime: any;
}

export interface UserInputType {
  id?: number | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  externalRoleName: string;
  externalRoleId: string;
}

export interface UserProfileInputType {
  id?: number | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  jobTitle?: string | null;
  employer?: string | null;
  independentFreelancer?: boolean | null;
  secondaryEmail?: string | null;
  workAddress?: string | null;
  countryOfCitizenship?: string | null;
  notAvailable?: boolean | null;
  userAvailabilities?: (UserAvailabilityInputType | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
