import gql from 'graphql-tag';
import {
    CategoryFragment,
    CycleFragment,
    FileFragment,
    FundingTypeFragment,
    OptionFragment,
    OptionWithParentFragment,
    ProfileFragment,
    RequestForInformationFormFragment,
    ResponderTypeFragment,
    SecurityClassificationFragment,
    SubCycleWithEvaluatorsFragment,
    SubServiceFragment,
    TemplateFragment,
    TemplateNameFragment,
    UserFragment,
    RequestForWhitePaperFormFragment,
    RequestForProposalFormFragment,
    SubCycleFormTypeFragment,
    TopicWithSuccessFactorsFragment,
    EvaluatorFragment,
    LibrarySuccessFactorFragment,
    ActiveSubCycleFormFragment,
    CurrentUserFragment,
    SuccessFactorsFragment,
    ResponseUserScoreFragment,
    TopicFragment,
    ResponseTableFragment,
} from './fragments';

export const GET_USERS = gql`
    query GetUsers {
        users {
            ...UserDisplayFields
        }
    }
    ${UserFragment}
`;

export const GET_AUTH0_ROLES = gql`
    query GetAuth0Roles {
        auth0Roles {
            id
            name
        }
    }
`;

export const GET_USER = gql`
    query GetUser($userId: Int) {
        user(userId: $userId) {
            id
            email
            firstName
            lastName
            phoneNumber
            externalRoleName
            externalRoleId
            isProfileComplete
        }
    }
`;

export const GET_CURRENT_USER = gql`
    query GetCurrentUser {
        currentUser {
            ...CurrentUserFields
        }
    }
    ${CurrentUserFragment}
`;

export const GET_PROFILE = gql`
    query GetProfile($userId: Int) {
        user(userId: $userId) {
            ...ProfileFields
        }
    }
    ${ProfileFragment}
`;

export const GET_CYCLES = gql`
    query GetCycles($search: String, $page: Int, $pageSize: Int, $orderBy: String, $orderDirection: String, $statusFilter: [CycleStatus]) {
        cycles(search: $search, page: $page, pageSize: $pageSize, orderBy: $orderBy, orderDirection: $orderDirection, statusFilter: $statusFilter) {
            items {
                id
                title
                externalId
                estimatedCloseDate
                status
                primaryContact {
                    fullName
                }
                cycleFundings(first: 1) {
                    fundingType {
                        name
                    }
                    fundingAmount
                }
            }
            pageInfo {
                total
            }
        }
    }
`;

export const GET_CYCLE = gql`
    query GetCycle($id: Int!) {
        cycle(id: $id) {
            id
            status
        }
    }
`;

export const GET_CYCLE_FULL = gql`
    query GetCycleFull($id: Int!) {
        cycle(id: $id) {
            ...CycleFields
        }
    }
    ${CycleFragment}
`;

export const GET_CYCLE_TITLE = gql`
    query GetCycleTitle($id: Int!) {
        cycle(id: $id) {
            id
            title
        }
    }
`;

export const GET_SUB_CYCLES_FULL = gql`
    query GetSubCyclesFull($id: Int!) {
        subCycles(id: $id) {
            ...SubCycleWithEvaluatorsFields
        }
    }
    ${SubCycleWithEvaluatorsFragment}
`;

export const GET_PRIMARY_CONTACTS = gql`
    query GetPrimaryContacts {
        primaryContacts {
            ...UserDisplayFields
        }
    }
    ${UserFragment}
`;

export const GET_DEPARTMENTS = gql`
    query GetDepartments {
        departments {
            id
            name
            acronym
            servicesCount
        }
    }
`;

export const GET_SERVICES = gql`
    query GetServices($departmentId: Int!) {
        services(departmentId: $departmentId) {
            id
            name
            acronym
        }
    }
`;

export const GET_SUB_SERVICES = gql`
    query GetSubServices($serviceId: Int!) {
        subServices(serviceId: $serviceId) {
            ...SubServiceFields
        }
    }
    ${SubServiceFragment}
`;

export const GET_TEMPLATES = gql`
    query GetTemplates {
        templates {
            ...TemplateNameFields
        }
    }
    ${TemplateNameFragment}
`;

export const GET_TEMPLATE = gql`
    query GetTemplate($id: Int!) {
        template(id: $id) {
            ...TemplateFields
        }
    }
    ${TemplateFragment}
`;

export const GET_RESPONSE_FILE_DOWNLOAD_LINKS = gql`
    query GetResponseFileDownloadLinks($responseId: Int!) {
        response(id: $responseId) {
            selfEvaluationFile {
                fileName
                url
            }
            responseFiles {
                fileName
                url
            }
        }
    }
`;

export const GET_FILE_DOWNLOAD_LINK = gql`
    query GetFileDownloadLink($id: Int!) {
        subCycleFile(id: $id) {
            id
            uploadId
            fileName
            url
        }
    }
`;

export const GET_TOKEN_DOWNLOAD_LINK = gql`
    query GetTokenDownloadLink($token: String!) {
        uploadTokenURL(token: $token) {
            sasUri
        }
    }
`;

export const GET_SUB_CYCLE_FILE = gql`
    query GetSubCycleFile($id: Int!) {
        subCycleFile(id: $id) {
            ...FileFields
        }
    }
    ${FileFragment}
`;

export const GET_SUB_CYCLE_FILES = gql`
    query GetSubCycleFiles($subCycleId: Int!) {
        subCycleFiles(subCycleId: $subCycleId) {
            ...FileFields
        }
    }
    ${FileFragment}
`;

export const GET_SUB_CYCLE_FORM = gql`
    query GetSubCycleForm($subCycleId: Int!) {
        subCycle(id: $subCycleId) {
            id
            order
            name
            status
            formType
            postedDate
            lastUpdatedDate
            originalClosingDate
            currentClosingDate
            originalArchiveDate
            archiveDate
            cycle {
                id
                title
                department {
                    name
                }
                service {
                    name
                }
                subService {
                    name
                }
                subCycles {
                    order
                    formType
                    form {
                        ... on RequestForInformationFormType {
                            locationPlaceOfPerformance
                        }
                    }
                }
                teamLeads: cycleUsers(roles: [TEAM_LEAD]) {
                    cycleRole {
                        roleType
                    }
                    user {
                        fullName
                    }
                }
                coLeads: cycleUsers(roles: [CO_LEAD]) {
                    cycleRole {
                        roleType
                    }
                    user {
                        fullName
                    }
                }
            }

            form {
                ... on RequestForInformationFormType {
                    ...RequestForInformationFormFields
                }
                ... on RequestForWhitePaperFormType {
                    ...RequestForWhitePaperFormFields
                }
                ... on RequestForProposalFormType {
                    ...RequestForProposalFormFields
                }
            }
        }
    }
    ${RequestForInformationFormFragment}
    ${RequestForWhitePaperFormFragment}
    ${RequestForProposalFormFragment}
`;

export const GET_SUBCYCLE_SUCCESS_FACTOR_FORM = gql`
    query GetSubCycleSuccessFactorForm($subCycleId: Int!) {
        subCycle(id: $subCycleId) {
            id
            name
            successFactorStatus
            order
            cycle {
                id
                title
            }
            topics {
                ...TopicWithSuccessFactorsFields
            }
            formType
            form {
                ... on RequestForInformationFormType {
                    hasBriefResponse
                }
            }
        }
    }
    ${TopicWithSuccessFactorsFragment}
`;

export const GET_EVALUATORS = gql`
    query GetEvaluators($excludeCycleId: Int) {
        users(profileComplete: true, excludeCycleId: $excludeCycleId) {
            ...EvaluatorFields
        }
        cycleRoles {
            id
            name
            limit
        }
    }
    ${EvaluatorFragment}
`;

export const GET_CYCLE_EVALUATORS = gql`
    query GetCycleEvaluators($cycleId: Int!, $excludeSubCycleId: Int) {
        cycleUsers(cycleId: $cycleId, excludeSubCycleId: $excludeSubCycleId) {
            user {
                ...EvaluatorFields
            }
            cycleRole {
                id
                name
            }
        }
    }
    ${EvaluatorFragment}
`;

export const GET_SUB_CYCLE_EVALUATORS = gql`
    query GetSubCycleEvaluators($subCycleId: Int!, $excludeResponseId: Int) {
        subCycleUsers(subCycleId: $subCycleId, excludeResponseId: $excludeResponseId) {
            user {
                ...EvaluatorFields
            }
            cycleRole {
                id
                name
                limit
            }
        }
    }
    ${EvaluatorFragment}
`;

export const GET_RESPONSE_EVALUATORS = gql`
    query GetResponseEvaluators($responseId: Int!) {
        responseUsers(responseId: $responseId) {
            user {
                ...EvaluatorFields
            }
            cycleRole {
                id
                name
                limit
            }
        }
    }
    ${EvaluatorFragment}
`;

export const GET_CYCLE_EVALUATORS_FULL = gql`
    query GetCycleEvaluatorsFull($cycleId: Int!) {
        cycleUsers(cycleId: $cycleId) {
            user {
                id
                firstName
                lastName
                fullName
                employer
                jobTitle
                cycleAssignments {
                    id
                }
                subCycleAssignments {
                    id
                    subCycle {
                        id
                        name
                        cycleId
                    }
                }
                email
                phoneNumber
                available
                lastLogin
            }
            cycleRole {
                id
                name
            }
        }
    }
`;

export const GET_CYCLE_TOPICS = gql`
    query GetCycleTopics($cycleId: Int!) {
        cycle(id: $cycleId) {
            id
            subCycles {
                id
                order
                formType
                topics {
                    ...TopicFields
                }
            }
        }
    }
    ${TopicFragment}
`;

export const GET_COMMON_FORM_OPTIONS = gql`
    query GetCommonFormOptions {
        categories {
            ...CategoryFields
        }
        securityClassifications {
            ...SecurityClassificationFields
        }
        researchDevelopmentCategories {
            ...OptionFields
        }
        technologyReadinessLevels {
            ...OptionWithParentFields
        }
        pointOfContactOrganizations {
            ...OptionFields
        }
        responderTypes {
            ...ResponderTypeFields
        }
    }
    ${CategoryFragment}
    ${SecurityClassificationFragment}
    ${OptionFragment}
    ${OptionWithParentFragment}
    ${ResponderTypeFragment}
`;

export const GET_RFI_FORM_OPTIONS = gql`
    query GetRFIFormOptions {
        timeHorizons {
            ...OptionFields
        }
        fundingSources {
            ...OptionFields
        }
        technologyKeywords {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const GET_SUCCESS_FACTOR_FORM_OPTIONS = gql`
    query GetSuccessFactorFormOptions {
        categories {
            ...CategoryFields
        }
        librarySuccessFactors {
            ...LibrarySuccessFactorFields
        }
        risks {
            ...OptionFields
        }
    }
    ${CategoryFragment}
    ${LibrarySuccessFactorFragment}
    ${OptionFragment}
`;

export const GET_CYCLE_FORM_OPTIONS = gql`
    query GetCycleFormOptions {
        subCycleFormTypes {
            ...SubCycleFormTypeFields
        }
        fundingTypes {
            ...FundingTypeFields
        }
    }
    ${SubCycleFormTypeFragment}
    ${FundingTypeFragment}
`;

export const GET_RESPONSE_ORGANIZATIONS = gql`
    query GetResponseOrganizations {
        responseOrganizations {
            ...OptionFields
        }
    }
    ${OptionFragment}
`;

export const GET_RESPONSES_TABLE = gql`
    query GetResponsesTable($cycleId: Int!) {
        cycle(id: $cycleId) {
            id
            title
            subCycles(status: OUT_FOR_RESPONSE) {
                id
                status
                name
                canImportSelectedResponses
                ...ActiveSubCycleFormFields
            }
        }
        responses(cycleId: $cycleId) {
            ...ResponseTableFields
        }
    }
    ${ResponseTableFragment}
    ${ActiveSubCycleFormFragment}
`;

export const GET_TOPIC_SUCCESS_FACTORS = gql`
    query GetTopicSuccessFactors($topicId: Int!) {
        successFactors(topicId: $topicId) {
            ...SuccessFactorsFields
        }
    }
    ${SuccessFactorsFragment}
`;

export const GET_RESPONSE_USER = gql`
    query GetResponseUser($responseId: Int!, $userId: Int!) {
        responseUser(responseId: $responseId, userId: $userId) {
            ...ResponseUserScoreFields
        }
    }
    ${ResponseUserScoreFragment}
`;

export const GET_RESPONSE_USERS = gql`
    query GetResponseUsers($responseId: Int!) {
        responseUsers(responseId: $responseId) {
            ...ResponseUserScoreFields
        }
    }
    ${ResponseUserScoreFragment}
`;

export const GET_RESPONSE_SCORE_TABLE = gql`
    query GetResponseScoreTable($cycleId: Int!) {
        cycle(id: $cycleId) {
            id
            title
            subCycles(status: OUT_FOR_RESPONSE) {
                id
                status
                form {
                    ... on RequestForInformationFormType {
                        researchDevelopmentCategories {
                            id
                            fundingRange
                            category {
                                name
                                id
                            }
                            selectionCompleted
                        }
                    }
                    ... on RequestForWhitePaperFormType {
                        researchDevelopmentCategories {
                            id
                            fundingRange
                            category {
                                name
                                id
                            }
                            selectionCompleted
                        }
                    }
                    ... on RequestForProposalFormType {
                        researchDevelopmentCategories {
                            id
                            fundingRange
                            category {
                                name
                                id
                            }
                            selectionCompleted
                        }
                    }
                }
            }
        }
        responses(cycleId: $cycleId) {
            id
            title
            researchCategoryId
            fundingRequested
            selectedStatus
            subCycle {
                id
                name
                status
                formType
            }
            organization {
                id
                name
            }
            researchCategory {
                name
            }
            assignedEvaluators(isTeamLead: true) {
                status
                isTeamLead
                evaluatorScores {
                    id
                    successFactor {
                        ...SuccessFactorsFields
                    }
                    score
                }
            }
            topic {
                id
                name
                categories {
                    id
                    name
                }
                subCycle {
                    id
                    status
                    formType
                    name
                }
            }
        }
    }
    ${SuccessFactorsFragment}
`;
