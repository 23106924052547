import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { FormMode } from '../../../../utils/Enums';

const CountrySelect: React.FC<CountrySelectProps> = ({ mode }) => {
    const { control, getValues, errors } = useFormContext();
    const countryOptions = ['USA'];

    return (
        <ReactHookFormSelect
            fullWidth
            control={control}
            label="Country of Citizenship*"
            name="countryOfCitizenship"
            defaultValue={getValues('countryOfCitizenship')}
            error={!!errors.countryOfCitizenship}
            helperText={errors.countryOfCitizenship?.message}
            rules={{ required: 'Country of Citizenship is required' }}
            readOnly={mode === FormMode.View}>
            {!countryOptions || countryOptions.length <= 0 ? (
                <MenuItem disabled>No Data Available</MenuItem>
            ) : (
                countryOptions.map((country, index) => (
                    <MenuItem key={index} value={country}>
                        {country}
                    </MenuItem>
                ))
            )}
        </ReactHookFormSelect>
    );
};

export interface CountrySelectProps {
    mode: FormMode;
}

export default CountrySelect;
