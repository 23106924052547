import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControl, TextField, withStyles, useMediaQuery } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useFormContext } from 'react-hook-form';
import MessageIcon from '@material-ui/icons/Message';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider';
import { FormMode } from '../../../../utils/Enums';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { GetResponseUsers_responseUsers_evaluatorScores } from '../../../../apollo/generated/types/GetResponseUsers';

interface FinalizeScoreItemProps {
    fpmCategory: FundamentalPrimeMeasurement;
    index: number;
    scoreData: GetResponseUsers_responseUsers_evaluatorScores;
    handleAverageScore: () => void;
    mode?: FormMode;
}

const Accordion = withStyles({
    root: {
        'boxShadow': 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const AccordionSummary = withStyles({
    root: {
        'backgroundColor': 'rgba(0, 0, 0, .03)',
        'marginBottom': -1,
        'minHeight': 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
    expandIcon: {
        'transform': 'rotate(0) !important',
        '&$ Mui-expanded': {
            color: 'red !important',

            transform: 'translateY(-50%) rotate(45deg)',
        },
    },
})(MuiAccordionSummary);

const FinalizeScoreItem: React.FC<FinalizeScoreItemProps> = ({ fpmCategory, index, scoreData, mode, handleAverageScore }) => {
    const { register, errors, getValues, watch } = useFormContext();
    const { loading } = useTeamLeadScore();
    const [hasComment, setHasComment] = useState(0);

    const readOnly = mode === FormMode.View;
    const fieldNamePrefix = `evaluations[0].categories.${fpmCategory}[${index}]`;
    const id = watch(`${fieldNamePrefix}.id`);
    const responseUserId = watch(`${fieldNamePrefix}.responseUserId`);

    const hasError = (field: string): boolean => {
        return (
            !!errors &&
            !!errors.evaluations &&
            errors.evaluations.length > 0 &&
            !!errors.evaluations[0][fpmCategory] &&
            !!errors.evaluations[0][fpmCategory][index] &&
            !!errors.evaluations[0][fpmCategory][index][field]
        );
    };

    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.evaluations[0][fpmCategory][index][field].message : '';
    };

    useEffect(() => {
        handleAverageScore();
        const commentLength = getValues(`${fieldNamePrefix}.comment`).length;
        setHasComment(commentLength ? commentLength : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseUserId]);

    const matches = useMediaQuery('(max-width:1400px)');

    return (
        <Grid container justify="space-between" style={{ padding: '5px 0' }}>
            <Grid item xs={12} style={{ overflow: 'hidden' }}>
                {/* Hidden inputs */}
                {id ? <input name={`${fieldNamePrefix}.id`} type="hidden" ref={register()} value={id} /> : null}
                {responseUserId ? <input name={`${fieldNamePrefix}.responseUserId`} type="hidden" ref={register()} value={responseUserId} /> : null}
                {scoreData.successFactor.id ? (
                    <input name={`${fieldNamePrefix}.successFactorId`} type="hidden" ref={register()} value={scoreData.successFactor.id} />
                ) : null}
                <Accordion square style={{ boxShadow: 'none' }}>
                    <AccordionSummary
                        style={{ backgroundColor: '#ffffff', padding: matches ? 0 : '0 16px', overflow: 'hidden' }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        expandIcon={hasComment ? <MessageIcon /> : <ChatBubbleIcon />}>
                        <Grid item xs={7} style={{ margin: 'auto', wordBreak: 'break-word' }}>
                            <Typography>{scoreData.successFactor.name}</Typography>
                        </Grid>
                        <Grid item xs={3} style={{ margin: 'auto' }}>
                            <FormControl
                                onDragStart={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                {!loading ? (
                                    <>
                                        <TextField
                                            style={{ minWidth: '60px' }}
                                            name={`${fieldNamePrefix}.score`}
                                            variant="outlined"
                                            type="number"
                                            inputRef={register({
                                                valueAsNumber: true,
                                                min: {
                                                    value: 4,
                                                    message: 'Score must not be below 4.',
                                                },
                                                max: {
                                                    value: 9.9,
                                                    message: 'Score must not be above 9.9.',
                                                },
                                            })}
                                            InputProps={{
                                                inputProps: {
                                                    min: 4,
                                                    max: 9.9,
                                                    step: 0.1,
                                                    style: { padding: '10px', textAlign: 'center' },
                                                    readOnly: readOnly,
                                                },
                                            }}
                                            defaultValue={scoreData && scoreData.score ? scoreData.score : ''}
                                            error={hasError('score')}
                                            onChange={handleAverageScore}
                                            helperText={getErrorMessage('score')}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </>
                                ) : (
                                    ''
                                )}
                            </FormControl>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                variant="outlined"
                                name={`${fieldNamePrefix}.comment`}
                                rows={2}
                                rowsMax={4}
                                placeholder="Enter a comment here"
                                defaultValue={scoreData.comment}
                                inputRef={register()}
                                InputProps={{ readOnly: readOnly }}
                                onBlur={() => {
                                    setHasComment(getValues(`${fieldNamePrefix}.comment`).length);
                                }}
                            />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default FinalizeScoreItem;
