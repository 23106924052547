import { createMuiTheme, IconButton, makeStyles, TextField, ThemeProvider } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserAvailabilityInputType } from '../../../../apollo/generated/types/globalTypes';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormMode } from '../../../../utils/Enums';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { theme } from '../../../../theme';
import ReactHookFormTime from '../../../ReactHookForm/ReactHookFormTime';

const timePickerTheme = createMuiTheme({
    palette: {
        primary: {
            light: theme.palette.primary.light,
            main: theme.palette.primary.main,
        },
        secondary: {
            main: theme.palette.secondary.main,
        },
    },
    typography: {
        fontFamily: theme.typography.fontFamily,
    },
});

const useStyles = makeStyles({
    timePicker: {
        margin: 0,
        fontSize: '30px !important',
    },
    dayField: {
        width: '33%',
    },
    removeSection: {
        width: '50px',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        margin: '5px',
        padding: '5px',
    },
});

export interface AvailabilityItemProps extends UserAvailabilityInputType {
    index: number;
    mode: FormMode;
    remove: (index: number) => void;
}

const AvailabilityItem: React.FC<AvailabilityItemProps> = ({ id, userId, day, startTime, endTime, index, mode, remove }) => {
    const classes = useStyles();
    const { register, errors, control } = useFormContext();
    const [startTimeVal, setStartTimeVal] = useState(startTime);
    const [endTimeVal, setEndTimeVal] = useState(endTime);

    const hasError = (field: string): boolean => {
        return !!errors && !!errors.userAvailabilities && !!errors.userAvailabilities[index] && !!errors.userAvailabilities[index][field];
    };
    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.userAvailabilities[index][field]?.message : '';
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div>
                {id ? <input name={`userAvailabilities[${index}].id`} type="hidden" ref={register()} defaultValue={id} /> : null}
                {userId ? <input name={`userAvailabilities[${index}].userId`} type="hidden" ref={register()} value={userId} /> : null}
            </div>
            <div className={classes.root}>
                <TextField
                    id="day"
                    className={classes.dayField}
                    name={`userAvailabilities[${index}].day`}
                    label="Day"
                    defaultValue={day}
                    inputRef={register()}
                    error={hasError('day')}
                    helperText={errors.jobTitle?.message}
                    fullWidth
                    InputLabelProps={{
                        'aria-readonly': true,
                    }}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                {/* Override font changes made in original theme */}
                <ThemeProvider theme={timePickerTheme}>
                    <ReactHookFormTime
                        className={classes.timePicker}
                        name={`userAvailabilities[${index}].startTime`}
                        label="Start Time"
                        mask="__:__ _M"
                        control={control}
                        defaultValue={startTime}
                        onTimeChange={setStartTimeVal}
                        readOnly={mode === FormMode.View}
                        rules={{
                            required: {
                                value: true,
                                message: 'You must enter a start time.',
                            },
                            validate: () => {
                                const startMinutesTotal = startTimeVal.getHours() * 60 + startTimeVal.getMinutes(),
                                    endMinutesTotal = endTimeVal.getHours() * 60 + endTimeVal.getMinutes();
                                return startMinutesTotal < endMinutesTotal || 'Start time must occur prior to end time';
                            },
                        }}
                        error={hasError('startTime')}
                        helperText={getErrorMessage('startTime')}
                    />
                    <ReactHookFormTime
                        className={classes.timePicker}
                        name={`userAvailabilities[${index}].endTime`}
                        label="End Time"
                        mask="__:__ _M"
                        control={control}
                        defaultValue={endTime}
                        onTimeChange={setEndTimeVal}
                        readOnly={mode === FormMode.View}
                        rules={{
                            required: {
                                value: true,
                                message: 'You must enter an end time.',
                            },
                        }}
                        error={hasError('endTime')}
                        helperText={getErrorMessage('endTime')}
                    />
                </ThemeProvider>
                {mode !== FormMode.View ? (
                    <div className={classes.removeSection}>
                        <IconButton aria-label="remove" onClick={() => remove(index)}>
                            <RemoveCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                ) : null}
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default AvailabilityItem;
