import React from 'react';
import { StatusMenuOption } from '../index';
import { MenuItem } from '@material-ui/core';
import { SuccessFactorStatus } from '../../../apollo/generated/types/globalTypes';

const SuccessFactorMenuOption: React.FC<StatusMenuOption> = React.forwardRef(({ status, changeStatus }, ref) => {
    return (
        <>
            {status === SuccessFactorStatus.DRAFT ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SuccessFactorStatus.PENDING_APPROVAL)}>
                    Submit for Approval
                </MenuItem>
            ) : null}
            {status === SuccessFactorStatus.PENDING_APPROVAL || status === SuccessFactorStatus.APPROVED ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SuccessFactorStatus.DRAFT)}>
                    Revert to Draft
                </MenuItem>
            ) : null}
            {status === SuccessFactorStatus.PENDING_APPROVAL ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SuccessFactorStatus.APPROVED)}>
                    Set as Approved
                </MenuItem>
            ) : null}
            {status === SuccessFactorStatus.APPROVED ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SuccessFactorStatus.PENDING_APPROVAL)}>
                    Revert to Pending Approval
                </MenuItem>
            ) : null}
        </>
    );
});
export default SuccessFactorMenuOption;
