import React from 'react';

const FileSadIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
            <g transform="translate(-1 -1)">
                <path fill="#f6b445" d="M39,13H22V7H38a.979.979,0,0,1,1,1Z" transform="translate(0 0)" />
                <path fill="#fed049" d="M22,7v6H43a4,4,0,0,1,4,4V43a4,4,0,0,1-4,4H5a4,4,0,0,1-4-4V5S1,1,5,1H18s4,0,4,4Z" transform="translate(0 0)" />
                <path fill="#f6b445" d="M19,25a1,1,0,0,1-1-1,2,2,0,1,0-4,0,1,1,0,0,1-2,0,4,4,0,1,1,8,0A1,1,0,0,1,19,25Z" transform="translate(0 0)" />
                <path fill="#f6b445" d="M35,25a1,1,0,0,1-1-1,2,2,0,0,0-4,0,1,1,0,0,1-2,0,4,4,0,0,1,8,0A1,1,0,0,1,35,25Z" transform="translate(0 0)" />
                <path fill="#f6b445" d="M32,39a1,1,0,0,1-1-1,7,7,0,0,0-14,0,1,1,0,0,1-2,0,9,9,0,1,1,18,0A1,1,0,0,1,32,39Z" transform="translate(0 0)" />
            </g>
        </svg>
    );
};

export default FileSadIcon;
