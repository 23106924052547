/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { IconButton, Grid, Typography, Chip } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode, fpmLabels, fpmsOrdered, getFpmCategoryFromScoreSection, getScoreSectionsTypeDisplayName, ScoreSectionsType } from '../../../../utils/Enums';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useEvaluatorScore } from '../../../Providers/EvaluatorScoreProvider';
import EvaluatorScoreSection from './EvaluatorScoreSection';
import { FpmCategory } from '../../../../apollo/generated/types/globalTypes';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600,
    },
});

export interface Risk {
    fpmCategory: FpmCategory;
    risk: {
        name: string;
    };
}

const EvaluatorScoreSections: React.FC<EvaluatorScoreSectionsProps> = ({ mode, sectionNumber }) => {
    const classes = useStyles();
    const { evaluatorResponse } = useEvaluatorScore();
    // Construct risks using topic and responder risks
    const risks: Risk[] = evaluatorResponse?.response?.topic?.risks ?? [];
    risks.push(...(evaluatorResponse?.response.responderRisks ?? []));

    return (
        <>
            <FormSection justify="space-between">
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {getScoreSectionsTypeDisplayName(sectionNumber)}
                        <IconButton>
                            <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid container justify="space-between">
                    <Typography variant="subtitle2" align="left" className={classes.bold}>
                        9-Hi™ Categories
                    </Typography>
                    <Grid style={{ display: 'flex' }}>
                        <Typography variant="subtitle2" align="left" style={{ marginRight: '30px' }} className={classes.bold}>
                            Response Documents
                        </Typography>
                        <Typography variant="subtitle2" align="left" style={{ marginRight: '30px' }} className={classes.bold}>
                            Responder
                        </Typography>
                        <Typography variant="subtitle2" align="left" style={{ marginRight: '95px' }} className={classes.bold}>
                            Evaluator
                        </Typography>
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection>
                <Grid container spacing={4}>
                    {fpmLabels[sectionNumber].map((category, fpmIndex) => (
                        <EvaluatorScoreSection
                            key={category}
                            mode={mode}
                            category={category}
                            fpmCategory={fpmsOrdered[sectionNumber * fpmLabels.length + fpmIndex]}
                        />
                    ))}
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography variant="h2">Risks:</Typography>
                    {risks.length > sectionNumber &&
                        risks
                            // For this section, display only the risks associated with this FPM Category
                            .filter((risk) => getFpmCategoryFromScoreSection(sectionNumber) === risk.fpmCategory)
                            // Isolate risk names
                            .map((riskData) => riskData.risk.name)
                            // Remove duplicates
                            .filter((riskName, index, riskNames) => riskNames.indexOf(riskName) === index)
                            // Construct chip from risk name
                            .map((riskName, index) => (
                                <div key={index} style={{ marginLeft: '20px', paddingBottom: '10px' }}>
                                    <Chip label={<span style={{ fontWeight: 'bold', fontSize: '15px' }}>{riskName}</span>} />
                                </div>
                            ))}
                </div>
            </FormSection>
        </>
    );
};

export interface EvaluatorScoreSectionsProps {
    mode: FormMode;
    sectionNumber: ScoreSectionsType;
}

export default EvaluatorScoreSections;
