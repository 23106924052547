import * as React from 'react';
import { createContext, useEffect, useState, useCallback } from 'react';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { GET_SUCCESS_FACTOR_FORM_OPTIONS } from '../../../apollo/queries';
import { CREATE_RISK } from '../../../apollo/mutations';
import { OptionFields } from '../../../apollo/generated/types/OptionFields';
import { CategoryFields } from '../../../apollo/generated/types/CategoryFields';
import { LibrarySuccessFactorFields } from '../../../apollo/generated/types/LibrarySuccessFactorFields';
import { GetSuccessFactorFormOptions } from '../../../apollo/generated/types/GetSuccessFactorFormOptions';
import { CreateRisk, CreateRiskVariables } from '../../../apollo/generated/types/CreateRisk';

interface SuccessFactorFormContextProps {
    categories?: CategoryFields[];
    librarySuccessFactors: LibrarySuccessFactorFields[];
    loading: boolean;
    error?: ApolloError;
    risks?: OptionFields[];
    addRisk: (name: string) => Promise<OptionFields | null>;
}

const SuccessFactorFormContext = createContext<SuccessFactorFormContextProps | undefined>(undefined);

const SuccessFactorFormProvider: React.FC = ({ children }) => {
    const [risks, setRisks] = useState<Array<OptionFields> | undefined>();

    const [createRisk] = useMutation<CreateRisk, CreateRiskVariables>(CREATE_RISK);

    const { loading, error, data } = useQuery<GetSuccessFactorFormOptions>(GET_SUCCESS_FACTOR_FORM_OPTIONS, {
        fetchPolicy: 'network-only',
    });

    // No Manual Entry
    const categories = data?.categories as CategoryFields[];
    const librarySuccessFactors = data?.librarySuccessFactors as LibrarySuccessFactorFields[];

    useEffect(() => {
        setRisks(data?.risks as OptionFields[]);
    }, [data]);

    const addRisk = useCallback(
        async (name: string) => {
            const result = await createRisk({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createRisk) {
                const risk = result.data.createRisk;
                setRisks((currentList) => [...(currentList || []), risk as OptionFields]);
                return risk as OptionFields;
            }
            return null;
        },
        [createRisk],
    );

    return (
        <SuccessFactorFormContext.Provider
            value={{
                loading,
                error,
                categories,
                librarySuccessFactors,
                risks,
                addRisk,
            }}>
            {children}
        </SuccessFactorFormContext.Provider>
    );
};

const useSuccessFactorForm = (): SuccessFactorFormContextProps => {
    const context = React.useContext(SuccessFactorFormContext);
    if (context === undefined) {
        throw new Error('useSuccessFactorForm must be used within a SuccessFactorFormProvider');
    }
    return context;
};

export { SuccessFactorFormProvider, useSuccessFactorForm };
