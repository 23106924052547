import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Toolbar, Typography } from '@material-ui/core';
import { NavLink, useParams } from 'react-router-dom';
import { PageWrapper, PageHeader, PageBody, AcquisitionCycleTabs, EvaluatorScoreProvider, EvaluatorScoreForm, usePermissions } from '../../../components';
import { useQuery } from '@apollo/client';
import Link from '@material-ui/core/Link';
import { GET_CYCLE_FULL } from '../../../apollo/queries';
import { GetCycleFull, GetCycleFullVariables } from '../../../apollo/generated/types/GetCycleFull';
import { CycleTab } from '../../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
        padding: 0,
    },
    backButton: {
        background: '#F0F0F0',
    },
    title: {
        height: '44px',
        marginLeft: '15px',
        alignItems: 'center',
        display: 'flex',
    },
    tabText: {
        marginLeft: '5px',
    },
});
const EvaluatorScorePage: React.FC = () => {
    const classes = useStyles();
    const wrapperRef = useRef<null | HTMLDivElement>(null);

    interface URLParams {
        id: string;
        responseId: string;
    }
    const { id, responseId } = useParams<URLParams>();
    const { user } = usePermissions();
    const cycleId = Number(id);
    const { loading, data } = useQuery<GetCycleFull, GetCycleFullVariables>(GET_CYCLE_FULL, {
        variables: {
            id: cycleId,
        },
        fetchPolicy: 'no-cache',
    });

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <div>
                        <div className={classes.title}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link component={NavLink} to={`/acquisition-cycle/${id}/${CycleTab.Response}`} color="inherit">
                                    Acquisition Cycles
                                </Link>
                                <Typography variant="h2">{data?.cycle?.title}</Typography>
                            </Breadcrumbs>
                        </div>
                        <div>
                            <AcquisitionCycleTabs id={cycleId} tab="response" />
                        </div>
                    </div>
                </Toolbar>
            </PageHeader>
            <PageBody divRef={wrapperRef} hiddenScroll={true}>
                <EvaluatorScoreProvider responseId={Number(responseId)} userId={user?.id}>
                    <EvaluatorScoreForm loading={loading} parentRef={wrapperRef} />
                </EvaluatorScoreProvider>
            </PageBody>
        </PageWrapper>
    );
};

export default EvaluatorScorePage;
