/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StarsIcon from '@material-ui/icons/Stars';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { usePermissions } from '../../Providers';
import { makeStyles } from '@material-ui/core/styles';
import { GET_SUB_CYCLES_FULL } from '../../../apollo/queries';
import { useLazyQuery } from '@apollo/client';
import { GetSubCyclesFull, GetSubCyclesFullVariables } from '../../../apollo/generated/types/GetSubCyclesFull';
import { SubCycleForm, SubCycleStatus } from '../../../apollo/generated/types/globalTypes';
import { CycleSubTab, CycleTab } from '../../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    tabText: {
        marginLeft: '5px',
    },
    hidden: {
        width: '15ch',
    },
});
export interface AcquisitionCycleTabsProps {
    id: number;
    tab: string;
}

const AcquisitionCycleTabs: React.FC<AcquisitionCycleTabsProps> = ({ id, tab }) => {
    const classes = useStyles();
    const { updateUserInfo } = usePermissions();
    const [showAward, setAward] = useState<any>(undefined);

    const [getSubCycles, { data }] = useLazyQuery<GetSubCyclesFull, GetSubCyclesFullVariables>(GET_SUB_CYCLES_FULL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: id,
        },
    });

    // Return award management if last sub-cycle has OUT_FOR_RESPONSE
    const handleLastSubCycle = useCallback(() => {
        if (data?.subCycles && data.subCycles.length > 0) {
            if (
                data.subCycles[data.subCycles.length - 1].status === SubCycleStatus.OUT_FOR_RESPONSE &&
                data.subCycles[data.subCycles.length - 1].formType === SubCycleForm.REQUEST_FOR_PROPOSAL
            ) {
                setAward(true);
            } else {
                setAward(false);
            }
        } else {
            setAward(false);
        }
    }, [data]);

    useEffect(() => {
        getSubCycles();
        handleLastSubCycle();
    }, [data, handleLastSubCycle, getSubCycles]);

    return (
        <Tabs value={tab} aria-label="Acquisition Cycle Tabs">
            <Tab
                value={CycleTab.Evaluator}
                to={`/acquisition-cycle/${id}/${CycleTab.Evaluator}`}
                component={NavLink}
                onMouseEnter={() => updateUserInfo()}
                label={
                    <>
                        <AccountCircleIcon style={{ fontSize: 18 }} />
                        <span className={classes.tabText}>Evaluator Management</span>
                    </>
                }
            />
            <Tab
                value={CycleTab.SubCycle}
                to={`/acquisition-cycle/${id}/${CycleTab.SubCycle}`}
                component={NavLink}
                onMouseEnter={() => updateUserInfo()}
                label={
                    <>
                        <EventIcon style={{ fontSize: 18 }} />
                        <span className={classes.tabText}>Sub-Cycle Management</span>
                    </>
                }
            />
            <Tab
                value={CycleTab.Response}
                to={`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.InProcessResponses}`}
                component={NavLink}
                onMouseEnter={() => updateUserInfo()}
                label={
                    <>
                        <StarsIcon style={{ fontSize: 18 }} />
                        <span className={classes.tabText}>Response Management</span>
                    </>
                }
            />

            <Tab
                style={{ display: showAward ? 'inherit' : 'none' }}
                value={CycleTab.Award}
                to={`/acquisition-cycle/${id}/${CycleTab.Award}`}
                component={NavLink}
                onMouseEnter={() => updateUserInfo()}
                label={
                    <>
                        <MonetizationOnIcon style={{ fontSize: 18 }} />
                        <span className={`${classes.tabText} ${showAward !== undefined ? '' : classes.hidden}`}>
                            {showAward !== undefined ? ' Award Management' : ''}
                        </span>
                    </>
                }
            />

            <Tab
                style={{ display: showAward ? 'none' : 'inherit' }}
                value={CycleTab.DownSelection}
                to={`/acquisition-cycle/${id}/${CycleTab.DownSelection}`}
                component={NavLink}
                onMouseEnter={() => updateUserInfo()}
                label={
                    <>
                        <MonetizationOnIcon style={{ fontSize: 18 }} />
                        <span className={`${classes.tabText} ${showAward !== undefined ? '' : classes.hidden}`}>
                            {showAward !== undefined ? ' Down-Selection' : ''}
                        </span>
                    </>
                }
            />
        </Tabs>
    );
};

export default AcquisitionCycleTabs;
