import * as React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { ReactHookFormCheckbox, ReactHookFormDateTime, ReactHookFormTextArea, ReactHookFormTimezone } from '../../../index';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const SubmissionInstructionsForm: React.FC<SubmissionInstructionsFormProps> = ({ mode }) => {
    const { register, control, errors, watch } = useFormContext();
    const watchHasBriefResponse = watch('hasBriefResponse', false);
    const watchIsAcceptingQuestionSubmission = watch('isAcceptingQuestionSubmission', false);
    const status = watch('status', SubCycleStatus.DRAFT);
    const required = status !== SubCycleStatus.DRAFT;
    const readOnly = mode === FormMode.View;

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Submission Instructions</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={6}>
                    <ReactHookFormDateTime
                        control={control}
                        format="MM/dd/yyyy HH:mm"
                        label="Deadline Date & Time*"
                        name="deadlineDateTime"
                        defaultValue={null}
                        rules={{ required: required ? 'Deadline Date & Time is required' : false }}
                        error={!!errors.deadlineDateTime}
                        helperText={errors.deadlineDateTime?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormTimezone
                        fullWidth
                        control={control}
                        name="deadlineTimezone"
                        label="Deadline Timezone*"
                        rules={{ required: required ? 'Deadline Timezone is required' : false }}
                        error={!!errors.deadlineTimezone}
                        helperText={errors.deadlineTimezone?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Instructions*"
                        name="instructions"
                        rules={{
                            required: required ? 'Instructions must have some content.' : false,
                            validate: (value) => !required || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Instructions must have some content.',
                        }}
                        error={!!errors.instructions}
                        helperText={errors.instructions?.message}
                        subHeading={subHeadingInstructions}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Response Instructions*"
                        name="responseInstructions"
                        rules={{
                            required: required ? 'Response Instructions must have some content.' : false,
                            validate: (value) => {
                                return !required || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Response Instructions must have some content.';
                            },
                        }}
                        error={!!errors.responseInstructions}
                        helperText={errors.responseInstructions?.message}
                        subHeading={subHeadingResponse}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasBriefResponse"
                        label="Brief RFI Response (1-3 pages)"
                        defaultValue={false}
                        readOnly={readOnly}
                    />
                </Grid>
                {watchHasBriefResponse ? (
                    <Grid item xs={12}>
                        <TextField
                            label="Brief RFI Response Description*"
                            name="briefResponseDescription"
                            inputRef={register({ required: required ? 'Brief RFI Response Description is required' : false })}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                            defaultValue=""
                            error={!!errors.briefResponseDescription}
                            helperText={errors.briefResponseDescription?.message}
                        />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="isAcceptingQuestionSubmission"
                        label="Will you have an Open Question Period?"
                        defaultValue={false}
                        readOnly={readOnly}
                    />
                </Grid>
                {watchIsAcceptingQuestionSubmission ? (
                    <Grid item xs={12}>
                        <ReactHookFormTextArea
                            fullWidth
                            control={control}
                            label="Include Open Period Instructions Here"
                            name="submissionOfQuestions"
                            defaultValue=""
                            error={!!errors.submissionOfQuestions}
                            helperText={errors.submissionOfQuestions?.message}
                            readOnly={readOnly}
                        />
                    </Grid>
                ) : null}
            </FormSection>
        </>
    );
};

const subHeadingInstructions = `There is information that is commonly described in the instructions area of a Request for Information(RFI) such as including the
Response Page Limit outlining the sections that count towards the overall page count. Provide the details that should be displayed on
the submission Cover Page. Provide any specific instructions relating to the submission's formatting requirements, such as margins, font
size, and font style. Provide the required method of submission such as email or upload to specific government website, including
required information for the subject line and/or URL to submission website. Provide the deadline date of the submission including, date,
time, and time zone. If required, you may want to provide the acceptable classified submissions & instructions such as FOUO, SECRET, TOP
SECRET, TS/SCI, etc., and provide any remaining instructions or comments you want to include in the instructions.`;

const subHeadingResponse = `You must provide a description of the Response Instructions in the below text editor or create the instructions in a separate document 
and upload to this section. There is information that is commonly described in the response instructions area of a Request for Information(RFI) such as including a detailed 
paragraph of what information is need in the response. Provide a list of questions that relates to the responder's industry experience, capabilities, capacities, 
current supported contract vehicles, feedback to technical issues, and specific instructions relating to the content of the instructions. Provide specific information 
related to the RFI such as Contractor Information (name, address, CAGE code), DUNS Number, NAICS Code, Socio-economic status (ex: Veteran Owned, Woman Owned, Small Business, etc.), 
Point of Contact Information, and website URL.`;

export interface SubmissionInstructionsFormProps {
    mode: FormMode;
    subCycleId: number;
}

export default SubmissionInstructionsForm;
