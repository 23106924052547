import * as React from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_CYCLE_FORM_OPTIONS } from '../../../apollo/queries';
import { CREATE_FUNDING_TYPE } from '../../../apollo/mutations';
import { CreateFundingType, CreateFundingTypeVariables } from '../../../apollo/generated/types/CreateFundingType';
import { FundingTypeFields } from '../../../apollo/generated/types/FundingTypeFields';
import { GetCycleFormOptions } from '../../../apollo/generated/types/GetCycleFormOptions';
import { SubCycleFormTypeFields } from '../../../apollo/generated/types/SubCycleFormTypeFields';

interface CycleFormContextProps {
    loading: boolean;
    error?: ApolloError;
    formTypes?: SubCycleFormTypeFields[];
    fundingTypes?: FundingTypeFields[];

    addFundingType: (name: string) => Promise<FundingTypeFields | null>;
}

const CycleFormContext = createContext<CycleFormContextProps | undefined>(undefined);

const CycleFormProvider: React.FC = ({ children }) => {
    const [loading, setLoading] = useState(true);
    // Allows Manual Entry
    const [fundingTypes, setFundingTypes] = useState<Array<FundingTypeFields> | undefined>();

    const [createFundingType] = useMutation<CreateFundingType, CreateFundingTypeVariables>(CREATE_FUNDING_TYPE);
    const { loading: queryLoading, data, error } = useQuery<GetCycleFormOptions>(GET_CYCLE_FORM_OPTIONS, { fetchPolicy: 'cache-and-network' });

    // No Manual Entry
    const formTypes = data?.subCycleFormTypes as SubCycleFormTypeFields[];

    useEffect(() => {
        setFundingTypes(data?.fundingTypes as FundingTypeFields[]);
        setLoading(queryLoading);
    }, [data, queryLoading]);

    const addFundingType = useCallback(
        async (name: string) => {
            const result = await createFundingType({
                variables: {
                    fundingType: {
                        name: name,
                    },
                },
            });
            if (result && result.data && result.data.createFundingType) {
                const fundingType = result.data.createFundingType;
                setFundingTypes((currentList) => [...(currentList || []), fundingType as FundingTypeFields]);
                return fundingType as FundingTypeFields;
            }
            return null;
        },
        [createFundingType],
    );
    return (
        <CycleFormContext.Provider
            value={{
                loading,
                error,
                formTypes,
                fundingTypes,
                addFundingType,
            }}>
            {children}
        </CycleFormContext.Provider>
    );
};

const useCycleForm = (): CycleFormContextProps => {
    const context = React.useContext(CycleFormContext);
    if (context === undefined) {
        throw new Error('useCycleForm must be used within a CycleFormProvider');
    }
    return context;
};

export { CycleFormProvider, useCycleForm };
