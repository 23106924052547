/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { FormMode } from '../../../../utils/Enums';
import EvaluatorScoreItem from './EvaluatorScoreItem';
import { useFormContext } from 'react-hook-form';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import { useEvaluatorScore } from '../../../Providers/EvaluatorScoreProvider';
import { GetResponseUser_responseUser_evaluatorScores } from '../../../../apollo/generated/types/GetResponseUser';
import { ScoreTitleDisplay } from '../../../ScoreDisplay';

const EvaluatorScoreSection: React.FC<EvaluatorScoreProps> = ({ mode, category, fpmCategory }) => {
    const { errors } = useFormContext();
    const [totalScore, setScore] = useState({ score: 0, weight: 0 });
    const [weightDict, setWeightDict] = useState({});

    const { getValues } = useFormContext();
    // Default static score data
    const { evaluatorResponse } = useEvaluatorScore();

    const hasError = !!errors && !!errors;
    const errorMessage = (errors && errors?.message) || '';

    const handleAverageScore = () => {
        const scoreData = getValues();
        let score = 0;
        let weight = 0;
        if (scoreData.categories[`${fpmCategory}`] && scoreData.categories[`${fpmCategory}`].length > 0) {
            scoreData.categories[`${fpmCategory}`].map((i: any) => {
                const sfWeight = weightDict[i.successFactorId];
                score = score + parseFloat(i.score) * parseFloat(sfWeight);
                if (isNaN(score)) score = 0;
                weight = weight + parseFloat(sfWeight);
            });
            setScore({ score, weight });
        }
    };

    let total = parseFloat((totalScore.score / totalScore.weight).toFixed(1));
    total = isNaN(total) ? 0 : total;

    useEffect(() => {
        const temp = {};
        evaluatorResponse &&
            evaluatorResponse?.evaluatorScores &&
            evaluatorResponse?.evaluatorScores.map((i) => {
                i.successFactor.id ? (temp[i.successFactor.id] = i.successFactor.weight) : null;
            });
        setWeightDict({ ...temp });
    }, [fpmCategory, evaluatorResponse]);

    return (
        <Grid container item xs={12} spacing={2} key={category}>
            <Grid container item xs={12}>
                <ScoreTitleDisplay
                    hasError={hasError}
                    errorMessage={errorMessage}
                    gridSize={10}
                    label={category}
                    score={total}
                    gridProps={{ style: { padding: '8px' } }}
                />
                <ScoreTitleDisplay gridSize={2} label={total} score={total} dark={true} gridProps={{ style: { padding: '8px' } }} />
            </Grid>
            <Grid item xs={12}>
                {(evaluatorResponse?.evaluatorScores ?? [])
                    .filter((score) => score.successFactor.fpmCategory === fpmCategory)
                    .map((score: GetResponseUser_responseUser_evaluatorScores, index: number) => {
                        const successFactor = score.successFactor;
                        const responderScore = evaluatorResponse?.response.responderScores?.find((rs) => rs.successFactorId === successFactor.id)?.score;
                        return successFactor && successFactor.fpmCategory !== undefined && successFactor.fpmCategory === fpmCategory ? (
                            <EvaluatorScoreItem
                                key={successFactor.id}
                                index={index}
                                successFactor={successFactor}
                                fpmCategory={fpmCategory}
                                mode={mode}
                                responderScore={responderScore}
                                handleAverageScore={handleAverageScore}
                            />
                        ) : null;
                    })}
            </Grid>
        </Grid>
    );
};

export interface EvaluatorScoreProps {
    mode: FormMode;
    category: string;
    fpmCategory: FundamentalPrimeMeasurement;
}

export default EvaluatorScoreSection;
