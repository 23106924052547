import React from 'react';
import { StatusMenuOption } from '../index';
import { MenuItem } from '@material-ui/core';
import { SubCycleStatus } from '../../../apollo/generated/types/globalTypes';

const SubCycleMenuOption: React.FC<StatusMenuOption> = React.forwardRef(({ status, changeStatus }, ref) => {
    return (
        <>
            {status === SubCycleStatus.DRAFT ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SubCycleStatus.PENDING_APPROVAL)}>
                    Submit for Approval
                </MenuItem>
            ) : null}
            {status === SubCycleStatus.PENDING_APPROVAL || status === SubCycleStatus.APPROVED ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SubCycleStatus.DRAFT)}>
                    Revert to Draft
                </MenuItem>
            ) : null}
            {status === SubCycleStatus.PENDING_APPROVAL ? <MenuItem onClick={() => changeStatus(SubCycleStatus.APPROVED)}>Set as Approved</MenuItem> : null}
            {status === SubCycleStatus.APPROVED ? (
                <MenuItem innerRef={ref} onClick={() => changeStatus(SubCycleStatus.PENDING_APPROVAL)}>
                    Revert to Pending Approval
                </MenuItem>
            ) : null}
        </>
    );
});
export default SubCycleMenuOption;
