import * as React from 'react';
import { Grid, Paper, Chip } from '@material-ui/core';
import { FormMode } from '../../../../utils/Enums';
import TopicFPMContainerForm from './TopicFPMContainerForm';

import { useFormContext } from 'react-hook-form';
import { SubCycleForm } from '../../../../apollo/generated/types/globalTypes';
import { TabPanel } from '../../../';
import { GetSubCycleSuccessFactorForm_subCycle_form } from '../../../../apollo/generated/types/GetSubCycleSuccessFactorForm';
import { useSuccessFactorForm } from '../../../Providers/SuccessFactorFormProvider';
import { makeStyles } from '@material-ui/core/styles';
import { TopicWithSuccessFactorsFields } from '../../../../apollo/generated/types/TopicWithSuccessFactorsFields';

const useStyles = makeStyles({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
    },
    chip: {
        margin: 2,
    },
});

const TopicSuccessFactorPanelForm: React.FC<TopicSuccessFactorPanelFormProps> = ({
    mode,
    value,
    formType,
    form,
    topicInfo,
    organizationSectionRef,
    applicationSectionRef,
    collaborationSectionRef,
    previousFormType,
    subCycleOrder,
}) => {
    const { register } = useFormContext();
    const { categories: options } = useSuccessFactorForm();
    const classes = useStyles();

    return (
        <>
            {topicInfo &&
                topicInfo.map((topic, index) => (
                    <TabPanel value={value} index={index} key={topic.id}>
                        <input name={`topics[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} value={topic.id} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper elevation={0}>
                                    <div className={classes.chips}>
                                        {topicInfo &&
                                            topicInfo[index].categories.map((cat) => {
                                                if (options) {
                                                    const category = options.find((c) => c.id.toString() === cat.id.toString());
                                                    if (category) {
                                                        return (
                                                            <Chip
                                                                key={category.id}
                                                                label={`${category.name}: ${category.description}`}
                                                                className={classes.chip}
                                                            />
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                    </div>
                                </Paper>
                            </Grid>
                            <TopicFPMContainerForm
                                mode={mode}
                                formType={formType}
                                form={form}
                                topicIndex={index}
                                organizationSectionRef={organizationSectionRef}
                                applicationSectionRef={applicationSectionRef}
                                collaborationSectionRef={collaborationSectionRef}
                                previousFormType={previousFormType}
                                subCycleOrder={subCycleOrder}
                                topicInfo={topic}
                            />
                        </Grid>
                    </TabPanel>
                ))}
        </>
    );
};

export interface TopicSuccessFactorPanelFormProps {
    mode: FormMode;
    value: number;
    formType?: SubCycleForm;
    form?: GetSubCycleSuccessFactorForm_subCycle_form | null;
    topicInfo?: TopicWithSuccessFactorsFields[];
    organizationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    applicationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    collaborationSectionRef: React.MutableRefObject<HTMLDivElement | null>;
    previousFormType: SubCycleForm | null;
    subCycleOrder: number;
}

export default TopicSuccessFactorPanelForm;
