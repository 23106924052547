import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, makeStyles, DialogActions, Typography } from '@material-ui/core';
import { getFormTypeShortName } from '../../../../utils/Enums/Helpers/FormTypeUtil';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { SubCycleForm } from '../../../../apollo/generated/types/globalTypes';

interface TransitionModalProps {
    open: boolean;
    onClose: (bool) => void;
    formType: SubCycleForm;
    downSelected: number;
}

const useStyles = makeStyles({
    dialog: {
        '& .MuiDialog-paper': {
            padding: '52px 24px 34px 24px',
        },
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    secondaryButton: {
        marginLeft: '0!important',
        marginTop: '10px',
    },
});

const TransitionModal: React.FC<TransitionModalProps> = ({ open, onClose, formType, downSelected }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" className={classes.dialog}>
            <DialogTitle disableTypography style={{ padding: '20px 0 0 0' }}>
                <div style={{ textAlign: 'center' }}>
                    <i
                        style={{
                            background: '#1F8BF3',
                            margin: 'auto',
                            height: '100%',
                            width: '100',
                            borderRadius: '50px',
                            display: 'inline-flex',
                            padding: '9px',
                        }}>
                        <AssignmentTurnedInIcon color="secondary" style={{ fontSize: 50 }} />
                    </i>
                    <div style={{ marginTop: '15px' }}>
                        <Typography variant="h1" style={{ fontWeight: 400 }}>
                            Confirm{' '}
                            <b>
                                <u>{getFormTypeShortName(formType)}</u>
                            </b>{' '}
                            Move to Next Sub-Cycle
                        </Typography>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{ textAlign: 'center', padding: '10px 0' }}>
                    <div>
                        <Typography variant="h3" style={{ fontWeight: 600 }}>
                            Organizations selected for next Sub-Cycle: {downSelected}
                        </Typography>
                    </div>
                    <div style={{ padding: '15px 0', width: '92%', margin: 'auto' }}>
                        <Typography variant="body2" style={{ lineHeight: '1.6' }}>
                            By selection of the confirm button, you will be moving the following Organizations to the next sub-cycle, and this sub-cycle will be
                            considered Complete. Once moved, you cannot undo this change. Are you sure you want to take this action?
                        </Typography>
                    </div>
                </div>

                <DialogActions className={classes.centerItems}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            onClose(true);
                        }}>
                        Confirm
                    </Button>
                    <Button
                        onClick={() => {
                            onClose(false);
                        }}
                        variant="text"
                        className={classes.secondaryButton}>
                        Cancel
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default TransitionModal;
