import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormCheckbox } from '../../..';
import { ReactHookFormAddress } from '../../../ReactHookForm';

const EmploymentForm: React.FC<EmploymentFormProps> = ({ mode }) => {
    const { register, errors, watch, getValues, control } = useFormContext();
    const isIndependentFreelancer = watch('independentFreelancer');

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Employment Details</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={6}>
                    <TextField
                        id="jobTitle"
                        name="jobTitle"
                        label="Job Title*"
                        inputRef={register({
                            required: 'Job Title is required',
                        })}
                        error={!!errors.jobTitle}
                        helperText={errors.jobTitle?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormAddress
                        mode={mode}
                        name="workAddress"
                        label="Work Address*"
                        rules={{
                            required: 'Work Address is required',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="employer"
                        name="employer"
                        label={'Employer' + (isIndependentFreelancer ? '' : '*')}
                        inputRef={register({
                            required: {
                                value: !isIndependentFreelancer,
                                message: 'Employer is required (if "Independent Freelancer" is not selected)',
                            },
                        })}
                        error={!!errors.employer}
                        helperText={errors.employer?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormCheckbox
                        name="independentFreelancer"
                        label="Independent Freelancer"
                        control={control}
                        defaultValue={getValues('independentFreelancer')}
                        readOnly={mode === FormMode.View}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface EmploymentFormProps {
    mode: FormMode;
}

export default EmploymentForm;
