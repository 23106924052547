import commentBox from 'commentbox.io';
import { useEffect, useRef } from 'react';

const CommentBoxTemp: React.FC = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const removeCommentBox = commentBox('5701573690785792-proj');
    useEffect(() => {
        if (containerRef.current !== null && containerRef.current.children.length > 0) {
            const iframe = containerRef.current.children[0] as HTMLIFrameElement;

            iframe.style.height = '500px';
            iframe.scrolling = 'yes';
        }
        return () => {
            removeCommentBox();
        };
    });

    return (
        <>
            <div className="commentbox" ref={containerRef} />
        </>
    );
};

export default CommentBoxTemp;
