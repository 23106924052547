import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DayOfWeek, UserAvailabilityInputType } from '../../../../apollo/generated/types/globalTypes';
import FormSection from '../../FormSection';
import AvailabilityItem from './AvailabilityItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import { ReactHookFormCheckbox } from '../../../ReactHookForm';
import { makeLocalTime } from '../../../../utils/date-format';

const useStyles = makeStyles((theme) => ({
    daySelect: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    availabilityControl: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
}));

const DAY_ORDER = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
};

const AvailabilityForm: React.FC<AvailabilityFormProps> = ({ mode }) => {
    const classes = useStyles();
    const { watch, control, errors } = useFormContext();
    const { fields: userAvailabilities, append, remove } = useFieldArray<UserAvailabilityInputType, 'key'>({
        control,
        name: 'userAvailabilities',
        keyName: 'key',
    });
    const [dayOptions, setDayOptions] = useState<Array<DayOfWeek>>([]);
    const [selectedDay, setSelectedDay] = useState<DayOfWeek | null>(null);

    const selectAvailableDay = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const day = event.target.value as DayOfWeek;
        setSelectedDay(day);
    };
    const addAvailableDay = () => {
        if (selectedDay) {
            append({
                day: selectedDay,
                startTime: new Date(0, 0, 0, 9), // 9:00 AM
                endTime: new Date(0, 0, 0, 17), // 5:00 PM
            });
            setSelectedDay(null); // clear selection
        }
    };

    useEffect(() => {
        // Initialize dayOptions list
        const numSelectedDays = Object.values(DayOfWeek).length - dayOptions.length;
        if (userAvailabilities.length !== numSelectedDays) {
            const newDayOptions = new Array<DayOfWeek>();
            Object.values(DayOfWeek).forEach((day) => {
                // Add day option if it has not already been selected
                if (!userAvailabilities.some((userAvailability) => userAvailability.day == day)) newDayOptions.push(day);
            });

            // Sort by order in which days appear in a week
            setDayOptions(
                newDayOptions.sort((a, b) => {
                    return DAY_ORDER[a] - DAY_ORDER[b];
                }),
            );
        }
    }, [userAvailabilities, dayOptions, setDayOptions]);

    const availabilitySubHeader = mode === FormMode.View ? 'Available Days/Hours' : `Set Available Days/Hours${watch('notAvailable') ? '' : '*'}`;

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Availability</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={6}>
                    <ReactHookFormCheckbox
                        name="notAvailable"
                        label="Not Available"
                        control={control}
                        readOnly={mode === FormMode.View}
                        rules={{
                            validate: (value) =>
                                value || userAvailabilities.length > 0 || '"Not Available" must be checked if there are no available days added',
                        }}
                        error={!!errors.notAvailable}
                        helperText={errors.notAvailable?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2">{availabilitySubHeader}</Typography>
                </Grid>
                {/* Controls for adding available days */}
                {
                    // Only show availability controls if in Edit/Create mode
                    mode === FormMode.View ? null : (
                        <div className={classes.availabilityControl}>
                            <FormControl className={classes.daySelect}>
                                <InputLabel id="daysAvailableLabel">Days Available</InputLabel>
                                <Select labelId="daysAvailableLabel" id="daysAvailable" value={selectedDay ?? ''} onChange={selectAvailableDay}>
                                    {!dayOptions || dayOptions.length <= 0 ? (
                                        <MenuItem disabled>No Days Available</MenuItem>
                                    ) : (
                                        dayOptions.map((day, index) => (
                                            <MenuItem key={index} value={day}>
                                                {day.toString()}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                            <Button onClick={addAvailableDay} startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />}>
                                Add Day
                            </Button>
                        </div>
                    )
                }
                {/* List of available days with time options */}
                <Grid container justify="space-around">
                    {userAvailabilities
                        // Sort current list of available days based on set order
                        .sort((a, b) => {
                            const dayA = a.day;
                            const dayB = b.day;
                            if (!dayA || !dayB) return 0;
                            return DAY_ORDER[dayA] - DAY_ORDER[dayB];
                        })
                        // Map available days to AvailabilityItem components
                        .map((userAvailability, index) => {
                            if (userAvailability.day) {
                                let startTime = userAvailability.startTime;
                                let endTime = userAvailability.endTime;
                                if (typeof startTime === 'string' || typeof endTime === 'string') {
                                    startTime = makeLocalTime(new Date(startTime));
                                    endTime = makeLocalTime(new Date(endTime));
                                }

                                return (
                                    <AvailabilityItem
                                        key={userAvailability.key}
                                        day={userAvailability.day}
                                        startTime={startTime}
                                        endTime={endTime}
                                        index={index}
                                        mode={mode}
                                        remove={remove}
                                    />
                                );
                            }

                            return null;
                        })}
                </Grid>
            </FormSection>
        </>
    );
};

export interface AvailabilityFormProps {
    mode: FormMode;
}

export default AvailabilityForm;
