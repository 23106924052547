import * as React from 'react';
import { REMOVE_CYCLE_USERS } from '../../../../apollo/mutations';
import { RemoveCycleUsers } from '../../../../apollo/generated/types/RemoveCycleUsers';
import { GetCycleEvaluatorsFull_cycleUsers } from '../../../../apollo/generated/types/GetCycleEvaluatorsFull';
import WarningIcon from '@material-ui/icons/Warning';
import { ConfirmDialog } from '../../..';
import { useMutation } from '@apollo/client';
import { CycleUserInputType } from '../../../../apollo/generated/types/globalTypes';

const RemoveEvaluatorsDialog: React.FC<RemoveEvaluatorsDialogProps> = ({ selectedEvaluators, cycleId, open, closeDialog, onClose }) => {
    const initClose = () => {
        onClose();
        closeDialog();
    };
    const [removeCycleUsers, { loading }] = useMutation<RemoveCycleUsers>(REMOVE_CYCLE_USERS, {
        variables: {
            cycleId: cycleId,
            users: selectedEvaluators.map((evaluator) => ({ userId: evaluator.user?.id, cycleRoleId: evaluator.cycleRole?.id } as CycleUserInputType)),
        },
        onCompleted: (_: RemoveCycleUsers) => {
            initClose();
        },
    });

    const evaluatorNames = selectedEvaluators.map((evaluator) => evaluator.user?.fullName);
    const numEvals = evaluatorNames.length;
    // Format list of evaluators grammatically
    let selectedEvaluatorsStr;
    switch (numEvals) {
        case 0:
            selectedEvaluatorsStr = '';
            break;
        case 1:
            selectedEvaluatorsStr = evaluatorNames[0];
            break;
        default:
            selectedEvaluatorsStr = `${evaluatorNames.splice(0, numEvals - 1).join(', ')}${numEvals > 2 ? ',' : ''} and ${evaluatorNames[0]}`;
            break;
    }
    return (
        <ConfirmDialog
            open={open}
            icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
            title={`Are you sure you want to remove ${numEvals === 1 ? 'this evaluator' : 'these evaluators'}?`}
            message={
                <>
                    {selectedEvaluatorsStr} will be removed from this Acquisition Cycle.
                    <br />
                    Press Confirm to remove, or Cancel to keep them as {numEvals === 1 ? 'an evaluator' : 'evaluators'}.
                </>
            }
            primaryText="Confirm"
            secondaryText="Cancel"
            primaryBusy={loading}
            onPrimaryClick={removeCycleUsers}
            onSecondaryClick={initClose}
        />
    );
};

export interface RemoveEvaluatorsDialogProps {
    selectedEvaluators: Array<GetCycleEvaluatorsFull_cycleUsers>;
    cycleId: number;
    open: boolean;
    closeDialog: () => void;
    onClose: () => void;
}

export default RemoveEvaluatorsDialog;
