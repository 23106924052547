import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_PROFILE } from '../apollo/queries';
import { LoadingButton, ProfileForm, PageWrapper, PageHeader, PageBody, LoadingMessage, ErrorMessage, usePermissions } from '../components';
import { FormProvider, useForm } from 'react-hook-form';
import { GetProfile, GetProfileVariables } from '../apollo/generated/types/GetProfile';
import { UserProfileInputType } from '../apollo/generated/types/globalTypes';
import { FormMode } from '../utils/Enums';
import { UpdateUserProfile, UpdateUserProfileVariables } from '../apollo/generated/types/UpdateUserProfile';
import { UPDATE_USER_PROFILE } from '../apollo/mutations';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        flex: 1,
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogCreated: {
        textDecoration: 'underline',
        fontWeight: 900,
    },
    dialogDescription: {
        color: '#1A2A3D',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
    },
});

const ProfilePage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user, updateUserInfo } = usePermissions();
    const [apiError, setAPIError] = useState<ApolloError | null>();

    const { loading, error, data: profile } = useQuery<GetProfile, GetProfileVariables>(GET_PROFILE, {
        variables: {
            userId: user?.id,
        },
        skip: !user,
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => console.log(data),
        onError: (error: ApolloError) => {
            console.error(error.message);
            setAPIError(error);
        },
    });

    const convertProfileDataToForm = useCallback((userData?: GetProfile): UserProfileInputType => {
        const data = userData?.user;
        const defaults = {
            id: data?.id,
            firstName: data?.firstName,
            lastName: data?.lastName,
            countryOfCitizenship: data?.countryOfCitizenship,
            email: data?.email,
            secondaryEmail: data?.secondaryEmail,
            phoneNumber: data?.phoneNumber,
            employer: data?.employer,
            workAddress: data?.workAddress,
            jobTitle: data?.jobTitle,
            independentFreelancer: data?.independentFreelancer ? data?.independentFreelancer : false,
            notAvailable: data?.notAvailable ? data?.notAvailable : false,
            cycleAssignments: data?.cycleAssignments,
            userAvailabilities: data?.userAvailabilities,
            isProfileComplete: data?.isProfileComplete,
        } as UserProfileInputType;
        return defaults;
    }, []);

    const methods = useForm<UserProfileInputType>({
        defaultValues: convertProfileDataToForm(profile),
    });

    useEffect(() => {
        methods.reset(convertProfileDataToForm(profile));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [convertProfileDataToForm, profile]);

    // Updates profile with form data
    const [updateUserProfile, { loading: mutationLoading }] = useMutation<UpdateUserProfile, UpdateUserProfileVariables>(UPDATE_USER_PROFILE, {
        onCompleted: () => {
            setAPIError(null);
            updateUserInfo();
            setTimeout(()=>{
                history.push('/dashboard');
            }, 1000);
        },
        onError: (error: ApolloError) => {
            console.error(error.message);
            setAPIError(error);
        },
    });

    // Submits form using update mutation
    const onSubmitUser = (data: UserProfileInputType) => {
        updateUserProfile({
            variables: {
                userProfile: data,
            },
        });
    };

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    {!user?.isProfileComplete ? null : (
                        <IconButton onClick={() => history.push('/dashboard')}>
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    <Typography variant="h1" className={classes.title}>
                        Profile
                    </Typography>
                    {!loading || !error ? (
                        <LoadingButton pending={mutationLoading} form="user-profile-form" type="submit" variant="contained">
                            {user?.isProfileComplete ? 'Save Changes' : 'Complete Profile'}
                        </LoadingButton>
                    ) : null}
                </Toolbar>
            </PageHeader>
            <PageBody>
                {loading ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : (
                    <div className={classes.root}>
                        <FormProvider {...methods}>
                            <ProfileForm
                                onSubmit={onSubmitUser}
                                mode={FormMode.Edit}
                                profileComplete={user?.isProfileComplete ?? false}
                                apiError={apiError}
                                teamAssignments={profile?.user?.cycleAssignments}
                            />
                        </FormProvider>
                    </div>
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default ProfilePage;
