import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Logo from './Logo';
import Navigation from './Navigation';
import UserProfile from './UserProfile';
import Reports from './Reports';
import { useAuth0 } from '@auth0/auth0-react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { usePermissions } from '../Providers';

const useStyles = makeStyles({
    root: {
        color: '#FFFFFF',
        position: 'relative',
        fontSize: '15px',
        lineHeight: '18px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    signOut: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        color: '#FFFFFF',
        letterSpacing: '-0.3px',
    },
    dividerLogo: {
        height: '2px',
        marginBottom: '6px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    dividerProfile: {
        height: '2px',
        marginTop: '6px',
        marginBottom: '22px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    divider: {
        height: '2px',
        marginTop: '8px',
        marginBottom: '28px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
    space: {
        flexGrow: 1,
    },
});

const SideBar: React.FC = () => {
    const classes = useStyles();
    const { logout } = useAuth0();
    const { user } = usePermissions();
    // handle the hiding of nav links based on profile completion
    const [reduce, setReduce] = useState<boolean>(false);
    useEffect(() => {
        const isProfileComplete = user?.isProfileComplete ?? false;
        setReduce(!isProfileComplete);
    }, [user, setReduce]);

    return (
        <nav className={classes.root}>
            <Logo />
            <Divider className={classes.dividerLogo} />
            <UserProfile reduce={reduce} />
            <Divider className={classes.dividerProfile} />
            {!reduce ? ( // include navigation and report links if not on the profile page
                <>
                    <Navigation />
                    <Divider className={classes.divider} />
                    <Reports />
                    <Divider className={classes.divider} />
                </>
            ) : null}
            <div className={classes.space} />
            <ListItem button className={classes.signOut} onClick={() => logout({ returnTo: window.location.origin })}>
                <ListItemIcon>
                    <ExitToAppIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Sign Out" />
            </ListItem>
        </nav>
    );
};

export default SideBar;
