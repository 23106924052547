import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import { Avatar, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import { usePermissions } from '../Providers';

const useStyles = makeStyles({
    button: {
        width: '100%',
    },
    image: {
        width: '42px',
        height: '42px',
    },
    textPrimary: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '18px',
        fontWeight: 'bold',
        fontFamily: "'Lato', sans-serif",
        textTransform: 'none',
        letterSpacing: '-0.3px',
    },
    textSecondary: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '18px',
        opacity: '0.6',
        fontFamily: "'Lato', sans-serif",
        textTransform: 'none',
        letterSpacing: '-0.3px',
    },
    skeleton: {
        backgroundColor: 'rgba(255, 255, 255, 0.11)',
    },
    iconWrapper: {
        minWidth: '32px',
    },
    link: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        color: '#FFFFFF',
        letterSpacing: '-0.3px',
    },
    activeLink: {
        color: '#FFFFFF',
        background: '#1976D2',
        borderRadius: '2px',
    },
});

export interface UserProfileProps {
    reduce?: boolean;
}

const UserProfile: React.FC<UserProfileProps> = ({ reduce }) => {
    const { user: auth0User } = useAuth0();
    const { user } = usePermissions();
    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        // Ensure that profile navigation links remain visible if on the profile page
        if (reduce) setOpen(true);
    }, [setOpen, reduce]);

    const handleClick = () => {
        let newOpen = !open;
        if (reduce) newOpen = true;
        setOpen(newOpen);
    };

    return (
        <>
            <List>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        {user ? (
                            <Avatar src={auth0User.picture} alt={auth0User.name} className={auth0User.image} onClick={() => history.push('/profile')} />
                        ) : (
                            <Skeleton variant="circle" className={`${classes.image} ${classes.skeleton}`} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            user ? (
                                <Typography className={classes.textPrimary}>
                                    {user.fullName && user.fullName?.trim() !== '' ? user.fullName : user.email}
                                </Typography>
                            ) : (
                                <Skeleton variant="text" className={`${classes.textPrimary} ${classes.skeleton}`} />
                            )
                        }
                        secondary={
                            user ? (
                                <Typography className={classes.textSecondary}>{user.externalRoleName}</Typography>
                            ) : (
                                <Skeleton variant="text" className={`${classes.textSecondary} ${classes.skeleton}`} />
                            )
                        }
                    />
                    {
                        // don't display icon if on profile page
                        !reduce ? open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon /> : null
                    }
                </ListItem>
                {!reduce ? (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List>
                            <ListItem to="/profile" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                                <ListItemIcon className={classes.iconWrapper}>
                                    <PersonIcon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                <ListItemText primary="Profile" />
                            </ListItem>
                            <ListItem to="/settings" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                                <ListItemIcon className={classes.iconWrapper}>
                                    <SettingsIcon fontSize="small" color="secondary" />
                                </ListItemIcon>
                                <ListItemText primary="Settings" />
                            </ListItem>
                        </List>
                    </Collapse>
                ) : null}
            </List>
        </>
    );
};

export default UserProfile;
