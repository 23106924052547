import * as React from 'react';
import { Button, createStyles, Paper, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MUIDataTableStateRows, MUIDataTableToolbarSelect } from 'mui-datatables';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'backgroundColor': theme.palette.background.default,
            'flex': '1 1 100%',
            'display': 'flex',
            'position': 'relative',
            'zIndex': 120,
            'justifyContent': 'space-between',
            'alignItems': 'center',
            'paddingTop': theme.spacing(1),
            'paddingBottom': theme.spacing(1),
            'margin': '10px 22px 0 22px',
            '@media print': {
                display: 'none',
            },
        },
        title: {
            paddingLeft: '26px',
        },
        iconButton: {
            marginRight: '24px',
        },
    }),
);

export interface ToolbarSelectProps extends MUIDataTableToolbarSelect {
    selectedRows: MUIDataTableStateRows;
    openAssignModal: (selectedRows: number[], assign: boolean) => void;
    openRemoveModal: (selectedRows: number[]) => void;
}

const ToolbarSelect: React.FC<ToolbarSelectProps> = ({ selectedRows, openAssignModal, openRemoveModal }) => {
    const classes = useStyles();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getSelectedRowIndices = () => selectedRows.data.map((row: any) => row.dataIndex);
    const handleAssignClick = () => {
        openAssignModal(getSelectedRowIndices(), true);
    };
    const handleRemoveClick = () => {
        openRemoveModal(getSelectedRowIndices());
    };

    return (
        <Paper className={classes.root}>
            <div>
                <Typography variant="subtitle1" className={classes.title}>
                    {selectedRows.data.length} row(s) selected
                </Typography>
            </div>
            {/* Action Buttons */}
            <div>
                <Button startIcon={<AddCircleIcon />} className={classes.iconButton} onClick={handleAssignClick}>
                    Assign Sub-Cycles
                </Button>
                <Button startIcon={<RemoveCircleIcon />} className={classes.iconButton} onClick={handleRemoveClick}>
                    Remove Evaluator(s)
                </Button>
            </div>
        </Paper>
    );
};

export default ToolbarSelect;
