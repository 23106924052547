import * as React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    pendingIndicator: {
        position: 'absolute',
        visibility: 'visible',
        display: 'flex',
        left: '50%',
        transform: 'translate(-50%)',
    },
});

// Have to wrap in forwardRef call so tooltips work on button
const LoadingIconButton: React.FC<LoadingIconButtonProps> = React.forwardRef(({ children, disabled = false, pending = false, ...other }, ref) => {
    const classes = useStyles({
        pending,
    });

    return (
        <IconButton disabled={disabled || pending} ref={ref} {...other}>
            {pending && (
                <div className={classes.pendingIndicator}>
                    <CircularProgress color="inherit" size={16} />
                </div>
            )}
            {children}
        </IconButton>
    );
});

export interface LoadingIconButtonProps extends IconButtonProps {
    pending?: boolean;
}

export default LoadingIconButton;
