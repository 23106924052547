import * as React from 'react';
import { Grid, Typography, Paper, Breadcrumbs, Link, Button, IconButton, MenuItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { makeStyles } from '@material-ui/core/styles';
import { ReactHookFormCheckbox, ReactHookFormSelect } from '../../../ReactHookForm';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useFormContext } from 'react-hook-form';
import ResponseDocumentModal from './ResponseDocumentModal';
import AllCommentsModal from './AllCommentsModal';
import { useEffect } from 'react';
import { ResponseStatus } from '../../../../apollo/generated/types/globalTypes';
import { CycleTab } from '../../../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    bold: {
        fontWeight: 600,
    },
    breadcrumbs: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    checkBox: {
        marginRight: 0,
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 2,
    },
    hidden: {
        visibility: 'hidden',
        position: 'absolute',
    },
});

interface URLParams {
    id: string; // Acquisition Cycle ID
}

interface EvaluationHeaderProps {
    mode?: FormMode;
}

const EvaluationHeader: React.FC<EvaluationHeaderProps> = ({ mode }) => {
    const classes = useStyles();
    const { id } = useParams<URLParams>();
    const { evaluations, handleNextEvaluation, handlePreviousEvaluation, handleSelectEvaluation, evaluationIndex, loading } = useTeamLeadScore();
    const { control, getValues, setValue, watch } = useFormContext();
    const [showAllComments, setShowAllComments] = React.useState<boolean>(false);
    const [showResponseDocument, setShowResponseDocument] = React.useState<boolean>(false);
    const teamLeadStatus = watch(`evaluations.0.status`);
    const readOnly = mode === FormMode.View || teamLeadStatus === ResponseStatus.LOCKED;

    // Updates selected evaluation
    const handleTemplateChange = (newTemplateId: number) => {
        const oldTemplateId = getValues('selected-evaluator');
        if (oldTemplateId !== newTemplateId) {
            setValue('selected-evaluator', newTemplateId);
            handleSelectEvaluation(newTemplateId);
        }
    };

    const handleResponseDocumentModal = () => {
        setShowResponseDocument(!showResponseDocument);
    };

    const handleAllCommentsModal = () => {
        setShowAllComments(!showAllComments);
    };

    // Ensures that the select is updated when the next/previous buttons are pressed
    useEffect(() => {
        const oldTemplateId = getValues('selected-evaluator');
        if (evaluations[evaluationIndex].id !== oldTemplateId) {
            setValue('selected-evaluator', evaluations[evaluationIndex].id);
        }
    }, [evaluationIndex, evaluations, getValues, setValue]);

    return (
        <>
            <Paper style={{ borderRadius: '8px', width: '100%' }} className={classes.stickyHeader}>
                <FormSection>
                    <Grid container justify="space-between">
                        <Grid style={{ margin: 'auto 0' }}>
                            <Typography variant="h2">
                                {!loading ? evaluations[evaluationIndex].response.organization.name : 'Organization'} Evaluations
                            </Typography>
                        </Grid>
                        <Grid style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" align="left" className={classes.bold} style={{ margin: 'auto 0', borderRadius: '25px' }}>
                                <ReactHookFormSelect
                                    selectProps={{ style: { margin: 0 }, disableUnderline: true }}
                                    onSelectChange={(e) => handleTemplateChange((e.target.value ?? -1) as number)}
                                    name="selected-evaluator"
                                    control={control}
                                    defaultValue={!loading ? evaluations[evaluationIndex].id : 0}>
                                    {!loading
                                        ? evaluations.map((evaluation) => {
                                              return (
                                                  <MenuItem key={evaluation.id} value={evaluation.id}>
                                                      {evaluation.user.fullName}
                                                  </MenuItem>
                                              );
                                          })
                                        : ''}
                                </ReactHookFormSelect>
                            </Typography>

                            <IconButton
                                style={{ marginRight: '10px', borderRadius: '25px' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handlePreviousEvaluation();
                                }}>
                                <ArrowBackIcon />
                            </IconButton>

                            <IconButton
                                style={{ marginRight: '30px', borderRadius: '25px' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleNextEvaluation();
                                }}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <div className={classes.breadcrumbs}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link component={NavLink} to={`/acquisition-cycle/${id}/${CycleTab.Response}`}>
                                <Typography variant="h3" style={{ color: '#1A2A3D', opacity: '0.7' }}>
                                    Response Evaluation
                                </Typography>
                            </Link>
                            <Typography variant="h3" style={{ color: '#1A2A3D', opacity: '0.7' }}>
                                {!loading ? evaluations[evaluationIndex].response.organization.name : 'Organization'}
                            </Typography>
                            <Typography variant="h3" style={{ color: '#1976D2' }}>
                                Evaluation #{evaluationIndex}
                            </Typography>
                        </Breadcrumbs>
                    </div>
                </FormSection>
                <div style={{ width: '97%', margin: 'auto' }}>
                    <FormDivider />
                </div>
                <FormSection>
                    <Grid container justify="space-between">
                        <Grid item style={{ display: 'flex' }}>
                            <Button style={{ marginRight: '8px', padding: '8px' }} onClick={() => handleResponseDocumentModal()}>
                                <Typography variant="subtitle2" className={classes.bold}>
                                    <AttachFileIcon style={{ verticalAlign: 'middle' }} />
                                    Response Documents
                                </Typography>
                            </Button>
                            <Button style={{ marginRight: '8px', padding: '8px' }} onClick={() => handleAllCommentsModal()}>
                                <Typography variant="subtitle2" className={classes.bold}>
                                    <SpeakerNotesOutlinedIcon style={{ verticalAlign: 'middle' }} />
                                    All Comments
                                </Typography>
                            </Button>
                        </Grid>
                        <>
                            {!loading ? (
                                evaluations.map((evaluation, index) => {
                                    return (
                                        <Grid key={evaluation.id} item className={index === evaluationIndex ? classes.checkBox : classes.hidden}>
                                            <ReactHookFormCheckbox
                                                readOnly={readOnly}
                                                control={control}
                                                name={`evaluations[${index + 1}].reviewed`}
                                                defaultValue={evaluation.reviewed}
                                                label="Mark As Reviewed"
                                            />
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid item className={classes.checkBox}>
                                    <ReactHookFormCheckbox
                                        readOnly={readOnly}
                                        control={control}
                                        name={'header-checkbox'}
                                        defaultValue={false}
                                        label="Mark As Reviewed"
                                    />
                                </Grid>
                            )}
                        </>
                    </Grid>
                </FormSection>
            </Paper>
            <ResponseDocumentModal open={showResponseDocument} close={handleResponseDocumentModal} />
            <AllCommentsModal open={showAllComments} close={handleAllCommentsModal} />
        </>
    );
};

export default EvaluationHeader;
