/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { TabPanel, TabPanelProps } from '../index';
import { Button, createStyles, Grid, IconButton, Paper, Popover, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import ToolbarSelect, { ToolbarSelectProps } from './ToolbarSelect';
import { ErrorMessage, LoadingMessage, ScreenMessage, TableActionMenu, usePermissions } from '../../../index';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EvaluatorsModal, { CycleRole, EvaluatorData, EvaluatorFormInput } from '../../../Modals/EvaluatorModal';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_EVALUATORS, GET_CYCLE_EVALUATORS_FULL } from '../../../../apollo/queries';
import { useParams } from 'react-router-dom';
import {
    GetCycleEvaluatorsFull,
    GetCycleEvaluatorsFull_cycleUsers,
    GetCycleEvaluatorsFull_cycleUsers_user_subCycleAssignments,
} from '../../../../apollo/generated/types/GetCycleEvaluatorsFull';
import { formatDistanceToNow, parseISO } from 'date-fns';
import SubCyclesModal from './SubCyclesModal';
import RemoveEvaluatorsDialog from './RemoveEvaluatorsDialog';
import { GetEvaluators } from '../../../../apollo/generated/types/GetEvaluators';
import { AddCycleUsers } from '../../../../apollo/generated/types/AddCycleUsers';
import { ADD_CYCLE_USERS } from '../../../../apollo/mutations';
import { CycleUserInputType } from '../../../../apollo/generated/types/globalTypes';
import { useCycleData } from '../../../Providers/CycleDataProvider';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullTab: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        tooltip: {
            padding: theme.spacing(2),
        },
        popover: {
            pointerEvents: 'none',
        },
        header: {
            alignItems: 'center',
            padding: '26px 30px 0px 30px !important',
        },
        table: {
            'paddingBottom': '0!important',
            '&.MuiPaper-root': {
                margin: '5px 22px!important',
            },
        },
        subCycleCell: {
            '&:hover': {
                color: 'blue',
            },
        },
        email: {
            'textDecoration': 'none !important',
            'color': 'black',
            '&:hover': {
                textDecoration: 'underline !important',
                color: 'blue',
            },
        },
    }),
);

interface URLParams {
    id: string; // Acquisition Cycle ID
}

export type EvaluatorsTabPanelProps = TabPanelProps;

const EvaluatorsTabPanel: React.FC<EvaluatorsTabPanelProps> = ({ index, value, ...other }) => {
    const classes = useStyles();
    const { id } = useParams<URLParams>();
    const cycleId = parseInt(id);
    const { readOnly: cycleReadOnly } = useCycleData();
    const [evaluators, setEvaluators] = useState<Array<GetCycleEvaluatorsFull_cycleUsers>>();
    const [tooltipEl, setTooltipEl] = useState<HTMLElement | null>(null);
    const [subCyclesModalOpen, setSubCyclesModalOpen] = useState<boolean>(false);
    const [assignSubCycles, setAssignSubCycles] = useState<boolean>(true);
    const [selectedEvaluators, setSelectedEvaluators] = useState<Array<GetCycleEvaluatorsFull_cycleUsers>>();
    const [removeEvalDialogOpen, setRemoveEvalDialogOpen] = useState<boolean>(false);
    // Data for Add Evaluator modal
    const [addEvaluatorModalEl, setAddEvaluatorModalEl] = useState<HTMLElement | null>(null);
    const [evaluatorChoices, setEvaluatorChoices] = useState<EvaluatorData[] | null>(null);
    const [cycleRoles, setCycleRoles] = useState<CycleRole[] | null>(null);

    const { isEvaluator, isSystemProgramManagement, isTeamLead } = usePermissions();

    const { loading, error, refetch } = useQuery<GetCycleEvaluatorsFull>(GET_CYCLE_EVALUATORS_FULL, {
        fetchPolicy: 'cache-and-network',
        variables: {
            cycleId: id,
        },
        onCompleted: (data) => {
            if (data) {
                setEvaluators(data.cycleUsers || []);
            }
        },
    });
    /// Functions for loading and submitting data for Evaluator modal ///
    // Loading functons
    const [getAddData] = useLazyQuery<GetEvaluators>(GET_EVALUATORS, {
        fetchPolicy: 'no-cache',
        variables: {
            excludeCycleId: id,
        },
        onCompleted: (data) => {
            setEvaluatorChoices(data?.users as EvaluatorData[]);
            setCycleRoles(data?.cycleRoles);
        },
    });
    // Submission functions
    const [addEvaluators, { loading: addLoading }] = useMutation<AddCycleUsers>(ADD_CYCLE_USERS);
    const onAdd = async (data: EvaluatorFormInput): Promise<ApolloError | null> => {
        try {
            const addUsers = data.evaluators
                .filter((user) => user.selected)
                .map(
                    (user) =>
                        ({
                            userId: user.userId,
                            cycleRoleId: user.cycleRoleId,
                        } as CycleUserInputType),
                );
            validateEvaluatorLimits(addUsers);
            await addEvaluators({
                variables: {
                    cycleId: id,
                    users: addUsers,
                },
            });

            return null;
        } catch (e) {
            return e;
        }
    };

    const validateEvaluatorLimits = (newData: CycleUserInputType[]) => {
        if (evaluators && newData && cycleRoles) {
            const evaluatorRole = cycleRoles.find((role) => role.name === 'Evaluator');
            const evaluatorCount = evaluators.length + newData?.length;
            const evaluatorLimit = evaluatorRole?.limit !== null ? evaluatorRole?.limit : 15;
            if (evaluatorCount > evaluatorLimit!) {
                throw new Error(`You have reached the maximum team size of ${evaluatorLimit} evaluators. You must remove team members to add new ones.`);
            }

            const teamLeadRole = cycleRoles.find((role) => role.name === 'Team Lead');
            const teamLeadCount =
                newData.filter((evaluator) => evaluator.cycleRoleId === teamLeadRole?.id).length +
                evaluators.filter((evaluator) => evaluator.cycleRole.id === teamLeadRole?.id).length;
            const teamLeadLimit = teamLeadRole?.limit !== null ? teamLeadRole?.limit : 1;
            if (teamLeadCount > teamLeadLimit!) {
                throw new Error(`You have reached the Team Lead size limit of ${teamLeadLimit} for this cycle. You must remove team members to add new ones.`);
            }

            const coLeadRole = cycleRoles.find((role) => role.name === 'Co-Lead');
            const coLeadCount =
                newData.filter((evaluator) => evaluator.cycleRoleId === coLeadRole?.id).length +
                evaluators.filter((evaluator) => evaluator.cycleRole.id === coLeadRole?.id).length;
            const coLeadLimit = coLeadRole?.limit !== null ? coLeadRole?.limit : 3;
            if (coLeadCount > coLeadLimit!) {
                throw new Error(`You have reached the Co-Lead size limit of ${coLeadLimit} for this cycle. You must remove team members to add new ones.`);
            }
        }
    };

    // Tooltip controls
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setTooltipEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setTooltipEl(null);
    };

    // Add Evaluators modal controls
    const handleAddOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        getAddData();
        setAddEvaluatorModalEl(event.currentTarget);
    };
    const handleAddClose = async () => {
        setAddEvaluatorModalEl(null);
        setEvaluatorChoices(null);
        setCycleRoles(null);
        await refetch();
    };

    // Assign/Remove Sub-Cycles modal controls
    const handleSubCyclesOpen = (selectedRows: Array<number>, assign: boolean) => {
        setSelectedEvaluators(evaluators?.filter((_, index) => selectedRows.includes(index)));
        setAssignSubCycles(assign);
        setSubCyclesModalOpen(true);
    };
    const handleSubCyclesClose = async () => {
        setSubCyclesModalOpen(false);
        await refetch();
    };

    // Evaluator Removal Confirmation Dialog controls
    const handleRemoveEvalOpen = (selectedRows: Array<number>) => {
        setSelectedEvaluators(evaluators?.filter((_, index) => selectedRows.includes(index)));
        setRemoveEvalDialogOpen(true);
    };

    // Column definitions
    const arrayColumnCompare = (order: 'asc' | 'desc', filter: ((list: any[]) => any[]) | null = null) => {
        // return comparison function
        return (obj1: { data: any }, obj2: { data: any }) => {
            let dataSet1 = obj1.data;
            let dataSet2 = obj2.data;
            // apply the filter if one is provided
            if (filter) {
                dataSet1 = filter(dataSet1);
                dataSet2 = filter(dataSet2);
            }
            const numItems1 = dataSet1.length;
            const numItems2 = dataSet2.length;
            // perform comparison based on sort order
            if (order === 'asc') return numItems1 - numItems2;
            else return numItems2 - numItems1;
        };
    };
    const removeExternalSubCycles = (subCycleAssignments: GetCycleEvaluatorsFull_cycleUsers_user_subCycleAssignments[]) => {
        return subCycleAssignments.filter((sca) => `${sca.subCycle?.cycleId}` === id);
    };
    const columns = [
        {
            name: 'user.firstName',
            label: 'First Name',
            options: {
                filter: true,
                display: false,
            },
        },
        {
            name: 'user.lastName',
            label: 'Last Name',
            options: {
                filter: true,
                display: false,
            },
        },
        {
            name: 'user.fullName',
            label: 'Name',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'user.employer',
            label: 'Employer',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'user.jobTitle',
            label: 'Job Title',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'cycleRole.name',
            label: 'Role',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'user.cycleAssignments',
            label: 'Team Assignments',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: []) => (value ? `${value.length}` : '0'),
                sortCompare: (order: 'asc' | 'desc') => arrayColumnCompare(order),
            },
        },
        {
            name: 'user.subCycleAssignments',
            label: 'Sub-Cycle Assignments',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
                setCellProps: (cellValue: string) => {
                    let className = '';
                    const style = { textAlign: 'center', textDecoration: 'none', cursor: 'auto' };
                    if (cellValue !== '0' && !cycleReadOnly) {
                        className = classes.subCycleCell;
                        style.textDecoration = 'underline';
                        style.cursor = 'pointer';
                    }

                    return { className, style };
                },
                customBodyRender: (value: []) => (value ? `${removeExternalSubCycles(value).length}` : '0'),
                sortCompare: (order: 'asc' | 'desc') => arrayColumnCompare(order, removeExternalSubCycles),
            },
        },
        {
            name: 'user.email',
            label: 'Email Address',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                customBodyRender: (value: any) => (
                    <a className={classes.email} href={`mailto:${value}`}>
                        {value}
                    </a>
                ),
            },
        },
        {
            name: 'user.phoneNumber',
            label: 'Phone Number',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'user.available',
            label: 'Availability',
            options: {
                filter: true,
                sort: true,
                sortThirdClickReset: true,
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: boolean) => (value ? 'Yes' : 'No'),
            },
        },
        {
            name: 'user.lastLogin',
            label: 'Last Login',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                customBodyRender: (value: string | null) => (value ? formatDistanceToNow(parseISO(value)) + ' ago' : 'Never'),
            },
        },
        {
            name: 'user.id',
            label: ' ',
            options: {
                display: !isEvaluator(cycleId) || isSystemProgramManagement() || isTeamLead(cycleId),
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex: number, _rowIndex: number) => {
                    const evaluator = evaluators ? evaluators[dataIndex] : null;

                    return (
                        <TableActionMenu
                            id={evaluator?.user?.id ?? dataIndex}
                            index={dataIndex}
                            unassignText={'Remove'}
                            showUnassign={!cycleReadOnly}
                            onUnassignClick={() => handleRemoveEvalOpen([dataIndex])} // selects current evaluator for removal
                        />
                    );
                },
            },
        },
    ];

    const options = {
        enableNestedDataAccess: '.',
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRows: (!isEvaluator(cycleId) || isSystemProgramManagement() || isTeamLead(cycleId)) && !cycleReadOnly ? 'multiple' : false,
        selectableRowsHeader: false,
        onRowsDelete: () => false,
        onCellClick: (cellData, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }) => {
            if (evaluators) {
                const evaluatorIndex = cellMeta.dataIndex;
                const columnName = columns[cellMeta.colIndex].name;
                if (columnName === 'user.subCycleAssignments' && !cycleReadOnly) {
                    if (cellData !== '0' && (!isEvaluator(cycleId) || isSystemProgramManagement() || isTeamLead(cycleId)))
                        handleSubCyclesOpen([evaluatorIndex], false);
                } else if (columnName === 'user.email') {
                    return;
                } else {
                    const evaluator = evaluators[evaluatorIndex];
                    if (evaluator.user && (!isEvaluator(cycleId) || isSystemProgramManagement() || isTeamLead(cycleId))) {
                        window.open(`/evaluator/${evaluator.user?.id}`, '_blank');
                    }
                }
            }
        },
        selectToolbarPlacement: 'above',
        pagination: false,
    } as MUIDataTableOptions;

    const tooltipOpen = Boolean(tooltipEl);
    const addModalOpen = Boolean(addEvaluatorModalEl);

    return (
        <TabPanel index={index} value={value} className={loading || !evaluators || evaluators.length === 0 ? classes.fullTab : ''} {...other}>
            {loading ? (
                <LoadingMessage />
            ) : error ? (
                <ErrorMessage error={error} />
            ) : evaluators && evaluators.length > 0 ? (
                <Paper elevation={0}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} justify="space-between" className={classes.header}>
                            <Typography variant="h1">
                                Evaluators
                                <IconButton
                                    aria-owns={tooltipOpen ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseLeave={handlePopoverClose}
                                    onMouseEnter={handlePopoverOpen}>
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Typography>
                            <Popover
                                id="mouse-over-popover"
                                open={tooltipOpen}
                                anchorEl={tooltipEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handlePopoverClose}
                                className={classes.popover}
                                disableRestoreFocus>
                                <Paper className={classes.tooltip}>
                                    <Typography>There is a maximum of 15 evaluators per team.</Typography>
                                </Paper>
                            </Popover>
                            {(!isEvaluator(cycleId) || isSystemProgramManagement() || isTeamLead(cycleId)) && (
                                <Button startIcon={<AddCircleIcon />} variant="contained" onClick={handleAddOpen} disabled={cycleReadOnly}>
                                    Add Evaluator
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12} className={classes.table}>
                            <MUIDataTable
                                columns={columns}
                                data={evaluators}
                                options={options}
                                title=""
                                components={{
                                    TableToolbarSelect: function (props: ToolbarSelectProps) {
                                        return <ToolbarSelect {...props} openAssignModal={handleSubCyclesOpen} openRemoveModal={handleRemoveEvalOpen} />;
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            ) : (
                <ScreenMessage
                    icon={<AccountCircleIcon style={{ fontSize: 46 }} />}
                    header="Welcome!"
                    subHeader="Get started by Adding Evaluators to your Team!">
                    <Button startIcon={<AddCircleIcon />} variant="contained" onClick={handleAddOpen}>
                        Add Evaluator
                    </Button>
                </ScreenMessage>
            )}

            {/* Modals/Dialogs */}
            <EvaluatorsModal
                numEvaluators={evaluators?.length}
                evaluatorLimit={15}
                roleRequired={true}
                open={addModalOpen}
                loading={addLoading}
                evaluators={evaluatorChoices}
                cycleRoles={cycleRoles}
                filterRoles={(evaluator: EvaluatorData, roles: CycleRole[]) => {
                    if (evaluator.externalRoleName.toUpperCase() !== 'PROGRAM MANAGEMENT') return roles;

                    return roles.filter((role) => role.name.toUpperCase() !== 'EVALUATOR');
                }}
                title="Add Evaluators"
                primaryBtnLabel="Add"
                submit={onAdd}
                onClose={handleAddClose}
            />
            <SubCyclesModal
                assign={assignSubCycles}
                selectedEvaluators={selectedEvaluators ?? []}
                cycleId={Number(id)}
                open={subCyclesModalOpen}
                onClose={handleSubCyclesClose}
            />
            <RemoveEvaluatorsDialog
                selectedEvaluators={selectedEvaluators ?? []}
                cycleId={Number(id)}
                open={removeEvalDialogOpen}
                closeDialog={() => setRemoveEvalDialogOpen(false)}
                onClose={refetch}
            />
        </TabPanel>
    );
};

export default EvaluatorsTabPanel;
