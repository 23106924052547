import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { BoldUnderline, ConfirmDialog, LoadingButton, UnSavedChangesDialog } from '../../index';
import { CycleInputType, CycleStatus } from '../../../apollo/generated/types/globalTypes';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles({
    ButtonDivider: {
        marginRight: '25px',
        height: '54px',
    },
    SaveIcon: {
        color: '#1976D2',
    },
});

const FormSaveButtons: React.FC<FormSaveButtonsProps> = ({ onSubmit, readOnly = false }) => {
    const history = useHistory();
    const classes = useStyles();
    const { handleSubmit, formState, trigger } = useFormContext();
    const { isDirty, dirtyFields } = formState;
    const [isContinueButton, setIsContinueButton] = useState(false);
    const [success, setSuccess] = useState(false);
    const [templateWarning, setTemplateWarning] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [saveCloseLoading, setSaveCloseLoading] = useState(false);
    const [nextUrl, setNextUrl] = useState('/');

    const saveCycle = async (data: CycleInputType) => {
        setSaveLoading(true);
        await onSubmit(data, false);
        setSaveLoading(false);
    };

    const saveCycleAndContinue = async (data: CycleInputType) => {
        setSaveCloseLoading(true);
        const url = await onSubmit(data, true);
        setSaveCloseLoading(false);
        if (typeof url === 'string') {
            setNextUrl(url);
            setSuccess(true);
        }
    };

    const onSaveClick = async (data: CycleInputType) => {
        setIsContinueButton(false);
        if (!isTemplateWarning(data)) {
            await saveCycle(data);
        }
    };

    const onSaveContinueClick = async (data: CycleInputType) => {
        setIsContinueButton(true);
        if (!isTemplateWarning(data)) {
            await saveCycleAndContinue(data);
        }
    };

    const isTemplateWarning = (data: CycleInputType): boolean => {
        if (data.status === CycleStatus.ACTIVE) {
            if (isDirty && !!dirtyFields['subCycles'] && !data.templateId) {
                setTemplateWarning(true);
                return true;
            }
        }
        return false;
    };

    const onTemplateContinueClick = async (data: CycleInputType) => {
        setTemplateWarning(false);
        if (isContinueButton) {
            await saveCycleAndContinue(data);
        } else {
            await saveCycle(data);
        }
    };

    const onCreateRequestTopicsClick = () => {
        setSuccess(false);
        history.push(nextUrl);
    };

    const onTakeMeHomeClick = () => {
        setSuccess(false);
        history.push(`/`);
    };

    return (
        <>
            <LoadingButton
                pending={saveLoading}
                onClick={async () => {
                    await trigger();
                    handleSubmit(onSaveClick)();
                }}
                startIcon={<SaveIcon className={classes.SaveIcon} />}
                disabled={readOnly}>
                Save
            </LoadingButton>
            <Divider orientation="vertical" className={classes.ButtonDivider} />
            <LoadingButton pending={saveCloseLoading} onClick={handleSubmit(onSaveContinueClick)} variant="contained" disabled={readOnly}>
                Save & Continue
            </LoadingButton>
            <ConfirmDialog
                open={success}
                icon={<CheckCircleIcon style={{ color: '#0EAE79', fontSize: 91 }} />}
                title={
                    <>
                        You’ve successfully created an <BoldUnderline>Acquisition Cycle</BoldUnderline>
                    </>
                }
                message="You can go straight into building your topics, or we can take you right back to where you started."
                onClose={() => setSuccess(false)}
                primaryText="Take me to Sub-Cycle"
                onPrimaryClick={onCreateRequestTopicsClick}
                secondaryText="Take Me Home"
                onSecondaryClick={onTakeMeHomeClick}
            />
            <ConfirmDialog
                open={templateWarning}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
                title="You have not saved your sub-cycles as a template."
                message="When you save your sub-cycles as a template, this allows you to re-use the template for a future Acquisition Cycle. Are you sure you want to continue to approve your Acquisition Cycle?"
                onClose={() => setTemplateWarning(false)}
                primaryText="Continue"
                onPrimaryClick={handleSubmit(onTemplateContinueClick)}
                secondaryText="Cancel"
                onSecondaryClick={() => setTemplateWarning(false)}
            />
            <UnSavedChangesDialog />
        </>
    );
};

export interface FormSaveButtonsProps {
    onSubmit: (data: CycleInputType, close: boolean) => Promise<false | string>;
    readOnly?: boolean;
}

export default FormSaveButtons;
