import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { FormMode } from '../../../../utils/Enums';
import { getRespondersTypeDisplayName, RespondersType } from '../../../../utils/Enums/RespondersType';

const EligibleRespondersSelect: React.FC<EligibleRespondersSelectProps> = ({ readOnly }) => {
    const { control, getValues, errors } = useFormContext();
    const responderOptions = Object.values(RespondersType).filter((v) => typeof v !== 'string');
    responderOptions.unshift('');

    return (
        <ReactHookFormSelect
            fullWidth
            control={control}
            label="Eligible Responders"
            name="eligibleResponders"
            defaultValue={getValues('eligibleResponders')}
            error={!!errors.eligibleResponders}
            helperText={errors.eligibleResponders?.message}
            readOnly={readOnly}>
            {!responderOptions || responderOptions.length <= 0 ? (
                <MenuItem disabled>No Data Available</MenuItem>
            ) : (
                responderOptions.map((responder, index) => (
                    <MenuItem key={index} value={responder === '' ? undefined : responder}>
                        {getRespondersTypeDisplayName(responder as RespondersType)}
                    </MenuItem>
                ))
            )}
        </ReactHookFormSelect>
    );
};

export interface EligibleRespondersSelectProps {
    readOnly: boolean;
}

export default EligibleRespondersSelect;
