import React from 'react';
import NineHiLogo from '../../assets/images/NineHiLogo';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
    root: {
        'height': '90px',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    image: {
        width: '70px',
        height: '60px',
        fontSize: '14px',
        fontSizeAdjust: 'none',
    },
});

const Logo: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    function handleClick() {
        history.push('/');
    }
    return (
        <div className={classes.root} onClick={handleClick}>
            <NineHiLogo />
        </div>
    );
};

export default Logo;
