import * as React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    pendingIndicator: {
        position: 'absolute',
        visibility: 'visible',
        display: 'flex',
        left: (props: LoadingButtonProps) => (props.pendingPosition === 'start' ? 14 : '50%'),
        transform: (props: LoadingButtonProps) => (props.pendingPosition === 'start' ? '' : 'translate(-50%)'),
    },
});

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, disabled = false, pending = false, pendingPosition = 'center', ...other }) => {
    const classes = useStyles({
        pendingPosition,
        pending,
    });
    return (
        <Button disabled={disabled || pending} {...other}>
            {pending && (
                <div className={classes.pendingIndicator}>
                    <CircularProgress color="inherit" size={16} />
                </div>
            )}
            {children}
        </Button>
    );
};

export interface LoadingButtonProps extends ButtonProps {
    pending?: boolean;
    pendingPosition?: 'start' | 'center';
    disabled?: boolean;
}

export default LoadingButton;
