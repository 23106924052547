import React from 'react';
import { PageWrapper, PageHeader, PageBody } from '../components';

const NotFoundPage: React.FC = () => {
    return (
        <PageWrapper>
            <PageHeader>
                <div />
            </PageHeader>
            <PageBody>
                <div>Page Not Found</div>
            </PageBody>
        </PageWrapper>
    );
};

export default NotFoundPage;
