/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Typography, Grid, Paper, Tabs, Tab } from '@material-ui/core';
import { LoadingButton } from '../../../Buttons';
import FormDivider from '../../../Form/FormDivider';
import { TabPanel } from '../../index';
import TabPanels from './TabPanels';
import { useAwardManagement } from '../../../Providers/AwardManagementProvider';
import { usePermissions } from '../../../Providers/PermissionProvider';
import { LoadingMessage } from '../../..';
import { UPDATE_SUB_CYCLE_STATUS, COMPLETE_CYCLE } from '../../../../apollo/mutations';
import { useMutation } from '@apollo/client';
import { UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables } from '../../../../apollo/generated/types/UpdateSubCycleFormStatus';
import { CompleteCycle, CompleteCycleVariables } from '../../../../apollo/generated/types/CompleteCycle';
import { useCycleData } from '../../../Providers/CycleDataProvider';

const AwardManagementTabPanel: React.FC<any> = ({ index, value, ...other }) => {
    const { items, responsesLoading, tabs, researchDevelopmentCategories, pending, selected, rejected, checkBox, cycleId, subCycleId } = useAwardManagement();
    const { isTeamLead, isSystemProgramManagement } = usePermissions();
    const { readOnly: cycleReadOnly } = useCycleData();

    const finalized = selected + pending + rejected;
    const downSelected = selected + rejected;

    const readOnly = cycleReadOnly || !isSystemProgramManagement();

    const [categoryTab, setCategoryTab] = useState(0);

    const [updateSubCycleStatus] = useMutation<UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables>(UPDATE_SUB_CYCLE_STATUS);
    const [completeCycle] = useMutation<CompleteCycle, CompleteCycleVariables>(COMPLETE_CYCLE);

    const handleCompleteClick = () => {
        completeCycle({
            variables: {
                cycleId: cycleId,
            },
        });
    };

    const handleChange = (_, newValue: number) => {
        setCategoryTab(newValue);
    };
    return (
        <TabPanel index={index} value={value} {...other}>
            {!items ? (
                <LoadingMessage />
            ) : (
                <Paper>
                    <Grid container spacing={2} style={{ padding: '20px' }}>
                        <Grid item xs={10} style={{ margin: 'auto' }}>
                            <Typography variant="h2">Award Management</Typography>
                            <Grid style={{ marginTop: 'auto', color: '#A0A0A0' }}>
                                <Typography variant="body2">{`Finalized Responses: ${finalized} / ${items.length} `}</Typography>
                            </Grid>
                            <Grid style={{ marginTop: 'auto', color: '#A0A0A0' }}>
                                <Typography variant="body2">{`Down-Selected Responses: ${downSelected} / ${items.length}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                            <LoadingButton
                                variant="contained"
                                disabled={!checkBox.every((i) => i === true) || readOnly || !researchDevelopmentCategories}
                                onClick={() => handleCompleteClick()}>
                                Complete Acquisition Cycle
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <Tabs value={categoryTab} aria-label="Award Management Tabs" onChange={handleChange} style={{ marginBottom: '-2px' }}>
                        {!responsesLoading &&
                            researchDevelopmentCategories &&
                            researchDevelopmentCategories
                                ?.filter((i) => tabs?.some((j) => j === i.category.id))
                                .map((i) => (
                                    <Tab label={i.category.name} id={`award-tab-${index}`} aria-controls={`award-tab-panel-${index}`} key={i.category.id} />
                                ))}
                    </Tabs>
                    <FormDivider />
                    {tabs
                        ?.filter((i) => items.some((j) => j.researchCategoryId === i))
                        .map((i, index) => {
                            const tabData = items.filter((j) => j.researchCategoryId === i);
                            const rdCategory = researchDevelopmentCategories.find((x) => x.category.id === i);
                            const fundingRange = rdCategory && rdCategory.fundingRange ? rdCategory.fundingRange : 0;
                            return (
                                <TabPanels
                                    data={tabData}
                                    index={index}
                                    value={categoryTab}
                                    id={`award-tab-panel-${index}`}
                                    aria-labelledby={`award-tab-${index}`}
                                    key={index}
                                    fundingRange={fundingRange}
                                    rdCategory={rdCategory}
                                    readOnly={readOnly}
                                />
                            );
                        })}
                </Paper>
            )}
        </TabPanel>
    );
};

export default AwardManagementTabPanel;
