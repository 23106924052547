import { Grid, IconButton, Typography } from '@material-ui/core';
import FormDivider from '../FormDivider';
import FormSection from '../FormSection';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MultipleFileDropZone } from '../..';

const DocumentImportSection: React.FC<DocumentImportSectionProps> = ({ subCycleId, readOnly }) => {
    return (
        <>
            <FormSection justify="space-between">
                <Grid item xs={12}>
                    <Typography variant="h2">
                        Document Import
                        <IconButton>
                            <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid container direction="column">
                    {subCycleId ? (
                        <MultipleFileDropZone
                            altStyle={true}
                            label="Drag and drop evaluation files here or"
                            name="responseUserFiles"
                            subCycleId={subCycleId}
                            browseBtn={true}
                            fileListHeader={<Typography variant="h3">Imported Documents</Typography>}
                            openFiles={true}
                            readOnly={readOnly}
                        />
                    ) : null}
                </Grid>
            </FormSection>
        </>
    );
};

export interface DocumentImportSectionProps {
    subCycleId: number | undefined;
    readOnly: boolean;
}

export default DocumentImportSection;
