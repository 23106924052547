/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { RegisterOptions, Controller, useFormContext } from 'react-hook-form';
import { FormMode } from '../../utils/Enums';
import { getTimeZoneLabel } from '../../utils/date-format';

/**
 * Loads desired script into the page
 * @param src Script source
 * @param position HTML element into which the script should be inserted
 * @param id ID of the new Script element
 */
function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (position) {
        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', id);
        script.src = src;
        position.appendChild(script);
    }
}

// Holds Google Places autocomplete service
const autocompleteService = { current: null };
const placesService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

interface PlaceType {
    description: string;
    place_id: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}

interface AddressInfo {
    postalCode: string | null;
    timeZone: string | null;
}

export interface AddressFieldProps {
    mode: FormMode;
    name: string;
    label?: string;
    shrink?: boolean;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    addTimeZone?: boolean;
}

const AddressField: React.FC<AddressFieldProps> = ({ mode, label, shrink, rules, name, addTimeZone }) => {
    const classes = useStyles();
    const { control, getValues, errors } = useFormContext();
    const initialAddress = getValues(name) ?? '';
    const [placeValue, setPlaceValue] = React.useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = React.useState(initialAddress);
    const [options, setOptions] = React.useState<PlaceType[]>([]);
    const loaded = React.useRef(false);

    // Loads Google Places autocomplete script into the page
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBl5J2MAlTF-e0KGVBaTCD8a6kirbMCrlU&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    // handles fetching suggestions based on Address input value
    const fetch = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                (autocompleteService.current as any).getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    // Handles retrieving additional place details
    const getAdditionalAddressInfo = async (placeId: string) =>
        new Promise((resolve, reject) => {
            if (!placeId) reject('placeId not provided');

            try {
                if (placesService.current !== null) {
                    (placesService.current as any).getDetails(
                        {
                            placeId,
                            // each attribute request is charged separately, so individual attributes must be specified
                            fields: ['utc_offset_minutes', 'address_components'],
                        },
                        (details: any) => {
                            // convert offset to TimeZone string
                            const timeZone = getTimeZoneLabel(details?.utc_offset_minutes);
                            // extract postal code from address components
                            let postcode: string | null = null;
                            details?.address_components?.forEach((entry: any) => {
                                if (entry.types?.[0] === 'postal_code') {
                                    postcode = entry.long_name as string;
                                }
                            });
                            return resolve({ postalCode: postcode, timeZone: timeZone } as AddressInfo);
                        },
                    );
                } else resolve(null);
            } catch (e) {
                reject(e);
            }
        });

    React.useEffect(() => {
        let active = true;
        // loads Google Places autocomplete service
        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (!placesService.current && (window as any).google) {
            placesService.current = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
        }

        // removed all autocomplete options if there is not input text
        if (inputValue === '') {
            setOptions(placeValue ? [placeValue] : []);
            return undefined;
        }

        // fetches suggestions
        fetch({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (placeValue) {
                    newOptions = [placeValue];
                }

                // If there are suggestion results, add postal code and time zone data if possible
                if (results) {
                    const updatedResults = Promise.all(
                        results.map(async (result: PlaceType) => {
                            const addressDetails = (await getAdditionalAddressInfo(result.place_id)) as AddressInfo | null;
                            let description = result.description;
                            let secondaryText = result.structured_formatting.secondary_text;
                            // append postal code and time zone info, if it was found and prevent from duplicate postal codes being added
                            if (addressDetails && addressDetails.postalCode && !description.includes(addressDetails.postalCode)) {
                                description += `, ${addressDetails.postalCode}`;
                                secondaryText += `, ${addressDetails.postalCode}`;
                            }
                            if (addressDetails && addressDetails.timeZone && addTimeZone !== false) {
                                description += `, ${addressDetails.timeZone}`;
                                secondaryText += `, ${addressDetails.timeZone}`;
                            }

                            // return reconstructed PlaceType with new data appended
                            return {
                                description: description,
                                place_id: result.place_id,
                                structured_formatting: {
                                    main_text: result.structured_formatting.main_text,
                                    secondary_text: secondaryText,
                                    main_text_matched_substrings: result.structured_formatting.main_text_matched_substrings,
                                },
                            } as PlaceType;
                        }),
                    );

                    // resolve promise results and append to options list
                    updatedResults.then((resolvedResults) => setOptions([...newOptions, ...resolvedResults])).catch((error) => console.log(error));
                } else setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [placeValue, inputValue, fetch, addTimeZone]);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={initialAddress}
            render={({ onChange, name, value }) => (
                <Autocomplete
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    clearOnBlur={false}
                    includeInputInList
                    filterSelectedOptions
                    inputValue={value ?? ''}
                    value={placeValue}
                    disabled={mode === FormMode.View}
                    onChange={(_, newValue: PlaceType | null) => {
                        // append new place value to options list
                        setOptions(newValue ? [newValue, ...options] : options);
                        setPlaceValue(newValue);
                    }}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                        onChange(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            name={name}
                            label={label}
                            fullWidth
                            InputLabelProps={{
                                shrink: shrink,
                            }}
                            error={!!errors.workAddress}
                            helperText={errors.workAddress?.message}
                        />
                    )}
                    renderOption={(option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings ?? [];
                        // separate parts that match input text (for highlighting)
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <Grid container alignItems="center">
                                <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                    <Typography variant="body2" color="textSecondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            )}
        />
    );
};

export default AddressField;
