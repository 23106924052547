import React from 'react';
import { Dialog, DialogContent, Grid, Typography, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useTeamLeadScore } from '../../../../Providers/TeamLeadScoreProvider';
import AllCommentsModalItem from './AllCommentsModalItems';
import { getFPMOrder, getFPMLabelsList } from '../../../../../utils/Enums/Helpers/FpmCategories';

interface AllCommentsModalProps {
    open: boolean;
    close: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        sectionTitle: {
            padding: theme.spacing(2),
            color: '#f5f5f5',
            borderRadius: 5,
            backgroundColor: '#f5f5f5',
        },
        color: { background: '#ffffff !important' },
    }),
);

const AllCommentsModal: React.FC<AllCommentsModalProps> = ({ open, close }) => {
    const classes = useStyles();
    const { evaluations, loading, evaluationIndex, hasBriefResponse } = useTeamLeadScore();

    return (
        <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h1">All Comments</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {!loading
                    ? getFPMOrder(hasBriefResponse).map((fpmCategory, i) => {
                          const currScores = (evaluations[evaluationIndex].evaluatorScores ?? []).filter(
                              (score) => score.successFactor.fpmCategory === fpmCategory,
                          );
                          const hasComments = currScores.some((score) => score.comment !== undefined && score.comment !== '');

                          return (
                              <div key={i}>
                                  {hasComments ? (
                                      <div key={i} style={{ padding: '10px 0' }}>
                                          <Grid container>
                                              <Typography variant="h2">{getFPMLabelsList()[i]}</Typography>
                                          </Grid>
                                      </div>
                                  ) : (
                                      ''
                                  )}
                                  {currScores.map((score, j) => {
                                      score.comment ? true : false;
                                      return score && score.comment ? <AllCommentsModalItem key={`comment${i}-${j}`} scoreData={score} /> : null;
                                  })}
                              </div>
                          );
                      })
                    : ''}
            </DialogContent>
        </Dialog>
    );
};
export default AllCommentsModal;
