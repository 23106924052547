import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, TableCell, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingButton } from '../../index';
import MUIDataTable, { ExpandButton, MUIDataTableOptions } from 'mui-datatables';
import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { extractMilitaryTimeFromDate, makeLocalTime } from '../../../utils/date-format';
import { DayOfWeek } from '../../../apollo/generated/types/globalTypes';
import { WeekType } from '../../../types/WeekType';
import RoleSelect from './RoleSelect';
import { Alert, AlertTitle } from '@material-ui/lab';
import { EvaluatorFields, EvaluatorFields_userAvailabilities } from '../../../apollo/generated/types/EvaluatorFields';
import FormSection from '../../Form/FormSection';

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            padding: '0',
        },
        '& .MuiTableCell-root': {
            padding: '5px',
        },
        '& .MuiDialogContent-root': {
            padding: '8px 0px',
        },
    },
    title: {
        padding: '0',
    },
    centerText: {
        textAlign: 'center',
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogDescription: {
        color: '#ffffff',
        background: '#1976D2',
        borderRadius: '15px',
        padding: '2px 5px 4px 5px',
        fontSize: '14px',
        lineHeight: '19px',
        textAlign: 'center',
    },
    dialogDescriptionError: {
        background: theme.palette.error.light,
        borderRadius: '15px',
        padding: '2px 5px 4px 5px',
        fontSize: '14px',
        lineHeight: '19px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    available: {
        background: 'rgba(14, 174, 121, 0.5)',
        fontWeight: 'bold',
        borderRadius: '5px',
        padding: '5px 10px',
        textAlign: 'center',
        marginBottom: '0',
        margin: 'auto',
        width: '105px',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    unavailable: {
        background: 'rgba(237, 85, 49, 0.5)',
        fontWeight: 'bold',
        borderRadius: '5px',
        padding: '5px 10px',
        textAlign: 'center',
        marginBottom: '0',
        margin: 'auto',
        width: '105px',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    table: {
        // Name
        '& .MuiTableCell-head:nth-child(2)': {
            width: '15%',
        },
        // Availability
        '& .MuiTableCell-head:nth-child(3)': {
            width: '15%',
        },
        // Team Assignments
        '& .MuiTableCell-head:nth-child(4)': {
            width: '15%',
        },
        // Creates rightside spacing
        '& .MuiTableCell-head:last-child': {
            width: '65%',
        },
    },
    tableTitle: {
        paddingBottom: '0px',
    },
}));

export interface EvaluatorFormInput {
    evaluators: Array<Evaluator>;
}

export interface Evaluator {
    userId: number;
    cycleRoleId: string | number;
    selected: boolean;
}

export interface CycleRole {
    id: number;
    name: string;
    limit: number | null;
}

export interface EvaluatorData extends EvaluatorFields {
    cycleRole?: CycleRole;
}

const EvaluatorsModal: React.FC<EvaluatorsModalProps> = ({
    evaluatorLimit,
    numEvaluators,
    roleRequired = false,
    open,
    loading,
    evaluators,
    cycleRoles = null,
    filterRoles,
    title,
    primaryBtnLabel,
    secondaryBtnLabel = 'Cancel',
    submit,
    onClose,
}) => {
    const classes = useStyles();
    const [users, setUsers] = useState<EvaluatorData[] | null>(null);
    const [error, setError] = useState<ApolloError | null>(null);

    // Keep track of selected Evaluators
    const [count, setCount] = useState<number>(0);

    // Form validation for roles and selected
    const methods = useForm<EvaluatorFormInput>();
    const { handleSubmit, reset, getValues, setValue, register } = methods;
    const isRequired = useCallback(
        (index: number, value: number | string): boolean => {
            // no required fields for Assign and Unassign
            if (roleRequired) {
                const evaluator = getValues().evaluators[index];
                if (evaluator && evaluator.selected) {
                    return value !== '';
                }
            }

            // No validation needed
            return true;
        },
        [roleRequired, getValues],
    );

    // Resets form values prior to closing
    const close = () => {
        onClose();
        setCount(0);
        setUsers(null);
    };

    // Loads data into form and registers rows
    const getData = useCallback(() => {
        const newEvaluators: Array<Evaluator> = [];

        // Set Default form values
        if (evaluators) {
            evaluators.forEach((user: EvaluatorData) => {
                let cycleRoleId = '';
                if (user.cycleRole) cycleRoleId = user.cycleRole.id.toString();
                newEvaluators.push({ userId: user.id, cycleRoleId: cycleRoleId, selected: false });
            });
        }

        // Reset Form
        reset({
            evaluators: newEvaluators,
        });

        // Register rows
        if (evaluators) {
            evaluators.forEach((_user: EvaluatorData, index: number) => {
                register(`evaluators[${index}].userId`);
                register(`evaluators[${index}].cycleRoleId`, { validate: (value) => isRequired(index, value) || 'Role is required.' });
                register(`evaluators[${index}].selected`);
            });
        }

        setUsers(evaluators);
    }, [isRequired, register, reset, evaluators]);

    // General submission function
    const onSubmit = async (data: EvaluatorFormInput) => {
        const error = await submit(data);
        setError(error);
        if (!error) close();
    };

    // On mount and update
    useEffect(() => {
        setError(null);
        if (open && evaluators && !users) {
            getData();
        }
    }, [getData, open, evaluators, users]);

    const getUserAvailability = (user: EvaluatorData): 'Not Available' | 'Available' => {
        if (!user) {
            return 'Not Available';
        }
        if (user.notAvailable !== null && user.notAvailable) {
            return 'Not Available';
        } else if (user.userAvailabilities && user.userAvailabilities.length > 0) {
            return 'Available';
        }
        return 'Not Available';
    };

    const columns = [
        {
            name: 'fullName',
            label: 'Name',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'id',
            label: 'Availability',
            options: {
                filter: true,
                filterOptions: {
                    names: ['Not Available', 'Available'],
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    logic: (id: string, filters: any[]) => {
                        if (filters.length && users) {
                            const user = users.find((u: EvaluatorData) => u.id === Number(id));
                            if (user) {
                                const userAvailabilityStatus = getUserAvailability(user);
                                return !filters.includes(userAvailabilityStatus);
                            }
                        }
                        return false;
                    },
                },
                sort: true,
                sortThirdClickReset: true,
                customBodyRenderLite: (dataIndex: number) => {
                    if (users && users.length > dataIndex) {
                        const user = users[dataIndex];
                        return (
                            <p style={{ margin: '0' }} className={getUserAvailability(user) !== 'Not Available' ? classes.available : classes.unavailable}>
                                {getUserAvailability(user)}
                            </p>
                        );
                    }
                },
            },
        },
        {
            name: 'cycleAssignments',
            label: 'Team Assignments',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ style: { textAlign: 'left' } }),
                setCellProps: () => ({ style: { textAlign: 'left' } }),
                customBodyRender: (value: []) => (value ? value.length : 0),
            },
        },
        {
            name: 'cycleRole',
            label: 'Role',
            options: {
                filter: false,
                sort: false,
                display: roleRequired,
                customBodyRenderLite: (dataIndex: number, _rowIndex: number) => {
                    let currEvaluator: EvaluatorData | null = null;
                    if (evaluators) currEvaluator = evaluators[dataIndex];

                    if (roleRequired) {
                        let roleOptions = cycleRoles;
                        // Filter the roles if a filter func is provided
                        if (currEvaluator && roleOptions && filterRoles) {
                            roleOptions = filterRoles(currEvaluator, roleOptions);
                        }
                        return (
                            <RoleSelect index={dataIndex}>
                                {roleOptions &&
                                    roleOptions.map((role) => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.name}
                                        </MenuItem>
                                    ))}
                            </RoleSelect>
                        );
                    } else {
                        return currEvaluator?.cycleRole?.name;
                    }
                },
            },
        },
    ];

    const options = {
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        rowsPerPage: 5,
        rowsPerPageOptions: [5],
        selectableRows: 'multiple',
        selectableRowsHeader: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onRowSelectionChange: (currentRowSelected: any[], _allRowsSelected: any[], rowsSelected?: number[]) => {
            currentRowSelected.forEach((row) => {
                if (!rowsSelected || rowsSelected.filter((selectedRow) => selectedRow === row.index).length === 0) {
                    // Un-Selected
                    setCount(count - 1);
                    setValue(`evaluators[${row.index}].selected`, false, { shouldValidate: true });
                } else {
                    // Selected
                    setCount(count + 1);
                    setValue(`evaluators[${row.index}].selected`, true, { shouldValidate: true });
                }
            });
        },
        onRowsDelete: () => false,
        selectToolbarPlacement: 'none',
        expandableRows: true,
        expandableRowsHeader: false,
        isRowExpandable: (dataIndex) => {
            if (users && users.length > dataIndex) {
                const user = users[dataIndex];
                if (user.notAvailable !== null && user.notAvailable) {
                    return false;
                }
                return !!(user.userAvailabilities && user.userAvailabilities.length > 0);
            }
            return false;
        },
        rowsExpanded:
            users
                ?.filter((user) => {
                    if (user.notAvailable !== null && user.notAvailable) {
                        return false;
                    }
                    return user.userAvailabilities && user.userAvailabilities.length > 0;
                })
                .map((user) => users?.indexOf(user)) || [],
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const week = {
                sunday: null,
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
                saturday: null,
            } as WeekType;

            const makeDate = (dateStr: string) => makeLocalTime(new Date(dateStr));
            if (users && users.length > rowMeta.dataIndex) {
                const user = users[rowMeta.dataIndex];
                if (user.userAvailabilities) {
                    user.userAvailabilities.map((availability: EvaluatorFields_userAvailabilities) => {
                        switch (availability.day) {
                            case DayOfWeek.SUNDAY:
                                week.sunday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.MONDAY:
                                week.monday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.TUESDAY:
                                week.tuesday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.WEDNESDAY:
                                week.wednesday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.THURSDAY:
                                week.thursday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.FRIDAY:
                                week.friday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                            case DayOfWeek.SATURDAY:
                                week.saturday = {
                                    start: makeDate(availability.startTime),
                                    end: makeDate(availability.endTime),
                                };
                                break;
                        }
                    });
                }
            }
            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <Grid container>
                            <Grid item container xs={12}>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.sunday !== null ? classes.available : classes.unavailable}>Sunday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.monday !== null ? classes.available : classes.unavailable}>Monday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.tuesday !== null ? classes.available : classes.unavailable}> Tuesday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.wednesday !== null ? classes.available : classes.unavailable}> Wednesday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.thursday !== null ? classes.available : classes.unavailable}> Thursday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.friday !== null ? classes.available : classes.unavailable}> Friday</p>
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    <p className={week.saturday !== null ? classes.available : classes.unavailable}> Saturday</p>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs className={classes.centerText}>
                                    {week.sunday
                                        ? `${extractMilitaryTimeFromDate(week.sunday.start)} to ${extractMilitaryTimeFromDate(week.sunday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.monday
                                        ? `${extractMilitaryTimeFromDate(week.monday.start)} to ${extractMilitaryTimeFromDate(week.monday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.tuesday
                                        ? `${extractMilitaryTimeFromDate(week.tuesday.start)} to ${extractMilitaryTimeFromDate(week.tuesday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.wednesday
                                        ? `${extractMilitaryTimeFromDate(week.wednesday.start)} to ${extractMilitaryTimeFromDate(week.wednesday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.thursday
                                        ? `${extractMilitaryTimeFromDate(week.thursday.start)} to ${extractMilitaryTimeFromDate(week.thursday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.friday
                                        ? `${extractMilitaryTimeFromDate(week.friday.start)} to ${extractMilitaryTimeFromDate(week.friday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                                <Grid item xs className={classes.centerText}>
                                    {week.saturday
                                        ? `${extractMilitaryTimeFromDate(week.saturday.start)} to ${extractMilitaryTimeFromDate(week.saturday.end)}`
                                        : 'Unavailable'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            );
        },
    } as MUIDataTableOptions;

    const customComponents = {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ExpandButton: function (props: any) {
            return props.dataIndex !== undefined && evaluators && evaluators[props.dataIndex] && evaluators[props.dataIndex].notAvailable == false ? (
                <ExpandButton {...props} />
            ) : (
                <div style={{ width: '24px' }} />
            );
        },
    };

    return (
        <Dialog open={open} onClose={close} className={classes.dialog} maxWidth="lg" fullWidth={true} disableBackdropClick={true}>
            <DialogContent>
                <div className={classes.table}>
                    <form>
                        <FormProvider {...methods}>
                            <FormSection className={classes.tableTitle}>
                                <Grid item>
                                    <Typography variant="h1" className={classes.title}>
                                        {title}
                                    </Typography>
                                    {evaluatorLimit ? (
                                        numEvaluators! >= evaluatorLimit ? (
                                            <span className={classes.dialogDescriptionError}>
                                                {(numEvaluators ? numEvaluators : 0) + count} / {evaluatorLimit}{' '}
                                                {`You have reached the maximum team size of ${evaluatorLimit} evaluators. You must remove team members to add new ones.`}
                                            </span>
                                        ) : (
                                            <span className={classes.dialogDescription}>
                                                {(numEvaluators ? numEvaluators : 0) + count} / {evaluatorLimit}
                                            </span>
                                        )
                                    ) : null}
                                </Grid>
                            </FormSection>
                            <MUIDataTable columns={columns} data={users || []} options={options} components={customComponents} title={''} />
                            {error ? (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            <AlertTitle>Error</AlertTitle>
                                            {error?.message}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </FormProvider>
                    </form>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={close}>
                    {secondaryBtnLabel}
                </Button>
                <LoadingButton pending={loading} variant="contained" onClick={handleSubmit(onSubmit)}>
                    {primaryBtnLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export interface EvaluatorsModalProps {
    evaluatorLimit?: number;
    numEvaluators?: number;
    open: boolean;
    loading: boolean;
    roleRequired?: boolean;
    evaluators: EvaluatorData[] | null;
    cycleRoles?: CycleRole[] | null;
    filterRoles?: (evaluator: EvaluatorData, roles: CycleRole[]) => CycleRole[];
    title: string;
    primaryBtnLabel: string;
    secondaryBtnLabel?: string;
    submit: (data: EvaluatorFormInput) => Promise<ApolloError | null>;
    onClose: () => void;
}

export default EvaluatorsModal;
