import { PDFDocument, PDFTextField } from 'pdf-lib';
import { FormFieldValueInputType, ResponderRiskInputType, ResponderScoreInputType } from '../apollo/generated/types/globalTypes';
import { getFpmCategoryFromScoreSection } from './Enums';

/**
 * Class to store PDF field data
 */
export class PdfField {
    public name: string;
    public value: string;
    constructor(newName: string, newValue: string) {
        this.name = newName;
        this.value = newValue;
    }
}

/**
 * Parses PDF and extracts values from the form fields. Supplies a
 * callback function with the names and values of the form fields.
 * @param pdfFile File object with PDF data.
 * @param onComplete callback function to be called after fields are extracted.
 */
export const parseFieldsFromPDF = (pdfFile: File, onComplete: (fieldDict: Array<PdfField>) => void): void => {
    const reader = new FileReader();
    reader.onload = async function () {
        const result = reader.result;
        const fileByteData = result === null ? new Uint8Array() : result;
        const pdfDoc = await PDFDocument.load(fileByteData);
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        const fieldList = new Array<PdfField>();
        fields.forEach((field) => {
            const fieldName = field.getName();
            let fieldValue: string | null | undefined = null;
            // Check for field type and extract data accordingly
            if (fieldName.includes('Text Box') || fieldName.includes('Formatted Field')) {
                const textField = form.getTextField(fieldName);
                fieldValue = textField.getText();
            } else if (fieldName.includes('Combo Box') || fieldName.includes('List Box')) {
                const textField = form.getDropdown(fieldName);
                fieldValue = textField.getSelected().join(', ');
            } else if (fieldName.includes('Check Box')) {
                const textField = form.getCheckBox(fieldName);
                fieldValue = 'is checked: ' + textField.isChecked();
            }

            fieldValue = fieldValue ? fieldValue : '*NO VALUE*';
            // Add PDF field data to list
            const fieldObj = new PdfField(fieldName, fieldValue);
            fieldList.push(fieldObj);
        });

        // Send list of PDF fields (with their respective values) to callback function
        onComplete(fieldList);
    };
    reader.readAsArrayBuffer(pdfFile);
};

/**
 * Parses Self Evaluation PDF and extracts values from score/risk form fields (based on provided Success Factor IDs).
 * @param pdfFile - Self Evaluation PDF
 * @param successFactorIds - List of Success Factor IDs
 * @param onComplete - Callback function to provide the resulting Scores/Risks
 */
export const extractScoresFromPdf = (
    pdfFile: File,
    successFactorIds: number[],
    onComplete: (scores: ResponderScoreInputType[], risks: ResponderRiskInputType[]) => void,
): void => {
    const reader = new FileReader();
    reader.onload = async function () {
        const result = reader.result;
        const fileByteData = result === null ? new Uint8Array() : result;
        const pdfDoc = await PDFDocument.load(fileByteData);
        const form = pdfDoc.getForm();
        // Map Success Factor IDs with score values pulled from the fields
        const sfFieldMap = form.getFields().reduce((fields, field) => {
            const textField = field as PDFTextField;
            const fieldName = textField.getName();
            if (fieldName.includes('successFactor') && fieldName.includes('score')) {
                // retrieves Success Factor ID from field name
                const sfString = fieldName.substring(fieldName.indexOf('[') + 1, fieldName.indexOf(']'));
                const score = textField.getText() ?? null;
                fields[sfString] = score;
            }

            return fields;
        }, {});

        // Builds list of Success Factor Scores
        const scores: ResponderScoreInputType[] = [];
        successFactorIds.forEach((sfId) => {
            // Retrieve field data based on success factor ID
            const sfString = `${sfId}`;
            if (Object.keys(sfFieldMap).includes(sfString)) {
                scores.push({ successFactorId: sfId, score: sfFieldMap[sfString] } as ResponderScoreInputType);
            }
        });

        // Builds list of FPM Category Risks
        const risks: ResponderRiskInputType[] = [];
        form.getFields().forEach((field) => {
            const textField = field as PDFTextField;
            const fieldName = textField.getName();
            if (fieldName.includes('risks')) {
                // retrieves FPM Category #
                const fpmCategory = parseInt(fieldName.substring(fieldName.indexOf('[') + 1, fieldName.indexOf(']')));
                const riskString = textField.getText() ?? null;
                if (!isNaN(fpmCategory) && riskString !== null) {
                    const riskNames = riskString.split(',');
                    riskNames.forEach((riskName) =>
                        risks.push({
                            fpmCategory: getFpmCategoryFromScoreSection(fpmCategory),
                            risk: { name: riskName.trim() } as FormFieldValueInputType,
                        } as ResponderRiskInputType),
                    );
                }
            }
        });

        // Send list of PDF field values to callback function
        onComplete(scores, risks);
    };
    reader.readAsArrayBuffer(pdfFile);
};
