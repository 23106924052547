import * as React from 'react';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    divider: {
        height: '2px',
        background: '#7689A9',
        opacity: '0.2',
    },
});

const FormDivider: React.FC = () => {
    const classes = useStyles();
    return <Divider className={classes.divider} />;
};

export default FormDivider;
