/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from 'react';
import { Box, Dialog, DialogContent, Grid, TextField, Typography, DialogTitle, IconButton, MenuItem, Button, FormHelperText } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactHookFormAutocomplete, ReactHookFormCurrency, ReactHookFormSelect } from '../../../ReactHookForm/index';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';
import { MultipleFileDropZone, SingleFileDropZone } from '../../../FileDropZone/index';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { ADD_UPDATE_RESPONSE, CREATE_RESPONSE_ORGANIZATION } from '../../../../apollo/mutations';
import { GET_RESPONSE_ORGANIZATIONS, GET_TOPIC_SUCCESS_FACTORS } from '../../../../apollo/queries';
import { CreateResponseOrganization, CreateResponseOrganizationVariables } from '../../../../apollo/generated/types/CreateResponseOrganization';
import { GetResponseOrganizations } from '../../../../apollo/generated/types/GetResponseOrganizations';
import {
    FileUploadInputType,
    FormFieldValueInputType,
    ResponderRiskInputType,
    ResponderScoreInputType,
    ResponseInputType,
    SelectionStatus,
} from '../../../../apollo/generated/types/globalTypes';
import { GetResponsesTable_cycle_subCycles, GetResponsesTable_responses } from '../../../../apollo/generated/types/GetResponsesTable';
import { AddUpdateResponse, AddUpdateResponseVariables } from '../../../../apollo/generated/types/AddUpdateResponse';
import { LoadingButton } from '../../..';
import { FormMode } from '../../../../utils/Enums';
import { GetTopicSuccessFactors, GetTopicSuccessFactorsVariables } from '../../../../apollo/generated/types/GetTopicSuccessFactors';
import { extractScoresFromPdf } from '../../../../utils/pdf-util';
import { usePermissions } from '../../../../components';

export interface ImportModalProps {
    open: boolean;
    cycleName?: string;
    activeSubCycle?: GetResponsesTable_cycle_subCycles;
    response?: GetResponsesTable_responses;
    topics?: Topic[];
    close: () => void;
    mode: FormMode;
    researchDevelopmentCategories: any;
}

export interface Topic {
    id: number;
    name: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        sectionTitle: {
            padding: theme.spacing(2),
            color: '#f5f5f5',
            borderRadius: 5,
            backgroundColor: '#f5f5f5',
        },
    }),
);

const ImportModal: React.FC<ImportModalProps> = ({ open, close, mode, cycleName, activeSubCycle, response, topics, researchDevelopmentCategories }) => {
    const classes = useStyles();
    const { isSystemProgramManagement } = usePermissions();

    const convertResponseDataToForm = useCallback((data?: GetResponsesTable_responses): ResponseInputType => {
        const defaultValues = {
            id: data?.id,
            topicId: data?.topic?.id,
            organizationId: data?.organization.id,
            title: data?.title,
            pointOfContact: data?.pointOfContact,
            researchCategoryId: data?.researchCategoryId,
            fundingRequested: data?.fundingRequested,
            selectedStatus: data?.selectedStatus ?? SelectionStatus.PENDING,
        } as ResponseInputType;

        if (data) {
            // Self Evaluation File
            if (data.selfEvaluationFile) {
                defaultValues.selfEvaluationFile = {
                    id: data.selfEvaluationFile.id,
                } as FileUploadInputType;
            }

            // Response Files
            defaultValues.responseFiles = data.responseFiles.map(
                (file) =>
                    ({
                        id: file.id,
                    } as FileUploadInputType),
            );

            // Responder Scores
            defaultValues.responderScores = (data.responderScores ?? []).map(
                (scoreItem) =>
                    ({
                        successFactorId: scoreItem.successFactorId,
                        score: scoreItem.score,
                    } as ResponderScoreInputType),
            );

            // Responder Risks
            defaultValues.responderRisks = (data.responderRisks ?? []).map(
                (riskItem) =>
                    ({
                        fpmCategory: riskItem.fpmCategory,
                        risk: {
                            name: riskItem.risk.name,
                        } as FormFieldValueInputType,
                    } as ResponderRiskInputType),
            );
        }

        return defaultValues;
    }, []);
    const methods = useForm<ResponseInputType>({ defaultValues: convertResponseDataToForm(response) });

    // Important Form Values
    const id = methods.watch('id');
    const topicId = methods.watch('topicId');
    const responseFiles = methods.watch('responseFiles');
    methods.register('responderScores', {
        validate: {
            validScores: (responderScores) =>
                !responderScores || areValidScores(responderScores) || 'Scores from the Self Evaluation file should all be numbers between 4.0 and 9.9.',
        },
    });
    methods.register('responderRisks', {
        validate: {
            validScores: (responderRisks) =>
                !responderRisks || areValidRisks(responderRisks) || 'Risk names should not consist solely of whitespace characters.',
        },
    });
    const [selfEvalFile, setSelfEvalFile] = useState<File>();

    // Other State variables
    const [parseLoading, setParseLoading] = useState<boolean>(false);
    const [currResponse, setCurrResponse] = useState<GetResponsesTable_responses>();

    //// Validation functions
    // Ensures Scores are valid number values between 4.0 and 9.9
    const areValidScores = (scoreItems: ResponderScoreInputType[]): boolean => {
        let valid = true;
        scoreItems.forEach((scoreItem) => {
            if (scoreItem.score !== null) {
                const score = parseFloat(scoreItem.score);
                // return false if the string was not a number value between 4 and 9.9
                if (isNaN(score) || score < 4 || score > 9.9) valid = false;
            }
        });
        return valid;
    };
    // Ensures Risk names are not solely whitespace characters (or an empty string)
    const areValidRisks = (riskItems: ResponderRiskInputType[]): boolean => {
        let valid = true;
        riskItems.forEach((riskItem) => {
            const riskName = riskItem.risk.name;
            // return false if the string is empty
            if (riskName === '') valid = false;
        });
        return valid;
    };

    //// Retrieving and adding responding organizations ////
    // retrieving responding organization options
    const { data: res } = useQuery<GetResponseOrganizations>(GET_RESPONSE_ORGANIZATIONS, {
        fetchPolicy: 'cache-and-network',
    });
    const [responseOrganizations, setResponseOrganizations] = useState<Array<OptionFields> | undefined>();
    // Adding new responding organization options
    const [createResponseOrganization] = useMutation<CreateResponseOrganization, CreateResponseOrganizationVariables>(CREATE_RESPONSE_ORGANIZATION);
    const insertResponseOrg = (currentList: OptionFields[] | undefined, responseOrganization: OptionFields) =>
        [...(currentList || []), responseOrganization].sort((a, b) => a.name.localeCompare(b.name, 'en'));
    const addResponseOrganization = useCallback(
        async (name: string) => {
            const result = await createResponseOrganization({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createResponseOrganization) {
                const responseOrganization = result.data.createResponseOrganization;
                setResponseOrganizations((currentList) => insertResponseOrg(currentList, responseOrganization));
                return responseOrganization as OptionFields;
            }
            return null;
        },
        [createResponseOrganization],
    );

    // Adding/Updating a response
    const [addUpdateResponse, { loading: updateLoading }] = useMutation<AddUpdateResponse, AddUpdateResponseVariables>(ADD_UPDATE_RESPONSE, {
        onCompleted: (data: AddUpdateResponse) => {
            if (data) {
                beforeClose();
            }
        },
        onError: (error: ApolloError) => {
            console.log(error);
        },
    });
    const onSubmit = (data: ResponseInputType) => {
        // Add Responder Scores and Risks
        addUpdateResponse({
            variables: {
                response: data,
            },
        });
    };

    // Parsing Uploaded Self-Evaluation File
    const { data: successFactorData } = useQuery<GetTopicSuccessFactors, GetTopicSuccessFactorsVariables>(GET_TOPIC_SUCCESS_FACTORS, {
        fetchPolicy: 'no-cache',
        skip: !topicId,
        variables: {
            topicId: topicId,
        },
    });
    const setResponderScoresFile = (fileData: File) => {
        setSelfEvalFile(fileData);
    };
    const extractResponderScores = useCallback(() => {
        if (successFactorData?.successFactors && selfEvalFile) {
            setParseLoading(true);

            const successFactorIds = successFactorData.successFactors.filter((sf) => sf.id !== null).map((sf) => sf.id as number);
            extractScoresFromPdf(selfEvalFile, successFactorIds, (scores: ResponderScoreInputType[], risks: ResponderRiskInputType[]) => {
                methods.setValue('responderScores', scores);
                methods.setValue('responderRisks', risks);
            });

            setParseLoading(false);
        }
    }, [successFactorData?.successFactors, selfEvalFile, methods]);

    // UseEffect section
    useEffect(() => {
        if (!responseOrganizations) {
            setResponseOrganizations(res?.responseOrganizations as OptionFields[]);
        }

        if (response && !currResponse) {
            setCurrResponse(response);
            methods.reset(convertResponseDataToForm(response));
        }

        if (successFactorData && selfEvalFile) {
            extractResponderScores();
        }
    }, [
        res,
        response,
        methods,
        currResponse,
        setCurrResponse,
        convertResponseDataToForm,
        responseOrganizations,
        successFactorData,
        selfEvalFile,
        extractResponderScores,
    ]);

    // Function called prior to closing the modal (resets certain state variables)
    const beforeClose = () => {
        setCurrResponse(undefined);
        setResponseOrganizations(undefined);
        close();
    };

    const isEdit = mode === FormMode.Edit;
    const modalLabel = isEdit ? 'Edit Responses' : 'Import Responses';
    const loading = parseLoading || updateLoading;
    const isReadOnly = isEdit && !isSystemProgramManagement ? true : false;
    return (
        <Dialog open={open} onClose={beforeClose} maxWidth="md">
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h1">{modalLabel}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={beforeClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <FormProvider {...methods}>
                    {activeSubCycle ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className={classes.sectionTitle}>
                                    <Box>
                                        <Typography variant="h3">Assignments</Typography>
                                    </Box>
                                </div>
                            </Grid>
                            {id ? (
                                <Grid item xs={12}>
                                    <input id="id" name="id" type="hidden" ref={methods.register()} value={id} />
                                </Grid>
                            ) : null}
                            <Grid item xs={6}>
                                <TextField fullWidth disabled label="Acquisition Cycle" name="cycleName" value={cycleName ?? ''} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth disabled label="Sub-Cycle" name="subCycleName" value={activeSubCycle?.name ?? ''} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Response Title"
                                    name="title"
                                    InputLabelProps={{
                                        'shrink': true,
                                        'aria-readonly': isReadOnly,
                                        'className': isReadOnly ? 'Mui-disabled' : '',
                                    }}
                                    InputProps={{
                                        readOnly: isReadOnly,
                                        className: isReadOnly ? 'Mui-disabled' : '',
                                    }}
                                    inputRef={methods.register()}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Technical Point of Contact*"
                                    name="pointOfContact"
                                    InputLabelProps={{
                                        'shrink': true,
                                        'aria-readonly': isReadOnly,
                                        'className': isReadOnly ? 'Mui-disabled' : '',
                                    }}
                                    InputProps={{
                                        readOnly: isReadOnly,
                                        className: isReadOnly ? 'Mui-disabled' : '',
                                    }}
                                    inputRef={methods.register({ required: 'Technical Point of Contact is required' })}
                                    error={!!methods.errors.pointOfContact}
                                    helperText={methods.errors.pointOfContact?.message}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormSelect
                                    fullWidth
                                    control={methods.control}
                                    label="Response Topic*"
                                    name="topicId"
                                    rules={{ required: 'Response Topic is required' }}
                                    error={!!methods.errors.topicId}
                                    helperText={methods.errors.topicId?.message}
                                    defaultValue="">
                                    <MenuItem value="">None</MenuItem>
                                    {topics &&
                                        topics.map((option: Topic) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                </ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormAutocomplete
                                    label="Responding Organization*"
                                    name="organizationId"
                                    rules={{ required: 'Responding Organization is required.' }}
                                    error={!!methods.errors.organizationId}
                                    helperText={methods.errors.organizationId?.message}
                                    options={responseOrganizations}
                                    addOption={addResponseOrganization}
                                    getOptionSelected={(option: OptionFields, value?: number | OptionFields) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (typeof value === 'number') {
                                            return option.id === value;
                                        } else {
                                            return option.id === value.id;
                                        }
                                    }}
                                    getOptionValue={(option: OptionFields | null) => (option ? option?.id : null)}
                                    getOptionLabel={(option: OptionFields) => option?.name || ''}
                                    renderOption={(option: OptionFields) => option?.name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormSelect
                                    fullWidth
                                    control={methods.control}
                                    label="Research Category*"
                                    name="researchCategoryId"
                                    rules={{ required: 'Research Category is required' }}
                                    error={!!methods.errors.researchCategoryId}
                                    helperText={methods.errors.researchCategoryId?.message}>
                                    <MenuItem value="">None</MenuItem>
                                    {researchDevelopmentCategories &&
                                        researchDevelopmentCategories.map((res: any, index) => (
                                            <MenuItem key={index} value={res.category.id}>
                                                {res.category.name}
                                            </MenuItem>
                                        ))}
                                </ReactHookFormSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <ReactHookFormCurrency
                                    control={methods.control}
                                    name={`fundingRequested`}
                                    label="Funding Requested*"
                                    readOnly={mode === FormMode.View}
                                    rules={{
                                        required: 'Funding Requested is required',
                                        min: {
                                            value: 0,
                                            message: 'Funding Requested is a dollar amount and has a minimum of 0 dollars.',
                                        },
                                    }}
                                    error={!!methods.errors.fundingRequested}
                                    helperText={methods.errors.fundingRequested?.message}
                                    defaultValue={methods.getValues('fundingRequested') ?? undefined}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.sectionTitle}>
                                    <Box>
                                        <Typography variant="h3">Document Imports</Typography>
                                    </Box>
                                </div>
                            </Grid>
                            {<input name="selectedStatus" type="hidden" ref={methods.register()} />}
                            {/* Allows for validating files uploaded through the MultipleFileDropZone */}
                            {!responseFiles || responseFiles.length === 0 ? (
                                <input
                                    name="responseFilesLength"
                                    type="hidden"
                                    ref={methods.register({
                                        validate: () => responseFiles.length > 0 || 'At least one response file must be attached.',
                                    })}
                                />
                            ) : null}
                            <Grid item xs={12}>
                                <MultipleFileDropZone
                                    altStyle={true}
                                    label="Drag and drop responses here or"
                                    name="responseFiles"
                                    subCycleId={activeSubCycle.id}
                                    browseBtn={true}
                                    fileListHeader={<Typography variant="h3">Response Files</Typography>}
                                />
                                {(methods.errors as any).responseFilesLength ? (
                                    <FormHelperText error={true}>{(methods.errors as any).responseFilesLength?.message}</FormHelperText>
                                ) : null}
                            </Grid>
                            <Grid item xs={12}>
                                <SingleFileDropZone
                                    id={activeSubCycle.id}
                                    altStyle={true}
                                    name="selfEvaluationFile"
                                    browseBtn={true}
                                    label="Drag and drop a self evaluation form here or"
                                    fileType=".pdf"
                                    fileListHeader={<Typography variant="h3">Self Evaluation File</Typography>}
                                    rules={{ validate: (value) => !!value?.id || !!value?.uploadToken || 'A self evaluation file is required.' }}
                                    onFileUpload={setResponderScoresFile}
                                />
                                {methods.errors.selfEvaluationFile ? (
                                    <FormHelperText error={true}>{(methods.errors.selfEvaluationFile as any)?.message}</FormHelperText>
                                ) : null}
                                {methods.errors.responderScores ? (
                                    <FormHelperText error={true}>{(methods.errors.responderScores as any)?.message}</FormHelperText>
                                ) : null}
                                {methods.errors.responderRisks ? (
                                    <FormHelperText error={true}>{(methods.errors.responderRisks as any)?.message}</FormHelperText>
                                ) : null}
                            </Grid>
                            <Grid container item justify="flex-end" xs={12}>
                                <LoadingButton
                                    pending={loading}
                                    variant="contained"
                                    onClick={async () => {
                                        await methods.trigger();
                                        methods.handleSubmit(onSubmit)();
                                    }}>
                                    {modalLabel}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={4}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <ErrorOutlineIcon style={{ fontSize: '75px' }} fontSize="large" />
                                <Typography align="center" variant="h2">
                                    Sorry!
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" variant="h3">
                                    You must have an Active Sub-Cycle before importing responses
                                </Typography>
                            </Grid>
                            <Grid container item justify="center" xs={12}>
                                <Button style={{ width: '70%' }} size="medium" variant="contained" onClick={beforeClose}>
                                    OK
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
};

export default ImportModal;
