import { ResponseStatus } from '../../../apollo/generated/types/globalTypes';

export const ConvertResponseStatusEnumToDisplay = (value: string): string => {
    switch (value) {
        case ResponseStatus.NOT_STARTED:
            return 'Not Started';
        case ResponseStatus.IN_PROGRESS:
            return 'In Progress';
        case ResponseStatus.COMPLETE:
            return 'Completed';
        case ResponseStatus.LOCKED:
            return 'Locked';
        default:
            return '';
    }
};
