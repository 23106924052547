import * as React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';

const ReactHookFormCheckbox: React.FC<ReactHookFormCheckboxProps> = ({ name, label, control, defaultValue, readOnly, error, helperText, rules }) => {
    const helperTextId = name + '-helper';
    return (
        <FormControl>
            <Controller
                render={({ onChange, onBlur, value, name, ref }) => (
                    <FormControlLabel
                        name={name}
                        label={label}
                        labelPlacement="end"
                        onBlur={onBlur}
                        className={error ? 'Mui-error' : ''}
                        control={<Checkbox color="primary" disabled={readOnly} onChange={(e) => onChange(e.target.checked)} aria-describedby={helperTextId} />}
                        checked={value ?? false}
                        inputRef={ref}
                    />
                )}
                rules={rules}
                name={name}
                control={control}
                defaultValue={defaultValue ?? false}
            />

            {error ? (
                <FormHelperText id={helperTextId} className="Mui-error">
                    {helperText}
                </FormHelperText>
            ) : null}
        </FormControl>
    );
};
export interface ReactHookFormCheckboxProps {
    label: string;
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: boolean;
    readOnly?: boolean;
    error?: boolean;
    helperText?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormCheckbox;
