import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, Typography } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const useStyles = makeStyles({
    header: {
        fontSize: '15px',
        lineHeight: '18px',
        color: '#FFFFFF',
        opacity: '0.5',
        paddingLeft: '18px',
        paddingBottom: '10px',
    },
    item: {
        width: 'calc(100% - 30px)',
        marginLeft: '15px',
        paddingLeft: '15px',
        paddingRight: '5px',
    },
    iconWrapper: {
        minWidth: '32px',
    },
    icon: {
        color: '#1976D2',
    },
    text: {
        fontSize: '15px',
        lineHeight: '18px',
        letterSpacing: '-0.3px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

const Reports: React.FC = () => {
    const classes = useStyles();
    return (
        <List
            subheader={
                <ListSubheader>
                    <Typography className={classes.header}>Reports</Typography>
                </ListSubheader>
            }>
            <ListItem className={classes.item}>
                <ListItemIcon className={classes.iconWrapper}>
                    <BookmarkIcon className={classes.icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography className={classes.text}>Advanced Technology Development</Typography>} />
            </ListItem>
            <ListItem className={classes.item}>
                <ListItemIcon className={classes.iconWrapper}>
                    <BookmarkIcon className={classes.icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography className={classes.text}>High Performance Propulsion</Typography>} />
            </ListItem>
            <ListItem className={classes.item}>
                <ListItemIcon className={classes.iconWrapper}>
                    <BookmarkIcon className={classes.icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography className={classes.text}>High Performance Propulsion</Typography>} />
            </ListItem>
        </List>
    );
};

export default Reports;
