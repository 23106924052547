import * as React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { BoldUnderline, ConfirmDialog } from '../Dialogs';

const ModalDeleteConfirmDialog: React.FC<ModalDeleteConfirmDialogProps> = ({
    open,
    fileName,

    onPrimaryClick,
    onSecondaryClick,
    onClose,
}) => {
    return (
        <ConfirmDialog
            open={open}
            icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
            title={
                <>
                    Delete File <BoldUnderline>{fileName}</BoldUnderline>
                </>
            }
            message="Are you sure you want to permanently delete file? you can't undo this."
            primaryText="Delete"
            secondaryText="Keep It"
            onPrimaryClick={onPrimaryClick}
            onSecondaryClick={onSecondaryClick}
            onClose={onClose}
        />
    );
};

export interface ModalDeleteConfirmDialogProps {
    open: boolean;
    fileName: string;
    onPrimaryClick: () => void;
    onSecondaryClick: () => void;
    onClose: () => void;
}

export default ModalDeleteConfirmDialog;
