import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import FormSection from '../../FormSection';
import { FormMode } from '../../../../utils/Enums';
import FormDivider from '../../FormDivider';
import CountrySelect from './CountrySelect';
import { ReactHookFormPhone } from '../../..';
import { EmailRegex } from '../../../../utils/patterns';
import { useFormContext } from 'react-hook-form';

const GeneralForm: React.FC<GeneralFormProps> = ({ mode }) => {
    const { register, errors, control, watch } = useFormContext();
    const id = watch('id');

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">General Info</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                {id ? (
                    <Grid item xs={12}>
                        <input id="id" name="id" type="hidden" ref={register()} value={id} />
                    </Grid>
                ) : null}
                <Grid item xs={6}>
                    <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name*"
                        inputRef={register({
                            required: 'First Name is required',
                        })}
                        error={!!errors.firstName}
                        helperText={errors.firstName?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name*"
                        inputRef={register({
                            required: 'Last Name is required',
                        })}
                        error={!!errors.lastName}
                        helperText={errors.lastName?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="email"
                        name="email"
                        label="Email*"
                        inputRef={register({
                            required: 'Email is required',
                            pattern: {
                                value: EmailRegex,
                                message: 'Invalid email address',
                            },
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                            className: 'Mui-disabled',
                        }}
                        InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled',
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="secondaryEmail"
                        name="secondaryEmail"
                        label="Secondary Email"
                        inputRef={register({
                            pattern: {
                                value: EmailRegex,
                                message: 'Invalid email address',
                            },
                        })}
                        error={!!errors.secondaryEmail}
                        helperText={errors.secondaryEmail?.message}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormPhone
                        control={control}
                        name="phoneNumber"
                        label="Phone Number*"
                        onlyCountries={['us']}
                        error={!!errors.phoneNumber}
                        helperText={errors.phoneNumber?.message}
                        rules={{
                            required: 'Phone Number is required',
                            pattern: {
                                value: /^\+1 \(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/,
                                message: 'Phone Number is not valid',
                            },
                        }}
                        InputProps={{
                            readOnly: mode === FormMode.View,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CountrySelect mode={mode} />
                </Grid>
            </FormSection>
        </>
    );
};

export interface GeneralFormProps {
    mode: FormMode;
}

export default GeneralForm;
