import React, { useState } from 'react';
import { TabPanel, TabPanelProps } from '../index';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { InProcessResponsesTabPanel, FinalScoresCompleteTabPanel } from './SubPanels';
import FormDivider from '../../../Form/FormDivider';
import ResponseHistoryTabPanel from './SubPanels/ResponseHistoryTabPanel';
import { CycleSubTab } from '../../../../utils/Enums/TabEnum';
import { NavLink, useParams } from 'react-router-dom';

export type ResponseManagementTabPanelProps = TabPanelProps;

interface URLParams {
    subTab?: string;
}

const ResponseManagementTabPanel: React.FC<ResponseManagementTabPanelProps> = ({ index, value, ...other }) => {
    const { subTab: urlSubTab } = useParams<URLParams>();
    const [subTab, setSubTab] = useState(urlSubTab ?? CycleSubTab.InProcessResponses);

    const handleChange = (_, newValue: string) => {
        setSubTab(newValue);
    };

    return (
        <TabPanel index={index} value={value} {...other}>
            <Paper>
                <Tabs value={subTab} aria-label="Response Management Tabs" onChange={handleChange} style={{ marginBottom: '-2px' }}>
                    <Tab label="In Process Responses" value={CycleSubTab.InProcessResponses} />
                    <Tab label="Final Scores Complete" value={CycleSubTab.FinalizedResponses} />
                    <Tab label="Response History" value={CycleSubTab.ResponseHistory} />
                </Tabs>
                <FormDivider />
                <InProcessResponsesTabPanel index={CycleSubTab.InProcessResponses} value={subTab} />
                <FinalScoresCompleteTabPanel index={CycleSubTab.FinalizedResponses} value={subTab} />
                <ResponseHistoryTabPanel index={CycleSubTab.ResponseHistory} value={subTab} />
            </Paper>
        </TabPanel>
    );
};

export default ResponseManagementTabPanel;
