import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { LoadingButton, SelectPlaceholder } from '../../../index';
import { useFormContext } from 'react-hook-form';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { GET_SUB_SERVICES } from '../../../../apollo/queries';
import { CREATE_SUB_SERVICE } from '../../../../apollo/mutations';
import { SubServiceFields } from '../../../../apollo/generated/types/SubServiceFields';
import { GetSubServices, GetSubServicesVariables } from '../../../../apollo/generated/types/GetSubServices';
import { CreateSubService, CreateSubServiceVariables } from '../../../../apollo/generated/types/CreateSubService';

const filter = createFilterOptions<SubServiceOptionType>();

interface SubServiceOptionType extends SubServiceFields {
    disabled?: boolean;
    inputValue?: string;
}

const SubServiceSelect: React.FC<SubServiceSelectProps> = ({ required, readOnly }) => {
    const { watch, getValues, setValue, errors, register, formState } = useFormContext();
    const { isSubmitted } = formState;
    const [selectedSubService, setSelectedSubService] = useState<SubServiceOptionType | ''>('');
    const [serviceId, setServiceId] = useState<number>(getValues('serviceId'));
    const [subServices, setSubServices] = useState<Array<SubServiceFields>>([]);
    const [open, toggleOpen] = useState(false);
    const [createError, setCreateError] = useState<ApolloError | null>(null);
    const [dialogValue, setDialogValue] = useState({
        name: '',
        acronym: '',
        serviceId: serviceId,
    });
    const watchServiceId = watch('serviceId');
    const subServiceId = watch('subServiceId');

    const [getSubServices, { called, loading, error }] = useLazyQuery<GetSubServices, GetSubServicesVariables>(GET_SUB_SERVICES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data && data.subServices) {
                setSubServices(data.subServices);
                if (subServiceId) {
                    const found = data.subServices.find((ss: SubServiceFields) => ss.id.toString() === subServiceId.toString());
                    changeValue(found || '', false);
                } else {
                    changeValue('', false);
                }
            } else {
                setSubServices([]);
                changeValue('', false);
            }
        },
        onError: (error: ApolloError) => {
            console.error(error.message);
            setSubServices([]);
            changeValue('', false);
        },
    });
    const [createSubService, { loading: mutationLoading }] = useMutation<CreateSubService, CreateSubServiceVariables>(CREATE_SUB_SERVICE);

    useEffect(() => {
        register('subServiceId', { required: required ? 'Organization is required' : false });
    }, [register, required]);

    /**
     * Selected value changed so set Form subServiceId value
     */
    const changeValue = (value: '' | SubServiceOptionType, dirty: boolean) => {
        setSelectedSubService(value);
        if (!loading && called) {
            setValue('subServiceId', value ? value.id : undefined, { shouldValidate: isSubmitted, shouldDirty: dirty });
        }
    };

    /**
     * Watch fields changed so set local state
     */
    useEffect(() => {
        if (watchServiceId !== serviceId) {
            setServiceId(watchServiceId);
        }
    }, [serviceId, watchServiceId]);

    /**
     * ServiceId Changed so fetch Sub Services
     */
    useEffect(() => {
        if (serviceId) {
            getSubServices({ variables: { serviceId: serviceId } });
        } else {
            setSubServices([]);
        }
    }, [serviceId, getSubServices]);

    /**
     * Handle Close of Add Organization Popup
     */
    const handleClose = () => {
        setCreateError(null);
        setDialogValue({
            name: '',
            acronym: '',
            serviceId: serviceId,
        });
        toggleOpen(false);
    };

    /**
     * Handle Submit of Add Organization Popup
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation(); // Prevent Parent form from submitting
        setCreateError(null);
        try {
            const results = await createSubService({
                variables: {
                    subService: {
                        serviceId: dialogValue.serviceId,
                        name: dialogValue.name,
                        acronym: dialogValue.acronym,
                    },
                },
            });
            if (results && results.data) {
                // Organization was created so add it to the list and set it as selected value
                const subService = results.data.createSubService;
                setSubServices((currentList) => [...currentList, subService]);
                changeValue(subService, true);
            }
            handleClose();
        } catch (e) {
            setCreateError(e);
        }
    };

    const requiredAsterisk = required ? '*' : '';
    return loading ? (
        <SelectPlaceholder label={`Department Level III${requiredAsterisk}`} helperText="Type to filter or add new value" />
    ) : (
        <>
            <Autocomplete
                fullWidth
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                value={selectedSubService}
                forcePopupIcon={true}
                disabled={!!error || loading || !called}
                onChange={(_, newValue) => {
                    // Handle custom organizations being created
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                serviceId: serviceId,
                                name: newValue,
                                acronym: '',
                            });
                        });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            serviceId: serviceId,
                            name: newValue.inputValue,
                            acronym: '',
                        });
                    } else {
                        changeValue(newValue || '', true);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params) as SubServiceOptionType[];

                    // Add Option to add new Organizations
                    if (subServices.length === 0 && params.inputValue === '') {
                        filtered.push({
                            __typename: 'SubServiceType',
                            disabled: true,
                            id: 0,
                            acronym: '',
                            name: `No filter found. Start typing to add new...`,
                        });
                    } else if (params.inputValue !== '') {
                        filtered.push({
                            __typename: 'SubServiceType',
                            inputValue: params.inputValue,
                            id: 0,
                            acronym: '',
                            name: `Add "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                options={subServices}
                getOptionDisabled={(option) => (!readOnly ? !!option.disabled : true)}
                getOptionLabel={(option: SubServiceOptionType | string) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                renderOption={(option: SubServiceOptionType) => option.name}
                freeSolo
                disableClearable={readOnly}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={`Department Level III${requiredAsterisk}`}
                        error={!!errors.subServiceId}
                        helperText={errors.subServiceId?.message || 'Type to filter or add new value'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ ...params.InputProps, readOnly: readOnly }}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form id="form-create-sub-service" onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                        <Typography variant="h1">Add a new organization</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="name"
                                    fullWidth
                                    value={dialogValue.name}
                                    onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                                    label="Name"
                                    type="text"
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="acronym"
                                    fullWidth
                                    value={dialogValue.acronym}
                                    onChange={(event) => setDialogValue({ ...dialogValue, acronym: event.target.value })}
                                    label="Acronym/Short Name"
                                    type="text"
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {createError ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {createError?.message}
                                    </Alert>
                                </Grid>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="text">
                            Cancel
                        </Button>
                        <LoadingButton pending={mutationLoading} form="form-create-sub-service" type="submit" variant="contained">
                            Add
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export interface SubServiceSelectProps {
    required: boolean;
    readOnly: boolean;
}

export default SubServiceSelect;
