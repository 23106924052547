import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Divider, Grid, IconButton, Link, Paper, Step, StepButton, StepLabel, Stepper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { EvaluatorScoreIcon, StepperConnector } from '../../Stepper';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { LoadingButton, LoadingMessage } from '../..';
import { FormProvider, useForm } from 'react-hook-form';
import EvaluatorScoreSections from './EvaluatorScoreSections';
import { useEvaluatorScore } from '../../Providers/EvaluatorScoreProvider';
import {
    EvaluatorScoreInputType,
    FileUploadInputType,
    FundamentalPrimeMeasurement,
    ResponseStatus,
    ResponseUserScoreInputType,
} from '../../../apollo/generated/types/globalTypes';
import SaveIcon from '@material-ui/icons/Save';
import { FormMode, fpmLabels, fpmsOrdered, filterAndMapScores, ScoreSectionsType } from '../../../utils/Enums';
import { GetResponseUser_responseUser } from '../../../apollo/generated/types/GetResponseUser';
import { UpdateEvaluatorScores, UpdateEvaluatorScoresVariables } from '../../../apollo/generated/types/UpdateEvaluatorScores';
import { ApolloError, useMutation } from '@apollo/client';
import { UPDATE_EVALUATOR_SCORES } from '../../../apollo/mutations';
import { useSnackbar } from 'notistack';
import DocumentImportSection from './DocumentImportSetion';
import { UnSavedChangesDialog } from '../../Dialogs';
import { CycleTab } from '../../../utils/Enums/TabEnum';

// Interface definitions
interface EvaluatorScoreFormProps {
    loading?: boolean;
    parentRef: React.MutableRefObject<HTMLDivElement | null>;
}
interface URLParams {
    id: string;
    responseId: string;
}
interface ScoreFormData {
    id: number | null;
    userId: number | null;
    status: ResponseStatus | null;
    categories: {
        APPEAL: EvaluatorScoreInputType[];
        VALUE: EvaluatorScoreInputType[];
        RELIABILITY: EvaluatorScoreInputType[];
        PERSONNEL: EvaluatorScoreInputType[];
        PROCESSES: EvaluatorScoreInputType[];
        FINANCES: EvaluatorScoreInputType[];
        APPLICATION_SIZE: EvaluatorScoreInputType[];
        DEMAND: EvaluatorScoreInputType[];
        DELIVERY: EvaluatorScoreInputType[];
    };
    responseUserFiles: FileUploadInputType[] | null;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 2,
    },
    formHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '25px',
        padding: '15px',
    },
    backButton: {
        background: '#F0F0F0',
    },
    breadcrumbs: {
        marginLeft: '25px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    leftSide: {
        width: '320px',
        paddingLeft: '38px',
        paddingRight: '37px',
        [theme.breakpoints.up('md')]: { whiteSpace: 'nowrap' },
    },
    stepperLabel: {
        '& .MuiStepLabel-label': {
            color: '#1A2A3D',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: '#1A2A3D',
        },
    },
    rightSide: {
        flex: 1,
        maxWidth: '1000px',
        zIndex: 1,
    },
    sticky: {
        position: 'sticky',
        top: '95px',
    },
    ButtonDivider: {
        marginRight: '25px',
        height: '54px',
    },
    SaveIcon: {
        color: '#1976D2',
    },
    lockedMsg: {
        padding: '5px',
    },
}));

const EvaluatorScoreForm: React.FC<EvaluatorScoreFormProps> = ({ parentRef, loading }) => {
    const classes = useStyles();
    const history = useHistory();
    const { id: cycleId, responseId } = useParams<URLParams>();
    const { evaluatorResponse, loading: scoreLoading } = useEvaluatorScore();
    const formLoading = loading || scoreLoading;
    const { enqueueSnackbar } = useSnackbar();
    // State variables
    const [hasBriefResponse, setHasBriefResponse] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [id, setId] = useState<number>();
    const [userId, setUserId] = useState<number>();
    const [status, setStatus] = useState<ResponseStatus>();
    const setHiddenValues = (newId: number, newUserId: number, newStatus: ResponseStatus) => {
        setId(newId);
        setUserId(newUserId);
        setStatus(newStatus);
    };

    const convertResponseUserToForm = (data?: GetResponseUser_responseUser) => {
        const scores = data?.evaluatorScores ?? [];
        const result = {
            id: data?.id ?? null,
            userId: data?.userId ?? null,
            status: data?.status ?? null,
            categories: {
                APPEAL: filterAndMapScores(scores, FundamentalPrimeMeasurement.APPEAL),
                VALUE: filterAndMapScores(scores, FundamentalPrimeMeasurement.VALUE),
                RELIABILITY: filterAndMapScores(scores, FundamentalPrimeMeasurement.RELIABILITY),
                PERSONNEL: filterAndMapScores(scores, FundamentalPrimeMeasurement.PERSONNEL),
                PROCESSES: filterAndMapScores(scores, FundamentalPrimeMeasurement.PROCESSES),
                FINANCES: filterAndMapScores(scores, FundamentalPrimeMeasurement.FINANCES),
                APPLICATION_SIZE: filterAndMapScores(scores, FundamentalPrimeMeasurement.APPLICATION_SIZE),
                DEMAND: filterAndMapScores(scores, FundamentalPrimeMeasurement.DEMAND),
                DELIVERY: filterAndMapScores(scores, FundamentalPrimeMeasurement.DELIVERY),
            },
        } as ScoreFormData;

        if (data?.responseUserFiles) {
            // ResponseUser Files
            result.responseUserFiles = data.responseUserFiles.map(
                (file) =>
                    ({
                        id: file.id,
                    } as FileUploadInputType),
            );
        }

        return result;
    };
    const defaults = convertResponseUserToForm(evaluatorResponse);

    const methods = useForm<ScoreFormData>({
        defaultValues: defaults,
    });

    useEffect(() => {
        // Determine whether or not this is a brief response
        if (evaluatorResponse) {
            const form = evaluatorResponse.response.topic?.subCycle.form;
            setHasBriefResponse(form?.__typename === 'RequestForInformationFormType' && form.hasBriefResponse);
            setHiddenValues(evaluatorResponse.id, evaluatorResponse.userId, evaluatorResponse.status);
            methods.reset(convertResponseUserToForm(evaluatorResponse));
        } else {
            setHasBriefResponse(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaluatorResponse]);

    //// Form Stepper ////
    // Page refs
    const productTechnologyRef = useRef<null | HTMLDivElement>(null);
    const organizationRef = useRef<null | HTMLDivElement>(null);
    const applicationRef = useRef<null | HTMLDivElement>(null);
    const documentImportRef = useRef<null | HTMLDivElement>(null);
    const getSteps = () => (hasBriefResponse ? ['Product / Technology'] : ['Product / Technology', 'Organization', 'Application']);
    const steps = getSteps();
    const handleStep = (step: number) => () => {
        setActiveStep(step);
        if (!parentRef || !parentRef.current) {
            return;
        }
        switch (step) {
            case 0:
                if (productTechnologyRef && productTechnologyRef.current) {
                    parentRef.current.scrollBy({
                        top: productTechnologyRef.current.offsetTop - parentRef.current.scrollTop - 212,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
                break;
            case 1:
                if (organizationRef && organizationRef.current) {
                    parentRef.current.scrollBy({
                        top: organizationRef.current.offsetTop - parentRef.current.scrollTop - 212,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
                break;
            case 2:
                if (applicationRef && applicationRef.current) {
                    parentRef.current.scrollBy({
                        top: applicationRef.current.offsetTop - parentRef.current.scrollTop - 212,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
                break;
            case 3:
                if (documentImportRef && documentImportRef.current) {
                    parentRef.current.scrollBy({
                        top: documentImportRef.current.offsetTop - parentRef.current.scrollTop - 212,
                        left: 0,
                        behavior: 'smooth',
                    });
                }
                break;
        }
    };

    // Score Update mutation and Submission function
    const [updateScores] = useMutation<UpdateEvaluatorScores, UpdateEvaluatorScoresVariables>(UPDATE_EVALUATOR_SCORES, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            const evaluation = data?.updateEvaluatorScores;
            setHiddenValues(evaluation.id, evaluation.userId, evaluation.status);
            methods.reset(convertResponseUserToForm(evaluation));
            let snackbarMessage;
            if (evaluation.status === ResponseStatus.COMPLETE) {
                snackbarMessage = 'Evaluation completed successfully.';
                history.push(`/acquisition-cycle/${cycleId}/${CycleTab.Response}`);
            } else {
                snackbarMessage = 'Evaluation updated successfully';
            }
            enqueueSnackbar(snackbarMessage, { variant: 'success' });
        },
        onError: (e: ApolloError) => {
            console.log(e);
            enqueueSnackbar('Error updating evaluation.', { variant: 'error' });
        },
    });
    const onSubmit = (data: ScoreFormData, newStatus?: ResponseStatus): void => {
        if (data) {
            updateScores({
                variables: {
                    responseUser: {
                        id: data.id,
                        userId: data.userId,
                        responseId: Number(responseId),
                        statusId: newStatus,
                        evaluatorScores: Object.values(data.categories).flat(),
                        responseUserFiles: data.responseUserFiles ?? [],
                    } as ResponseUserScoreInputType,
                },
            });
        }
    };

    // Construct labels to watch individual score values
    const fpms = hasBriefResponse ? fpmsOrdered.slice(0, fpmLabels[0].length) : fpmsOrdered;
    const scoreWatchList = fpms.flatMap((fpm) =>
        (evaluatorResponse?.evaluatorScores ?? [])
            .filter((score) => score.successFactor.fpmCategory === fpm)
            .map((_score, scoreIndex) => `categories[${fpm}][${scoreIndex}].score`),
    );
    const data = methods.watch(scoreWatchList);
    const mode = status === ResponseStatus.COMPLETE || status === ResponseStatus.LOCKED ? FormMode.View : FormMode.Edit;
    const completeBtnStatus = status === ResponseStatus.COMPLETE ? ResponseStatus.IN_PROGRESS : ResponseStatus.COMPLETE;
    const readOnly = status === ResponseStatus.COMPLETE || status === ResponseStatus.LOCKED;

    return (
        <FormProvider {...methods}>
            <form id="evaluator-score-form" onSubmit={methods.handleSubmit((data: ScoreFormData) => onSubmit(data, ResponseStatus.IN_PROGRESS))}>
                <Grid container className={classes.stickyHeader}>
                    <Grid item xs={12}>
                        <Paper elevation={1}>
                            <div className={classes.formHeader}>
                                <IconButton component={NavLink} to={`/acquisition-cycle/${cycleId}/${CycleTab.Response}`}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <div className={classes.breadcrumbs}>
                                    <Typography variant="h2">
                                        {evaluatorResponse?.response.organization.name} Response ID #{responseId}
                                    </Typography>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link component={NavLink} to={`/acquisition-cycle/${responseId}/${CycleTab.Response}`}>
                                            <Typography variant="h3">{evaluatorResponse?.response.title ?? 'Untitled'}</Typography>
                                        </Link>
                                        <Typography variant="h3">{evaluatorResponse?.response.organization.name}</Typography>
                                        <Typography variant="h3" style={{ color: '#1976D2' }}>
                                            Response #{responseId}
                                        </Typography>
                                    </Breadcrumbs>
                                </div>
                                <LoadingButton
                                    pending={formLoading}
                                    type="submit"
                                    disabled={!status || status === ResponseStatus.COMPLETE}
                                    startIcon={<SaveIcon className={classes.SaveIcon} />}>
                                    Save Progress
                                </LoadingButton>
                                <Divider orientation="vertical" className={classes.ButtonDivider} />
                                {/* Disable the Complete button if all of the scores have not been entered, or if the form is locked */}
                                <LoadingButton
                                    pending={formLoading}
                                    onClick={methods.handleSubmit((data: ScoreFormData) => onSubmit(data, completeBtnStatus))}
                                    disabled={status === ResponseStatus.LOCKED || Object.values(data).some((score) => !score || Number.isNaN(score))}
                                    variant="contained">
                                    {readOnly ? 'Change Evaluation' : 'Complete Evaluation'}
                                </LoadingButton>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <div className={classes.root}>
                    <div className={classes.leftSide}>
                        <Grid container spacing={2} className={classes.sticky}>
                            <Grid item xs={12}>
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                    nonLinear
                                    connector={<StepperConnector />}
                                    style={{ background: 'transparent' }}>
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepButton onClick={handleStep(index)}>
                                                <StepLabel className={classes.stepperLabel} StepIconComponent={EvaluatorScoreIcon}>
                                                    {label}
                                                </StepLabel>
                                            </StepButton>
                                        </Step>
                                    ))}
                                    {/* Step for document upload section */}
                                    <Step key="Document Import">
                                        <StepButton onClick={handleStep(3)}>
                                            <StepLabel className={classes.stepperLabel} StepIconComponent={EvaluatorScoreIcon}>
                                                Document Import
                                            </StepLabel>
                                        </StepButton>
                                    </Step>
                                </Stepper>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.rightSide}>
                        <Grid container spacing={2}>
                            {formLoading && !evaluatorResponse ? (
                                <LoadingMessage />
                            ) : (
                                <>
                                    {status === ResponseStatus.LOCKED ? (
                                        <Grid item xs={12}>
                                            <Paper elevation={0} className={classes.lockedMsg}>
                                                <em>
                                                    <Typography variant="subtitle1">
                                                        This response is currently locked. Please contact an admin or Team Lead for more information.
                                                    </Typography>
                                                </em>
                                            </Paper>
                                        </Grid>
                                    ) : null}
                                    {id ? <input name="id" type="hidden" ref={methods.register()} value={id} /> : null}
                                    {userId ? <input name="userId" type="hidden" ref={methods.register()} value={userId} /> : null}
                                    {status ? <input name="status" type="hidden" ref={methods.register()} value={status} /> : null}
                                    <Grid ref={productTechnologyRef} item xs={12}>
                                        <Paper elevation={0}>
                                            <EvaluatorScoreSections mode={mode} sectionNumber={ScoreSectionsType.PRODUCT_TECHNOLOGY} />
                                        </Paper>
                                    </Grid>
                                    {!hasBriefResponse && (
                                        <>
                                            <Grid ref={organizationRef} item xs={12}>
                                                <Paper elevation={0}>
                                                    <Paper elevation={0}>
                                                        <EvaluatorScoreSections mode={mode} sectionNumber={ScoreSectionsType.ORGANIZATION} />
                                                    </Paper>
                                                </Paper>
                                            </Grid>
                                            <Grid ref={applicationRef} item xs={12}>
                                                <Paper elevation={0}>
                                                    <EvaluatorScoreSections mode={mode} sectionNumber={ScoreSectionsType.APPLICATION} />
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}

                                    {/* Document Import section */}
                                    <Grid ref={documentImportRef} item xs={12}>
                                        <Paper elevation={0}>
                                            <DocumentImportSection subCycleId={evaluatorResponse?.response.topic?.subCycle.id} readOnly={readOnly} />
                                        </Paper>
                                    </Grid>

                                    <UnSavedChangesDialog />
                                </>
                            )}
                        </Grid>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default EvaluatorScoreForm;
