import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthorizedApolloProvider, PermissionProvider } from './components';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import Pages from './pages';
import PageRoutes from './pages/PageRoutes';
import './index.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { SnackbarProvider } from 'notistack';
import Auth0ProviderWithHistory from './components/Auth0ProviderWithHistory';

ReactDOM.render(
    <Router>
        <Auth0ProviderWithHistory>
            <AuthorizedApolloProvider>
                <PermissionProvider>
                    <DndProvider backend={HTML5Backend}>
                        <ThemeProvider theme={theme}>
                            <SnackbarProvider
                                maxSnack={6}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <CssBaseline />
                                    <Switch>
                                        <Route exact path="/Login" component={Pages.LoginPage} />
                                        <Route path="/" component={PageRoutes} />
                                    </Switch>
                                </MuiPickersUtilsProvider>
                            </SnackbarProvider>
                        </ThemeProvider>
                    </DndProvider>
                </PermissionProvider>
            </AuthorizedApolloProvider>
        </Auth0ProviderWithHistory>
    </Router>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
