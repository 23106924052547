import * as React from 'react';
import { Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import TopicItem from './TopicItem';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SubCycleStatus, TopicInputType } from '../../../../apollo/generated/types/globalTypes';
import { useCallback, useState } from 'react';
import { GET_CYCLE_TOPICS } from '../../../../apollo/queries';
import { GetCycleTopics } from '../../../../apollo/generated/types/GetCycleTopics';
import { SubCycleForm } from '../../../../apollo/generated/types/globalTypes';

const useStyles = makeStyles({
    expandButton: {
        marginRight: '12px',
    },
});

const TopicDescriptionsSection: React.FC<TopicDescriptionsSectionProps> = ({ mode, cycleId, subcycleOrder, subCycleType }) => {
    const classes = useStyles();
    const [forceExpanded, setForceExpanded] = useState(false);
    const [expand, setExpand] = useState(false);
    const { control, register, errors, watch } = useFormContext();
    const readOnly = mode === FormMode.View;
    const status = watch('status');

    const { fields: topics, append, remove } = useFieldArray<TopicInputType, 'key'>({
        control,
        name: 'topics', // unique name for your Field Array
        keyName: 'key',
    });

    const { loading, data } = useQuery<GetCycleTopics>(GET_CYCLE_TOPICS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            cycleId: cycleId,
        },
    });

    const previousSubCycle = data?.cycle?.subCycles.filter((x) => x.order === subcycleOrder - 1)[0] ?? null;
    const previousTopics = previousSubCycle?.topics ?? [];

    if (
        previousSubCycle &&
        previousSubCycle.formType !== SubCycleForm.REQUEST_FOR_INFORMATION &&
        !(subCycleType === SubCycleForm.REQUEST_FOR_WHITE_PAPER && previousSubCycle.formType === SubCycleForm.REQUEST_FOR_WHITE_PAPER)
    ) {
        mode = FormMode.View;
    }

    const expandAll = () => {
        setForceExpanded(!forceExpanded);
        setExpand(true);
    };

    const collapseAll = () => {
        setForceExpanded(!forceExpanded);
        setExpand(false);
    };

    const removeTopic = useCallback(
        (index: number) => {
            if (topics.length > 1) {
                remove(index);
            }
        },
        [remove, topics.length],
    );

    const importPreviousTopics = () => {
        if (previousTopics.length > 0) {
            append(
                previousTopics
                    .filter((x) => topics.find((y) => y.name === x.name) === undefined)
                    .map((x) => ({ name: x.name, description: x.description, categories: x.categories.map((x) => x.id), importedID: x.id } as TopicInputType)),
            );
        }
    };

    return (
        <>
            <FormSection justify="space-between">
                <Grid item>
                    <Typography variant="h1">Topic Descriptions</Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" className={classes.expandButton} onClick={expandAll}>
                        Expand All
                    </Button>
                    <Button variant="contained" color="secondary" onClick={collapseAll}>
                        Collapse All
                    </Button>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    {topics.map((topic, index) => (
                        <TopicItem
                            key={topic.key}
                            index={index}
                            mode={mode}
                            {...topic}
                            forceExpanded={forceExpanded}
                            expand={expand}
                            showRemove={topics.length > 1}
                            remove={removeTopic}
                        />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    {/* Allows for validating files uploaded through the MultipleFileDropZone */}
                    {status === SubCycleStatus.APPROVED && (!topics || topics.length === 0) ? (
                        <input
                            name="topicsLength"
                            type="hidden"
                            ref={register({
                                validate: () => topics.length > 0 || 'At least one topic must be added.',
                            })}
                        />
                    ) : null}
                    {errors.topicsLength ? <FormHelperText error={true}>{errors.topicsLength?.message}</FormHelperText> : null}
                </Grid>
                {mode !== FormMode.View ? (
                    <Grid item xs={12}>
                        <Button
                            startIcon={<AddCircleIcon style={{ color: '#1976D2' }} />}
                            onClick={() =>
                                append({
                                    name: '',
                                    description: '',
                                    categories: [],
                                })
                            }>
                            Add new topic
                        </Button>
                    </Grid>
                ) : null}
                {subcycleOrder !== 0 && !loading ? (
                    <Grid container justify="center">
                        <Button variant="contained" color="secondary" onClick={importPreviousTopics} disabled={readOnly}>
                            Import Topics from Previous Sub-Cycle
                        </Button>
                    </Grid>
                ) : null}
            </FormSection>
        </>
    );
};

export interface TopicDescriptionsSectionProps {
    mode: FormMode;
    cycleId: number;
    subcycleOrder: number;
    subCycleType: SubCycleForm;
}

export default TopicDescriptionsSection;
