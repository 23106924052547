import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from '@apollo/client';
import { REQUEST_UPLOAD_TOKEN } from '../../apollo/mutations';
import { RequestUploadToken, RequestUploadTokenVariables } from '../../apollo/generated/types/RequestUploadToken';
import { uploadBlobsToStorage } from '../../utils/file-storage-util';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles({
    base: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
    active: {
        borderColor: '#2196f3',
    },
    accept: {
        borderColor: '#00e676',
    },
    reject: {
        borderColor: '#ff1744',
    },
});

const FileDropZone: React.FC<FileDropZoneProps> = ({ id, maxFiles, onFileUploaded }) => {
    const classes = useStyles();
    const [requestUploadToken, { error }] = useMutation<RequestUploadToken, RequestUploadTokenVariables>(REQUEST_UPLOAD_TOKEN);
    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        maxFiles: maxFiles,
        onDrop: async (acceptedFiles: File[]) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                try {
                    const file = acceptedFiles[0];
                    const tokenResult = await requestUploadToken({
                        variables: {
                            input: {
                                subCycleId: id,
                                fileName: file.name,
                                fileSize: file.size,
                            },
                        },
                    });

                    const uploadTokenRequest = tokenResult.data?.uploadTokenRequest;
                    if (uploadTokenRequest) {
                        await uploadBlobsToStorage(file, uploadTokenRequest.sasUri);
                        if (onFileUploaded) {
                            onFileUploaded({ fileName: file.name, fileSize: file.size, token: uploadTokenRequest.token });
                        }
                    } else {
                        console.error('Invalid uploadTokenRequest');
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
    });

    return (
        <div
            {...getRootProps({
                className: `${classes.base} ${isDragActive ? classes.active : ''} ${isDragAccept ? classes.accept : ''} ${isDragReject ? classes.reject : ''}`,
            })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
            {/* Subcycle Files Error */}
            {error ? (
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <AlertTitle>File Upload Failed</AlertTitle>
                            {error?.message}
                        </Alert>
                    </Grid>
                </Grid>
            ) : null}
        </div>
    );
};

export interface FileUploadedEvent {
    fileName: string;
    fileSize: number;
    token: string;
}

export interface FileDropZoneProps {
    maxFiles?: number;
    id: number;
    onFileUploaded?: (event: FileUploadedEvent) => void;
}

export default FileDropZone;
