import * as React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DatePickerView } from '@material-ui/pickers/DatePicker/DatePicker';

const ReactHookFormDate: React.FC<ReactHookFormDateProps> = ({ name, format, views, label, helperText, control, defaultValue, readOnly, rules }) => {
    return (
        <Controller
            render={({ ref, onChange, value, ...rest }, { invalid }) => (
                <KeyboardDatePicker
                    fullWidth
                    format={format}
                    views={views}
                    label={label}
                    readOnly={readOnly}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    okLabel={null}
                    autoOk={true}
                    error={invalid}
                    helperText={helperText}
                    inputProps={{ readOnly: readOnly }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    value={value}
                    onChange={(date) => {
                        date?.setHours(12, 0);
                        onChange(date ? date?.toUTCString() : null);
                    }}
                    {...rest}
                />
            )}
            rules={rules}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
};
export interface ReactHookFormDateProps {
    label: string;
    name: string;
    format?: string;
    views?: DatePickerView[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: Date | null;
    helperText?: string;
    readOnly?: boolean;
    error?: boolean;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormDate;
