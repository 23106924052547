import * as React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import ReactHookFormTextArea from '../../../ReactHookForm/ReactHookFormTextArea';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormCheckbox } from '../../..';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import file from './SOW-template-10-June-2021.docx';

const ContentBuilderForm: React.FC<ContentBuilderFormProps> = ({ mode }) => {
    const { control, errors, watch } = useFormContext();

    const mainFormFields = [
        'hasIntroduction',
        'hasProposalPreparationInstructions',
        'hasNonDisclosureStatement',
        'hasGovernmentInterfacing',
        'hasLicensingRights',
        'hasTechnicalProposalInstructions',
        'hasStatementOfWork',
        'hasPriceInformation',
        'hasBasisForProposalReview',
        'hasAwarding',
        'hasGeneralInformation',
        'hasFollowOnProduction',
        'hasMandatoryGovernmentTerms',
        'hasExportControl',
        'hasSubmissionInstructions',
    ];
    const mainFormDefaults = mainFormFields.reduce((current, item) => {
        current[item] = false;
        return current;
    }, {});
    const mainFormVals = watch(mainFormFields, mainFormDefaults);

    const status = watch('status', SubCycleStatus.DRAFT);
    const required = status !== SubCycleStatus.DRAFT;
    const readOnly = mode === FormMode.View;
    const requiredAsterisk = required ? '*' : '';

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Content Builder</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasIntroduction" label="Introduction" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Introduction"
                        name="introduction"
                        error={!!errors.introduction}
                        helperText={errors.introduction?.message}
                        hidden={!mainFormVals.hasIntroduction}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasProposalPreparationInstructions"
                        label="Proposal Preparation Instructions"
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Proposal Preparation Instructions"
                        name="proposalPreparationInstructions"
                        error={!!errors.proposalPreparationInstructions}
                        helperText={errors.proposalPreparationInstructions?.message}
                        hidden={!mainFormVals.hasProposalPreparationInstructions}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasNonDisclosureStatement" label="Data Non-Disclosure Statement" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Data Non-Disclosure Statement"
                        name="nonDisclosureStatement"
                        error={!!errors.nonDisclosureStatement}
                        helperText={errors.nonDisclosureStatement?.message}
                        hidden={!mainFormVals.hasNonDisclosureStatement}
                        rules={{
                            required: required && mainFormVals.hasNonDisclosureStatement ? 'Data Non-Disclosure Statement is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasNonDisclosureStatement ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Data Non-Disclosure Statement must have some content.'
                                );
                            },
                        }}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasGovernmentInterfacing" label="Interfacing with the Government" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Interfacing with the Government"
                        name="governmentInterfacing"
                        error={!!errors.governmentInterfacing}
                        helperText={errors.governmentInterfacing?.message}
                        hidden={!mainFormVals.hasGovernmentInterfacing}
                        rules={{
                            required: required && mainFormVals.hasGovernmentInterfacing ? 'Interfacing with the Government is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasGovernmentInterfacing ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Interfacing with the Government must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasLicensingRights" label="Licensing Rights and Patents" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Licensing Rights and Patents"
                        name="licensingRights"
                        error={!!errors.licensingRights}
                        helperText={errors.licensingRights?.message}
                        hidden={!mainFormVals.hasLicensingRights}
                        rules={{
                            required: required && mainFormVals.hasLicensingRights ? 'Licensing Rights and Patents is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasLicensingRights ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Licensing Rights and Patents must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasTechnicalProposalInstructions"
                        label="Document 1: Technical Proposal Instructions"
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Document 1: Technical Proposal Instructions"
                        name="technicalProposalInstructions"
                        error={!!errors.technicalProposalInstructions}
                        helperText={errors.technicalProposalInstructions?.message}
                        hidden={!mainFormVals.hasTechnicalProposalInstructions}
                        rules={{
                            required: required && mainFormVals.hasTechnicalProposalInstructions ? 'Technical Proposal Instructions is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasTechnicalProposalInstructions ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Technical Proposal Instructions must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasStatementOfWork" label="Document 2: Statement of Work" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    {mainFormVals.hasStatementOfWork && (
                        <Typography style={{ margin: '0 0 10px 0' }}>
                            <Link style={{ color: '#1976D2' }} href={file} download="SOW-template-10-June-2021.docx">
                                Export SOW template
                            </Link>
                        </Typography>
                    )}

                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Document 2: Statement of Work"
                        name="statementOfWork"
                        error={!!errors.statementOfWork}
                        helperText={errors.statementOfWork?.message}
                        hidden={!mainFormVals.hasStatementOfWork}
                        rules={{
                            required: required && mainFormVals.hasStatementOfWork ? 'Statement of Work is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasStatementOfWork ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Statement of Work must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasPriceInformation" label="Document 3: Price Information" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Document 3: Price Information"
                        name="priceInformation"
                        error={!!errors.priceInformation}
                        helperText={errors.priceInformation?.message}
                        hidden={!mainFormVals.hasPriceInformation}
                        rules={{
                            required: required && mainFormVals.hasPriceInformation ? 'Price Information is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasPriceInformation ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Price Information must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasBasisForProposalReview" label="Basis for Proposal Review" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Basis for Proposal Review"
                        name="basisForProposalReview"
                        error={!!errors.basisForProposalReview}
                        helperText={errors.basisForProposalReview?.message}
                        hidden={!mainFormVals.hasBasisForProposalReview}
                        rules={{
                            required: required && mainFormVals.hasBasisForProposalReview ? 'Basis for Proposal Review is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasProposalReview ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Basis for Proposal Review must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasAwarding" label="Awarding" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Awarding"
                        name="awarding"
                        error={!!errors.Awarding}
                        helperText={errors.Awarding?.message}
                        hidden={!mainFormVals.hasAwarding}
                        rules={{
                            required: required && mainFormVals.hasAwarding ? 'Awarding is required' : false,
                            validate: (value) => {
                                return (
                                    !required || !mainFormVals.hasAwarding || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Awarding must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasGeneralInformation" label="General Information" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="General Information"
                        name="generalInformation"
                        error={!!errors.generalInformation}
                        helperText={errors.generalInformation?.message}
                        hidden={!mainFormVals.hasGeneralInformation}
                        rules={{
                            required: required && mainFormVals.hasGeneralInformation ? 'General Information is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasGeneralInformation ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'General Information must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} name="hasFollowOnProduction" label="Follow-On Production" readOnly={readOnly} />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Follow-On Production"
                        name="followOnProduction"
                        error={!!errors.followOnProduction}
                        helperText={errors.followOnProduction?.message}
                        hidden={!mainFormVals.hasFollowOnProduction}
                        rules={{
                            required: required && mainFormVals.hasFollowOnProduction ? 'Follow-On Production is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasFollowOnProduction ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Follow-On Production must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasMandatoryGovernmentTerms"
                        label="Mandatory Government Terms and Conditions"
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Mandatory Government Terms and Conditions"
                        name="mandatoryGovernmentTerms"
                        error={!!errors.mandatoryGovernmentTerms}
                        helperText={errors.mandatoryGovernmentTerms?.message}
                        hidden={!mainFormVals.hasMandatoryGovernmentTerms}
                        rules={{
                            required: required && mainFormVals.hasMandatoryGovernmentTerms ? 'Mandatory Government Terms and Conditions" is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasMandatoryGovernmentTerms ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Mandatory Government Terms and Conditions" must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox control={control} readOnly={readOnly} name="hasExportControl" label="Export Control" />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Export Control"
                        name="exportControl"
                        hidden={!mainFormVals.hasExportControl}
                        error={!!errors.exportControl}
                        helperText={errors.exportControl?.message}
                        rules={{
                            required: required && mainFormVals.hasExportControl ? 'Export Control is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasExportControl ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Export Control must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasSubmissionInstructions"
                        label="Submission Instructions/Requirements"
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label={`Submission Instructions/Requirements ${requiredAsterisk}`}
                        name="submissionInstructions"
                        error={!!errors.submissionInstructions}
                        helperText={errors.submissionInstructions?.message}
                        hidden={!mainFormVals.hasSubmissionInstructions}
                        rules={{
                            required: required && mainFormVals.hasSubmissionInstructions ? 'Submission Instructions/Requirements is required' : false,
                            validate: (value) => {
                                return (
                                    !required ||
                                    !mainFormVals.hasSubmissionInstructions ||
                                    value.replace(/<[^>]*>?/gm, '').length > 0 ||
                                    'Submission Instructions/Requirements must have some content.'
                                );
                            },
                        }}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface ContentBuilderFormProps {
    mode: FormMode;
}

export default ContentBuilderForm;
