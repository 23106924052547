import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    FormHelperText,
    Grid,
    GridSize,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RDCategoryItem from './RDCategoryItem';
import { ReactHookFormSelect, StaticField, ReactHookFormAutocomplete, MultipleFileDropZone, useRFIForm, useCommonForm } from '../../../index';
import { GetSubCycleForm_subCycle_cycle } from '../../../../apollo/generated/types/GetSubCycleForm';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';
import { ResearchDevelopmentCategoryInputType, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import { useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
});

const GeneralForm: React.FC<GeneralFormProps> = ({ mode, subCycleId, cycleInfo }) => {
    const classes = useStyles();
    const { register, errors, control, watch } = useFormContext();
    const { timeHorizons, addTimeHorizon, technologyKeywords, addTechnologyKeyword, loading } = useRFIForm();
    const { securityClassifications, researchDevelopmentCategories, loading: commonLoading, responderTypes } = useCommonForm();
    const { fields: rdList, append, remove } = useFieldArray<ResearchDevelopmentCategoryInputType, 'key'>({
        control,
        name: 'researchDevelopmentCategories', // unique name for your Field Array
        keyName: 'key',
    });
    const status = watch('status', SubCycleStatus.DRAFT);
    const required = status !== SubCycleStatus.DRAFT;
    const readOnly = mode === FormMode.View;

    const [categoryId, setCategoryId] = React.useState<number[]>([]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selected = event.target.value as number[];

        if (selected.length > rdList.length) {
            // Option Selected
            const addedId = selected.filter((id) => !rdList.find((item) => (Number(item.categoryId) || -1) === id));
            if (addedId && addedId.length > 0) {
                append({
                    categoryId: addedId[0],
                    fundingRange: null,
                });
            }
        } else {
            // Option Un-Selected
            for (let index = 0; index < rdList.length; index++) {
                const item = rdList[index];
                if (item.categoryId && selected.indexOf(Number(item.categoryId)) === -1) {
                    remove(index);
                    break;
                }
            }
        }
    };

    const getName = (categoryId: number) => {
        const found = researchDevelopmentCategories?.find((option) => option.id === Number(categoryId));
        return found?.name || '';
    };

    useEffect(() => {
        setCategoryId(rdList ? rdList.map((item) => Number(item.categoryId) || -1) : []);
    }, [rdList]);

    let rowWidth = 12 as GridSize;
    const teamLead = cycleInfo.teamLeads.length > 0 ? cycleInfo.teamLeads[0] : null;
    const coLead1 = cycleInfo.coLeads.length > 0 ? cycleInfo.coLeads[0] : null;
    if (coLead1) {
        rowWidth = 6;
    }
    const coLead2 = cycleInfo.coLeads.length > 1 ? cycleInfo.coLeads[1] : null;
    if (coLead2) {
        rowWidth = 4;
    }
    const coLead3 = cycleInfo.coLeads.length > 2 ? cycleInfo.coLeads[2] : null;
    if (coLead3) {
        rowWidth = 3;
    }
    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">General</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <TextField
                        label="RFI Title*"
                        name="title"
                        inputRef={register({ required: required ? 'Title is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="RFI ID*"
                        inputRef={register({ required: required ? 'RFI ID is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="externalId"
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.externalId}
                        helperText={errors.externalId?.message}
                    />
                </Grid>
                {teamLead ? (
                    <Grid item xs={rowWidth}>
                        <StaticField label="Team Lead" value={teamLead.user?.fullName || 'N/A'} />
                    </Grid>
                ) : null}
                {coLead1 ? (
                    <Grid item xs={rowWidth}>
                        <StaticField label="Co-Lead" value={coLead1.user?.fullName || 'N/A'} />
                    </Grid>
                ) : null}
                {coLead2 ? (
                    <Grid item xs={rowWidth}>
                        <StaticField label="Co-Lead" value={coLead2.user?.fullName || 'N/A'} />
                    </Grid>
                ) : null}
                {coLead3 ? (
                    <Grid item xs={rowWidth}>
                        <StaticField label="Co-Lead" value={coLead3.user?.fullName || 'N/A'} />
                    </Grid>
                ) : null}
                <Grid item xs={6}>
                    <StaticField label="Selected Department" value={cycleInfo.department?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <StaticField label="Department Level II" value={cycleInfo.service?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <StaticField label="Department Level III" value={cycleInfo.subService?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormSelect
                        fullWidth
                        control={control}
                        label="Eligible Responders"
                        name="eligibleResponderTypeId"
                        defaultValue={undefined}
                        loading={loading}
                        error={!!errors.eligibleResponderTypeId}
                        helperText={errors.eligibleResponderTypeId?.message}
                        readOnly={readOnly}>
                        {responderTypes?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormSelect
                        fullWidth
                        control={control}
                        label="Security Classification*"
                        name="securityClassificationId"
                        loading={commonLoading}
                        rules={{ required: required ? 'Security Classification is required' : false }}
                        error={!!errors.securityClassificationId}
                        helperText={errors.securityClassificationId?.message}
                        readOnly={readOnly}>
                        {securityClassifications?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="totalBudget"
                        label="Total Budget"
                        inputRef={register()}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Location / Place of  Performance*"
                        name="locationPlaceOfPerformance"
                        inputRef={register({ required: required ? 'Location / Place of  Performance is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.locationPlaceOfPerformance}
                        helperText={errors.locationPlaceOfPerformance?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true} error={!!errors.rdCategoryLength && required}>
                        <InputLabel id="research-and-development-category-label" shrink>
                            Research & Development Category*
                        </InputLabel>
                        <Select
                            multiple
                            input={<Input />}
                            labelId="research-and-development-category-label"
                            value={categoryId}
                            onChange={handleChange}
                            disabled={commonLoading}
                            error={!!errors.rdCategoryLength}
                            inputProps={{
                                readOnly: readOnly,
                            }}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {(selected as string[]).map((value, index) => {
                                        return (
                                            <Chip
                                                key={value}
                                                label={
                                                    researchDevelopmentCategories &&
                                                    researchDevelopmentCategories.find((e) => e.id === rdList[index]?.categoryId)
                                                        ? researchDevelopmentCategories.find((e) => e.id === rdList[index].categoryId)?.name
                                                        : ''
                                                }
                                                className={classes.chip}
                                                clickable
                                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                                                onDelete={() => (researchDevelopmentCategories ? remove(index) : console.log('cant delete'))}
                                            />
                                        );
                                    })}
                                </div>
                            )}>
                            {researchDevelopmentCategories?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.rdCategoryLength ? <FormHelperText error={true}>{errors.rdCategoryLength?.message}</FormHelperText> : null}
                    </FormControl>
                    {!rdList || (rdList.length === 0 && required) ? (
                        <>
                            <input
                                name="rdCategoryLength"
                                type="hidden"
                                ref={register({ validate: () => rdList.length > 0 || 'At least one Research & Development Category required' })}
                            />
                        </>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    {rdList.map((item, index) => {
                        if (item.categoryId) {
                            return (
                                <RDCategoryItem
                                    key={item.key}
                                    mode={mode}
                                    index={index}
                                    id={item.id}
                                    name={item.categoryId ? getName(item.categoryId) : ''}
                                    categoryId={Number(item.categoryId)}
                                    fundingRange={item.fundingRange}
                                    technologyReadinessLevelId={item.technologyReadinessLevelId}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormAutocomplete
                        label="Time Horizon*"
                        name="timeHorizonId"
                        loading={loading}
                        rules={{ required: required ? 'Time Horizon is required' : false }}
                        error={!!errors.timeHorizonId}
                        helperText={errors.timeHorizonId?.message}
                        options={timeHorizons}
                        addOption={addTimeHorizon}
                        getOptionSelected={(option: OptionFields, value?: number | OptionFields) => {
                            if (!value) {
                                return false;
                            }
                            if (typeof value === 'number') {
                                return option.id === value;
                            } else {
                                return option.id === value.id;
                            }
                        }}
                        getOptionValue={(option: OptionFields | null) => (option ? option?.id : null)}
                        getOptionLabel={(option: OptionFields) => option?.name || ''}
                        renderOption={(option: OptionFields) => option?.name}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        type="number"
                        label="Number of Expected Awards"
                        name="expectedAwards"
                        inputRef={register({ valueAsNumber: true, min: { value: 0, message: 'Value must be greater than 0' } })}
                        fullWidth
                        defaultValue={0}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        inputProps={{ min: 0 }}
                        error={!!errors.expectedAwards}
                        helperText={errors.expectedAwards?.message}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Recommended NAICS Code"
                        name="naicscode"
                        inputRef={register({ maxLength: { value: 6, message: 'Max length of 6 characters' } })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        inputProps={{ maxLength: 6 }}
                        error={!!errors.naicscode}
                        helperText={errors.naicscode?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormAutocomplete
                        multiple={true}
                        label="Technology Keywords*"
                        name="technologyKeywords"
                        loading={loading}
                        rules={{
                            required: required ? 'Technology Keywords is required' : false,
                            validate: (value) => !required || value.length > 0 || 'At least one Technology Keyword required',
                        }}
                        error={!!errors.technologyKeywords}
                        helperText={errors.technologyKeywords?.message}
                        options={technologyKeywords}
                        addOption={addTechnologyKeyword}
                        getOptionSelected={(option: OptionFields, value?: number[] | OptionFields) => {
                            if (!value) {
                                return false;
                            }
                            if (Array.isArray(value)) {
                                return value.includes(option.id);
                            } else {
                                return value.id === option.id;
                            }
                        }}
                        getOptionValue={(options: OptionFields[]) => options.map((option: OptionFields) => option.id)}
                        getOptionLabel={(option: OptionFields) => {
                            return option.name;
                        }}
                        renderTags={(values: OptionFields[], getTagProps) =>
                            values.map((option: OptionFields, index: number) => {
                                return <Chip label={option.name} {...getTagProps({ index })} disabled={readOnly} />;
                            })
                        }
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography variant="h3">Reference Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MultipleFileDropZone name="referenceFiles" subCycleId={subCycleId} readOnly={readOnly} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </FormSection>
        </>
    );
};

export interface GeneralFormProps {
    mode: FormMode;
    subCycleId: number;
    cycleInfo: GetSubCycleForm_subCycle_cycle;
}

export default GeneralForm;
