import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob';
import download from 'downloadjs';

/**
 *
 * @param file File to upload.
 * @param sasUri Temporary SAS URI with Write privileges.
 */
export const uploadBlobsToStorage = async (file: File, sasUri: string): Promise<BlobUploadCommonResponse> => {
    try {
        const blobClient = new BlockBlobClient(sasUri);

        // Construct BlobClient to handle upload to Azure Storage
        const options = { blobHTTPHeaders: { blobContentType: file.type } };

        // upload file
        return await blobClient.uploadData(file, options);
    } catch (e) {
        console.log(e);
        return e;
    }
};

/**
 *
 * @param fileName
 * @param sasUri Temporary SAS URI with Read privileges.
 */
export const downloadBlobFromStorage = (fileName: string, sasUri: string, openFile = false): void => {
    // Constructs a BlobClient to handle download from Azure Storage
    const blobClient = new BlockBlobClient(sasUri);
    blobClient
        .download()
        .then((blobResponse) => {
            return blobResponse.blobBody;
        })
        .then((blob) => {
            if (blob !== undefined) {
                if (openFile) {
                    const url = URL.createObjectURL(blob);
                    window.open(url,'_blank');
                } else 
                {
                    download(blob, fileName, blob.type);
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
