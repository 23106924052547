import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: 'auto',
            borderRadius: '8px',
            textAlign: 'center',
            width: '50px',
            padding: '8px',
            whiteSpace: 'nowrap',
            fontWeight: 600,
        },
        error: {
            background: theme.palette.error.light,
        },
        warning: {
            background: theme.palette.warning.light,
        },
        success: {
            background: theme.palette.success.light,
        },
        errorLight: {
            background: theme.palette.error.main,
        },
        warningLight: {
            background: theme.palette.warning.main,
        },
        successLight: {
            background: theme.palette.success.main,
        },
    }),
);

export interface ScoreDisplayProps {
    score: number;
    light?: boolean;
}

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ score, light = false }) => {
    const classes = useStyles();

    let color = '';
    if (score >= 8) {
        color = light ? classes.successLight : classes.success;
    } else if (score < 8 && score >= 7) {
        color = light ? classes.warningLight : classes.warning;
    } else if (score < 7) {
        color = light ? classes.errorLight : classes.error;
    }

    return <div className={`${color} ${classes.card}`}>{score}</div>;
};

export default ScoreDisplay;
