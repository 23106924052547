import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTeamLeadScore } from '../../../Providers';
import { ScoreTitleDisplay } from '../../../ScoreDisplay';

interface EvaluationSectionProps {
    fpmCategory: string;
    fpmLabel: string;
}

const EvaluationSection: React.FC<EvaluationSectionProps> = ({ fpmCategory, fpmLabel }) => {
    const { evaluations, loading, evaluationIndex } = useTeamLeadScore();
    const [totalScore, setScore] = useState({ score: 0, responderScore: 0, weight: 0 });

    // Map Responder scores to Success Factors
    const respScoreMap = useMemo(
        () =>
            evaluationIndex >= evaluations.length
                ? {}
                : (evaluations[evaluationIndex].response.responderScores ?? []).reduce((responderScores, responderScore) => {
                      responderScores[`${responderScore.successFactorId}`] = responderScore.score;
                      return responderScores;
                  }, {}),
        [evaluationIndex, evaluations],
    );

    // Updates scores based on loaded evaluation data
    const updateScores = useCallback(() => {
        let score = 0;
        let responderScore = 0;
        let weight = 0;

        if (!loading) {
            (evaluations[evaluationIndex].evaluatorScores ?? [])
                .filter((evaluationScore) => evaluationScore.successFactor.fpmCategory === fpmCategory)
                .map((evaluationScore) => {
                    const weightVal = parseFloat(evaluationScore.successFactor.weight);
                    score = score + parseFloat(evaluationScore.score) * weightVal;
                    // if the responder has provided a score for this success factor, add it to the total
                    const sfId = `${evaluationScore.successFactor.id ?? ''}`;
                    if (sfId && Object.keys(respScoreMap).includes(sfId)) {
                        const responderScoreVal = respScoreMap[sfId];
                        responderScore = responderScore + parseFloat(responderScoreVal) * weightVal;
                    }
                    weight = weight + weightVal;
                });
            setScore({ score, responderScore, weight });
        } else {
            setScore({ score: 0, responderScore: 0, weight: 0 });
        }
    }, [loading, evaluations, evaluationIndex, fpmCategory, respScoreMap]);

    useEffect(() => {
        updateScores();
    }, [updateScores]);

    // Calculate weighted averages
    let weightedEvalAvg = parseFloat((totalScore.score / totalScore.weight).toFixed(1));
    weightedEvalAvg = isNaN(weightedEvalAvg) ? 0 : weightedEvalAvg;
    let weightedRespAvg = parseFloat((totalScore.responderScore / totalScore.weight).toFixed(1));
    weightedRespAvg = isNaN(weightedRespAvg) ? 0 : weightedRespAvg;

    return (
        <Box width="30%" justifyContent="center">
            <Grid container justify="space-around" style={{ padding: '8px' }}>
                <ScoreTitleDisplay gridSize={8} label={fpmLabel} score={weightedEvalAvg} gridProps={{ style: { padding: '8px' } }} />
                <ScoreTitleDisplay gridSize={2} label={weightedRespAvg} score={weightedEvalAvg} gridProps={{ style: { padding: '8px' } }} />
                <ScoreTitleDisplay gridSize={2} label={weightedEvalAvg} score={weightedEvalAvg} dark={true} gridProps={{ style: { padding: '8px' } }} />
            </Grid>

            {!loading
                ? (evaluations[evaluationIndex].evaluatorScores ?? [])
                      .filter((evaluationScore) => evaluationScore.successFactor.fpmCategory === fpmCategory)
                      .map((evaluationScore, index) => {
                          const name = evaluationScore.successFactor.name;
                          // Retrieve responder score
                          const sfId = `${evaluationScore.successFactor.id ?? ''}`;
                          let responderScore = null;
                          if (sfId && Object.keys(respScoreMap).includes(sfId)) {
                              responderScore = respScoreMap[sfId];
                          }

                          return (
                              <Grid container justify="space-around" key={name + index} style={{ padding: '8px' }}>
                                  <Grid item xs={8} style={{ padding: '0 8px' }}>
                                      <Typography variant="h3">{name}</Typography>
                                  </Grid>
                                  <Grid item xs={2} style={{ padding: '0 8px' }}>
                                      <Typography variant="body1">{responderScore}</Typography>
                                  </Grid>
                                  <Grid item xs={2} style={{ padding: '0 8px', textAlign: 'center' }}>
                                      <Typography variant="body1">{evaluationScore.score}</Typography>
                                  </Grid>
                              </Grid>
                          );
                      })
                : ''}
        </Box>
    );
};

export default EvaluationSection;
