import { Controller, RegisterOptions } from 'react-hook-form';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    country: {
        '& .MuiPhoneNumber-flagButton': {
            backgroundColor: 'transparent',
        },
    },
});

const ReactHookFormPhone: React.FC<ReactHookFormPhoneProps> = ({ name, label, control, defaultValue, rules, children, ...props }) => {
    const classes = useStyles();
    return (
        <Controller
            render={({ onChange, onBlur, value, name }, { invalid }) => (
                <MuiPhoneNumber
                    defaultCountry={'us'}
                    fullWidth
                    className={classes.country}
                    name={name}
                    label={label}
                    onBlur={onBlur}
                    placeholder="Enter Phone Number"
                    error={invalid}
                    value={value}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...props}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};
export interface ReactHookFormPhoneProps extends MuiPhoneNumberProps {
    name: string;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormPhone;
