import { Grid } from '@material-ui/core';
import React from 'react';
import { Typography } from '@material-ui/core';
import FormDivider from '../../../FormDivider';
import { ScoreTitleDisplay } from '../../../../ScoreDisplay';
import { GetResponseUsers_responseUsers_evaluatorScores } from '../../../../../apollo/generated/types/GetResponseUsers';

interface AllCommentsModalItemProps {
    scoreData: GetResponseUsers_responseUsers_evaluatorScores;
}

const AllCommentsModalItem: React.FC<AllCommentsModalItemProps> = ({ scoreData }) => {
    return (
        <Grid container style={{ margin: '20px 0px' }}>
            <ScoreTitleDisplay
                gridSize={10}
                label={scoreData.successFactor.name}
                score={scoreData.score.toFixed(1)}
                variant="h2"
                gridProps={{ style: { padding: '8px' } }}
            />
            <ScoreTitleDisplay
                gridSize={2}
                label={scoreData.score.toFixed(1)}
                score={scoreData.score.toFixed(1)}
                dark={true}
                variant="h2"
                lastCard={true}
                gridProps={{ style: { padding: '8px' } }}
            />
            <Grid item>
                <Typography variant="h3" style={{ padding: '8px 15px' }}>
                    {scoreData.comment}
                </Typography>
            </Grid>
            <FormDivider />
        </Grid>
    );
};

export default AllCommentsModalItem;
