/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { TabPanel, TabPanelProps } from '../../index';
import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableMeta, FilterType, MUIDataTableColumn } from 'mui-datatables';
import { ConvertResponseStatusEnumToDisplay, FormMode } from '../../../../../utils/Enums';
import CustomToolBar from '../CustomToolBar';
import { GetApp, PictureAsPdf } from '@material-ui/icons/';
import { ConfirmDialog, ErrorMessage, LoadingMessage, ScreenMessage, TableActionMenu, usePermissions } from '../../../../index';
import AssignedEvaluators, { AssignedEvaluatorsProps } from '../../../../TableComponents/AssignedEvaluators';
import { ResponseStatus, ResponseUserInputType, SubCycleStatus } from '../../../../../apollo/generated/types/globalTypes';
import ImportModal, { Topic } from '../ImportModal';
import EvaluatorsModal, { CycleRole, EvaluatorData, EvaluatorFormInput } from '../../../../Modals/EvaluatorModal';
import { GET_RESPONSES_TABLE, GET_RESPONSE_FILE_DOWNLOAD_LINKS, GET_RESPONSE_EVALUATORS, GET_SUB_CYCLE_EVALUATORS } from '../../../../../apollo/queries';
import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GetSubCycleEvaluators, GetSubCycleEvaluators_subCycleUsers } from '../../../../../apollo/generated/types/GetSubCycleEvaluators';
import {
    GetResponsesTable,
    GetResponsesTable_responses,
    GetResponsesTable_responses_assignedEvaluators,
    GetResponsesTable_cycle_subCycles,
    GetResponsesTable_responses_responseFiles,
    GetResponsesTableVariables,
    GetResponsesTable_responses_subCycle,
    GetResponsesTable_responses_topic,
} from '../../../../../apollo/generated/types/GetResponsesTable';
import { GetResponseEvaluators, GetResponseEvaluators_responseUsers } from '../../../../../apollo/generated/types/GetResponseEvaluators';
import { GetResponseFileDownloadLinks, GetResponseFileDownloadLinksVariables } from '../../../../../apollo/generated/types/GetResponseFileDownloadLinks';
import { downloadBlobFromStorage } from '../../../../../utils/file-storage-util';
import { ADD_RESPONSE_USERS, IMPORT_SELECTED_RESPONSES, REMOVE_RESPONSE_USERS } from '../../../../../apollo/mutations';
import { AddResponseUsers } from '../../../../../apollo/generated/types/AddResponseUsers';
import { RemoveResponseUsers } from '../../../../../apollo/generated/types/RemoveResponseUsers';
import { ImportSelectedResponses } from '../../../../../apollo/generated/types/ImportSelectedResponses';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import LockIcon from '@material-ui/icons/Lock';
import { Result } from '../../../../../utils/Enums/ResultEnum';
import { useCycleData } from '../../../../Providers/CycleDataProvider';
import { CycleSubTab, CycleTab } from '../../../../../utils/Enums/TabEnum';

export type InProcessResponsesTabPanelProps = TabPanelProps;

interface URLParams {
    id: string; // Acquisition Cycle ID
}

// Used for mapping response and sub-cycle users to EvaluatorData more easily
interface SubCycleResponseUser {
    user: EvaluatorData;
    cycleRole: CycleRole;
}

// Used for mapping Subcycle Form Research & Development categories
interface RDCategory {
    category: {
        name: string;
    };
}

interface ResponseCounts {
    completed: number;
    inProgress: number;
    notStarted: number;
    count: number;
}

interface SubCycle {
    id: number;
    name: string;
    status: SubCycleStatus;
}

const useStyles = makeStyles({
    responseId: {
        background: 'rgba(14, 174, 121, 0.5)',
        fontWeight: 'bold',
        borderRadius: '5px',
    },
    categories: {
        maxWidth: ' 8rem',
    },
    documentLink: {
        display: 'flex',
        alignItems: 'center',
    },
    singleDocument: {
        'overflow': 'hidden',
        'maxWidth': '100%',
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            color: 'mediumblue',
            cursor: 'pointer',
        },
    },
    statusComplete: {
        color: 'rgb(14, 174, 121)',
    },
    statusProgress: {
        color: 'rgb(237, 172, 67)',
    },
    statusNotStarted: {
        color: 'rgb(237, 85, 49)',
    },
});

const InProcessResponsesTabPanel: React.FC<InProcessResponsesTabPanelProps> = ({ index, value, ...other }) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const [responses, setResponses] = useState<GetResponsesTable_responses[] | null>(null);
    const cycleId = parseInt(id);
    const { readOnly: cycleReadOnly } = useCycleData();
    const { user: currentUser, isEvaluator, isTeamLead, isSystemProgramManagement } = usePermissions();

    // Import modal fields
    const [importModal, setImportModal] = useState<boolean>(false);
    const [importMode, setImportMode] = useState<FormMode>(FormMode.Create);
    const [cycleName, setCycleName] = useState<string>();
    const [activeSubCycle, setActiveSubCycle] = useState<GetResponsesTable_cycle_subCycles>();
    const [topics, setTopics] = useState<Topic[]>();
    const [researchDevelopmentCategories, setResearchDevelopmentCategories] = useState<any[]>();
    const [selectedResponseImport, setSelectedResponseImport] = useState<GetResponsesTable_responses>();
    // Assign/Unassign Modal fields
    const [evaluatorChoices, setEvaluatorChoices] = useState<EvaluatorData[] | null>(null);
    const [showEvaluatorModal, setShowEvaluatorModal] = useState<boolean>(false);
    const [evalModalMode, setEvalModalMode] = useState<'assign' | 'unassign' | null>(null);
    const [selectedResponseId, setSelectedResponseId] = useState<number | null>(null);
    const [openNoEval, setOpenNoEval] = useState<boolean>(false);
    // Import Selected Responses vars
    const [importResult, setImportResult] = useState<Result | null>(null);
    const [importResultTitle, setImportResultTitle] = useState<string | null>(null);
    const [importResultMsg, setImportResultMsg] = useState<string | null>(null);

    const resetState = () => {
        // Assign/Unassign modal state values
        setEvaluatorChoices(null);
        setSelectedResponseId(null);
        setEvalModalMode(null);
        setShowEvaluatorModal(false);
        // Reset Import Result vars
        setImportResult(null);
        setImportResultTitle(null);
        setImportResultMsg(null);
    };

    //// Loads currently Active Sub-Cycle and Responses ////
    const { loading: responseTableLoading, error: responseTableError, refetch: refetchResponses } = useQuery<GetResponsesTable, GetResponsesTableVariables>(
        GET_RESPONSES_TABLE,
        {
            variables: {
                cycleId: cycleId,
            },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data: GetResponsesTable) => {
                if (data && data.cycle && data.cycle.subCycles.length > 0) {
                    // Isolate Incomplete responses
                    const inProgressResponses = data?.responses?.filter(
                        (response) =>
                            response.subCycleId === data.cycle?.subCycles[0].id &&
                            !response.assignedEvaluators.some(
                                (evaluation) =>
                                    evaluation.isTeamLead && (evaluation.status === ResponseStatus.COMPLETE || evaluation.status === ResponseStatus.LOCKED),
                            ),
                    );
                    setResponses(inProgressResponses ?? []);
                    setCycleName(data.cycle?.title);
                    if (data.cycle?.subCycles && data.cycle?.subCycles.length > 0) {
                        const tempActiveSubCycle = data.cycle?.subCycles[0];
                        setActiveSubCycle(tempActiveSubCycle);
                        setTopics(tempActiveSubCycle.form?.topics.map((rdc) => ({ id: rdc.id, name: rdc.name } as Topic)));
                        setResearchDevelopmentCategories(tempActiveSubCycle.form?.researchDevelopmentCategories ?? []);
                    }
                } else {
                    console.log('Error retrieving Active Sub-Cycle/Response data');
                }
            },
            onError: (err: ApolloError) => {
                if (err.graphQLErrors) {
                    const hasAuthenticationError = err.graphQLErrors.some(
                        (e) => e.extensions?.code.toUpperCase() === 'AUTHORIZATION' || e.extensions?.code.toUpperCase() === 'AUTHENTICATION',
                    );
                    if (hasAuthenticationError) {
                        history.push('/acquisition-cycles');
                    }
                }
                console.error(err);
            },
        },
    );

    //// Handles Importing Previously-Selected Responses
    const [importResponses, { loading: importLoading }] = useMutation<ImportSelectedResponses>(IMPORT_SELECTED_RESPONSES, {
        variables: {
            cycleId: cycleId,
        },
        onCompleted: (data) => {
            if (data.importResponses && data.importResponses.length > 0) {
                setImportResult(Result.Success);
                setImportResultMsg('Responses imported successfully!');
            } else {
                setImportResult(Result.Warning);
                setImportResultTitle('No responses imported.');
                setImportResultMsg(
                    'Either there is no previously completed Sub-Cycle, ' +
                        'there are no selected responses from the previous Sub-Cycle, ' +
                        'or the previous responses have already been imported.',
                );
            }
        },
        onError: (e: ApolloError) => {
            setImportResult(Result.Error);
            setImportResultTitle('Selected Response Import Failed');
            setImportResultMsg(e.message);
        },
    });
    const handleImportResponses = () => {
        importResponses();
    };
    const handleCompleteImportResponses = () => {
        if (importResult && importResult === Result.Success) {
            refetchResponses();
        }
        resetState();
    };

    //// Handles Document Link downloads ////
    const [getFileDownloadLink] = useLazyQuery<GetResponseFileDownloadLinks, GetResponseFileDownloadLinksVariables>(GET_RESPONSE_FILE_DOWNLOAD_LINKS, {
        fetchPolicy: 'network-only',
        onCompleted: (result) => {
            const selfEvalFile = result.response.selfEvaluationFile;
            if (selfEvalFile) {
                downloadBlobFromStorage(selfEvalFile.fileName, selfEvalFile.url);
            }

            const responseFiles = result.response.responseFiles;
            if (responseFiles) {
                responseFiles.forEach((file) => downloadBlobFromStorage(file.fileName, file.url));
            }
        },
        onError: (error: ApolloError) => {
            console.log(error.message);
        },
    });
    const handleDocumentClick = (responseId?: number) => {
        if (responseId) {
            getFileDownloadLink({
                variables: {
                    responseId: responseId,
                },
            });
        }
    };

    //// Import Modal Functions ////
    const handleImportModalOpen = (mode: FormMode) => {
        setImportMode(mode);
        setImportModal(true);
    };
    const handleImportModalClose = () => {
        setImportModal(false);
        refetchResponses();
        setSelectedResponseImport(undefined);
    };
    const handleEditImport = (currResponse: GetResponsesTable_responses | null) => {
        if (currResponse) {
            setSelectedResponseImport(currResponse);
            handleImportModalOpen(FormMode.Edit);
        }
    };

    //// Assign/Unassign Modal functions ////
    // Parses subCycleUser data
    const parseSubCycleData = (cycleUsers: GetSubCycleEvaluators_subCycleUsers[] | GetResponseEvaluators_responseUsers[] | null): EvaluatorData[] | null => {
        return cycleUsers
            ? (cycleUsers as SubCycleResponseUser[]).map((cycleUser: SubCycleResponseUser) => {
                  return { ...cycleUser.user, cycleRole: cycleUser.cycleRole } as EvaluatorData;
              })
            : null;
    };

    // Loads data for assigning evaluators to responses
    const [getAssignData] = useLazyQuery<GetSubCycleEvaluators>(GET_SUB_CYCLE_EVALUATORS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetSubCycleEvaluators) => {
            const users = data?.subCycleUsers;
            if (users === null || users.length === 0) {
                setOpenNoEval(true);
            } else {
                // ensure only evaluators can be assigned to responses
                setEvaluatorChoices(parseSubCycleData(data.subCycleUsers?.filter((user) => user.cycleRole?.name.toLowerCase() === 'evaluator') ?? null));
                setEvalModalMode('assign');
                setShowEvaluatorModal(true);
            }
        },
    });
    // Loads data for removing evaluators from responses
    const [getUnassignData] = useLazyQuery<GetResponseEvaluators>(GET_RESPONSE_EVALUATORS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetResponseEvaluators) => {
            setEvaluatorChoices(parseSubCycleData(data.responseUsers));
            setEvalModalMode('unassign');
            setShowEvaluatorModal(true);
        },
    });
    // Assign/Unassign Modal Open/Close functions
    const handleAssignModalClick = (_: number, index: number) => {
        if (responses) {
            const response = responses[index];
            setSelectedResponseId(response.id);
            getAssignData({
                variables: {
                    subCycleId: response.subCycle.id,
                    excludeResponseId: response.id,
                },
            });
        }
    };
    const handleUnassignModalClick = (_: number, index: number) => {
        if (responses) {
            const response = responses[index];
            setSelectedResponseId(response.id);
            getUnassignData({
                variables: {
                    responseId: response.id,
                },
            });
        }
    };
    const handleEvaluatorClose = () => {
        refetchResponses();
        resetState();
    };
    // Performs provided mutations
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const evalMutationHelper = async (data: EvaluatorFormInput, evalMutation: any): Promise<ApolloError | null> => {
        try {
            const assignUsers = data.evaluators
                .filter((user) => user.selected)
                .map(
                    (user) =>
                        ({
                            userId: user.userId,
                            responseId: selectedResponseId,
                        } as ResponseUserInputType),
                );
            await evalMutation({
                variables: {
                    responseUsers: assignUsers,
                },
            });

            return null;
        } catch (e) {
            return e;
        }
    };
    const [addResponseUsers, { loading: assignLoading }] = useMutation<AddResponseUsers>(ADD_RESPONSE_USERS);
    const onAssign = async (data: EvaluatorFormInput): Promise<ApolloError | null> => {
        return await evalMutationHelper(data, addResponseUsers);
    };
    const [removeResponseUsers, { loading: unassignLoading }] = useMutation<RemoveResponseUsers>(REMOVE_RESPONSE_USERS);
    const onUnassign = async (data: EvaluatorFormInput): Promise<ApolloError | null> => {
        return await evalMutationHelper(data, removeResponseUsers);
    };

    // Score click handler
    const handleEvaluatorScoreClick = (responseId: number) => {
        history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.InProcessResponses}/${responseId}/evaluate`);
    };

    const handleEvaluationsClick = (responseId: number) => {
        history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.InProcessResponses}/${responseId}/evaluations`);
    };

    // Joins a list of strings into a comma-separated list
    const joinStringList = (list: string[]): string => {
        return list.length >= 2 && list[1].split(' ').length > 2
            ? `${list[0]}, ${list[1].split(' ')[0]}...`
            : list.length > 2
            ? `${list[0]}, ${list[1]}...`
            : list.length === 2
            ? `${list[0]}, ${list[1]}`
            : list.toString();
    };
    // Column definition
    const columns = [
        {
            name: 'id',
            label: 'Response Id',
            options: {
                display: true,
                sort: true,
                filter: false,
                filterType: 'textField' as FilterType,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ style: { minWidth: '110px' } }),
                customBodyRender: (value: string) => {
                    return (
                        <p style={{ padding: '0 10px', textAlign: 'center' }} className={classes.responseId}>
                            {value}
                        </p>
                    );
                },
            },
        },
        {
            name: 'title',
            label: 'Response Title',
            options: {
                display: true,
                sort: true,
                filter: false,
                setCellHeaderProps: () => ({ style: { minWidth: '125px' } }),
                filterType: 'textField' as FilterType,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'organization.name',
            label: 'Organization',
            options: {
                display: true,
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterOptions: {
                    fullWidth: true,
                },
            },
        },
        {
            name: 'subCycle',
            label: 'Sub-Cycle',
            options: {
                display: true,
                sort: true,
                filter: true,
                filterType: 'custom' as FilterType,
                sortThirdClickReset: true,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (subCycle: string) => subCycle,
                    logic: (location, filters) => {
                        if (filters.length) return !filters.includes(location);
                        return false;
                    },
                    display: (
                        filterList: string[][],
                        onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
                        index: number,
                        column: MUIDataTableColumn,
                    ) => {
                        let options = [] as SubCycle[];
                        if (responses) {
                            options = responses
                                .map((response) => response.subCycle as SubCycle)
                                .filter(
                                    (subCycle: SubCycle, index: number, self: SubCycle[]) =>
                                        index === self.findIndex((t) => t && subCycle && t.id === subCycle.id),
                                );
                        }
                        return (
                            <FormControl>
                                <InputLabel htmlFor="sub-cycle-filter-select">Sub-Cycle</InputLabel>
                                <Select
                                    id="sub-cycle-filter-select"
                                    value={filterList[index].length ? filterList[index].toString() : 'All'}
                                    onChange={(event) => {
                                        const value = event.target.value === 'All' ? [] : [event.target.value as string];
                                        onChange(value, index, column);
                                    }}>
                                    <MenuItem key={0} value="All">
                                        All
                                    </MenuItem>
                                    {options.map((option: SubCycle) => (
                                        <MenuItem key={option.id} value={option?.name || ''} style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <div style={{ flex: 1 }}>{option.name}</div>
                                                {option.status === SubCycleStatus.COMPLETE ? <LockIcon /> : null}
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    },
                },
                customBodyRender: (subCycle: GetResponsesTable_responses_subCycle) => {
                    return subCycle.name;
                },
            },
        },
        {
            name: 'topic.name',
            label: 'Topic',
            options: {
                display: true,
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterOptions: {
                    fullWidth: true,
                },
            },
        },
        {
            name: 'subCycle.form.researchDevelopmentCategories',
            label: 'Category',
            options: {
                display: true,
                sort: false,
                filter: false,
                sortThirdClickReset: true,
                customBodyRender: (value: RDCategory[]) => {
                    const categories = value.map((cat) => cat.category.name);
                    const toolTipText = categories.length >= 2 ? categories.join(', ') : false;
                    const cellData = joinStringList(categories);
                    return (
                        <>
                            {toolTipText ? (
                                <Tooltip title={toolTipText}>
                                    <p className={classes.categories}>{cellData}</p>
                                </Tooltip>
                            ) : (
                                <p className={classes.categories}>{cellData}</p>
                            )}
                        </>
                    );
                },
            },
        },
        {
            name: 'topic',
            label: 'Technical Group',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                customBodyRender: (topic: GetResponsesTable_responses_topic) => {
                    const categories = (topic?.categories ?? []).map((cat) => cat.name);
                    const toolTipText = categories.length > 2 ? categories.join(', ') : false;
                    const cellData = joinStringList(categories);
                    return (
                        <>
                            {toolTipText ? (
                                <Tooltip title={toolTipText}>
                                    <p className={classes.categories}>{cellData}</p>
                                </Tooltip>
                            ) : (
                                <p className={classes.categories}>{cellData}</p>
                            )}
                        </>
                    );
                },
            },
        },
        {
            name: 'responseFiles',
            label: 'Document Link',
            options: {
                display: true,
                sort: false,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ style: { width: '150px' } }),
                customBodyRender: (value: GetResponsesTable_responses_responseFiles[], tableMeta: MUIDataTableMeta) => {
                    const responseId = tableMeta.rowData[0]; // first data point should be the Response ID
                    const response = responses?.find((response) => response.id === responseId) ?? null;
                    const files = value.slice(); // shallow copy of file list
                    // Add self evaluation file
                    if (response) {
                        const selfEvalFile = response.selfEvaluationFile;
                        if (selfEvalFile) files.unshift(selfEvalFile);
                    }
                    const fileNames = files.map((file) => file.fileName);
                    // Construct download links
                    return fileNames.length > 1 ? (
                        <Tooltip title={`${fileNames.join(', ')}`}>
                            <div className={classes.documentLink}>
                                <Button
                                    onClick={() => handleDocumentClick(response?.id)}
                                    startIcon={<GetApp style={{ color: '#000000' }} />}
                                    variant="outlined">
                                    <Typography>{fileNames.length} Files</Typography>
                                </Button>
                            </div>
                        </Tooltip>
                    ) : (
                        <div className={classes.documentLink}>
                            <PictureAsPdf style={{ color: '#b72b1f' }} />
                            <div className={classes.singleDocument} onClick={() => handleDocumentClick(response?.id)}>
                                <span style={{ marginLeft: '5px' }}>{fileNames[0]}</span>{' '}
                            </div>
                        </div>
                    );
                },
            },
        },
        {
            name: 'assignedEvaluators',
            label: 'Assigned Evaluators',
            options: {
                display: true,
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterType: 'dropdown' as FilterType,
                filterOptions: {
                    fullWidth: true,
                    names: ['Yes', 'No'],
                    logic(value: string, filters: string[]) {
                        const assignedlength = value.length;
                        const show = (filters.indexOf('Yes') >= 0 && assignedlength >= 1) || (filters.indexOf('No') >= 0 && assignedlength === 0);
                        return !show;
                    },
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                sortCompare: (order: string) => (obj1: any, obj2: any) => (obj1.data.length - obj2.data.length) * (order === 'asc' ? 1 : -1),
                customBodyRender: (value: GetResponsesTable_responses_assignedEvaluators[]) => {
                    const evaluators = value
                        .filter((evaluator) => !evaluator.isTeamLead)
                        .map((evaluator) => ({ firstname: evaluator.user.firstName, lastname: evaluator.user.lastName } as AssignedEvaluatorsProps));
                    return value ? (
                        <Tooltip title={`${value.length} Assigned Evaluators`}>
                            <div>
                                <AssignedEvaluators evaluators={evaluators} />
                            </div>
                        </Tooltip>
                    ) : (
                        'None'
                    );
                },
            },
        },
        {
            name: 'assignedEvaluators',
            label: 'Status',
            options: {
                display: isEvaluator(cycleId),
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                customBodyRender: (value: GetResponsesTable_responses_assignedEvaluators[]) => {
                    const userResponse = value?.find((response: GetResponsesTable_responses_assignedEvaluators) => response.user.id === currentUser?.id);
                    return (
                        <Typography className={status} align="center">
                            {ConvertResponseStatusEnumToDisplay(userResponse?.status || '')}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'assignedEvaluators',
            label: 'Completed Evaluations',
            options: {
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                display: isSystemProgramManagement() || isTeamLead(cycleId),
                customBodyRender: (value: GetResponsesTable_responses_assignedEvaluators[]) => {
                    const results = value.reduce(
                        (totals: ResponseCounts, response: GetResponsesTable_responses_assignedEvaluators) => {
                            if (!response.isTeamLead) {
                                switch (response.status) {
                                    case ResponseStatus.COMPLETE:
                                        totals.completed++;
                                        break;
                                    case ResponseStatus.IN_PROGRESS:
                                        totals.inProgress++;
                                        break;
                                    case ResponseStatus.NOT_STARTED:
                                        totals.notStarted++;
                                        break;
                                }
                                totals.count++;
                            }
                            return totals;
                        },
                        {
                            completed: 0,
                            inProgress: 0,
                            notStarted: 0,
                            count: 0,
                        } as ResponseCounts,
                    );
                    const status: string =
                        results.completed === results.count
                            ? classes.statusComplete
                            : results.inProgress > 0
                            ? classes.statusProgress
                            : classes.statusNotStarted;
                    return (
                        <Typography className={status} align="center">
                            {results.completed} of {results.count}
                        </Typography>
                    );
                },
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                display: true,
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex: number, _rowIndex: number) => {
                    const response = responses ? responses[dataIndex] : null;
                    const assignedEvaluatorsCount = response?.assignedEvaluators?.length ?? 0;
                    const hasTopic = !!response?.topic;
                    const currSubCycle = response?.subCycle;
                    const currSubCycleActive = currSubCycle?.status === SubCycleStatus.OUT_FOR_RESPONSE;
                    const showEvalScore = hasTopic && response?.assignedEvaluators.some((evaluator) => evaluator.user.id === currentUser?.id);
                    return (
                        <TableActionMenu
                            id={response?.id ?? dataIndex}
                            index={dataIndex}
                            showView={hasTopic && assignedEvaluatorsCount > 0 && (isEvaluator(cycleId) || isTeamLead(cycleId) || isSystemProgramManagement())} // only show "View Evaluations" if there are assigned evaluators
                            showEdit={!cycleReadOnly && currSubCycleActive && isSystemProgramManagement()}
                            showAssign={!cycleReadOnly && hasTopic && currSubCycleActive && (isTeamLead(cycleId) || isSystemProgramManagement())}
                            showUnassign={
                                !cycleReadOnly && currSubCycleActive && (isTeamLead(cycleId) || isSystemProgramManagement()) && assignedEvaluatorsCount > 0
                            } // only show unassign if there are assigned evaluators
                            showEvaluatorScore={!cycleReadOnly && showEvalScore}
                            viewText="View Evaluations"
                            editText="Edit Response"
                            assignText="Assign Evaluators"
                            unassignText="Unassign Evaluators"
                            onViewClick={handleEvaluationsClick}
                            onEditClick={() => handleEditImport(response)}
                            onAssignClick={handleAssignModalClick}
                            onUnassignClick={handleUnassignModalClick}
                            onEvaluatorScoreClick={
                                response
                                    ? () => {
                                          handleEvaluatorScoreClick(response?.id);
                                      }
                                    : undefined
                            }
                        />
                    );
                },
            },
        },
    ];
    // Table options definition
    const options = {
        enableNestedDataAccess: '.',
        responsive: 'simple',
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRowsHeader: false,
        selectableRows: 'none',
        onRowsDelete: () => false,
        selectToolbarPlacement: 'above',
        pagination: false,
        customToolbar: () => (
            <CustomToolBar
                openModal={() => handleImportModalOpen(FormMode.Create)}
                importResponses={handleImportResponses}
                importDisabled={cycleReadOnly || !isSystemProgramManagement()}
                importSelectedDisabled={cycleReadOnly || !activeSubCycle || !isSystemProgramManagement() || !activeSubCycle.canImportSelectedResponses}
                loading={importLoading}
            />
        ),
        customToolbarSelect: (selectedRows) => console.log('click!', selectedRows),
    } as MUIDataTableOptions;

    return (
        <TabPanel index={index} value={value} {...other}>
            {responseTableLoading ? (
                <LoadingMessage />
            ) : responseTableError ? (
                <ErrorMessage error={responseTableError} />
            ) : responses ? (
                <>
                    <MUIDataTable title="" columns={columns} data={responses} options={options} />
                    <ImportModal
                        open={importModal}
                        cycleName={cycleName}
                        activeSubCycle={activeSubCycle}
                        topics={topics}
                        close={handleImportModalClose}
                        mode={importMode}
                        response={selectedResponseImport}
                        researchDevelopmentCategories={researchDevelopmentCategories}
                    />
                    <EvaluatorsModal
                        numEvaluators={0}
                        evaluatorLimit={evaluatorChoices?.length}
                        roleRequired={false}
                        open={showEvaluatorModal}
                        loading={assignLoading || unassignLoading}
                        evaluators={evaluatorChoices}
                        title={evalModalMode === 'assign' ? 'Assign to Response' : 'Unassign from Response'}
                        primaryBtnLabel={evalModalMode === 'assign' ? 'Assign' : 'Unassign'}
                        submit={evalModalMode === 'assign' ? onAssign : onUnassign}
                        onClose={handleEvaluatorClose}
                    />

                    <ConfirmDialog
                        open={openNoEval}
                        icon={<WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />}
                        title="Unable to assign evaluators"
                        message="There are currently no evaluators associated with this active Sub-Cycle. Evaluators must be added before any can be assigned to a Response."
                        showSecondary={false}
                        primaryText="Ok"
                        onPrimaryClick={() => setOpenNoEval(false)}
                        onClose={() => setOpenNoEval(false)}
                    />

                    <ConfirmDialog
                        open={!!importResult && !!importResultMsg}
                        icon={
                            importResult === Result.Success ? (
                                <CheckCircleIcon style={{ color: '#0EAE79', fontSize: 80 }} />
                            ) : importResult === Result.Warning ? (
                                <WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />
                            ) : (
                                <ErrorIcon style={{ color: '#ED5531', fontSize: 80 }} />
                            )
                        }
                        title={importResultTitle ?? undefined}
                        message={importResultMsg}
                        showSecondary={false}
                        primaryText="Ok"
                        onPrimaryClick={handleCompleteImportResponses}
                        onClose={handleCompleteImportResponses}
                    />
                </>
            ) : (
                <ScreenMessage header="Welcome!" subHeader="It seems there is no data for Response Management to load">
                    <Typography> Please contact an admin </Typography>
                </ScreenMessage>
            )}
        </TabPanel>
    );
};

export default InProcessResponsesTabPanel;
