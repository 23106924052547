import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { TabPanel } from '../index';
import { Paper, Tooltip, Typography } from '@material-ui/core';
import MUIDataTable, { MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import { ApolloError, NetworkStatus, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_CYCLE_EVALUATORS, GET_SUB_CYCLES_FULL, GET_SUB_CYCLE_EVALUATORS } from '../../../../apollo/queries';
import { ConfirmDialog, ErrorMessage, LoadingMessage, TableActionMenu, usePermissions } from '../../../index';
import { SubCycleWithEvaluatorsFields, SubCycleWithEvaluatorsFields_assignedEvaluators } from '../../../../apollo/generated/types/SubCycleWithEvaluatorsFields';
import { GetSubCyclesFull, GetSubCyclesFullVariables } from '../../../../apollo/generated/types/GetSubCyclesFull';
import { ADD_SUB_CYCLE_USERS, REMOVE_SUB_CYCLE_USERS, UPDATE_SUB_CYCLE_STATUS } from '../../../../apollo/mutations';
import { UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables } from '../../../../apollo/generated/types/UpdateSubCycleFormStatus';
import WarningIcon from '@material-ui/icons/Warning';
import { SubCycleForm, SubCycleStatus, SubCycleUserInputType, SuccessFactorStatus } from '../../../../apollo/generated/types/globalTypes';
import { ConvertFormTypeEnumToDisplay, getSubCycleStatusDisplayName } from '../../../../utils/Enums';
import EvaluatorsModal, { CycleRole, EvaluatorData, EvaluatorFormInput } from '../../../Modals/EvaluatorModal';
import { GetCycleEvaluators, GetCycleEvaluators_cycleUsers } from '../../../../apollo/generated/types/GetCycleEvaluators';
import { GetSubCycleEvaluators, GetSubCycleEvaluators_subCycleUsers } from '../../../../apollo/generated/types/GetSubCycleEvaluators';
import { AddSubCycleUsers } from '../../../../apollo/generated/types/AddSubCycleUsers';
import { RemoveSubCycleUsers } from '../../../../apollo/generated/types/RemoveSubCycleUsers';
import AssignedEvaluators, { AssignedEvaluatorsProps } from '../../../TableComponents/AssignedEvaluators';
import { useSnackbar } from 'notistack';
import { useCycleData } from '../../../Providers/CycleDataProvider';
import { CycleTab } from '../../../../utils/Enums/TabEnum';

interface URLParams {
    id: string; // Acquisition Cycle ID
}

interface CycleSubCycleUser {
    user: EvaluatorData;
    cycleRole: CycleRole;
}

const SubCyclesTabPanel: React.FC = () => {
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const cycleId = parseInt(id);
    const { readOnly: cycleReadOnly } = useCycleData();
    const [subCycles, setSubCycles] = useState<Array<SubCycleWithEvaluatorsFields>>([]);
    const [updateParams, setUpdateParams] = useState<UpdateSubCycleFormStatusVariables | null>(null);
    const [prevSubCycle, setPrevSubCycle] = useState<SubCycleWithEvaluatorsFields | null>(null);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    // Data for Assign/Unassign Evaluator modal
    const [selectedSubCycleId, setSelectedSubCycleId] = useState<number | null>(null);
    const [showEvaluatorModal, setShowEvaluatorModal] = useState<boolean>(false);
    const [evalModalMode, setEvalModalMode] = useState<'assign' | 'unassign' | null>(null);
    const [evaluatorChoices, setEvaluatorChoices] = useState<EvaluatorData[] | null>(null);
    const [openNoEval, setOpenNoEval] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const { isSystemProgramManagement, isEvaluator, isTeamLead } = usePermissions();

    const resetState = () => {
        // Sub-Cycle status override dialog state values
        setUpdateParams(null);
        setPrevSubCycle(null);
        setShowConfirm(false);

        // Evaluator modal state values
        setSelectedSubCycleId(null);
        setEvaluatorChoices(null);
        setEvalModalMode(null);
        setShowEvaluatorModal(false);
    };

    const { loading, error, refetch, networkStatus } = useQuery<GetSubCyclesFull, GetSubCyclesFullVariables>(GET_SUB_CYCLES_FULL, {
        variables: {
            id: Number(id),
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetSubCyclesFull) => {
            if (data.subCycles) {
                setSubCycles(data.subCycles);
            } else {
                history.push('/acquisition-cycles');
            }
        },
    });
    /// Sub-Cycle status updates ///
    // Updates Sub-Cycle status and refetches results
    const [updateSubCycleStatus, { loading: updateLoading, error: updateError }] = useMutation<UpdateSubCycleFormStatus, UpdateSubCycleFormStatusVariables>(
        UPDATE_SUB_CYCLE_STATUS,
        {
            onCompleted: (_) => {
                refetch();
                resetState();
            },
            onError: (error: ApolloError) => {
                console.log(error.message);
                resetState();
            },
        },
    );

    // callback function for updating the status of the selected sub-cycle
    const handleStatusUpdate = useCallback(() => {
        if (updateParams) {
            try {
                updateSubCycleStatus({
                    variables: updateParams,
                });
            } catch (e) {
                console.log(e.message);
            }
        }
    }, [updateParams, updateSubCycleStatus]);

    /// Assign/Unassign Evaluators ///
    // Parses subCycleUser data
    const parseSubCycleData = (cycleUsers: GetCycleEvaluators_cycleUsers[] | GetSubCycleEvaluators_subCycleUsers[] | null): EvaluatorData[] | null => {
        return cycleUsers
            ? (cycleUsers as CycleSubCycleUser[]).map((cycleUser: CycleSubCycleUser) => {
                  return { ...cycleUser.user, cycleRole: cycleUser.cycleRole } as EvaluatorData;
              })
            : null;
    };

    // Load Evaluator data
    const [getAssignData] = useLazyQuery<GetCycleEvaluators>(GET_CYCLE_EVALUATORS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const users = parseSubCycleData(data?.cycleUsers);
            // open dialog to inform user that there are no evaluators added to the aq cycle
            if (users === null || users.length === 0) {
                setOpenNoEval(true);
            } else {
                setEvaluatorChoices(users);
                setEvalModalMode('assign');
                setShowEvaluatorModal(true);
            }
        },
    });
    const [getUnassignData] = useLazyQuery<GetSubCycleEvaluators>(GET_SUB_CYCLE_EVALUATORS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            const users = parseSubCycleData(data?.subCycleUsers);
            setEvaluatorChoices(users);
            setEvalModalMode('unassign');
            setShowEvaluatorModal(true);
        },
    });

    // Assign/Unassign Evaluators
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const evalMutationHelper = async (data: EvaluatorFormInput, evalMutation: any): Promise<ApolloError | null> => {
        try {
            const assignUsers = data.evaluators
                .filter((user) => user.selected)
                .map(
                    (user) =>
                        ({
                            userId: user.userId,
                            subCycleId: selectedSubCycleId,
                        } as SubCycleUserInputType),
                );
            await evalMutation({
                variables: {
                    subCycleUsers: assignUsers,
                },
            });

            return null;
        } catch (e) {
            return e;
        }
    };
    const [addSubCycleUsers, { loading: assignLoading }] = useMutation<AddSubCycleUsers>(ADD_SUB_CYCLE_USERS);
    const onAssign = async (data: EvaluatorFormInput): Promise<ApolloError | null> => {
        return await evalMutationHelper(data, addSubCycleUsers);
    };
    const [removeSubCycleUsers, { loading: unassignLoading }] = useMutation<RemoveSubCycleUsers>(REMOVE_SUB_CYCLE_USERS);
    const onUnassign = async (data: EvaluatorFormInput): Promise<ApolloError | null> => {
        return await evalMutationHelper(data, removeSubCycleUsers);
    };

    // Modal Open/Close functions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOpenAssign = (_: any, index: number) => {
        const subCycleId = subCycles[index].id;
        setSelectedSubCycleId(subCycleId);
        getAssignData({
            variables: {
                cycleId: id,
                excludeSubCycleId: subCycleId,
            },
        });
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOpenUnassign = (_: any, index: number) => {
        const subCycleId = subCycles[index].id;
        setSelectedSubCycleId(subCycleId);
        getUnassignData({ variables: { subCycleId: subCycleId } });
    };
    const handleEvaluatorClose = () => {
        refetch();
        resetState();
    };

    useEffect(() => {
        // call the update function if the parameters are set and the confirmation modal will not be shown
        if (updateParams && !showConfirm) {
            handleStatusUpdate();
        }
    }, [updateParams, showConfirm, handleStatusUpdate]);

    const columns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'formType',
            label: 'Form Type',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: SubCycleForm) => ConvertFormTypeEnumToDisplay(value),
            },
        },
        {
            name: 'postedDate',
            label: 'Posted Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'lastUpdatedDate',
            label: 'Last Updated Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'originalClosingDate',
            label: 'Original Closing Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'currentClosingDate',
            label: 'Current Closing Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'originalArchiveDate',
            label: 'Original Archive Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'archiveDate',
            label: 'Archive Date',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '135px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: string) => (value ? format(new Date(value), 'MM/dd/yyyy') : ''),
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: SubCycleStatus) => getSubCycleStatusDisplayName(value),
            },
        },
        {
            name: 'assignedEvaluators',
            label: 'Assigned Evaluators',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: SubCycleWithEvaluatorsFields_assignedEvaluators[]) => {
                    const evaluators = value.map(
                        (evaluator) => ({ firstname: evaluator.user?.firstName, lastname: evaluator.user?.lastName } as AssignedEvaluatorsProps),
                    );

                    return value ? (
                        <Tooltip title={`${value.length} Assigned Evaluators`}>
                            <div>
                                <AssignedEvaluators evaluators={evaluators} />
                            </div>
                        </Tooltip>
                    ) : (
                        'None'
                    );
                },
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ style: { textAlign: 'center', width: '50px' } }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => {
                    const currSubCycle = subCycles ? subCycles[tableMeta.rowIndex] : null;
                    const status = currSubCycle?.status;
                    const numAssignedEvals = currSubCycle?.assignedEvaluators?.length ?? null;
                    const isComplete = status === SubCycleStatus.COMPLETE;

                    return (
                        <TableActionMenu
                            id={value}
                            index={tableMeta.rowIndex}
                            busy={updateLoading}
                            showView={true}
                            showEdit={
                                isSystemProgramManagement() && !cycleReadOnly
                                    ? currSubCycle?.canBeSetActive || status === SubCycleStatus.OUT_FOR_RESPONSE
                                    : isTeamLead(cycleId) && !cycleReadOnly
                                    ? status === SubCycleStatus.OUT_FOR_RESPONSE
                                    : false
                            }
                            showDelete={false}
                            showAssign={!cycleReadOnly && !isComplete && (isSystemProgramManagement() || isTeamLead(cycleId) || !isEvaluator(cycleId))}
                            showUnassign={
                                !cycleReadOnly &&
                                !isComplete &&
                                !!numAssignedEvals &&
                                numAssignedEvals > 0 &&
                                (isSystemProgramManagement() || isTeamLead(cycleId) || !isEvaluator(cycleId))
                            } // only show unassign if there are assigned evaluators
                            editText={currSubCycle?.canBeSetActive && isSystemProgramManagement() ? 'Set as Out for Response' : 'Mark as Complete'}
                            onViewClick={(_, index) => {
                                if (subCycles) {
                                    const subCycle = subCycles[index];
                                    if (subCycle) {
                                        history.push(`/acquisition-cycle/${id}/sub-cycle/${subCycle.id}/form`);
                                    }
                                }
                            }}
                            onEditClick={(_, index) => {
                                if (currSubCycle) {
                                    if (currSubCycle.successFactorStatus !== SuccessFactorStatus.APPROVED) {
                                        enqueueSnackbar('The Success Factors must first be “Approved”', { variant: 'error' });
                                        return;
                                    }
                                    let newStatus = SubCycleStatus.OUT_FOR_RESPONSE;
                                    if (status === newStatus) {
                                        newStatus = SubCycleStatus.COMPLETE;
                                    }

                                    let showOverrideModal = false;
                                    // retrieve previous sub-cycle in case it's status will be overridden to "Complete"
                                    if (index > 0) {
                                        const prevSubCycle = subCycles[index - 1];
                                        setPrevSubCycle(prevSubCycle);
                                        showOverrideModal = prevSubCycle.status === SubCycleStatus.OUT_FOR_RESPONSE;
                                    }

                                    // show confirmation dialog if necessary and set update parameters
                                    if (showOverrideModal) {
                                        setShowConfirm(true);
                                    }
                                    setUpdateParams({ status: newStatus, id: currSubCycle.id } as UpdateSubCycleFormStatusVariables);
                                }
                            }}
                            onAssignClick={handleOpenAssign}
                            onUnassignClick={handleOpenUnassign}
                        />
                    );
                },
            },
        },
    ];

    const options = {
        search: false,
        sort: false,
        filter: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        pagination: false,
        selectableRows: 'none' as const,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onCellClick: (_: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number }) => {
            // ensure clicking outside of the table action menu doesn't redirect to the sub-cycle edit page
            if (subCycles && cellMeta.colIndex !== columns.length - 1) {
                const subCycle = subCycles[cellMeta.rowIndex];
                history.push(`/acquisition-cycle/${id}/sub-cycle/${subCycle.id}/form`);
            }
        },
    } as MUIDataTableOptions;

    return (
        <TabPanel index={CycleTab.SubCycle} value={CycleTab.SubCycle}>
            {loading && networkStatus !== NetworkStatus.refetch ? (
                <LoadingMessage />
            ) : error || updateError ? (
                <ErrorMessage error={error ?? updateError} />
            ) : (
                <Paper elevation={0}>
                    <MUIDataTable title={<Typography variant="h1">Sub-Cycles List</Typography>} data={subCycles} columns={columns} options={options} />
                    <ConfirmDialog
                        open={showConfirm && !!updateParams}
                        icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
                        title="Override Out for Response Sub-Cycle"
                        message={
                            <>
                                By proceeding to set this sub-cycle to Out for Response, the system will automatically mark the {prevSubCycle?.name + ' '}
                                as Completed. Are you sure you want to override the Out for Response sub-cycle?
                            </>
                        }
                        primaryText="Override"
                        secondaryText="Cancel"
                        primaryBusy={updateLoading}
                        onPrimaryClick={handleStatusUpdate}
                        onSecondaryClick={() => resetState()}
                        onClose={() => resetState()}
                    />
                </Paper>
            )}

            {/* Modals and Dialogs */}
            <EvaluatorsModal
                open={showEvaluatorModal}
                loading={assignLoading || unassignLoading}
                evaluators={evaluatorChoices}
                title={evalModalMode === 'assign' ? 'Assign to Sub-Cycle' : 'Unassign from Sub-Cycle'}
                primaryBtnLabel={evalModalMode === 'assign' ? 'Assign' : 'Unassign'}
                submit={evalModalMode === 'assign' ? onAssign : onUnassign}
                onClose={handleEvaluatorClose}
            />

            <ConfirmDialog
                open={openNoEval}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />}
                title="Unable to assign evaluators"
                message="There are currently no evaluators associated with this Acquisition Cycle. Evaluators must be added before any can be assigned to a Sub-Cycle."
                showSecondary={false}
                primaryText="Ok"
                onPrimaryClick={() => setOpenNoEval(false)}
                onClose={() => setOpenNoEval(false)}
            />
        </TabPanel>
    );
};

export default SubCyclesTabPanel;
