import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const PerformanceAndSchedule: React.FC<PerformanceAndSchedule> = ({ mode }) => {
    const { register, errors, watch } = useFormContext();
    const status = watch('status');
    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Performance & Schedule</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={6}>
                    <TextField
                        label={`Location / Place of  Performance${requiredAsterisk}`}
                        name="locationPlaceOfPerformance"
                        type="text"
                        inputRef={register({ required: required ? 'Location / Place of  Performance is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.locationPlaceOfPerformance}
                        helperText={errors.locationPlaceOfPerformance?.message}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Base Period (total number months)"
                        name="basePeriod"
                        type="number"
                        inputRef={register({
                            min: { value: 0, message: 'Value must be greater than 0' },
                            max: { value: 120, message: 'Value must be less than 120' },
                            valueAsNumber: true,
                        })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                            inputProps: { min: 0, max: 120, step: 1 },
                        }}
                        error={!!errors.basePeriod}
                        helperText={errors.basePeriod?.message}
                    />
                </Grid>
            </FormSection>
        </>
    );
};
export default PerformanceAndSchedule;

export interface PerformanceAndSchedule {
    mode: FormMode;
}
