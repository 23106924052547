import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { Grid, Typography, Paper, MenuItem, Button } from '@material-ui/core';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import {
    getScoreSectionsTypeFromDisplayName,
    getScoreSectionsTypeDisplayName,
    findFPMSection,
    fpmLabels,
    fpmsOrdered,
    ScoreSectionsType,
    FormMode,
} from '../../../../utils/Enums';
import FinalizeScoreSection from './FinalizeScoreSection';
import SaveIcon from '@material-ui/icons/Save';
import { LoadingButton } from '../../../Buttons';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider';
import { getFPMOrder, getFPMLabelsList } from '../../../../utils/Enums/Helpers/FpmCategories';
import { ResponseStatus } from '../../../../apollo/generated/types/globalTypes';
import { ScoreFormData } from '..';
import { usePermissions } from '../../..';
import { useHistory, useParams } from 'react-router-dom';
import { CycleSubTab, CycleTab } from '../../../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    container: {
        height: '80%',
        marginLeft: '20px',
        borderRadius: '8px 8px 0 0',
    },
    bold: {
        fontWeight: 600,
    },
    visible: {
        width: '100%',
    },
    hidden: {
        visibility: 'hidden',
        position: 'absolute',
    },
});
interface FinalizeScoreProps {
    mode?: FormMode;
    onSubmit: (data: ScoreFormData, newStatus?: ResponseStatus | undefined, finalize?: boolean) => Promise<void>;
    loading?: boolean;
    isHistoryView: boolean;
}

interface UrlParams {
    id: string;
}

const FinalizeScore: React.FC<FinalizeScoreProps> = ({ mode, onSubmit, loading = false, isHistoryView }) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<UrlParams>();
    const { user } = usePermissions();
    const { control, getValues, setValue, handleSubmit, watch, register } = useFormContext();
    const [section, setSection] = useState<ScoreSectionsType>(ScoreSectionsType.PRODUCT_TECHNOLOGY);
    const { teamLeadEvaluation, evaluations, hasBriefResponse, loading: scoreLoading } = useTeamLeadScore();
    const formLoading = loading || scoreLoading;

    const readOnly = mode === FormMode.View;
    const evaluationPrefix = `evaluations.0`;

    // handles changing the FPM category displayed
    const handleTemplateChange = (newTemplateId: string) => {
        const oldTemplateId = getValues('score-category');

        if (oldTemplateId !== newTemplateId) {
            setValue('score-category', newTemplateId);
            setSection(getScoreSectionsTypeFromDisplayName(newTemplateId));
        }
    };

    const getSections = !hasBriefResponse
        ? [ScoreSectionsType.PRODUCT_TECHNOLOGY, ScoreSectionsType.ORGANIZATION, ScoreSectionsType.APPLICATION]
        : [ScoreSectionsType.PRODUCT_TECHNOLOGY];

    // Construct labels to watch individual score values
    const fpms = hasBriefResponse ? fpmsOrdered.slice(0, fpmLabels[0].length) : fpmsOrdered;
    const scoreWatchList = fpms.flatMap((fpm) =>
        (teamLeadEvaluation?.evaluatorScores ?? [])
            .filter((score) => score.successFactor.fpmCategory === fpm)
            .map((_score, scoreIndex) => `evaluations[0].categories.${fpm}[${scoreIndex}].score`),
    );
    const data = watch(scoreWatchList);
    const status = watch('evaluations[0].status');
    const submitStatus = status && status !== ResponseStatus.LOCKED ? ResponseStatus.LOCKED : ResponseStatus.IN_PROGRESS;

    return (
        <>
            <Paper className={classes.container}>
                <FormSection>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h2">Score Finalization</Typography>
                        </Grid>
                        <Grid style={{ display: 'flex' }} item xs={12}>
                            <Typography variant="subtitle2" align="left" className={classes.bold}>
                                Based on <span style={{ textDecoration: 'underline' }}>{!scoreLoading ? evaluations.length : ''} Evaluations</span>
                            </Typography>
                            {!readOnly ? ( // Ensure that the scorer's name only appears if a team lead is on this page
                                <>
                                    <Typography variant="subtitle2" align="left" style={{ margin: '0px 10px' }} className={classes.bold}>
                                        <span style={{ opacity: '0.5' }}>|</span>
                                    </Typography>
                                    <Typography variant="subtitle2" align="left" className={classes.bold}>
                                        Scoring as <span style={{ textDecoration: 'underline' }}>{user?.fullName}</span>
                                    </Typography>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </FormSection>
                <FormDivider />
                <div>
                    {/* Hidden inputs */}
                    {teamLeadEvaluation ? <input name={`${evaluationPrefix}.id`} type="hidden" ref={register()} value={teamLeadEvaluation.id} /> : null}
                    {teamLeadEvaluation ? <input name={`${evaluationPrefix}.userId`} type="hidden" ref={register()} value={teamLeadEvaluation.userId} /> : null}
                    {status ? <input name={`${evaluationPrefix}.status`} type="hidden" ref={register()} value={status} /> : null}
                </div>
                <div style={{ padding: '26px 30px', height: '80%' }}>
                    <Grid container justify="space-between" style={{ marginBottom: '10px' }}>
                        <ReactHookFormSelect
                            selectProps={{ style: { margin: 0 }, disableUnderline: true }}
                            onSelectChange={(e) => handleTemplateChange(e.target.value as string)}
                            name="score-category"
                            control={control}
                            defaultValue={getScoreSectionsTypeDisplayName(ScoreSectionsType.PRODUCT_TECHNOLOGY)}>
                            {getSections.map((category, index) => (
                                <MenuItem key={index} value={getScoreSectionsTypeDisplayName(category)}>
                                    {getScoreSectionsTypeDisplayName(category)}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    </Grid>
                    <Grid container spacing={2} style={{ margin: 'auto', overflowY: 'scroll', maxHeight: '100%', width: '100%', height: 'auto' }}>
                        {getFPMLabelsList().map((category, fpmIndex) => {
                            return (
                                <div key={category + fpmIndex} className={findFPMSection(category) !== section ? classes.hidden : classes.visible}>
                                    <FinalizeScoreSection mode={mode} fpmLabel={category} fpmCategory={getFPMOrder()[fpmIndex]} />
                                </div>
                            );
                        })}
                    </Grid>
                </div>
            </Paper>
            <div
                style={{
                    background: '#ffffff',
                    marginLeft: '20px',
                    borderRadius: '0 0 8px 8px',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    overflowY: 'hidden',
                }}>
                <FormSection>
                    <Grid item xs={12}>
                        {!isHistoryView ? (
                            <>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="outlined"
                                    style={{ padding: '5px 15px', margin: '10px 0' }}
                                    pending={formLoading}
                                    disabled={readOnly || status === ResponseStatus.LOCKED}>
                                    <SaveIcon /> Save Progress
                                </LoadingButton>
                                <LoadingButton
                                    fullWidth
                                    pending={formLoading}
                                    disabled={readOnly || Object.values(data).some((score) => !score || Number.isNaN(score))}
                                    variant="contained"
                                    onClick={handleSubmit((data: ScoreFormData) => onSubmit(data, submitStatus, true))}
                                    style={{ padding: '5px 15px' }}>
                                    {status && status !== ResponseStatus.LOCKED ? 'Finalize Score' : 'Change Score and Unlock Evaluations'}
                                </LoadingButton>
                            </>
                        ) : (
                            <Button
                                fullWidth
                                onClick={() => history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.ResponseHistory}`)}
                                variant="contained"
                                style={{ padding: '5px 15px' }}>
                                Back to History
                            </Button>
                        )}
                    </Grid>
                </FormSection>
            </div>
        </>
    );
};

export default FinalizeScore;
