/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { DetailedHTMLProps, useState, useEffect } from 'react';
import { TabPanel } from '../../../index';
import { Box, Checkbox, Typography } from '@material-ui/core';
import { FormControlLabel } from '@material-ui/core';
import AwardManagementContainer from './AwardManagementContainer';
import FundingBar from './FundingBar';
import { useAwardManagement } from '../../../Providers';
import { SelectionStatus, ResponseStatus } from '../../../../apollo/generated/types/globalTypes';

interface TabPanelProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    index: number;
    value: number | string;
    data: any;
    fundingRange: number;
    rdCategory: any;
    readOnly: boolean;
}

const TabPanels: React.FC<TabPanelProps> = ({ index, value, data, fundingRange, rdCategory, readOnly, ...other }) => {
    const finalizedData = data.filter((i) => i.assignedEvaluators.some((x) => x.isTeamLead && x.status === ResponseStatus.LOCKED));
    const [tabData, setTabItems] = useState(finalizedData);

    const { handleSetCheckBox, initalizeCheckbox, checkBox, researchDevelopmentCategories } = useAwardManagement();

    // for multi selection functionality
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const fundingAwardedItems = tabData.filter((i) => i.selectedStatus === SelectionStatus.AWARD_SELECTED);

    let fundingAwardedItemsTotal = 0;
    fundingAwardedItems.forEach((i) => (fundingAwardedItemsTotal = fundingAwardedItemsTotal + i.fundingRequested));

    const selected = tabData.filter((i) => i.selectedStatus === SelectionStatus.AWARD_SELECTED).length;
    const rejected = tabData.filter((i) => i.selectedStatus === SelectionStatus.AWARD_REJECTED).length;
    const pending = tabData.filter((i) => i.selectedStatus === SelectionStatus.PENDING).length;

    useEffect(() => {
        // change false with saved boolean based on category id
        if (checkBox.length <= index) {
            initalizeCheckbox(rdCategory.selectionCompleted);
        }
    }, [data, initalizeCheckbox]);

    return (
        <TabPanel index={index} value={value} {...other}>
            <div style={{ padding: '20px 0 0 20px', display: 'flex', flexDirection: 'row-reverse' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!checkBox[index]}
                            disabled={readOnly}
                            onChange={() => handleSetCheckBox(!checkBox[index], index, rdCategory.id)}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Complete Research Category"
                />
            </div>
            <Typography style={{ padding: '20px 0 0 20px', fontWeight: 'bold' }}>Remaining Budget</Typography>
            <FundingBar fundingAwardedItemsTotal={fundingAwardedItemsTotal} fundingRange={fundingRange} />
            <Box>
                <AwardManagementContainer
                    title={`Awarded`}
                    count={selected}
                    finishedResponses={tabData}
                    setTabItems={setTabItems}
                    status={SelectionStatus.AWARD_SELECTED}
                    awardContainer={true}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    readOnly={readOnly}
                />
                <AwardManagementContainer
                    title={`Rejected`}
                    count={rejected}
                    finishedResponses={tabData}
                    setTabItems={setTabItems}
                    status={SelectionStatus.AWARD_REJECTED}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    readOnly={readOnly}
                />
                <AwardManagementContainer
                    title={`The Basket`}
                    count={pending}
                    finishedResponses={tabData}
                    setTabItems={setTabItems}
                    status={SelectionStatus.PENDING}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    readOnly={readOnly}
                />
            </Box>
        </TabPanel>
    );
};
export default TabPanels;
