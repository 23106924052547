import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { listTimeZones } from 'timezone-support';
import { format } from 'date-fns-tz';
import { Controller, RegisterOptions } from 'react-hook-form';
import { FormControlProps } from '@material-ui/core/FormControl';
import { DEFAULT_TIMEZONE, reorderTimeZoneList } from '../../utils/date-format';

const ReactHookFormTimezone: React.FC<ReactHookFormTimezoneProps> = ({
    name,
    label,
    control,
    defaultValue,
    helperText,
    readOnly,
    rules,
    children,
    ...formProps
}) => {
    const labelId = `${name}-label`;
    const options: string[] = React.useMemo(() => {
        const timezoneNames = listTimeZones();

        const orderedTimezoneNames = reorderTimeZoneList(
            timezoneNames.map((name) => {
                const offset = format(new Date(), 'zzz', { timeZone: name });
                return `${name} (${offset})`;
            }),
        );

        return orderedTimezoneNames;
    }, []);
    return (
        <FormControl {...formProps}>
            <InputLabel id={labelId} shrink>
                {label}
            </InputLabel>
            <Controller
                render={({ onChange, onBlur, value, name, ref }, { invalid }) => (
                    <Select
                        name={name}
                        inputRef={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value || ''}
                        labelId={labelId}
                        label={label}
                        error={invalid}
                        readOnly={readOnly}
                        defaultValue={DEFAULT_TIMEZONE}
                        placeholder="Timezone ...">
                        {options.map((timezone, index) => (
                            <MenuItem key={index} value={timezone}>
                                {timezone}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                rules={rules}
                name={name}
                control={control}
                defaultValue={defaultValue}
            />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};

interface ReactHookFormTimezoneProps extends FormControlProps {
    name: string;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: string | number | string[];
    helperText?: string;
    readOnly?: boolean;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormTimezone;
