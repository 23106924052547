import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { AutocompleteGetTagProps, AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LoadingButton } from '../index';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filter = createFilterOptions<any>();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ReactHookFormAutocomplete: React.FC<ReactHookFormAutocompleteProps<any>> = ({
    multiple,
    label,
    defaultValue,
    error,
    helperText,
    name,
    rules,
    addOption,
    options,
    renderTags,
    renderOption,
    getOptionSelected,
    getOptionLabel,
    getOptionValue,
    loading,
    readOnly,
    fixedOptions,
}) => {
    const { control, getValues, setValue } = useFormContext();
    const [open, toggleOpen] = useState(false);
    const [submitBusy, setSubmitBusy] = useState(false);
    const [createError, setCreateError] = useState<ApolloError | null>(null);
    const [dialogValue, setDialogValue] = useState({
        name: '',
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const convertFormValue = (value: any) => {
        return multiple
            ? options?.filter((option) => getOptionSelected(option, value)) || []
            : options?.find((option) => getOptionSelected(option, value)) || null;
    };

    /**
     * Handle Close of Add Option Popup
     */
    const handleClose = () => {
        setCreateError(null);
        setDialogValue({
            name: '',
        });
        toggleOpen(false);
    };

    /**
     * Handle Submit of Add Option Popup
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation(); // Prevent Parent form from submitting
        setSubmitBusy(true);
        setCreateError(null);
        try {
            const value = await addOption(dialogValue.name);
            if (multiple) {
                // Multi Select and value is a number[]
                const currentValues = getValues(name);
                setValue(name, [...currentValues, value.id]);
            } else {
                // Single Selected value and is a number
                setValue(name, value.id);
            }

            handleClose();
        } catch (e) {
            setCreateError(e);
        } finally {
            setSubmitBusy(false);
        }
    };

    return (
        <>
            <Controller
                rules={rules}
                name={name}
                control={control}
                defaultValue={defaultValue || null}
                render={({ onChange, onBlur, value: formValue, ref }) => (
                    <Autocomplete
                        ref={ref}
                        multiple={multiple}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        value={convertFormValue(formValue)}
                        forcePopupIcon={true}
                        onBlur={onBlur}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onChange={(_event, value: any | any[]) => {
                            if (Array.isArray(value)) {
                                let addNew = false;
                                value.forEach((val) => {
                                    if (val) {
                                        if (typeof val === 'string') {
                                            addNew = true;
                                            setTimeout(() => {
                                                toggleOpen(true);
                                                setDialogValue({
                                                    name: val,
                                                });
                                            });
                                        } else if (val.inputValue) {
                                            addNew = true;
                                            toggleOpen(true);
                                            setDialogValue({
                                                name: val.inputValue,
                                            });
                                        }
                                    }
                                });
                                if (!addNew) {
                                    if (fixedOptions) {
                                        fixedOptions.forEach((x) => value.push(x));
                                    }
                                    onChange(getOptionValue(value));
                                }
                            } else if (typeof value === 'string') {
                                // timeout to avoid instant validation of the dialog's form.
                                setTimeout(() => {
                                    toggleOpen(true);
                                    setDialogValue({
                                        name: value,
                                    });
                                });
                            } else if (value && value.inputValue) {
                                toggleOpen(true);
                                setDialogValue({
                                    name: value.inputValue,
                                });
                            } else {
                                onChange(getOptionValue(value));
                            }
                        }}
                        filterOptions={(options, params) => {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const filtered = filter(options, params) as any[];

                            // Add Option to add new Option
                            if (options.length === 0 && params.inputValue === '') {
                                filtered.push({
                                    disabled: true,
                                    name: `No filter found. Start typing to add new...`,
                                });
                            } else if (params.inputValue !== '') {
                                filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Add "${params.inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        options={options || []}
                        getOptionSelected={getOptionSelected}
                        getOptionDisabled={(option) => (!readOnly ? !!option.disabled : true)}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        renderTags={renderTags}
                        freeSolo
                        disableClearable={readOnly}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={label}
                                error={error}
                                disabled={loading}
                                helperText={helperText}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ ...params.InputProps, readOnly: readOnly }}
                            />
                        )}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby={`form-create-${name}-title`}>
                <form id={`form-create-${name}`} onSubmit={handleSubmit}>
                    <DialogTitle id={`form-create-${name}-title`}>
                        <Typography variant="h1">Add a new {label}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    id="name"
                                    fullWidth
                                    value={dialogValue.name}
                                    onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                                    label="Name"
                                    type="text"
                                    required={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            {createError ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {createError?.message}
                                    </Alert>
                                </Grid>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="text">
                            Cancel
                        </Button>
                        <LoadingButton pending={submitBusy} form={`form-create-${name}`} type="submit" variant="contained">
                            Add
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export interface ReactHookFormAutocompleteProps<T> {
    multiple?: true;
    name: string;
    label?: string;
    options?: T[];
    fixedOptions?: T[];
    defaultValue?: T;
    error?: boolean;
    helperText?: string;
    // Dont catch errors let this component catch them
    addOption: (name: string) => Promise<T>;
    getOptionSelected: (option: T, value: T) => boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getOptionValue: (option: T | null) => number | any[] | null;
    getOptionLabel: (option: T) => string;
    renderTags?: (value: T[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
    renderOption?: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    loading?: boolean;
    readOnly?: boolean;
}

export default ReactHookFormAutocomplete;
