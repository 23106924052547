import { Grid } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import FinalizeScoreItem from './FinalizeScoreItem';
import { useFormContext } from 'react-hook-form';
import { ScoreTitleDisplay } from '../../../ScoreDisplay';
import { FormMode } from '../../../../utils/Enums';
import { useTeamLeadScore } from '../../../Providers';

interface FinalizeScoreItemProps {
    fpmCategory: string;
    fpmLabel: string;
    mode?: FormMode;
}

const FinalizeScoreSection: React.FC<FinalizeScoreItemProps> = ({ fpmCategory, fpmLabel, mode }) => {
    // Default dynamic score data
    const [totalScore, setScore] = useState({ score: 0, weight: 0 });
    const { teamLeadEvaluation } = useTeamLeadScore();
    const { getValues } = useFormContext();

    // Maps weights with their associated success factors
    const weightMap = (teamLeadEvaluation?.evaluatorScores ?? []).reduce((weights, scoreItem) => {
        const sf = scoreItem.successFactor;
        weights[`${sf.id}`] = sf.weight;
        return weights;
    }, {});
    const handleAverageScore = useCallback(() => {
        const scoreData = getValues();
        let score = 0;
        let weight = 0;
        if (scoreData && scoreData.evaluations.length > 0 && scoreData.evaluations[0].categories[fpmCategory]) {
            const scores = scoreData.evaluations[0].categories[fpmCategory];
            scores.map((scoreItem) => {
                const weightVal = parseFloat(weightMap[`${scoreItem.successFactorId}`]);
                score = score + parseFloat(scoreItem.score) * weightVal;
                weight = weight + weightVal;
            });
            setScore({ score, weight });
        } else {
            setScore({ score: 0, weight: 0 });
        }
    }, [getValues, fpmCategory, weightMap]);

    let weightedScoreAvg = parseFloat((totalScore.score / totalScore.weight).toFixed(1));
    weightedScoreAvg = isNaN(weightedScoreAvg) ? 0 : weightedScoreAvg;

    return (
        <>
            <Grid container justify="space-between">
                <ScoreTitleDisplay gridSize={10} label={fpmLabel} score={weightedScoreAvg} gridProps={{ style: { padding: '8px' } }} />
                <ScoreTitleDisplay
                    gridSize={2}
                    label={weightedScoreAvg}
                    score={weightedScoreAvg}
                    dark={true}
                    variant="h2"
                    gridProps={{ style: { padding: '8px' } }}
                />
            </Grid>
            <Grid item xs={12}>
                {(teamLeadEvaluation?.evaluatorScores ?? [])
                    .filter((scoreData) => scoreData.successFactor.fpmCategory === fpmCategory)
                    .map((scoreData, index: number) => (
                        <FinalizeScoreItem
                            mode={mode}
                            key={index}
                            handleAverageScore={handleAverageScore}
                            index={index}
                            scoreData={scoreData}
                            fpmCategory={scoreData.successFactor.fpmCategory}
                        />
                    ))}
            </Grid>
        </>
    );
};

export default FinalizeScoreSection;
