import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import ReactHookFormTextArea from '../../../ReactHookForm/ReactHookFormTextArea';
import { useFormContext } from 'react-hook-form';

const ContentBuilderForm: React.FC<ContentBuilderFormProps> = ({ mode }) => {
    const { control, errors } = useFormContext();
    const readOnly = mode === FormMode.View;

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Content Builder</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Opportunity/Customer Description"
                        name="opportunityDescription"
                        error={!!errors.opportunityDescription}
                        helperText={errors.opportunityDescription?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Background/Overview"
                        name="backgroundOverview"
                        error={!!errors.backgroundOverview}
                        helperText={errors.backgroundOverview?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Additional Information"
                        name="additionalInformation"
                        error={!!errors.additionalInformation}
                        helperText={errors.additionalInformation?.message}
                        readOnly={readOnly}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface ContentBuilderFormProps {
    mode: FormMode;
}

export default ContentBuilderForm;
