import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    makeStyles,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MultipleFileDropZone, ReactHookFormAddress, ReactHookFormAutocomplete, ReactHookFormSelect, StaticField, useCommonForm } from '../../../index';
import { GetSubCycleForm_subCycle_cycle } from '../../../../apollo/generated/types/GetSubCycleForm';
import { ResearchDevelopmentCategoryInputType, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';
import { FormMode } from '../../../../utils/Enums';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';
import RDCategoryItem from '../../RFPForm/GeneralForm/RDCategoryItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
});

// REMOVE for functionality ticket
/* tslint:disable */
/* eslint-disable */
const GeneralForm: React.FC<GeneralFormProps> = ({ mode, subCycleId, cycleInfo }) => {
    const classes = useStyles();
    const { securityClassifications, researchDevelopmentCategories, loading: commonLoading, responderTypes, organizations, addOrganization } = useCommonForm();
    const { register, errors, control, watch } = useFormContext();

    const status = watch('status', SubCycleStatus.DRAFT);
    const { fields: rdList, append, remove } = useFieldArray<ResearchDevelopmentCategoryInputType, 'key'>({
        control,
        name: 'researchDevelopmentCategories', // unique name for your Field Array
        keyName: 'key',
    });
    const [categoryId, setCategoryId] = React.useState<number[]>([]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selected = event.target.value as number[];

        if (selected.length > rdList.length) {
            // Option Selected
            const addedId = selected.filter((id) => !rdList.find((item) => (Number(item.categoryId) || -1) === id));
            if (addedId && addedId.length > 0) {
                append({
                    categoryId: addedId[0],
                    fundingRange: null,
                });
            }
        } else {
            // Option Un-Selected
            for (let index = 0; index < rdList.length; index++) {
                const item = rdList[index];
                if (item.categoryId && selected.indexOf(Number(item.categoryId)) === -1) {
                    remove(index);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        setCategoryId(rdList ? rdList.map((item) => Number(item.categoryId) || -1) : []);
    }, [rdList]);

    const getName = (categoryId: number) => {
        const found = researchDevelopmentCategories?.find((option) => option.id === Number(categoryId));
        return found?.name || '';
    };
    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">General</Typography>
                </Grid>
            </FormSection>

            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <TextField
                        label={`Title${requiredAsterisk}`}
                        name="title"
                        inputRef={register({ required: required ? 'Title is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={`RFP ID Number/Solicitation Number${requiredAsterisk}`}
                        name="externalId"
                        inputRef={register({ required: required ? 'RFP ID Number/Solicitation Number is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.externalId}
                        helperText={errors.externalId?.message}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StaticField label="Selected Department" value={cycleInfo.department?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <StaticField label="Department Level II" value={cycleInfo.service?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <StaticField label="Department Level III" value={cycleInfo.subService?.name || 'N/A'} />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormSelect
                        fullWidth
                        control={control}
                        label="Eligible Responders"
                        name="eligibleResponderTypeId"
                        defaultValue={undefined}
                        loading={commonLoading}
                        error={!!errors.eligibleResponderTypeId}
                        helperText={errors.eligibleResponderTypeId?.message}
                        readOnly={readOnly}>
                        {responderTypes?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormSelect
                        fullWidth
                        control={control}
                        label={`Security Classification${requiredAsterisk}`}
                        name="securityClassificationId"
                        loading={commonLoading}
                        rules={{ required: required ? 'Security Classification is required' : false }}
                        error={!!errors.securityClassificationId}
                        helperText={errors.securityClassificationId?.message}
                        readOnly={readOnly}>
                        {securityClassifications?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormAutocomplete
                        label={`Issuing / Contracting Organization${requiredAsterisk}`}
                        name="issuingOrganizationId"
                        loading={commonLoading}
                        rules={{ required: required ? 'Issuing / Contracting Organization is required' : false }}
                        error={!!errors.issuingOrganizationId}
                        helperText={errors.issuingOrganizationId?.message}
                        options={organizations}
                        addOption={addOrganization}
                        getOptionSelected={(option: OptionFields, value?: number | OptionFields) => {
                            if (!value) {
                                return false;
                            }
                            if (typeof value === 'number') {
                                return option.id === value;
                            } else {
                                return option.id === value.id;
                            }
                        }}
                        getOptionValue={(option: OptionFields | null) => (option ? option?.id : null)}
                        getOptionLabel={(option: OptionFields) => option?.name || ''}
                        renderOption={(option: OptionFields) => option?.name}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ReactHookFormAddress mode={mode} name="contractOfficeAddress" label="Contracting Office Address" shrink={true} addTimeZone={false} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Building Number/Mailstop"
                        name="buildingNumberMailstop"
                        inputRef={register()}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        inputProps={{ maxLength: 6 }}
                        error={!!errors.buildingNumberMailstop}
                        helperText={errors.buildingNumberMailstop?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth={true} error={!!errors.rdCategoryLength && required}>
                        <InputLabel id="research-and-development-category-label" shrink>
                            Research & Development Category{requiredAsterisk}
                        </InputLabel>
                        <Select
                            multiple
                            input={<Input />}
                            labelId="research-and-development-category-label"
                            value={categoryId}
                            onChange={handleChange}
                            disabled={commonLoading}
                            error={!!errors.rdCategoryLength}
                            inputProps={{
                                readOnly: readOnly,
                            }}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {(selected as string[]).map((value, index) => {
                                        return (
                                            <Chip
                                                key={value}
                                                label={
                                                    researchDevelopmentCategories &&
                                                    researchDevelopmentCategories.find((e) => e.id === rdList[index]?.categoryId)
                                                        ? researchDevelopmentCategories.find((e) => e.id === rdList[index].categoryId)?.name
                                                        : ''
                                                }
                                                className={classes.chip}
                                                clickable
                                                deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                                                onDelete={() => (researchDevelopmentCategories ? remove(index) : console.log('cant delete'))}
                                            />
                                        );
                                    })}
                                </div>
                            )}>
                            {researchDevelopmentCategories?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.rdCategoryLength ? <FormHelperText error={true}>{errors.rdCategoryLength?.message}</FormHelperText> : null}
                    </FormControl>
                    {!rdList || (rdList.length === 0 && required) ? (
                        <>
                            <input
                                name="rdCategoryLength"
                                type="hidden"
                                ref={register({ validate: () => rdList.length > 0 || 'At least one Research & Development Category required' })}
                            />
                        </>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    {rdList.map((item, index) => {
                        if (item.categoryId) {
                            return (
                                <RDCategoryItem
                                    key={item.key}
                                    mode={mode}
                                    index={index}
                                    id={item.id}
                                    name={item.categoryId ? getName(item.categoryId) : ''}
                                    categoryId={Number(item.categoryId)}
                                    fundingRange={item.fundingRange}
                                    technologyReadinessLevelId={item.technologyReadinessLevelId}
                                />
                            );
                        } else {
                            return null;
                        }
                    })}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={`Recommended NAICS Code${requiredAsterisk}`}
                        name="naicsCode"
                        inputRef={register({
                            maxLength: { value: 6, message: 'Max length of 6 characters' },
                            minLength: { value: 2, message: 'Minimum length of 2 characters' },
                            required: required ? 'NAICS Code is required' : false,
                        })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        inputProps={{ maxLength: 6 }}
                        error={!!errors.naicsCode}
                        helperText={errors.naicsCode?.message}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="program"
                        label={`Program${requiredAsterisk}`}
                        inputRef={register({ required: required ? 'Program is required' : false })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            readOnly: readOnly,
                        }}
                        error={!!errors.program}
                        helperText={errors.program?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography variant="h3">Reference Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MultipleFileDropZone name="referenceFiles" subCycleId={subCycleId} readOnly={readOnly} />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </FormSection>
        </>
    );
};

export interface GeneralFormProps {
    mode: FormMode;
    subCycleId: number;
    cycleInfo: GetSubCycleForm_subCycle_cycle;
}

export default GeneralForm;
