import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    pageWrapper: {
        flex: 1,
        padding: '25px',
        overflowX: 'hidden',
    },
    pageWrapperHidden: {
        flex: 1,
        padding: '0px 25px 25px 25px',
        overflowX: 'hidden',
    },
});

const PageBody: React.FC<PageBodyProps> = ({ divRef, children, hiddenScroll }) => {
    const classes = useStyles();

    return (
        <>
            {hiddenScroll && <div style={{ background: '#F6F8FC', height: '25px' }}></div>}
            <div ref={divRef} className={hiddenScroll ? classes.pageWrapperHidden : classes.pageWrapper}>
                {children}
            </div>
        </>
    );
};

export interface PageBodyProps {
    divRef?: React.MutableRefObject<HTMLDivElement | null>;
    hiddenScroll?: boolean;
}

export default PageBody;
