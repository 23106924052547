import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles({
    a: {
        fill: 'none',
    },
    b: {
        fill: '#fff',
    },
    c: {
        isolation: 'isolate',
    },
    d: {
        isolation: 'isolate',
        opacity: '0.2',
    },
    e: {
        fill: '#fff',
        fontSize: '23px',
        fontFamily: "'Lato', sans-serif",
        fontWeight: 400,
        letterSpacing: '-0.015em',
    },
});

const NineHiLogo: React.FC = () => {
    const classes = useStyles();
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="70.001" height="60" viewBox="0 0 70.001 60">
            <g transform="translate(-94.999 -14)">
                <g transform="translate(95.439 12.85)">
                    <path className={classes.a} d="M34.56,1.15l35,60h-70Z" transform="translate(0)" />
                    <path className={classes.b} d="M34.56,7.453,5.079,57.993H64.041L34.56,7.453m0-6.3,35,60h-70Z" transform="translate(0 0)" />
                    <g className={classes.c} transform="translate(25.969 43.566)">
                        <g className={classes.c}>
                            <path
                                className={classes.b}
                                d="M281.574,441v11.055H279v-4.532h-5.064v4.532H271.36V441h2.577v4.357H279V441Z"
                                transform="translate(-271.36 -441)"
                            />
                            <path className={classes.b} d="M421.67,441h2.577v11.055H421.67Z" transform="translate(-407.066 -441)" />
                        </g>
                    </g>
                    <g className={classes.c} transform="translate(28.593 19.252)">
                        <g className={classes.c}>
                            <path
                                className={classes.b}
                                d="M308.216,190.631a7.168,7.168,0,0,1,1.652,5.082,8.522,8.522,0,0,1-.885,4.026,6,6,0,0,1-2.5,2.547,7.718,7.718,0,0,1-3.718.868,8.948,8.948,0,0,1-2.128-.247,5.709,5.709,0,0,1-1.73-.7l1.193-2.348a4.548,4.548,0,0,0,2.6.688,3.782,3.782,0,0,0,2.8-1.037,4.317,4.317,0,0,0,1.114-2.989,4.7,4.7,0,0,1-3.46,1.242,5.477,5.477,0,0,1-2.429-.532,4.24,4.24,0,0,1-1.728-1.505,4.054,4.054,0,0,1-.635-2.26,4.278,4.278,0,0,1,.7-2.427,4.523,4.523,0,0,1,1.9-1.609,6.233,6.233,0,0,1,2.674-.562A5.955,5.955,0,0,1,308.216,190.631ZM305.6,194.8a2.009,2.009,0,0,0,.02-2.971,2.6,2.6,0,0,0-1.78-.573,2.494,2.494,0,0,0-1.68.553,1.872,1.872,0,0,0-.646,1.5,1.9,1.9,0,0,0,.646,1.51,2.872,2.872,0,0,0,3.44-.019Z"
                                transform="translate(-298.372 -188.864)"
                            />
                        </g>
                    </g>
                    <g className={classes.d} transform="translate(22.23 37.252)">
                        <rect className={classes.b} width="24.708" height="3.158" />
                    </g>
                </g>
                <text className={classes.e} transform="translate(147 44)">
                    <tspan x="0" y="0">
                        ™
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export default NineHiLogo;
