import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import RedirectingPage from '../../pages/RedirectingPage';

interface ProtectedRouteProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, ...rest }) => {
    return (
        <Route
            {...rest}
            component={withAuthenticationRequired(component, {
                onRedirecting: () => <RedirectingPage />,
            })}
        />
    );
};
export default ProtectedRoute;
