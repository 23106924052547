import React, { useRef } from 'react';
import { AcquisitionCycleTabs, ErrorMessage, LoadingMessage, PageBody, PageHeader, PageWrapper, RFIForm, WhitePaperForm, RFPForm } from '../../../components';
import { NavLink, Redirect, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_SUB_CYCLE_FORM } from '../../../apollo/queries';
import { useHistory } from 'react-router';
import { GetSubCycleForm, GetSubCycleFormVariables } from '../../../apollo/generated/types/GetSubCycleForm';
import { Breadcrumbs, Toolbar, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { SubCycleForm } from '../../../apollo/generated/types/globalTypes';
import { CycleDataProvider } from '../../../components/Providers/CycleDataProvider';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
        padding: 0,
    },
    title: {
        height: '44px',
        marginLeft: '15px',
        alignItems: 'center',
        display: 'flex',
    },
});

interface URLParams {
    cycleId: string;
    subCycleId: string;
}

const SubCycleFormPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const wrapperRef = useRef<null | HTMLDivElement>(null);
    const { cycleId: id, subCycleId } = useParams<URLParams>();
    const cycleId = Number(id);

    const { error, loading, data } = useQuery<GetSubCycleForm, GetSubCycleFormVariables>(GET_SUB_CYCLE_FORM, {
        variables: {
            subCycleId: Number(subCycleId),
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetSubCycleForm) => {
            if (!data || !data.subCycle) {
                history.push('/acquisition-cycles');
            }
        },
    });

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <div>
                        <div className={classes.title}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link component={NavLink} to="/acquisition-cycles" color="inherit">
                                    Acquisition Cycles
                                </Link>
                                <Typography variant="h2">{data?.subCycle?.cycle?.title}</Typography>
                            </Breadcrumbs>
                        </div>
                        <div>
                            <AcquisitionCycleTabs id={cycleId} tab="sub-cycles" />
                        </div>
                    </div>
                </Toolbar>
            </PageHeader>
            <PageBody divRef={wrapperRef} hiddenScroll={true}>
                <CycleDataProvider id={cycleId}>
                    {loading ? (
                        <LoadingMessage />
                    ) : error ? (
                        <ErrorMessage error={error} />
                    ) : data &&
                    data.subCycle &&
                    data.subCycle.formType === SubCycleForm.REQUEST_FOR_WHITE_PAPER &&
                    (!data.subCycle.form || data.subCycle.form.__typename === 'RequestForWhitePaperFormType') ? (
                        <WhitePaperForm
                            cycleId={data.subCycle.cycle.id}
                            subCycleId={data.subCycle.id}
                            order={data.subCycle.order}
                            parentRef={wrapperRef}
                            defaults={data.subCycle.form || undefined}
                            cycleInfo={data.subCycle.cycle}
                            subCycleName={data.subCycle.name}
                        />
                    ) : data &&
                    data.subCycle &&
                    data.subCycle.formType === SubCycleForm.REQUEST_FOR_INFORMATION &&
                    (!data.subCycle.form || data.subCycle.form.__typename === 'RequestForInformationFormType') ? (
                        <RFIForm
                            cycleId={data.subCycle.cycle.id}
                            subCycleId={data.subCycle.id}
                            order={data.subCycle.order}
                            postedDate={data.subCycle.postedDate}
                            lastUpdatedDate={data.subCycle.lastUpdatedDate}
                            originalClosingDate={data.subCycle.originalClosingDate}
                            currentClosingDate={data.subCycle.currentClosingDate}
                            originalArchiveDate={data.subCycle.originalArchiveDate}
                            archiveDate={data.subCycle.archiveDate}
                            defaults={data.subCycle.form || undefined}
                            parentRef={wrapperRef}
                            cycleInfo={data.subCycle.cycle}
                            subCycleName={data.subCycle.name}
                        />
                    ) : data &&
                    data.subCycle &&
                    data.subCycle.name &&
                    data.subCycle.formType === SubCycleForm.REQUEST_FOR_PROPOSAL &&
                    (!data.subCycle.form || data.subCycle.form.__typename === 'RequestForProposalFormType') ? (
                        <RFPForm
                            cycleId={data.subCycle.cycle.id}
                            subCycleId={data.subCycle.id}
                            order={data.subCycle.order}
                            parentRef={wrapperRef}
                            defaults={data.subCycle.form || undefined}
                            cycleInfo={data.subCycle.cycle}
                            subCycleName={data.subCycle.name}
                        />
                    ) : (
                        <Redirect to="/acquisition-cycles" />
                    )}
                </CycleDataProvider>
            </PageBody>
        </PageWrapper>
    );
};

export default SubCycleFormPage;
