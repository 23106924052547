import { StepConnector, withStyles } from '@material-ui/core';

export const StepperConnector = withStyles({
    active: {
        '& $line': {
            background: '#eaeaf0',
        },
    },
    completed: {
        '& $line': {
            background: '#eaeaf0',
        },
    },
    vertical: {
        marginLeft: '18px',
        padding: 0,
    },
    line: {
        width: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);
