import React from 'react';
import { getFpmCategoryFromScoreSection, getScoreSectionsTypeDisplayName, ScoreSectionsType } from '../../../../utils/Enums';
import { Grid, Typography, Paper, makeStyles } from '@material-ui/core';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import EvaluationSection from './EvaluationSection';
import { useTeamLeadScore } from '../../../Providers/TeamLeadScoreProvider';
import { getFPMOrder, getFPMLabels } from '../../../../utils/Enums/Helpers/FpmCategories';
import { useFormContext } from 'react-hook-form';
import { Risk } from '../../EvaluatorScoreForm/EvaluatorScoreSections';

export interface EvaluationScoreProps {
    sectionNumber: ScoreSectionsType;
}

const useStyles = makeStyles({
    risk: {
        background: '#1976D2',
        color: '#ffffff',
        padding: '8px',
        borderRadius: '5px',
        fontWeight: 'bold',
    },
});
const EvaluationScore: React.FC<EvaluationScoreProps> = ({ sectionNumber }) => {
    const classes = useStyles();
    const { evaluations, loading, evaluationIndex } = useTeamLeadScore();
    const { register, watch } = useFormContext();

    // Construct risks using topic and responder risks
    const risks: Risk[] = evaluations[evaluationIndex].response.topic?.risks ?? [];
    risks.push(...(evaluations[evaluationIndex].response.responderRisks ?? []));

    const statusWatchList = evaluations.map((_evaluation, index) => `evaluations.${index + 1}.status`);
    const statuses = watch(statusWatchList);

    return (
        <Paper style={{ borderRadius: '8px', width: '100%', marginTop: '30px' }}>
            <FormSection>
                <Grid container justify="space-between">
                    <Grid>
                        <Typography variant="h2">{getScoreSectionsTypeDisplayName(sectionNumber)}</Typography>
                    </Grid>
                    <Grid style={{ display: 'flex' }}>
                        {!loading
                            ? risks
                                  // For this section, display only the risks associated with this FPM Category
                                  .filter((risk) => getFpmCategoryFromScoreSection(sectionNumber) === risk.fpmCategory)
                                  // Isolate risk names
                                  .map((riskData) => riskData.risk.name)
                                  // Remove duplicates
                                  .filter((riskName, index, riskNames) => riskNames.indexOf(riskName) === index)
                                  // Construct chip from risk name
                                  .map((risk, index) => {
                                      return (
                                          <Typography key={index} variant="subtitle2" align="left" style={{ marginRight: '30px' }} className={classes.risk}>
                                              {risk}
                                          </Typography>
                                      );
                                  })
                            : ''}
                    </Grid>
                </Grid>
            </FormSection>
            <div style={{ width: '97%', margin: 'auto' }}>
                <FormDivider />
            </div>
            <FormSection>
                <Grid container spacing={2} style={{ margin: 'auto' }} justify="space-around">
                    {/* Hidden inputs */}
                    {!loading
                        ? evaluations.map((evaluation, index) => {
                              const evaluationPrefix = `evaluations.${index + 1}`;
                              const status = statuses ? statuses[`${evaluationPrefix}.status`] : null;
                              return (
                                  <div style={{ display: 'none' }} key={`evaluation-${evaluation.id}-${index}`}>
                                      <input name={`${evaluationPrefix}.id`} type="hidden" ref={register()} value={evaluation.id} />
                                      <input name={`${evaluationPrefix}.userId`} type="hidden" ref={register()} value={evaluation.userId} />
                                      {status ? <input name={`${evaluationPrefix}.status`} type="hidden" ref={register()} value={status} /> : null}
                                  </div>
                              );
                          })
                        : null}

                    {/* Score sections */}
                    {getFPMLabels()[sectionNumber].map((category, fpmIndex) => (
                        <EvaluationSection key={sectionNumber * 3 + fpmIndex} fpmLabel={category} fpmCategory={getFPMOrder()[sectionNumber * 3 + fpmIndex]} />
                    ))}
                </Grid>
            </FormSection>
        </Paper>
    );
};

export default EvaluationScore;
