/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Avatar, createStyles, makeStyles } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';

export interface EvaluatorsProps {
    evaluators: AssignedEvaluatorsProps[];
}

export interface AssignedEvaluatorsProps {
    firstname: string;
    lastname: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        evaluatorsWrapper: {
            'fontSize': 'inherit',
            '&:hover': { transform: 'scale(1.05)', transition: 'all .2s ease-in-out' },
        },
        moreMembers: {
            display: 'flex',
            marginLeft: '0',
            border: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
        },
    }),
);

const AssignedEvaluators: React.FC<EvaluatorsProps> = ({ evaluators }) => {
    const classes = useStyles();

    // String to Hash
    const hashCode = (str: string) => {
        let hash: any = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };

    // Hash to Hex
    const intToRGB = (i: any) => {
        const c = (i & 0x00ffffff).toString(16).toUpperCase();
        return '00000'.substring(0, 6 - c.length) + c;
    };

    // Hex to RBG
    const convertToRGB = (hex: any): React.CSSProperties => {
        if (hex.length != 6) {
            throw 'Only six-digit hex colors are allowed.';
        }
        const aRgbHex = hex.match(/.{1,2}/g);
        const r = 127.5 + 0.5 * parseInt(aRgbHex[0], 16);
        const g = 127.5 + 0.5 * parseInt(aRgbHex[1], 16);
        const b = 127.5 + 0.5 * parseInt(aRgbHex[2], 16);
        return {
            background: `rgb(${r},${g}, ${b})`,
            // http://stackoverflow.com/a/3943023/112731
            color: r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF',
        } as React.CSSProperties;
    };

    return (
        <AvatarGroup>
            {evaluators.slice(0, 4).map((res, i) => {
                return (
                    <Avatar key={i} className={classes.evaluatorsWrapper} style={convertToRGB(intToRGB(hashCode(res.firstname + res.lastname)))}>
                        {res.firstname.charAt(0).toUpperCase()} {res.lastname.charAt(0).toUpperCase()}
                    </Avatar>
                );
            })}
            {evaluators.length > 4 ? <div className={classes.moreMembers}>+ {evaluators.length - 4}</div> : null}
        </AvatarGroup>
    );
};

export default AssignedEvaluators;
