import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { TabPanel, TabPanelProps } from '../../index';
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableOptions, FilterType, MUIDataTableColumn } from 'mui-datatables';
import { ErrorMessage, LoadingMessage, ScreenMessage, TableActionMenu, usePermissions } from '../../../../index';
import { FundamentalPrimeMeasurement, SelectionStatus, SubCycleForm, SubCycleStatus } from '../../../../../apollo/generated/types/globalTypes';
import { GET_RESPONSE_SCORE_TABLE } from '../../../../../apollo/queries';
import { ApolloError, useQuery } from '@apollo/client';
import {
    GetResponseScoreTable,
    GetResponseScoreTableVariables,
    GetResponseScoreTable_responses,
    GetResponseScoreTable_responses_assignedEvaluators_evaluatorScores,
    GetResponseScoreTable_responses_subCycle,
} from '../../../../../apollo/generated/types/GetResponseScoreTable';
import { getSelectionStatusText } from '../../../../../utils/Enums/Helpers/SelectionStatusUtil';
import { ScoreDisplay } from '../../../../ScoreDisplay';
import FileIcon from '../../../../../images/FileIcon';
import { CycleSubTab, CycleTab } from '../../../../../utils/Enums/TabEnum';

export type ResponseHistoryTabPanelProps = TabPanelProps;

interface URLParams {
    id: string; // Acquisition Cycle ID
}

interface ResponseHistoryTableItem {
    id: number;
    title: string;
    organization: string;
    subCycle: SubCycle;
    status: SelectionStatus;
    appeal: number;
    value: number;
    reliability: number;
    people: number;
    planningProcess: number;
    finances: number;
    sizeScope: number;
    marketDemand: number;
    delivery: number;
    researchCategory?: string;
    topic?: string;
}

interface SubCycle {
    id: number;
    name: string;
    status: SubCycleStatus;
}

const useStyles = makeStyles({
    responseId: {
        background: 'rgba(14, 174, 121, 0.5)',
        fontWeight: 'bold',
        borderRadius: '5px',
    },
    categories: {
        maxWidth: ' 8rem',
    },
    documentLink: {
        display: 'flex',
        alignItems: 'center',
    },
    singleDocument: {
        'overflow': 'hidden',
        'maxWidth': '100%',
        'textOverflow': 'ellipsis',
        'whiteSpace': 'nowrap',
        '&:hover': {
            textDecoration: 'underline',
            color: 'mediumblue',
            cursor: 'pointer',
        },
    },
    statusComplete: {
        color: 'rgb(14, 174, 121)',
    },
    statusProgress: {
        color: 'rgb(237, 172, 67)',
    },
    statusNotStarted: {
        color: 'rgb(237, 85, 49)',
    },
    centerHeader: {
        '& span': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    initMessageArea: {
        padding: '10px',
        height: '500px',
    },
});

const ResponseHistoryTabPanel: React.FC<ResponseHistoryTabPanelProps> = ({ index, value, ...other }) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const [filterApplied, setFilterApplied] = useState<boolean>(false);
    const [responses, setResponses] = useState<ResponseHistoryTableItem[]>([]);
    const cycleId = parseInt(id);
    const { isEvaluator, isTeamLead, isSystemProgramManagement } = usePermissions();

    const handleEvaluationsClick = (responseId: number) => {
        history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.ResponseHistory}/${responseId}/evaluations`);
    };

    /**
     * Filters out scores within a particular Fundamental Prime Measurement category and then
     * calculates the weighted average of those scores.
     * @param scores List of evaluator scores from within the response
     * @param fpmCategory Fundamental Prime Measurement to filter by
     * @returns Weighted Average of the filtered scores
     */
    const filterAndAverage = (
        scores: GetResponseScoreTable_responses_assignedEvaluators_evaluatorScores[],
        fpmCategory: FundamentalPrimeMeasurement,
    ): number => {
        let score = 0;
        let weight = 0;
        scores
            .filter((scoreItem) => scoreItem.successFactor.fpmCategory === fpmCategory)
            .map((scoreItem) => {
                const weightVal = parseFloat(scoreItem.successFactor.weight);
                score = score + parseFloat(scoreItem.score) * weightVal;
                weight = weight + weightVal;
            });

        let weightedScoreAvg = parseFloat((score / weight).toFixed(1));
        weightedScoreAvg = isNaN(weightedScoreAvg) ? 0 : weightedScoreAvg;

        return weightedScoreAvg;
    };
    /**
     * Generates response score data for the Finalized Responses table.
     * @param finalizedResponses List of finalized responses
     * @returns Response items with weighted average scores from the Team Lead evaluation
     */
    const mapFinalizedResponses = (finalizedResponses: GetResponseScoreTable_responses[]): ResponseHistoryTableItem[] => {
        return finalizedResponses.map((response) => {
            const teamLeadScores = response.assignedEvaluators.find((ae) => ae.isTeamLead)?.evaluatorScores ?? [];

            return {
                id: response.id,
                title: response.title,
                organization: response.organization.name,
                subCycle: response.subCycle,
                status: response.selectedStatus,
                appeal: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.APPEAL),
                value: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.VALUE),
                reliability: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.RELIABILITY),
                people: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.PERSONNEL),
                planningProcess: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.PROCESSES),
                finances: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.FINANCES),
                sizeScope: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.APPLICATION_SIZE),
                marketDemand: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.DEMAND),
                delivery: filterAndAverage(teamLeadScores, FundamentalPrimeMeasurement.DELIVERY),
                researchCategory: response.researchCategory?.name,
                topic: response.topic?.name,
            } as ResponseHistoryTableItem;
        });
    };

    //// Loads currently Active Sub-Cycle and Responses ////
    const { loading: responseTableLoading, error: responseTableError } = useQuery<GetResponseScoreTable, GetResponseScoreTableVariables>(
        GET_RESPONSE_SCORE_TABLE,
        {
            variables: {
                cycleId: cycleId,
            },
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
            onCompleted: (data: GetResponseScoreTable) => {
                if (data && data.responses) {
                    // Isolate Down-selected responses
                    const finalizedResponses = data?.responses?.filter(
                        (response) =>
                            response.subCycle.status === SubCycleStatus.COMPLETE &&
                            (response.selectedStatus === SelectionStatus.DOWN_SELECTION_SELECTED ||
                                response.selectedStatus === SelectionStatus.DOWN_SELECTION_REJECTED ||
                                response.selectedStatus === SelectionStatus.AWARD_SELECTED ||
                                response.selectedStatus === SelectionStatus.AWARD_REJECTED ||
                                (response.subCycle.formType === SubCycleForm.REQUEST_FOR_PROPOSAL && response.selectedStatus === SelectionStatus.PENDING)),
                    );
                    setResponses(mapFinalizedResponses(finalizedResponses ?? []));
                } else {
                    console.log('Error retrieving Response data');
                }
            },
            onError: (err: ApolloError) => {
                if (err.graphQLErrors) {
                    const hasAuthenticationError = err.graphQLErrors.some(
                        (e) => e.extensions?.code.toUpperCase() === 'AUTHORIZATION' || e.extensions?.code.toUpperCase() === 'AUTHENTICATION',
                    );
                    if (hasAuthenticationError) {
                        history.push('/acquisition-cycles');
                    }
                }
                console.error(err);
            },
        },
    );

    // Table helpers
    const generateFilterDisplayFunc = (
        selectId: string,
        label: string,
        getFilterOptions: () => any[],
        mapOptions: (value: any) => string,
        multiple = false,
    ) => {
        return (
            filterList: string[][],
            onChange: (val: string | string[], index: number, column: MUIDataTableColumn) => void,
            index: number,
            column: MUIDataTableColumn,
        ) => {
            const ALL_VAL = 'Any';
            const options = getFilterOptions();
            const value = multiple
                ? filterList[index].length
                    ? filterList[index]
                    : [ALL_VAL]
                : filterList[index].length
                ? filterList[index].toString()
                : ALL_VAL;
            const renderValue = multiple
                ? (selected: unknown) => {
                      return ((selected ?? []) as string[]).join(', ');
                  }
                : undefined;
            const newOnChange = multiple
                ? (event) => {
                      const selected: string[] = (event.target.value ?? []) as string[];
                      let value;
                      if (selected.includes(ALL_VAL)) {
                          const anyIndex = selected.indexOf(ALL_VAL);
                          if (anyIndex === 0) value = selected.length > 1 ? selected.slice(1) : [];
                          else value = [];
                      } else {
                          value = selected;
                      }

                      onChange(value, index, column);
                  }
                : (event) => {
                      const value = event.target.value === ALL_VAL ? [] : [event.target.value as string];
                      onChange(value, index, column);
                  };

            return (
                <FormControl>
                    <InputLabel htmlFor={selectId}>{label}</InputLabel>
                    <Select id={selectId} multiple={multiple} value={value} renderValue={renderValue} onChange={newOnChange}>
                        <MenuItem key={0} value={ALL_VAL}>
                            {ALL_VAL}
                        </MenuItem>
                        {options.map(mapOptions).map((option: string, index: number) => (
                            <MenuItem key={index} value={option ?? ''} style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <div style={{ flex: 1 }}>{option ?? ''}</div>
                                </div>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        };
    };
    const filterLogicFunc = (location, filters) => {
        if (filters.length) return !filters.includes(location);
        return false;
    };

    // Column definition
    const columns = [
        {
            name: 'id',
            label: 'Response Id',
            options: {
                display: true,
                sort: true,
                filter: false,
                filterType: 'textField' as FilterType,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ style: { minWidth: '110px' } }),
                customBodyRender: (value: string) => {
                    return (
                        <p style={{ padding: '0 10px', textAlign: 'center' }} className={classes.responseId}>
                            {value}
                        </p>
                    );
                },
            },
        },
        {
            name: 'title',
            label: 'Response Title',
            options: {
                display: true,
                sort: true,
                filter: false,
                setCellHeaderProps: () => ({ style: { minWidth: '125px' } }),
                filterType: 'textField' as FilterType,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'organization',
            label: 'Organization',
            options: {
                display: true,
                sort: true,
                sortThirdClickReset: true,
                filter: true,
                filterType: 'custom' as FilterType,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (organization: string) => organization,
                    logic: filterLogicFunc,
                    display: generateFilterDisplayFunc(
                        'organization-filter-select',
                        'Organization',
                        () => {
                            let options = [] as string[];
                            if (responses) {
                                options = Array.from(new Set(responses.map((response) => response.organization)));
                            }

                            return options;
                        },
                        (option: string) => option,
                        true,
                    ),
                },
                customBodyRender: (organization: string) => {
                    return organization;
                },
            },
        },
        {
            name: 'subCycle',
            label: 'Sub-Cycle',
            options: {
                display: true,
                sort: true,
                filter: true,
                filterType: 'custom' as FilterType,
                sortThirdClickReset: true,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (subCycle: string) => subCycle,
                    logic: filterLogicFunc,
                    display: generateFilterDisplayFunc(
                        'sub-cycle-filter-select',
                        'Sub-Cycle',
                        () => {
                            let options = [] as SubCycle[];
                            if (responses) {
                                options = responses
                                    .map((response) => response.subCycle as SubCycle)
                                    .filter(
                                        (subCycle: SubCycle, index: number, self: SubCycle[]) =>
                                            index === self.findIndex((t) => t && subCycle && t.id === subCycle.id),
                                    );
                            }

                            return options;
                        },
                        (option: SubCycle) => option?.name,
                        true,
                    ),
                },
                customBodyRender: (subCycle: GetResponseScoreTable_responses_subCycle) => {
                    return subCycle.name;
                },
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterType: 'custom' as FilterType,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (status: string) => status,
                    logic: filterLogicFunc,
                    display: generateFilterDisplayFunc(
                        'status-filter-select',
                        'Status',
                        () => {
                            let options = [] as string[];
                            if (responses) {
                                options = Array.from(new Set(responses.map((response) => getSelectionStatusText(response.status))));
                            }

                            return options;
                        },
                        (option: string) => option,
                    ),
                },
                customBodyRender: (value: SelectionStatus) => {
                    return getSelectionStatusText(value);
                },
            },
        },
        {
            name: 'appeal',
            label: 'Appeal',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'value',
            label: 'Value',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'reliability',
            label: 'Reliability',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'people',
            label: 'People',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'planningProcess',
            label: 'Planning / Process',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'finances',
            label: 'Finances',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'sizeScope',
            label: 'Size / Scope',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'marketDemand',
            label: 'Market Demand',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'delivery',
            label: 'Delivery (Market Access)',
            options: {
                display: true,
                sort: true,
                filter: false,
                sortThirdClickReset: true,
                setCellHeaderProps: () => ({ className: classes.centerHeader }),
                setCellProps: () => ({ style: { textAlign: 'center' } }),
                customBodyRender: (value: number) => <ScoreDisplay score={value} />,
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                display: true,
                sort: false,
                filter: false,
                customBodyRenderLite: (dataIndex: number, _rowIndex: number) => {
                    const response = responses ? responses[dataIndex] : null;

                    return (
                        <TableActionMenu
                            id={response?.id ?? dataIndex}
                            index={dataIndex}
                            showView={isEvaluator(cycleId) || isTeamLead(cycleId) || isSystemProgramManagement()}
                            viewText="View Evaluations"
                            onViewClick={handleEvaluationsClick}
                        />
                    );
                },
            },
        },
        // Hidden columns
        {
            name: 'researchCategory',
            label: 'Research Category',
            options: {
                display: false,
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterType: 'custom' as FilterType,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (researchCategory: string) => researchCategory,
                    logic: filterLogicFunc,
                    display: generateFilterDisplayFunc(
                        'research-category-filter-select',
                        'Research Category',
                        () => {
                            let options = [] as string[];
                            if (responses) {
                                options = Array.from(new Set(responses.map((response) => response.researchCategory ?? '')));
                            }

                            return options;
                        },
                        (option: string) => option,
                    ),
                },
                customBodyRender: (researchCategory?: string) => {
                    return researchCategory ?? '';
                },
            },
        },
        {
            name: 'topic',
            label: 'Topic',
            options: {
                display: false,
                sort: true,
                filter: true,
                sortThirdClickReset: true,
                filterType: 'custom' as FilterType,
                filterOptions: {
                    fullWidth: true,
                    renderValue: (topic: string) => topic,
                    logic: filterLogicFunc,
                    display: generateFilterDisplayFunc(
                        'topic-filter-select',
                        'Topic',
                        () => {
                            let options = [] as string[];
                            if (responses) {
                                options = Array.from(new Set(responses.map((response) => response.topic ?? '')));
                            }

                            return options;
                        },
                        (option: string) => option,
                    ),
                },
                customBodyRender: (topic?: string) => {
                    return topic ?? '';
                },
            },
        },
    ];
    // Table options definition
    const options = {
        enableNestedDataAccess: '.',
        responsive: 'simple',
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRowsHeader: false,
        selectableRows: 'none',
        onRowsDelete: () => false,
        selectToolbarPlacement: 'above',
        pagination: false,
        search: false,
        confirmFilters: true,
        tableBodyHeight: filterApplied ? 'auto' : '0px',
        onFilterConfirm: () => {
            if (!filterApplied) setFilterApplied(true);
        },
        customFilterDialogFooter: (_currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px' }}>
                    <Button variant="contained" onClick={applyNewFilters}>
                        Filter
                    </Button>
                </div>
            );
        },
    } as MUIDataTableOptions;

    return (
        <TabPanel index={index} value={value} {...other}>
            {responseTableLoading ? (
                <LoadingMessage />
            ) : responseTableError ? (
                <ErrorMessage error={responseTableError} />
            ) : responses ? (
                <>
                    <MUIDataTable title="" columns={columns} data={filterApplied ? responses : []} options={options} />

                    {!filterApplied ? (
                        <div className={classes.initMessageArea}>
                            <ScreenMessage icon={<FileIcon />} header="View responses from previous sub-cycles here.">
                                <Typography>Use the filter button on the top right to view filter options and search for a response.</Typography>
                            </ScreenMessage>
                        </div>
                    ) : null}
                </>
            ) : (
                <ScreenMessage header="Welcome!" subHeader="It seems there is no data for Response Management to load">
                    <Typography> Please contact an admin </Typography>
                </ScreenMessage>
            )}
        </TabPanel>
    );
};

export default ResponseHistoryTabPanel;
