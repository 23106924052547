/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { DragSourceMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { TableActionMenu } from '../../../../..';
import { FundamentalPrimeMeasurement, SelectionStatus } from '../../../../../../apollo/generated/types/globalTypes';
import { getSelectionStatusEnum } from '../../../../../../utils/Enums/Helpers/SelectionStatusUtil';
import { useDownSelection } from '../../../../../Providers';
import { ScoreDisplay } from '../../../../../ScoreDisplay';
import { useHistory, useParams } from 'react-router-dom';
import { CycleSubTab, CycleTab } from '../../../../../../utils/Enums/TabEnum';

interface DownSelectionItemProps {
    response: any;
    setTabData: (x: any) => void;
    index: number;
    sectionStatus: SelectionStatus;
    moveItemHandler: (dragIndex, hoverIndex) => void;
    backgroundColor: string;
    readOnly: boolean;
}

interface URLParams {
    id: string; // Acquisition Cycle ID
}

const useStyles = makeStyles({
    item: {
        margin: 'auto 0',
    },
    itemScore: {
        width: '7.55%',
    },
});

const DownSelectionItem: React.FC<DownSelectionItemProps> = ({ response, sectionStatus, setTabData, index, moveItemHandler, backgroundColor, readOnly }) => {
    const classes = useStyles();
    const { handleResponseChange } = useDownSelection();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const ref = useRef<HTMLDivElement>(null);
    const scores = response.assignedEvaluators.length > 0 ? response.assignedEvaluators[0].evaluatorScores : 0;

    const filterAndMapScores = (scores: any[], fpm: FundamentalPrimeMeasurement): any[] => {
        return scores
            .filter((score) => score.successFactor.fpmCategory === fpm)
            .map((score) => {
                return {
                    weight: score.successFactor.weight,
                    score: score.score ?? null,
                };
            });
    };

    // if evaluator score empty return 0's
    const scoreData = scores
        ? ({
              APPEAL: filterAndMapScores(scores, FundamentalPrimeMeasurement.APPEAL),
              VALUE: filterAndMapScores(scores, FundamentalPrimeMeasurement.VALUE),
              RELIABILITY: filterAndMapScores(scores, FundamentalPrimeMeasurement.RELIABILITY),
              PERSONNEL: filterAndMapScores(scores, FundamentalPrimeMeasurement.PERSONNEL),
              PROCESSES: filterAndMapScores(scores, FundamentalPrimeMeasurement.PROCESSES),
              FINANCES: filterAndMapScores(scores, FundamentalPrimeMeasurement.FINANCES),
              APPLICATION_SIZE: filterAndMapScores(scores, FundamentalPrimeMeasurement.APPLICATION_SIZE),
              DEMAND: filterAndMapScores(scores, FundamentalPrimeMeasurement.DEMAND),
              DELIVERY: filterAndMapScores(scores, FundamentalPrimeMeasurement.DELIVERY),
          } as any)
        : {
              APPEAL: 0,
              VALUE: 0,
              RELIABILITY: 0,
              PERSONNEL: 0,
              PROCESSES: 0,
              FINANCES: 0,
              APPLICATION_SIZE: 0,
              DEMAND: 0,
              DELIVERY: 0,
          };

    const handleAverageScore = (scoreData) => {
        let score = 0;
        let weight = 0;
        if (scoreData.length > 0) {
            scoreData.map((i: any) => {
                score = score + parseFloat(i.score) * parseFloat(i.weight);
                if (isNaN(score)) score = 0;
                weight = weight + parseFloat(i.weight);
            });
            let total = parseFloat((score / weight).toFixed(1));
            total = isNaN(total) ? 0 : total;
            return { total };
        }
        return { total: 0 };
    };

    const handleChangeSection = (section: SelectionStatus) => {
        // Updates response in tab state
        setTabData((prevState) => {
            const items = [...prevState];
            const item = { ...items[index] };
            item.selectedStatus = section;
            items[index] = item;
            return items;
        });

        // Updates response from global state
        handleResponseChange(section, response.id);
    };

    const [, drop] = useDrop({
        accept: 'response',
        hover(item: any, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveItemHandler(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });
    const responseId = response.id;
    const [{ isDragging }, drag] = useDrag({
        item: { index, responseId, type: 'response', sectionStatus },
        end: (item, monitor: DragSourceMonitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult) {
                const section = dropResult.targetSection;
                if (section) {
                    handleChangeSection(section);
                }
            }
        },
        canDrag: !readOnly,
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.4 : 1;

    drag(drop(ref));

    const handleEvaluationsClick = (responseId: number) => {
        history.push(`/acquisition-cycle/${id}/${CycleTab.Response}/${CycleSubTab.FinalizedResponses}/${responseId}/evaluations`);
    };

    return (
        <div ref={ref} style={{ opacity, padding: '10px', margin: '10px 0', background: backgroundColor, borderRadius: '5px', textAlign: 'center' }}>
            <Grid container spacing={2}>
                <Grid item xs={1} className={classes.item}>
                    {response.title}
                </Grid>
                <Grid item xs={1} className={classes.item}>
                    {response.id}
                </Grid>
                <Grid item xs={1} className={classes.item}>
                    {response.organization.name}
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.APPEAL).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.VALUE).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.RELIABILITY).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.PERSONNEL).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.PROCESSES).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.FINANCES).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.APPLICATION_SIZE).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.DEMAND).total} />
                </Grid>
                <Grid item className={classes.itemScore}>
                    <ScoreDisplay score={handleAverageScore(scoreData.DELIVERY).total} />
                </Grid>
                <Grid item style={{ margin: 'auto' }}>
                    <TableActionMenu id={response.id} index={0} showView={true} onViewClick={() => handleEvaluationsClick(response.id)} />
                </Grid>
            </Grid>
        </div>
    );
};

export default DownSelectionItem;
