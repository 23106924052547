import * as React from 'react';
import { Button, Grid, Typography, FormHelperText } from '@material-ui/core';
import { FormMode } from '../../../../../../utils/Enums';
import SuccessFactorItem from './SuccessFactorItem';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ArrayField, useFormContext } from 'react-hook-form';
import { SuccessFactorInputType, FundamentalPrimeMeasurement } from '../../../../../../apollo/generated/types/globalTypes';

const useStyles = makeStyles({
    blueBox: {
        background: '#ddebf8',
        marginLeft: '5px',
        marginRight: '5px',
    },
});

const SuccessFactorCategorySection: React.FC<SuccessFactorCategorySectionProps> = ({
    mode,
    category,
    successFactors,
    topicIndex,
    fpmCategory,
    append,
    remove,
    importedLocked,
    additionLocked,
}) => {
    const classes = useStyles();
    const { errors } = useFormContext();
    const readOnly = mode === FormMode.View;

    const key = `topics[${topicIndex}].category[${fpmCategory}]`;
    const hasError = !!errors && !!errors[key];
    const errorMessage = (errors && errors[key]?.message) || '';

    return (
        <Grid container item xs={12} spacing={2} key={category}>
            <Grid item xs={12} className={classes.blueBox}>
                <Typography variant="h3">{category}</Typography>
                {hasError ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
            </Grid>
            <Grid item xs={12}>
                {successFactors.map((successFactor, index) =>
                    successFactor.fpmCategory !== undefined && successFactor.fpmCategory === fpmCategory ? (
                        <SuccessFactorItem
                            key={successFactor.key}
                            index={index}
                            topicIndex={topicIndex}
                            fpmCategory={fpmCategory}
                            mode={mode}
                            {...successFactor}
                            remove={remove}
                            importedLocked={importedLocked}
                        />
                    ) : null,
                )}
            </Grid>

            <Grid item xs={12}>
                {!additionLocked && (
                    <Button
                        startIcon={<AddCircleOutlineIcon style={{ color: '#1976D2' }} />}
                        disabled={readOnly}
                        onClick={() =>
                            append({
                                name: '',
                                definition: '',
                                fpmCategory: fpmCategory,
                                imported: false,
                            })
                        }>
                        Add new success factor
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export interface SuccessFactorCategorySectionProps {
    mode: FormMode;
    category: string;
    successFactors: Partial<ArrayField<SuccessFactorInputType, 'key'>>[];
    topicIndex: number;
    fpmCategory: FundamentalPrimeMeasurement;
    append: (value: Partial<SuccessFactorInputType> | Partial<SuccessFactorInputType>[]) => void;
    remove: (index: number) => void;
    importedLocked: boolean;
    additionLocked: boolean;
}

export default SuccessFactorCategorySection;
