import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import { Export_Form_PDF } from '../../apollo/mutations';
import { ExportFormPDF, ExportFormPDFVariables } from '../../apollo/generated/types/ExportFormPDF';
import download from 'downloadjs';
import { LoadingButton } from '../index';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useCallback, useEffect, useState } from 'react';

export interface PDFInput {
    fileName: string;
}

const FormExportModal: React.FC<FormExportModalProps> = ({ open, onClose, subCycleId }) => {
    const [error, setError] = useState<ApolloError>();
    const { register, errors, handleSubmit } = useForm<PDFInput>({ defaultValues: { fileName: '' } });
    const [exportPDF, { loading }] = useMutation<ExportFormPDF, ExportFormPDFVariables>(Export_Form_PDF);

    const handleExport = useCallback(
        async (data: PDFInput) => {
            setError(undefined);
            try {
                const result = await exportPDF({
                    variables: {
                        id: subCycleId,
                    },
                });

                if (result && result.data && result.data.exportFormPDF) {
                    const { formPdf, instructionsDoc } = result.data.exportFormPDF;
                    if (formPdf) {
                        download(new Uint8Array(formPdf), `${data.fileName}.pdf`, 'application/pdf');
                    }
                    if (instructionsDoc) {
                        download(
                            new Uint8Array(instructionsDoc),
                            'JEMTP & JFTP FY22 6.2 & 6.3 Technology Paper Template.docx',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        );
                    }
                    onClose();
                }
            } catch (e) {
                setError(e);
            }
        },
        [exportPDF, onClose, subCycleId],
    );

    useEffect(() => {
        setError(undefined);
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle disableTypography>
                <Typography variant="h1">Export Form</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleSubmit(handleExport)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="File Name"
                                name="fileName"
                                inputRef={register({
                                    required: 'File Name is required',
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.fileName}
                                helperText={errors.fileName?.message}
                            />
                        </Grid>
                        {error ? (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {error?.message}
                                </Alert>
                            </Grid>
                        ) : null}
                        <Grid container item justify="flex-end" xs={12}>
                            <Button onClick={onClose}>Cancel</Button>
                            <LoadingButton pending={loading} variant="contained" type="submit">
                                Export
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export interface FormExportModalProps {
    open: boolean;
    onClose: () => void;
    subCycleId: number;
}

export default FormExportModal;
