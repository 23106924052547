export enum RespondersType {
    Government = 1,
    Industry = 2,
    Both = 3,
}

export const getRespondersTypeDisplayName = (value: RespondersType): string => {
    switch (value) {
        case RespondersType.Government:
            return 'Government';
        case RespondersType.Industry:
            return 'Industry';
        case RespondersType.Both:
            return 'Government & Industry';
        default:
            return 'None';
    }
};
