import * as React from 'react';
import { createContext, useCallback, useEffect, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_COMMON_FORM_OPTIONS } from '../../../apollo/queries';
import { CategoryFields } from '../../../apollo/generated/types/CategoryFields';
import { OptionFields } from '../../../apollo/generated/types/OptionFields';
import { SecurityClassificationFields } from '../../../apollo/generated/types/SecurityClassificationFields';
import { CREATE_POINT_OF_CONTACT_ORGANIZATION } from '../../../apollo/mutations';
import { OptionWithParentFields } from '../../../apollo/generated/types/OptionWithParentFields';
import { CreatePointOfContactOrganization, CreatePointOfContactOrganizationVariables } from '../../../apollo/generated/types/CreatePointOfContactOrganization';
import { SubCycleForm } from '../../../apollo/generated/types/globalTypes';
import { GetCommonFormOptions } from '../../../apollo/generated/types/GetCommonFormOptions';
import { ResponderTypeFields } from '../../../apollo/generated/types/ResponderTypeFields';

interface CommonFormProviderProps {
    formType: SubCycleForm;
}

interface CommonFormContextReturn {
    formType: SubCycleForm;
    loading: boolean;
    error?: ApolloError;
    categories?: CategoryFields[];
    researchDevelopmentCategories?: OptionFields[];
    securityClassifications?: SecurityClassificationFields[];
    technologyReadinessLevels?: OptionWithParentFields[];
    organizations?: OptionFields[];
    responderTypes?: ResponderTypeFields[];
    addOrganization: (name: string) => Promise<OptionFields | null>;
}

const CommonFormContext = createContext<CommonFormContextReturn | undefined>(undefined);

const CommonFormProvider: React.FC<CommonFormProviderProps> = ({ formType, children }) => {
    // Allows Manual Entry
    const [organizations, setOrganizations] = useState<Array<OptionFields> | undefined>();
    const [createOrganization] = useMutation<CreatePointOfContactOrganization, CreatePointOfContactOrganizationVariables>(CREATE_POINT_OF_CONTACT_ORGANIZATION);

    const { loading, error, data } = useQuery<GetCommonFormOptions>(GET_COMMON_FORM_OPTIONS, {
        fetchPolicy: 'cache-and-network',
    });

    // No Manual Entry
    const categories = data?.categories as CategoryFields[];
    const researchDevelopmentCategories = data?.researchDevelopmentCategories as OptionFields[];
    const securityClassifications = data?.securityClassifications as SecurityClassificationFields[];
    const technologyReadinessLevels = data?.technologyReadinessLevels as OptionWithParentFields[];
    const responderTypes = data?.responderTypes as ResponderTypeFields[];

    useEffect(() => {
        setOrganizations(data?.pointOfContactOrganizations as OptionFields[]);
    }, [data]);

    const addOrganization = useCallback(
        async (name: string) => {
            const result = await createOrganization({
                variables: {
                    name: name,
                },
            });
            if (result && result.data && result.data.createPointOfContactOrganization) {
                const organization = result.data.createPointOfContactOrganization;
                setOrganizations((currentList) => [...(currentList || []), organization as OptionFields]);
                return organization as OptionFields;
            }
            return null;
        },
        [createOrganization],
    );

    return (
        <CommonFormContext.Provider
            value={{
                formType,
                loading,
                error,
                categories,
                researchDevelopmentCategories,
                securityClassifications,
                technologyReadinessLevels,
                organizations,
                responderTypes,
                addOrganization,
            }}>
            {children}
        </CommonFormContext.Provider>
    );
};

const useCommonForm = (): CommonFormContextReturn => {
    const context = React.useContext(CommonFormContext);
    if (context === undefined) {
        throw new Error('useCommonForm must be used within a CommonFormProvider');
    }
    return context;
};

export { CommonFormProvider, useCommonForm };
