import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PageWrapper, PageHeader, PageBody, FormSaveButtons, AcquisitionCycleForm, LoadingMessage, ErrorMessage } from '../../components';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { NavLink, useParams } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormProvider, useForm } from 'react-hook-form';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { CREATE_ACQUISITION_CYCLE } from '../../apollo/mutations';
import { CycleFundingInputType, CycleInputType, CycleStatus, SubCycleForm, SubCycleInputType } from '../../apollo/generated/types/globalTypes';
import { FormMode } from '../../utils/Enums';
import { CreateAcquisitionCycle, CreateAcquisitionCycleVariables } from '../../apollo/generated/types/CreateAcquisitionCycle';
import { GetCycleFull, GetCycleFullVariables } from '../../apollo/generated/types/GetCycleFull';
import { GET_CYCLE_FULL } from '../../apollo/queries';
import { CycleFields } from '../../apollo/generated/types/CycleFields';
import { SubCycleFields } from '../../apollo/generated/types/SubCycleFields';
import { useSnackbar } from 'notistack';
import { usePermissions } from '../../components/Providers';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    backButton: {
        background: '#F0F0F0',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
});

interface URLParams {
    id: string;
}

const EditAcquisitionCyclePage: React.FC = () => {
    const classes = useStyles();
    const { id } = useParams<URLParams>();
    const { enqueueSnackbar } = useSnackbar();
    const [title, setTitle] = useState<string | null>();
    const [status, setStatus] = useState<CycleStatus>(CycleStatus.DRAFT);
    const { isSystemProgramManagement } = usePermissions();
    const getMode = useCallback(() => isSystemProgramManagement() && status !== CycleStatus.ARCHIVED && status !== CycleStatus.COMPLETED ? FormMode.Edit : FormMode.View, [isSystemProgramManagement, status]);
    const [mode, setMode] = useState<FormMode>(getMode());
    const readOnly = mode === FormMode.View;

    useEffect(() => {
        setMode(getMode());
    }, [isSystemProgramManagement, getMode]);

    const [apiError, setAPIError] = useState<ApolloError | null>();
    const [subCyclesData, setSubCyclesData] = useState<Array<SubCycleFields> | undefined>();
    const methods = useForm<CycleInputType>({
        defaultValues: {
            id: null,
            status: CycleStatus.DRAFT,
            title: '',
            externalId: '',
            estimatedCloseDate: new Date(),
            primaryContactId: null,
            departmentId: null,
            serviceId: null,
            subServiceId: null,
            templateId: null,
            eligibleResponders: null,
            distributionStatement: '<p><br></p>',
            subCycles: [
                {
                    id: null,
                    name: '',
                    formType: SubCycleForm.REQUEST_FOR_INFORMATION,
                    postedDate: new Date(),
                    lastUpdatedDate: new Date(),
                    originalClosingDate: new Date(),
                    currentClosingDate: new Date(),
                    archiveDate: new Date(),
                    originalArchiveDate: new Date(),
                    order: 0,
                },
            ],
            cycleFundings: [
                {
                    id: null,
                    fundingTypeId: null,
                    timeframe: new Date(),
                    fundingAmount: 0,
                },
            ],
        },
    });

    const convertCycleToCycleInput = (data: CycleFields): CycleInputType => {
        setSubCyclesData(data?.subCycles);
        return {
            id: data.id,
            status: data.status,
            title: data.title,
            externalId: data.externalId,
            estimatedCloseDate: data.estimatedCloseDate,
            primaryContactId: data.primaryContactId,
            departmentId: data.departmentId,
            serviceId: data.serviceId,
            subServiceId: data.subServiceId,
            templateId: data.templateId,
            eligibleResponders: data.eligibleResponders,
            distributionStatement: data.distributionStatement,
            subCycles: data.subCycles.map(
                (sc) =>
                    ({
                        id: sc.id,
                        name: sc.name,
                        formType: sc.formType,
                        postedDate: sc.postedDate,
                        lastUpdatedDate: sc.lastUpdatedDate,
                        originalClosingDate: sc.originalClosingDate,
                        currentClosingDate: sc.currentClosingDate,
                        archiveDate: sc.archiveDate,
                        originalArchiveDate: sc.originalArchiveDate,
                        order: sc.order,
                    } as SubCycleInputType),
            ),
            cycleFundings: data?.cycleFundings.map(
                (cf) =>
                    ({
                        id: cf.id,
                        fundingTypeId: cf.fundingTypeId,
                        timeframe: cf.timeframe,
                        fundingAmount: cf.fundingAmount,
                    } as CycleFundingInputType),
            ),
        } as CycleInputType;
    };
    const { loading, error } = useQuery<GetCycleFull, GetCycleFullVariables>(GET_CYCLE_FULL, {
        variables: {
            id: Number(id),
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data: GetCycleFull) => {
            if (data && data.cycle) {
                methods.reset(convertCycleToCycleInput(data.cycle));
                setTitle(data.cycle.title);
                setStatus(data.cycle.status);
            }
        },
    });

    const [createCycle] = useMutation<CreateAcquisitionCycle, CreateAcquisitionCycleVariables>(CREATE_ACQUISITION_CYCLE);

    const onFormSubmit = useCallback(
        async (data: CycleInputType) => {
            try {
                setAPIError(null);
                const result = await createCycle({
                    variables: {
                        cycle: data,
                    },
                });
                setTitle(data.title);
                // Update Form with new Ids
                if (result && result.data && result.data.createAcquisitionCycle) {
                    const cycle = result.data.createAcquisitionCycle;
                    methods.reset(convertCycleToCycleInput(cycle));
                    setStatus(cycle.status);
                    enqueueSnackbar('Form Saved!', {
                        variant: 'success',
                    });
                    return cycle.subCycles.length > 0 ? `/acquisition-cycle/${cycle.id}/sub-cycle/${cycle.subCycles[0].id}/form` : false;
                }
                enqueueSnackbar('Error Saving Form!', {
                    variant: 'error',
                });
                return false;
            } catch (error) {
                if (error) {
                    setAPIError(error);
                }
                enqueueSnackbar('Error Saving Form!', {
                    variant: 'error',
                });
                return false;
            }
        },
        [createCycle, enqueueSnackbar, methods],
    );

    return (
        <PageWrapper>
            <FormProvider {...methods}>
                <PageHeader>
                    <Toolbar className={classes.header}>
                        <IconButton component={NavLink} to="/acquisition-cycles" className={classes.backButton}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h1" className={classes.title}>
                            Acquisition Cycle {title ? ' - ' + title : ''}
                        </Typography>
                        <FormSaveButtons onSubmit={onFormSubmit} readOnly={readOnly} />
                    </Toolbar>
                </PageHeader>
                <PageBody>
                    {loading ? (
                        <LoadingMessage />
                    ) : error ? (
                        <ErrorMessage error={error} />
                    ) : (
                        <AcquisitionCycleForm mode={mode} apiError={apiError} subCyclesData={subCyclesData} />
                    )}
                </PageBody>
            </FormProvider>
        </PageWrapper>
    );
};

export default EditAcquisitionCyclePage;
