/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Typography } from '@material-ui/core';
import DownSelectionItem from './DownSelectionItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDrop, DropTargetMonitor } from 'react-dnd';
import { SelectionStatus } from '../../../../../apollo/generated/types/globalTypes';

interface DownSelectionContainerProps {
    title: string;
    finishedResponses: any;
    status: SelectionStatus;
    setTabData: (x: any) => void;
    count: number;
    readOnly: boolean;
}

const useStyles = makeStyles({
    itemW: {
        width: '7.5%',
    },
});

const DownSelectionContainer: React.FC<DownSelectionContainerProps> = ({ title, finishedResponses, setTabData, status, count, readOnly }) => {
    const classes = useStyles();

    const handleDrop = () => {
        return { targetSection: status };
    };

    const handleCanDrop = (item) => {
        const currentColumnName = item;
        return true;
    };

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: 'response',
        drop: handleDrop,
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
        canDrop: (item: any) => handleCanDrop(item),
    });

    const isActive = isOver && canDrop;
    let backgroundColor = '#ffffff';
    if (isActive) {
        backgroundColor = 'lightgreen';
    } else if (canDrop) {
        backgroundColor = 'lightblue';
    }

    const moveItemHandler = (dragIndex, hoverIndex) => {
        const dragItem = finishedResponses[dragIndex];
        if (dragItem) {
            setTabData((prevState) => {
                const coppiedStateArray = [...prevState];
                // remove item by "hoverIndex" and put "dragItem" instead
                const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
                // remove item by "dragIndex" and put "prevItem" instead
                coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
                return coppiedStateArray;
            });
        }
    };

    return (
        <Accordion ref={drop}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ background: '#ffffff', flexDirection: 'row-reverse' }}>
                <Grid container>
                    <Grid item>
                        <Typography style={{ fontWeight: isOver ? 'bold' : 'normal', color: isOver || canDrop ? `${backgroundColor}` : 'black' }}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px', marginTop: 'auto', color: '#A0A0A0' }}>
                        <Typography variant="body2">{`${count} records`}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{ borderRadius: '8px', background: '#f5f5f5f5', width: '100%', padding: '10px' }}>
                    <div>
                        <Grid container spacing={2} style={{ padding: '10px', textAlign: 'center' }}>
                            <Grid item xs={1}>
                                Response Title
                            </Grid>
                            <Grid item xs={1}>
                                Response ID#
                            </Grid>
                            <Grid item xs={1}>
                                Organization
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Appeal
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Value
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Reliability
                            </Grid>
                            <Grid item className={classes.itemW}>
                                People
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Planning/Process
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Finances
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Size/Scope
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Market Demand
                            </Grid>
                            <Grid item className={classes.itemW}>
                                Delivery (Market Access)
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        {finishedResponses
                            .filter((i) => i.selectedStatus === status)
                            .map((i) => {
                                const index = finishedResponses.findIndex((j) => j.id == i.id);
                                return (
                                    <DownSelectionItem
                                        key={i.id}
                                        response={i}
                                        sectionStatus={status}
                                        setTabData={setTabData}
                                        index={index}
                                        moveItemHandler={moveItemHandler}
                                        backgroundColor={backgroundColor}
                                        readOnly={readOnly}
                                    />
                                );
                            })}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default DownSelectionContainer;
