import React, { useEffect, useState } from 'react';
import { EvaluatorScoreInputType, FundamentalPrimeMeasurement, ResponseStatus, ResponseUserScoreInputType } from '../../../apollo/generated/types/globalTypes';
import { filterAndMapScores, FormMode, ScoreSectionsType } from '../../../utils/Enums';
import { useTeamLeadScore } from '../../Providers/TeamLeadScoreProvider';
import EvaluationHeader from './EvaluationScore/EvaluationHeader';
import { FormProvider, useForm } from 'react-hook-form';
import FinalizeScore from './FinalizeScore';
import { Box } from '@material-ui/core';
import './scrollbar.css';
import EvaluationScore from './EvaluationScore';
import { LoadingMessage, UnSavedChangesDialog } from '../..';
import { usePermissions } from '../../Providers';
import { useParams } from 'react-router-dom';
import { UPDATE_EVALUATOR_SCORES } from '../../../apollo/mutations';
import {
    UpdateEvaluatorScores,
    UpdateEvaluatorScoresVariables,
    UpdateEvaluatorScores_updateEvaluatorScores,
} from '../../../apollo/generated/types/UpdateEvaluatorScores';
import { ApolloError, useMutation } from '@apollo/client';
import { GetResponseUsers_responseUsers } from '../../../apollo/generated/types/GetResponseUsers';
import { useCycleData } from '../../Providers/CycleDataProvider';

interface URLParams {
    id: string; // Acquisition Cycle ID
    responseId: string;
}

export interface ScoreFormData {
    evaluations: {
        id: number | null;
        userId: number | null;
        status: ResponseStatus | null;
        reviewed: boolean;
        isTeamLead: boolean;
        categories: {
            APPEAL: EvaluatorScoreInputType[];
            VALUE: EvaluatorScoreInputType[];
            RELIABILITY: EvaluatorScoreInputType[];
            PERSONNEL: EvaluatorScoreInputType[];
            PROCESSES: EvaluatorScoreInputType[];
            FINANCES: EvaluatorScoreInputType[];
            APPLICATION_SIZE: EvaluatorScoreInputType[];
            DEMAND: EvaluatorScoreInputType[];
            DELIVERY: EvaluatorScoreInputType[];
        } | null;
    }[];
}

export interface TeamLeadScoreFormProps {
    isHistoryView?: boolean;
}

const TeamLeadScoreForm: React.FC<TeamLeadScoreFormProps> = ({ isHistoryView = false }) => {
    const { teamLeadEvaluation, evaluations, hasBriefResponse, loading } = useTeamLeadScore();
    const { isTeamLead, isSystemProgramManagement } = usePermissions();
    const { id, responseId } = useParams<URLParams>();
    const cycleId = parseInt(id);
    const { readOnly: cycleReadOnly } = useCycleData();
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);

    const convertResponseUsersToForm = (data?: GetResponseUsers_responseUsers[]) => {
        const result = {
            evaluations: data?.map((evaluation) => ({
                id: evaluation.id,
                userId: evaluation.userId,
                status: evaluation.status,
                reviewed: evaluation.reviewed,
                isTeamLead: evaluation.isTeamLead,
                categories: {
                    APPEAL: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.APPEAL),
                    VALUE: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.VALUE),
                    RELIABILITY: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.RELIABILITY),
                    PERSONNEL: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.PERSONNEL),
                    PROCESSES: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.PROCESSES),
                    FINANCES: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.FINANCES),
                    APPLICATION_SIZE: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.APPLICATION_SIZE),
                    DEMAND: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.DEMAND),
                    DELIVERY: filterAndMapScores(evaluation.evaluatorScores, FundamentalPrimeMeasurement.DELIVERY),
                },
            })),
        } as ScoreFormData;
        return result;
    };
    const defaults = convertResponseUsersToForm(teamLeadEvaluation ? [teamLeadEvaluation, ...evaluations] : evaluations);

    const methods = useForm<ScoreFormData>({ defaultValues: defaults });
    const mode = !cycleReadOnly && !isHistoryView && (isTeamLead(cycleId) || isSystemProgramManagement()) ? (defaults ? FormMode.Edit : FormMode.Create) : FormMode.View;

    useEffect(() => {
        if (!loading) methods.reset(convertResponseUsersToForm(teamLeadEvaluation ? [teamLeadEvaluation, ...evaluations] : evaluations));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, teamLeadEvaluation, evaluations]);

    // Score Update mutation and Submission function
    const [updateScores] = useMutation<UpdateEvaluatorScores, UpdateEvaluatorScoresVariables>(UPDATE_EVALUATOR_SCORES, {
        fetchPolicy: 'no-cache',
        onError: (e: ApolloError) => {
            console.log(e);
        },
    });
    const onSubmit = async (data: ScoreFormData, newStatus?: ResponseStatus, finalize = false): Promise<void> => {
        setSubmitLoading(true);
        if (data) {
            const newEvaluations: UpdateEvaluatorScores_updateEvaluatorScores[] = [];
            for (let i = 0; i < data.evaluations.length; i += 1) {
                const evaluation = data.evaluations[i];
                const newEvaluation = (
                    await updateScores({
                        variables: {
                            responseUser: {
                                id: evaluation.id,
                                userId: evaluation.userId,
                                responseId: Number(responseId),
                                statusId: finalize || evaluation.id === teamLeadEvaluation?.id ? newStatus : evaluation.status,
                                reviewed: evaluation.reviewed ?? null,
                                evaluatorScores: evaluation.categories ? Object.values(evaluation.categories).flat() : [],
                            } as ResponseUserScoreInputType,
                        },
                    })
                )?.data?.updateEvaluatorScores;

                if (newEvaluation) newEvaluations.push(newEvaluation);
            }

            methods.reset(convertResponseUsersToForm(newEvaluations));
        }
        setSubmitLoading(false);
    };

    return (
        <FormProvider {...methods}>
            {loading ? (
                <LoadingMessage />
            ) : (
                <form
                    id="score-finalization"
                    style={{ width: '100%', height: '100%', margin: '0px', display: 'flex', boxSizing: 'border-box' }}
                    onSubmit={methods.handleSubmit((data: ScoreFormData) => onSubmit(data, ResponseStatus.IN_PROGRESS))}>
                    <Box id="left" style={{ width: '70%', height: '100%', display: 'flex', flexDirection: 'column', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <EvaluationHeader mode={mode} />
                        <EvaluationScore sectionNumber={ScoreSectionsType.PRODUCT_TECHNOLOGY} />
                        {!hasBriefResponse && (
                            <>
                                <EvaluationScore sectionNumber={ScoreSectionsType.ORGANIZATION} />
                                <EvaluationScore sectionNumber={ScoreSectionsType.APPLICATION} />
                            </>
                        )}
                    </Box>

                    {teamLeadEvaluation ? (
                        <Box id="right" style={{ width: '30%', height: '100%' }}>
                            <FinalizeScore mode={mode} onSubmit={onSubmit} loading={submitLoading} isHistoryView={isHistoryView} />
                        </Box>
                    ) : null}

                    {mode === FormMode.View ? null : (<UnSavedChangesDialog />)}
                </form>
            )}
        </FormProvider>
    );
};

export default TeamLeadScoreForm;
