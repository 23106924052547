import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PageWrapper from '../components/PageWrapper';
import { IconButton, Toolbar, Typography, TextField, Button, Grid, ListItemText, ListItem, List, ListItemSecondaryAction } from '@material-ui/core';
import { downloadBlobFromStorage } from '../utils/file-storage-util';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SUB_CYCLE_FILES, GET_FILE_DOWNLOAD_LINK } from '../apollo/queries';
import { FileUploadedEvent, PageBody, PageHeader } from '../components';
import FormSection from '../components/Form/FormSection';
import { GetSubCycleFiles, GetSubCycleFiles_subCycleFiles } from '../apollo/generated/types/GetSubCycleFiles';
import { FileDropZone } from '../components/FileDropZone';
import SaveIcon from '@material-ui/icons/Save';
import { SAVE_FILE } from '../apollo/mutations';
import { SaveFile, SaveFileVariables } from '../apollo/generated/types/SaveFile';
import { GetFileDownloadLink } from '../apollo/generated/types/GetFileDownloadLink';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    secondaryText: {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        width: '100%',
        overflow: 'hidden',
    },
});

const FileUploadPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();

    const [subCycleId, setSubCycleId] = useState<number>(0);
    const [uploadedFiles, setUploadedFiles] = useState<Array<FileUploadedEvent>>([]);
    const [getSubCycleFiles, { data }] = useLazyQuery<GetSubCycleFiles>(GET_SUB_CYCLE_FILES, {
        fetchPolicy: 'cache-and-network',
    });
    // Handle file downloads

    const [saveFile] = useMutation<SaveFile, SaveFileVariables>(SAVE_FILE);
    const [getFileDownloadLink, { error: downloadError }] = useLazyQuery<GetFileDownloadLink>(GET_FILE_DOWNLOAD_LINK, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (result) => {
            if (result.subCycleFile) {
                downloadBlobFromStorage(result.subCycleFile.fileName, result.subCycleFile.url);
            }
        },
    });

    const onFileUploaded = (file: FileUploadedEvent) => {
        setUploadedFiles((files) => [...files, file]);
    };

    const onLoadFiles = () => {
        getSubCycleFiles({
            variables: { subCycleId },
        });
    };

    const downloadFile = (fileId: number) => {
        getFileDownloadLink({
            variables: {
                id: fileId,
            },
        });
    };

    const onSaveFile = async (token: string) => {
        await saveFile({
            variables: {
                token: token,
            },
        });
        await getSubCycleFiles({
            variables: { subCycleId },
        });
    };

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <IconButton onClick={history.goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1">Upload test</Typography>
                </Toolbar>
            </PageHeader>
            <PageBody>
                <div>
                    {/* Azure Storage upload/download test */}
                    <FormSection>
                        <Grid item xs={12}>
                            <Typography variant="h2">SubCycle File Upload Test</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="SubCycle ID"
                                id="subCycleId"
                                type="number"
                                value={subCycleId}
                                onChange={(event) => setSubCycleId(parseInt(event.target.value))}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" onClick={onLoadFiles}>
                                Load SubCycleFiles
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <FileDropZone maxFiles={1} id={subCycleId} onFileUploaded={onFileUploaded} />
                        </Grid>
                        <Grid item xs={6}/>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2">Temporary Files:</Typography>
                            <List>
                                {uploadedFiles ? (
                                    uploadedFiles.map((uploadedFile, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={uploadedFile.fileName}
                                                secondary={<p className={classes.secondaryText}>{uploadedFile.token}</p>}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" aria-label="delete" onClick={() => onSaveFile(uploadedFile.token)}>
                                                    <SaveIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                ) : (
                                    <div>Upload a file to begin</div>
                                )}
                            </List>
                        </Grid>

                        <Grid item xs={6}>
                            {/* Generates a list of links for downloading Azure storage files */}
                            <Typography variant="subtitle2">Saved Files:</Typography>
                            {data && data.subCycleFiles ? (
                                <ul>
                                    {data.subCycleFiles.map((subCycleFile: GetSubCycleFiles_subCycleFiles) => {
                                        return (
                                            <li key={subCycleFile.id}>
                                                <Button
                                                    onClick={() => {
                                                        downloadFile(subCycleFile.id);
                                                    }}>
                                                    {subCycleFile.fileName}
                                                </Button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <div>No file saved.</div>
                            )}
                            {downloadError?.message}
                        </Grid>
                    </FormSection>
                </div>
            </PageBody>
        </PageWrapper>
    );
};

export default FileUploadPage;
