import PageRoutes from './PageRoutes';
import LoginPage from './LoginPage';
import DashboardPage from './DashboardPage';
import AcquisitionCyclesPage from './Cycles/AcquisitionCyclesPage';
import UsersPage from './Users/UsersPage';
import RespondersPage from './RespondersPage';
import NotFoundPage from './NotFoundPage';
import AcquisitionCyclePage from './Cycles/AcquisitionCyclePage';
import CreateAcquisitionCyclePage from './Cycles/CreateAcquisitionCyclePage';
import EditAcquisitionCyclePage from './Cycles/EditAcquisitionCyclePage';
import SubCycleFormPage from './Cycles/SubCycle/SubCycleFormPage';
import SubCycleSuccessFactorPage from './Cycles/SubCycle/SubCycleSuccessFactorPage';
import CreateUserPage from './Users/CreateUserPage';
import EditUserPage from './Users/EditUserPage';
import FileUploadPage from './FileUploadPage';
import ProfilePage from './ProfilePage';
import EvaluatorProfilePage from './Evaluator/EvaluatorProfilePage';
import EvaluatorScorePage from './Cycles/SubCycle/EvaluatorScorePage';
import TeamLeadScorePage from './Cycles/SubCycle/TeamLeadScorePage';

export default {
    PageRoutes,
    LoginPage,
    DashboardPage,
    NotFoundPage,
    AcquisitionCyclesPage,
    UsersPage,
    RespondersPage,
    AcquisitionCyclePage,
    CreateAcquisitionCyclePage,
    EditAcquisitionCyclePage,
    SubCycleFormPage,
    SubCycleSuccessFactorPage,
    CreateUserPage,
    EditUserPage,
    FileUploadPage,
    ProfilePage,
    EvaluatorProfilePage,
    EvaluatorScorePage,
    TeamLeadScorePage,
};
