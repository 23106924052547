import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, InputLabel } from '@material-ui/core';

const ReactHookFormCurrency: React.FC<ReactHookFormCurrencyProps> = ({ name, label, error, helperText, control, defaultValue, rules, readOnly }) => {
    const labelId = `${name}-label`;
    return (
        <FormControl fullWidth error={error}>
            <InputLabel id={labelId} shrink>
                {label}
            </InputLabel>
            <div
                className={
                    'MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl ' +
                    (error ? 'Mui-error' : '')
                }>
                <Controller
                    render={({ ref, value, onChange, onBlur, name }) => (
                        <CurrencyInput
                            ref={ref}
                            className="MuiInputBase-input MuiInput-input"
                            name={name}
                            value={value || undefined}
                            allowDecimals={false}
                            allowNegativeValue={false}
                            prefix="$"
                            intlConfig={{ locale: 'en-US', currency: 'USD' }}
                            decimalsLimit={0}
                            readOnly={readOnly}
                            onValueChange={(value: string | undefined) => {
                                onChange(value ? Number(value) : value);
                            }}
                            onBlur={onBlur}
                        />
                    )}
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                />
            </div>
            {helperText ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};

export interface ReactHookFormCurrencyProps {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    label: string;
    defaultValue?: number;
    error?: boolean;
    readOnly?: boolean;
    helperText?: string;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
}

export default ReactHookFormCurrency;
