import * as React from 'react';
import { createContext, useCallback, useState } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { GetTemplates } from '../../../apollo/generated/types/GetTemplates';
import { GET_TEMPLATES } from '../../../apollo/queries';
import { TemplateNameFields } from '../../../apollo/generated/types/TemplateNameFields';

interface TemplateContextProps {
    loading: boolean;
    error?: ApolloError;
    templates?: Array<TemplateNameFields>;
    add: (template: TemplateNameFields) => void;
}

const TemplateContext = createContext<TemplateContextProps | undefined>(undefined);

const TemplateProvider: React.FC = ({ children }) => {
    const [templates, setTemplates] = useState<Array<TemplateNameFields> | undefined>();
    const { loading, error } = useQuery<GetTemplates>(GET_TEMPLATES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data && data.templates) {
                setTemplates(data.templates);
            }
        },
    });

    const add = useCallback((template: TemplateNameFields) => {
        setTemplates((currentList) => [...(currentList || []), template]);
    }, []);
    return (
        <TemplateContext.Provider
            value={{
                loading,
                error,
                templates,
                add,
            }}>
            {children}
        </TemplateContext.Provider>
    );
};

const useTemplate = (): TemplateContextProps => {
    const context = React.useContext(TemplateContext);
    if (context === undefined) {
        throw new Error('useTemplate must be used within a TemplateProvider');
    }
    return context;
};

export { TemplateProvider, useTemplate };
