import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BookIcon from '@material-ui/icons/Book';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { HasPermission } from '../index';

const useStyles = makeStyles({
    iconWrapper: {
        minWidth: '32px',
    },
    link: {
        'width': 'calc(100% - 30px)',
        'marginLeft': '15px',
        'color': '#FFFFFF',
        'letterSpacing': '-0.3px',
        '&:hover': {
            color: '#1976D2',
        },
    },
    activeLink: {
        'color': '#FFFFFF',
        'background': '#1976D2',
        'borderRadius': '2px',
        '&:hover': {
            background: '#1F8BF3',
            color: '#FFFFFF',
        },
    },
});

const Navigation: React.FC = () => {
    const classes = useStyles();

    return (
        <List>
            <ListItem exact to="/" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                <ListItemIcon className={classes.iconWrapper}>
                    <DashboardIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem to="/acquisition-cycles" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                <ListItemIcon className={classes.iconWrapper}>
                    <BookIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Acquisition Cycles" />
            </ListItem>
            <ListItem to="/responders" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                <ListItemIcon className={classes.iconWrapper}>
                    <AccountCircleIcon fontSize="small" color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Responders" />
            </ListItem>
            <HasPermission permission="read:users">
                <ListItem to="/users" component={NavLink} className={classes.link} activeClassName={classes.activeLink}>
                    <ListItemIcon className={classes.iconWrapper}>
                        <PermContactCalendarIcon fontSize="small" color="secondary" />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItem>
            </HasPermission>
        </List>
    );
};

export default Navigation;
