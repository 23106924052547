import { SubCycleForm } from '../../../apollo/generated/types/globalTypes';

export const getFormTypeShortName = (value: SubCycleForm): string => {
    switch (value) {
        case SubCycleForm.REQUEST_FOR_INFORMATION:
            return 'RFI';
        case SubCycleForm.REQUEST_FOR_WHITE_PAPER:
            return 'RWP';
        case SubCycleForm.REQUEST_FOR_PROPOSAL:
            return 'RFP';
        default:
            return '';
    }
};

export const ConvertFormTypeEnumToDisplay = (value: SubCycleForm): string => {
    switch (value) {
        case SubCycleForm.REQUEST_FOR_INFORMATION:
            return 'Request for Information';
        case SubCycleForm.REQUEST_FOR_WHITE_PAPER:
            return 'Request for White Paper';
        case SubCycleForm.REQUEST_FOR_PROPOSAL:
            return 'Request for Proposal';
        default:
            return '';
    }
};

export const getFormTypeShortNameToDisplayName = (value: string | undefined): string => {
    switch (value) {
        case 'RFI':
            return 'Request for Information';
        case 'RWP':
            return 'Request for White Paper';
        case 'RFP':
            return 'Request for Proposal';
        default:
            return '';
    }
};
