export enum CycleTab {
    Evaluator = 'evaluators',
    SubCycle = 'sub-cycles',
    Response = 'response',
    DownSelection = 'down-select',
    Award = 'award',
}

export enum CycleSubTab {
    InProcessResponses = 'in-process',
    FinalizedResponses = 'finalized',
    ResponseHistory = 'history',
}
