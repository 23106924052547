import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import { InputLabel, FormHelperText, Select, SelectProps } from '@material-ui/core';
import { Controller, RegisterOptions } from 'react-hook-form';
import * as React from 'react';
import { ChangeEvent } from 'react';

const ReactHookFormSelect: React.FC<ReactHookFormSelectProps> = ({
    name,
    label,
    control,
    defaultValue,
    helperText,
    readOnly,
    rules,
    onSelectChange,
    children,
    selectProps,
    loading,
    ...formProps
}) => {
    const labelId = `${name}-label`;
    return (
        <FormControl {...formProps}>
            <InputLabel id={labelId} shrink>
                {label}
            </InputLabel>
            <Controller
                control={control}
                rules={rules}
                name={name}
                defaultValue={defaultValue || null}
                render={({ onChange, onBlur, value, name, ref }, { invalid }) => (
                    <Select
                        name={name}
                        inputRef={ref}
                        onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                            if (onSelectChange) {
                                onSelectChange(e);
                            } else {
                                onChange(e);
                            }
                        }}
                        onBlur={onBlur}
                        value={value || ''}
                        labelId={labelId}
                        label={label}
                        error={invalid}
                        disabled={loading}
                        readOnly={readOnly}
                        {...selectProps}>
                        {children}
                    </Select>
                )}
            />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};
export interface ReactHookFormSelectProps extends FormControlProps {
    name: string;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValue?: string | number | string[] | any[];
    helperText?: string;
    readOnly?: boolean;
    loading?: boolean;
    onSelectChange?: (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    selectProps?: SelectProps;
}

export default ReactHookFormSelect;
