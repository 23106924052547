import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { ReactHookFormCheckbox, ReactHookFormTextArea } from '../../../index';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const SubmissionInstructionsForm: React.FC<SubmissionInstructionsFormProps> = ({ mode }) => {
    const { control, errors, watch } = useFormContext();
    const status = watch('status');
    const hasAcceptingQuestionSubmission = watch('hasAcceptingQuestionSubmission', false);

    const required = status !== SubCycleStatus.DRAFT;
    const requiredAsterisk = required ? '*' : '';
    const readOnly = mode === FormMode.View;
    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Submission Instructions</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label={`Instructions / Requirements${requiredAsterisk}`}
                        name="instructions"
                        defaultValue="<p>The White Paper (WP) templates&nbsp;(i.e., 6.2 and 6.3)&nbsp;are provided as attachments to this RWP.&nbsp;All White Papers shall be unclassified. White Papers containing data that is not to be disclosed to the public for any purpose or used by the Government except for evaluation purposes shall be clearly marked as such.&nbsp;The Government will not pay Companies for the costs associated with White Paper submissions.</p>"
                        rules={{
                            required: required ? 'Instructions / Requirements must have some content.' : false,
                            validate: (value) => {
                                return !required || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Instructions / Requirements must have some content.';
                            },
                        }}
                        error={!!errors.instructions}
                        helperText={errors.instructions?.message}
                        readOnly={readOnly}
                        subHeading={
                            <>
                                <p>Suggested Information to Include: </p>
                                <ul>
                                    <li>References/links to white paper templates for each R&D category</li>
                                    <li>Classification of submission: (UNCLASSIFIED, SECRET, TOP SECRET, etc.)</li>
                                    <li>
                                        If templates are not being provided, then additional information should be provided here such as: Formatting, page
                                        limits, required sections, cover sheet requirements, etc.
                                    </li>
                                    <li>Submission method (ex. email, upload to a proposal submission site, etc.)</li>
                                    <li>If by email, to whom, required subject line information</li>
                                    <li>Submissions shall be received no later than date / time / time zone</li>
                                    <li>Other specific instructions and comments</li>
                                </ul>
                            </>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="hasAcceptingQuestionSubmission"
                        label="Will you have an Open Question Period?"
                        defaultValue={false}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Include Open Period Instructions Here"
                        name="submissionOfQuestions"
                        defaultValue={`<p><span style="color: rgb(237, 92, 87);">Questions regarding White Paper procedures should be directed to Washington Headquarters Services , Acquisition Directorate, to </span><a href="mailto:whs.mc-alex.ad.mbx.rpab@mail.mil" rel="noopener noreferrer" target="_blank" style="color: rgb(237, 92, 87);">whs.mc-alex.ad.mbx.rpab@mail.mil</a><span style="color: rgb(237, 92, 87);">; please include JEMTP in the subject line. White Papers shall be submitted to whs.mc-alex.ad.mbx.rpab@mail.mil by 10 AM EDT on March 23, 2020. Companies may submit one White Paper in response to this RWP.</span></p>`}
                        error={!!errors.submissionOfQuestions}
                        helperText={errors.submissionOfQuestions?.message}
                        readOnly={readOnly}
                        hidden={!hasAcceptingQuestionSubmission}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormTextArea
                        fullWidth
                        control={control}
                        label="Additional Terms & Conditions"
                        name="additionalTermsAndConditions"
                        defaultValue=""
                        error={!!errors.additionalTermsAndConditions}
                        helperText={errors.additionalTermsAndConditions?.message}
                        readOnly={readOnly}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface SubmissionInstructionsFormProps {
    mode: FormMode;
}

export default SubmissionInstructionsForm;
