import React from 'react';
import { ErrorMessage, LoadingMessage, SideBar, usePermissions } from '../index';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        display: 'flex',
    },
    sidebar: {
        position: 'relative',
        width: '260px',
        height: '100vh',
        background: '#222A43',
    },
    page: {
        height: '100vh',
        width: '100%',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        background: '#F6F8FC',
    },
    center: {
        display: 'flex',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export interface PageWrapperProps {
    permission?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = ({ permission, children }) => {
    const history = useHistory();
    const classes = useStyles();
    const { hasPermission, authenticationLoading, error, userLoaded, user } = usePermissions();

    if (error) {
        return (
            <div className={classes.center}>
                <ErrorMessage error={error} reload={true} />
            </div>
        );
    }

    if (authenticationLoading || !userLoaded) {
        return (
            <div className={classes.center}>
                <LoadingMessage />
            </div>
        );
    }

    if (permission !== undefined && !hasPermission(permission)) {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        );
    }

    const profilePath = '/profile';
    if (!user?.isProfileComplete && history.location.pathname !== profilePath) {
        return (
            <Redirect
                to={{
                    pathname: profilePath,
                }}
            />
        );
    }

    return (
        <div className={classes.root}>
            <aside className={classes.sidebar}>
                <SideBar />
            </aside>
            <div className={classes.page}>{children}</div>
        </div>
    );
};

export default PageWrapper;
