import React, { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CircularProgress, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { GET_FILE_DOWNLOAD_LINK, GET_SUB_CYCLE_FILE } from '../../../apollo/queries';
import { useFormContext } from 'react-hook-form';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { GetSubCycleFile, GetSubCycleFileVariables } from '../../../apollo/generated/types/GetSubCycleFile';
import ModalDeleteConfirmDialog from '../ModalDeleteConfirmDialog';
import { GetFileDownloadLink, GetFileDownloadLinkVariables } from '../../../apollo/generated/types/GetFileDownloadLink';
import { downloadBlobFromStorage } from '../../../utils/file-storage-util';

const FileDisplay: React.FC<FileDisplayProps> = ({ index, id, name, onDelete, openFile = false }) => {
    const { register } = useFormContext();
    const [confirm, setConfirm] = useState(false);

    const { data, loading } = useQuery<GetSubCycleFile, GetSubCycleFileVariables>(GET_SUB_CYCLE_FILE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: id,
        },
    });

    const [getFileDownloadLink] = useLazyQuery<GetFileDownloadLink, GetFileDownloadLinkVariables>(GET_FILE_DOWNLOAD_LINK, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: id,
        },
        onCompleted: (result) => {
            if (result.subCycleFile) {
                downloadBlobFromStorage(result.subCycleFile.fileName, result.subCycleFile.url, openFile);
            }
        },
    });

    const handleConfirm = () => {
        setConfirm(false);
        onDelete(index);
    };

    const download = () => {
        getFileDownloadLink();
    };

    return (
        <ListItem button onClick={download}>
            <input name={`${name}[${index}].id`} ref={register({ valueAsNumber: true })} type="hidden" value={id} />
            <input name={`${name}[${index}].uploadToken`} ref={register({ valueAsNumber: true })} type="hidden" value="" />
            <ListItemIcon>{loading ? <CircularProgress /> : <AttachFileIcon />}</ListItemIcon>
            <ListItemText primary={loading ? 'Getting file information...' : data?.subCycleFile.fileName} />
            {!loading && (
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => setConfirm(true)}>
                        <DeleteIcon />
                    </IconButton>
                    <ModalDeleteConfirmDialog
                        open={confirm}
                        fileName={data?.subCycleFile.fileName || ''}
                        onPrimaryClick={handleConfirm}
                        onSecondaryClick={() => setConfirm(false)}
                        onClose={() => setConfirm(false)}
                    />
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};

export interface FileDisplayProps {
    index: number;
    id: number;
    name: string;
    onDelete: (index: number) => void;
    openFile?: boolean;
}

export default FileDisplay;
