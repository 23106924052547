import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { PageWrapper, PageHeader, PageBody, TableActionMenu, usePermissions, LoadingMessage, ErrorMessage, HasPermission } from '../../components';
import MUIDataTable, { debounceSearchRender, MUIDataTableOptions, MUIDataTableState } from 'mui-datatables';
import { useMutation, useLazyQuery, ApolloError } from '@apollo/client';
import { GET_CYCLES } from '../../apollo/queries';
import { GetCycles, GetCycles_cycles_items } from '../../apollo/generated/types/GetCycles';
import { useHistory } from 'react-router';
import { ARCHIVE_CYCLE } from '../../apollo/mutations';
import { ArchiveCycle, ArchiveCycleVariables } from '../../apollo/generated/types/ArchiveCycle';
import { format } from 'date-fns';
import { CycleStatus } from '../../apollo/generated/types/globalTypes';
import { getCycleStatusDisplayName } from '../../utils/Enums';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
    },
    table: {
        '& .MuiTableCell-head:last-child': {
            width: '50px',
        },
    },
});

const AcquisitionCyclesPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { isSystemProgramManagement } = usePermissions();
    const [cycles, setCycles] = useState<Array<GetCycles_cycles_items>>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [search, setSearch] = useState<string | null>('');
    const [sortColumn, setSortColumn] = useState<string>('status');
    const [sortDirection, setSortDirection] = useState<string>('none');
    const [statusFilter, setStatusFilter] = useState<Array<CycleStatus>>([]);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(false);
    const { updateUserInfo } = usePermissions();

    const [getCycles, { error, called }] = useLazyQuery<GetCycles>(GET_CYCLES, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetCycles) => {
            if (data.cycles?.items) {
                setCycles(data.cycles.items);
            }
            setCount(data.cycles?.pageInfo?.total || 0);
            setIsFirstLoad(true);
        },
        onError: (err: ApolloError) => {
            if (err.graphQLErrors) {
                const hasAuthenticationError = err.graphQLErrors.some(
                    (e) => e.extensions?.code.toUpperCase() === 'AUTHORIZATION' || e.extensions?.code.toUpperCase() === 'AUTHENTICATION',
                );
                if (hasAuthenticationError) {
                    history.push('/acquisition-cycles');
                }
            }
            console.error(err);
        },
    });
    const [archiveCycle] = useMutation<ArchiveCycle, ArchiveCycleVariables>(ARCHIVE_CYCLE, {
        onCompleted: (data: ArchiveCycle) => {
            setCycles(cycles.map((cycle) => (cycle.id === data.archiveCycle.id ? { ...cycle, status: data.archiveCycle.status } : cycle)));
        },
    });

    useEffect(() => {
        getCycles({
            variables: {
                page: page,
                pageSize: pageSize,
                search: search,
                orderBy: sortColumn,
                orderDirection: sortDirection,
                statusFilter: statusFilter,
            },
        });
    }, [getCycles, page, pageSize, search, sortColumn, sortDirection, statusFilter]);

    /**
     * handler for Viewing an Acquisition Cycle
     * @param id
     */
    const handleViewClick = (id: number) => {
        updateUserInfo();
        history.push(`/acquisition-cycle/${id}`);
    };

    /**
     * handler for Editing an Acquisition Cycle
     * @param id
     */
    const handleEditClick = (id: number) => {
        history.push(`/acquisition-cycle/${id}/edit`);
    };

    /**
     * Handler for Archiving an Acquisition Cycle
     * @param id
     */
    const handleArchiveClick = (id: number) => {
        archiveCycle({
            variables: {
                cycleId: id,
            },
        });
    };

    const columns = [
        {
            name: 'title',
            label: 'Acquisition Cycle Name',
            options: {
                filter: false,
                sort: true,
                sortThirdClickReset: true,
            },
        },
        {
            name: 'externalId',
            label: 'ID #',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                filterList: statusFilter,
                sort: true,
                sortThirdClickReset: true,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                filterType: 'multiselect' as any,
                filterOptions: {
                    fullWidth: true,
                    names: Object.values(CycleStatus),
                    renderValue: (value: string) => getCycleStatusDisplayName(value),
                },
                customFilterListOptions: {
                    render: (value: CycleStatus) => getCycleStatusDisplayName(value),
                },
                customBodyRender: (value: CycleStatus) => getCycleStatusDisplayName(value),
            },
        },
        {
            name: 'estimatedCloseDate',
            label: 'Estimated Closing Date',
            options: {
                filter: false,
                sort: false,
                sortThirdClickReset: true,
                customBodyRender: (value: string) => format(new Date(value), 'MM/dd/yyyy'),
            },
        },
        {
            name: 'cycleFundings.fundingType',
            label: 'Funding Instrument Type',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    const cycle = cycles[dataIndex];
                    if (cycle.cycleFundings && cycle.cycleFundings.length > 0) {
                        return cycle.cycleFundings[0].fundingType ? cycle.cycleFundings[0].fundingType?.name : '';
                    } else {
                        return '';
                    }
                },
            },
        },
        {
            name: 'cycleFundings.fundingAmount',
            label: 'Identified Funding',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                    const cycle = cycles[dataIndex];
                    if (cycle.cycleFundings && cycle.cycleFundings.length > 0) {
                        return cycle.cycleFundings[0].fundingAmount ? `$${Number(cycle.cycleFundings[0].fundingAmount).toLocaleString()}` : '';
                    } else {
                        return '';
                    }
                },
            },
        },
        {
            name: 'primaryContact',
            label: 'Primary Contact',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex: number) => cycles[dataIndex].primaryContact?.fullName,
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    const cycle = cycles[dataIndex];
                    const editable = isSystemProgramManagement() && cycle.status !== CycleStatus.ARCHIVED && cycle.status !== CycleStatus.COMPLETED;
                    return (
                    <TableActionMenu
                        id={cycle.id}
                        index={rowIndex}
                        viewText="View Sub-Cycles"
                        editText={editable ? 'Edit' : 'View Acquisition Cycle'}
                        deleteText="Archive"
                        showView={true}
                        showEdit={true}
                        showDelete={editable}
                        onViewClick={handleViewClick}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleArchiveClick}
                    />
                );
                },
            },
        },
    ];

    const options = {
        serverSide: true,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        page: page,
        rowsPerPage: pageSize,
        searchText: search,
        customSearchRender: debounceSearchRender(500),
        count: count,
        sortOrder: {
            name: sortColumn,
            direction: sortDirection,
        },
        selectableRows: 'none' as const,
        onTableChange: (action: string, tableState: MUIDataTableState) => {
            switch (action) {
                case 'changePage':
                    setPage(tableState.page);
                    break;
                case 'changeRowsPerPage':
                    setPage(0);
                    setPageSize(tableState.rowsPerPage);
                    break;
                case 'search':
                    setPage(0);
                    setSearch(tableState.searchText);
                    break;
                case 'sort':
                    setSortColumn(tableState.sortOrder.name);
                    setSortDirection(tableState.sortOrder.direction);
                    break;
                case 'filterChange':
                    setPage(0);
                    setStatusFilter(tableState.filterList[2] as CycleStatus[]);
                    // ex: filterList: Array(3)
                    //     0: []
                    //     1: ["Active"] // Status
                    //     2: []
                    break;
                case 'resetFilters':
                    setPage(0);
                    setPageSize(10);
                    setSearch(null);
                    setSortColumn('status');
                    setSortDirection('none');
                    setStatusFilter([]);
                    break;
                default:
                // Unused Actions
                // ------------------
                // columnOrderChange
                // expandRow
                // propsUpdate
                // rowDelete
                // rowExpansionChange
                // rowSelectionChange
                // viewColumnsChange
                //console.log(`action '${action}' not handled.`);
            }
        },
    } as MUIDataTableOptions;

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <Typography variant="h1" className={classes.title}>
                        Acquisition Cycles
                    </Typography>
                    <HasPermission permission="create:cycles">
                        <Button startIcon={<AddCircleIcon />} component={NavLink} to="/acquisition-cycle/create" variant="contained">
                            Create New
                        </Button>
                    </HasPermission>
                </Toolbar>
            </PageHeader>
            <PageBody>
                {!called || !isFirstLoad ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : (
                    <div className={classes.table}>
                        <MUIDataTable title="Acquisition Cycle List" data={cycles} columns={columns} options={options} />
                    </div>
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default AcquisitionCyclesPage;
