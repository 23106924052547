import * as React from 'react';
import { MenuItem } from '@material-ui/core';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { GET_TEMPLATE } from '../../../../apollo/queries';
import { ReactHookFormSelect } from '../../../ReactHookForm';
import { useFormContext } from 'react-hook-form';
import { FormMode } from '../../../../utils/Enums';
import { ConfirmDialog, BoldUnderline, useTemplate } from '../../../index';
import { ChangeEvent, useState } from 'react';
import { GetTemplate } from '../../../../apollo/generated/types/GetTemplate';
import { TemplateFields } from '../../../../apollo/generated/types/TemplateFields';
import { TemplateNameFields } from '../../../../apollo/generated/types/TemplateNameFields';
import WarningIcon from '@material-ui/icons/Warning';

const TemplateSelect: React.FC<TemplateSelectProps> = ({ mode, onTemplateChange }) => {
    const { control, getValues, setValue, errors } = useFormContext();
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<number>();
    const { loading, error, templates } = useTemplate();
    const [getTemplate] = useLazyQuery<GetTemplate>(GET_TEMPLATE, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetTemplate) => {
            onTemplateChange(data.template);
        },
        onError: (e: ApolloError) => {
            console.log(e);
        },
    });

    const handleTemplateChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
        const newTemplateId = e.target.value as number;
        const oldTemplateId = getValues('templateId');

        if (oldTemplateId) {
            setTemplateId(newTemplateId);
            setOpenConfirm(true);
        } else {
            updateTemplate(newTemplateId);
        }
    };

    const handleConfirm = () => {
        if (templateId) {
            updateTemplate(templateId);
        }
        setOpenConfirm(false);
    };

    const updateTemplate = (id: number) => {
        getTemplate({
            variables: {
                id: id,
            },
        });
        setValue('templateId', id);
    };

    return (
        <>
            <ReactHookFormSelect
                fullWidth
                control={control}
                label="Acquisition Cycle Template"
                name="templateId"
                onSelectChange={handleTemplateChange}
                defaultValue={getValues('templateId')}
                disabled={!!error}
                loading={loading}
                readOnly={mode === FormMode.View}
                error={!!error || !!errors.templateId}
                helperText={error ? 'Unable to retrieve data' : errors.templateId?.message}>
                {!templates || templates.length <= 0 ? (
                    <MenuItem disabled>No Data Available</MenuItem>
                ) : (
                    templates.map((template: TemplateNameFields) => (
                        <MenuItem key={template.id} value={template.id}>
                            {template.name}
                        </MenuItem>
                    ))
                )}
            </ReactHookFormSelect>
            <ConfirmDialog
                open={openConfirm}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
                title={
                    <>
                        Confirm to <BoldUnderline>Override Template</BoldUnderline>
                    </>
                }
                message="If you select to override the template, this will modify all existing sub-cycles and any changes will be lost. If you do not want to override, select cancel to keep the current sub-cycles."
                primaryText="Override"
                onPrimaryClick={handleConfirm}
                onSecondaryClick={() => setOpenConfirm(false)}
                onClose={() => setOpenConfirm(false)}
            />
        </>
    );
};

export interface TemplateSelectProps {
    mode: FormMode;
    onTemplateChange: (template: TemplateFields) => void;
}

export default TemplateSelect;
