import * as React from 'react';
import { FormControl, Select, InputLabel, FormHelperText } from '@material-ui/core';

const SelectPlaceholder: React.FC<SelectPlaceholderProps> = ({ label, helperText }) => {
    return (
        <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <Select value="" disabled={true} />
            {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
        </FormControl>
    );
};

export interface SelectPlaceholderProps {
    label?: string;
    helperText?: string;
}

export default SelectPlaceholder;
