/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { createContext, useState } from 'react';
import { GET_RESPONSE_USER, GET_TOPIC_SUCCESS_FACTORS } from '../../../apollo/queries';
import { GetResponseUser, GetResponseUser_responseUser, GetResponseUser_responseUser_evaluatorScores } from '../../../apollo/generated/types/GetResponseUser';
import { GetTopicSuccessFactors } from '../../../apollo/generated/types/GetTopicSuccessFactors';

interface EvaluatorScoreProviderProps {
    responseId?: number;
    userId?: number;
}

interface EvaluatorScoreContextProps {
    evaluatorResponse?: GetResponseUser_responseUser;
    loading: boolean;
}

const EvaluatorScoreContext = createContext<EvaluatorScoreContextProps | undefined>(undefined);

const EvaluatorScoreProvider: React.FC<EvaluatorScoreProviderProps> = ({ responseId, userId, children }) => {
    const [evaluatorResponse, setEvaluatorResponse] = useState<GetResponseUser_responseUser>();
    const [loading, setLoading] = useState<boolean>(true);

    // Query for fetching response data
    const { data: responseUserData } = useQuery<GetResponseUser>(GET_RESPONSE_USER, {
        fetchPolicy: 'no-cache',
        skip: !responseId || !userId,
        variables: {
            responseId: responseId,
            userId: userId,
        },
    });

    // Query for fetching success factor data
    const { data: successFactorData } = useQuery<GetTopicSuccessFactors>(GET_TOPIC_SUCCESS_FACTORS, {
        fetchPolicy: 'no-cache',
        skip: !responseUserData || !responseUserData?.responseUser.response.topic?.id,
        variables: {
            topicId: responseUserData?.responseUser.response.topic?.id,
        },
    });

    //  Extracts response user info from query data
    const setEvaluatorResponseData = useCallback(() => {
        if (responseUserData?.responseUser && successFactorData?.successFactors) {
            const responseUser = responseUserData.responseUser;
            const successFactors = successFactorData.successFactors;
            // Sets empty score data for ResponseUser
            let scores = responseUser.evaluatorScores;
            if (scores === null || scores.length !== successFactors.length) {
                if (!scores) scores = [];
                // Add new scores for Success Factors not already represented in EvaluatorScores
                scores.push(
                    ...successFactors
                        .filter((sFactor) => !scores?.some((score) => score.successFactor.id === sFactor.id))
                        .map(
                            (successFactor) =>
                                ({
                                    responseUserId: responseUser.id,
                                    successFactor: {
                                        id: successFactor.id,
                                        fpmCategory: successFactor.fpmCategory,
                                        name: successFactor.name,
                                        definition: successFactor.definition,
                                        weight: successFactor.weight,
                                    },
                                } as GetResponseUser_responseUser_evaluatorScores),
                        ),
                );
            }

            setEvaluatorResponse({
                id: responseUser.id,
                userId: responseUser.userId,
                response: responseUser.response,
                status: responseUser.status,
                evaluatorScores: scores,
                responseUserFiles: responseUser.responseUserFiles,
            } as GetResponseUser_responseUser);
            setLoading(false);
        }
    }, [responseUserData, successFactorData]);

    useEffect(() => {
        if (responseUserData && successFactorData) setEvaluatorResponseData();
    }, [responseUserData, successFactorData, setEvaluatorResponseData]);

    return (
        <EvaluatorScoreContext.Provider
            value={{
                evaluatorResponse,
                loading,
            }}>
            {children}
        </EvaluatorScoreContext.Provider>
    );
};

const useEvaluatorScore = (): EvaluatorScoreContextProps => {
    const context = React.useContext(EvaluatorScoreContext);
    if (context === undefined) {
        throw new Error('useEvaluatorScore must be used within a EvaluatorScoreProvider');
    }
    return context;
};

export { EvaluatorScoreProvider, useEvaluatorScore };
