import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, createStyles, Grid, IconButton, Paper, Popover, TextField, Theme, Typography } from '@material-ui/core';
import { FormMode } from '../../../../utils/Enums';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { FundamentalPrimeMeasurement } from '../../../../apollo/generated/types/globalTypes';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MessageIcon from '@material-ui/icons/Message';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { GetResponseUser_responseUser_evaluatorScores_successFactor } from '../../../../apollo/generated/types/GetResponseUser';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '15px',
        },
        accordion: {
            flex: 1,
        },
        removeSection: {
            width: '50px',
        },
        tooltip: {
            padding: theme.spacing(2),
        },
        alignRight: {
            textAlign: 'right',
        },
        weight: {
            textAlign: 'center',
            marginRight: '30px',
        },
        responder: {
            marginLeft: '100px',
            marginRight: '60px',
        },
        popover: {
            pointerEvents: 'none',
        },
        expandIcon: {
            'transform': 'rotate(0) !important',
            '&$ Mui-expanded': {
                color: 'red !important',

                transform: 'translateY(-50%) rotate(45deg)',
            },
        },
        customBadge: {
            backgroundColor: '#00AFD7',
            color: 'white',
        },
    }),
);

const EvaluatorScoreItem: React.FC<EvaluatorScoreItemProps> = ({ mode, index, fpmCategory, successFactor, responderScore, handleAverageScore }) => {
    const classes = useStyles();
    const { register, watch, getValues, errors } = useFormContext();
    const scoreFieldName = `categories[${fpmCategory}][${index}]`;
    const id = watch(`${scoreFieldName}.id`);
    const responseUserId = watch(`${scoreFieldName}.responseUserId`);
    const score = getValues(`${scoreFieldName}.score`);
    const [comment, setComment] = useState<string>(getValues(`${scoreFieldName}.comment`) ?? '');
    const readOnly = mode === FormMode.View;

    const [descRequirementsTooltipEl, setDescRequirementsTooltipEl] = useState<HTMLElement | null>(null);
    const handleDescRequirementsPopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setDescRequirementsTooltipEl(event.currentTarget);
    };
    const descRequirementsOpen = Boolean(descRequirementsTooltipEl);
    const handleDescRequirementsPopoverClose = () => {
        setDescRequirementsTooltipEl(null);
    };

    // Handling Errors
    const hasError = (field: string): boolean => {
        return !!errors.categories && !!errors.categories[`${field}`] && !!errors.categories[`${field}`][index];
    };
    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.categories[`${field}`][index]?.score.message : '';
    };

    useEffect(() => {
        handleAverageScore();
        const newComment = getValues(`${scoreFieldName}.comment`);
        if (newComment) {
            setComment(newComment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseUserId]);

    return (
        <div className={classes.root}>
            <Accordion elevation={0} className={classes.accordion}>
                <AccordionSummary
                    classes={{
                        expandIcon: classes.expandIcon,
                    }}
                    expandIcon={comment !== '' && comment !== null && comment !== undefined ? <MessageIcon /> : <ChatBubbleIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Grid container justify="space-between">
                        <Typography>
                            {successFactor.name}
                            {successFactor.definition && successFactor.definition !== '' ? (
                                <IconButton
                                    aria-owns={descRequirementsOpen ? 'instruction-tooltip' : undefined}
                                    aria-haspopup="true"
                                    onMouseLeave={handleDescRequirementsPopoverClose}
                                    onMouseEnter={handleDescRequirementsPopoverOpen}
                                    style={{ padding: '5px', marginLeft: '5px' }}>
                                    <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                                </IconButton>
                            ) : null}
                        </Typography>
                        <Popover
                            id="description-tooltip"
                            open={descRequirementsOpen}
                            anchorEl={descRequirementsTooltipEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handleDescRequirementsPopoverClose}
                            className={classes.popover}
                            disableRestoreFocus>
                            <Paper className={classes.tooltip}>
                                <p>{successFactor.definition}</p>
                            </Paper>
                        </Popover>
                        <Grid style={{ display: 'flex' }}>
                            <div style={{ margin: 'auto' }}>
                                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                <PictureAsPdfIcon style={{ color: '#b72b1f' }} onClick={(e: any) => e.stopPropagation()} />
                            </div>
                            <div style={{ margin: 'auto' }}>
                                <Typography className={classes.responder}>{responderScore}</Typography>
                            </div>
                            {/* Hidden inputs */}
                            {id ? <input name={`${scoreFieldName}.id`} type="hidden" ref={register()} value={id} /> : null}
                            {responseUserId ? <input name={`${scoreFieldName}.responseUserId`} type="hidden" ref={register()} value={responseUserId} /> : null}
                            {successFactor.id ? (
                                <input name={`${scoreFieldName}.successFactorId`} type="hidden" ref={register()} value={successFactor.id} />
                            ) : null}
                            {/* Score Form Fields */}
                            <FormControl
                                onDragStart={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                <TextField
                                    className={classes.weight}
                                    name={`${scoreFieldName}.score`}
                                    type="number"
                                    inputRef={register({
                                        valueAsNumber: true,
                                        min: {
                                            value: 4,
                                            message: 'Score must not be below 4.',
                                        },
                                        max: {
                                            value: 9.9,
                                            message: 'Score must not be above 9.9.',
                                        },
                                    })}
                                    InputProps={{
                                        inputProps: { min: 4, max: 9.9, step: 0.1, style: { width: '50px', textAlign: 'center' } },
                                        readOnly: readOnly,
                                    }}
                                    onChange={handleAverageScore}
                                    defaultValue={score && !isNaN(score) ? score : ''}
                                    onClick={(e) => e.stopPropagation()}
                                    error={hasError(fpmCategory)}
                                    helperText={getErrorMessage(fpmCategory)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Comment"
                                name={`${scoreFieldName}.comment`}
                                inputRef={register()}
                                fullWidth
                                defaultValue={getValues(`${scoreFieldName}.comment`) ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onBlur={(event) => setComment(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export interface EvaluatorScoreItemProps {
    mode: FormMode;
    index: number;
    fpmCategory: FundamentalPrimeMeasurement;
    successFactor: GetResponseUser_responseUser_evaluatorScores_successFactor;
    responderScore: number | null;
    handleAverageScore: () => void;
}

export default EvaluatorScoreItem;
