import * as React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useFormContext } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { UserProfileInputType } from '../../../apollo/generated/types/globalTypes';
import AvailabilityForm from './AvailabilityForm';
import { FormMode } from '../../../utils/Enums';
import GeneralForm from './GeneralForm';
import EmploymentForm from './EmploymentForm';
import TeamAssignments from './TeamAssignments';
import { GetProfile_user_cycleAssignments } from '../../../apollo/generated/types/GetProfile';

const useStyles = makeStyles({
    setupMessage: {
        padding: '10px',
        borderRadius: '5px',
    },
});

const ProfileForm: React.FC<UserProfileFormProps> = ({ mode, profileComplete, apiError, onSubmit, teamAssignments }) => {
    const { handleSubmit } = useFormContext();
    const classes = useStyles();

    return (
        <form id="user-profile-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                {profileComplete ? null : (
                    <Grid item>
                        <Paper elevation={0} className={classes.setupMessage}>
                            <em>
                                <Typography variant="h2">Profile setup is required.</Typography>
                            </em>
                        </Paper>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <GeneralForm mode={mode} />
                    </Paper>
                </Grid>
                <TeamAssignments teamAssignments={teamAssignments} />
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <EmploymentForm mode={mode} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <AvailabilityForm mode={mode} />
                    </Paper>
                </Grid>
                {/* Error Display */}
                {apiError ? (
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {apiError?.message}
                        </Alert>
                    </Grid>
                ) : null}
            </Grid>
        </form>
    );
};

export interface UserProfileFormProps {
    mode: FormMode;
    profileComplete: boolean;
    apiError?: ApolloError | null;
    onSubmit: (data: UserProfileInputType) => void;
    teamAssignments?: GetProfile_user_cycleAssignments[] | null;
}

export default ProfileForm;
