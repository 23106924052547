import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Chip,
    createStyles,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputBase,
    ListItemText,
    MenuItem,
    Theme,
} from '@material-ui/core';
import { FormMode } from '../../../../utils/Enums';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useFormContext } from 'react-hook-form';
import { ReactHookFormSelect, useCommonForm } from '../../../index';
import ReactHookFormTextArea from '../../../ReactHookForm/ReactHookFormTextArea';
import { SubCycleForm, SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            marginBottom: '15px',
        },
        accordion: {
            flex: 1,
        },
        removeSection: {
            width: '50px',
        },
        tooltip: {
            padding: theme.spacing(2),
        },
        popover: {
            pointerEvents: 'none',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
    }),
);

const TopicItem: React.FC<TopicItemProps> = ({ forceExpanded, expand, index, id, name, categories, description, showRemove, remove, importedID, mode }) => {
    const classes = useStyles();
    const { control, register, errors, watch } = useFormContext();
    const { formType, loading, categories: options } = useCommonForm();
    const [localExpand, setLocalExpand] = useState(false);
    const status = watch('status', SubCycleStatus.DRAFT);
    const required = status !== SubCycleStatus.DRAFT;
    const readOnly = mode === FormMode.View;

    const infoBoxText = ` A standard topic description should include information relating to the ${
        formType === SubCycleForm.REQUEST_FOR_INFORMATION ? 'Request for Information' : 'Request for White Paper'
    }'s overall: Objective, Purpose,
    Goal, Desired Technology (if known).`;

    useEffect(() => {
        setLocalExpand(expand);
    }, [expand, forceExpanded]);

    const selectedCategories = watch(`topics[${index}].categories`);

    const hasError = (field: string): boolean => {
        return !!errors && !!errors.topics && !!errors.topics[index] && !!errors.topics[index][field];
    };

    const getErrorMessage = (field: string): string => {
        return hasError(field) ? errors.topics[index][field]?.message : '';
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental, @typescript-eslint/no-explicit-any
    const expandChange = (event: any, isExpanded: boolean) => {
        setLocalExpand(isExpanded);
    };

    return (
        <div className={classes.root}>
            <Accordion elevation={0} className={classes.accordion} expanded={localExpand} onChange={expandChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <FormControl
                        fullWidth
                        draggable
                        onDragStart={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                        {id ? <input name={`topics[${index}].id`} type="hidden" ref={register({ valueAsNumber: true })} value={id} /> : null}
                        {importedID ? (
                            <input name={`topics[${index}].importedID`} type="hidden" ref={register({ valueAsNumber: true })} value={importedID} />
                        ) : null}
                        <InputBase
                            name={`topics[${index}].name`}
                            placeholder="Title/Number*"
                            inputRef={register({ required: required ? 'Title/Number is required' : false })}
                            readOnly={readOnly}
                            defaultValue={name || ''}
                            onClick={(e) => e.stopPropagation()}
                            error={hasError('name')}
                        />
                        {hasError('name') ? <FormHelperText error={true}>{getErrorMessage('name')}</FormHelperText> : null}
                    </FormControl>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                fullWidth
                                control={control}
                                label="Team Assignment*"
                                loading={loading}
                                name={`topics[${index}].categories`}
                                error={hasError('categories')}
                                helperText={getErrorMessage('categories')}
                                rules={{
                                    required: required ? 'At least one Team Assignment is required' : false,
                                    validate: (value) => !required || value.length > 0 || 'At least one Team Assignment is required',
                                }}
                                selectProps={{
                                    multiple: true,
                                    input: <Input />,
                                    renderValue: (selected) => (
                                        <div className={classes.chips}>
                                            {(selected as number[]).map((id: number) => {
                                                if (options) {
                                                    const category = options.find((c) => c.id === id);
                                                    if (category) {
                                                        return (
                                                            <Chip
                                                                key={category.id}
                                                                label={`${category.name}: ${category.description}`}
                                                                className={classes.chip}
                                                            />
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                        </div>
                                    ),
                                }}
                                defaultValue={categories ? categories.map((category) => category) : []}
                                readOnly={readOnly}>
                                {options &&
                                    options.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            <Checkbox color="primary" checked={selectedCategories && selectedCategories.indexOf(option.id) > -1} />
                                            <ListItemText primary={`${option.name}: ${option.description}`} />
                                        </MenuItem>
                                    ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormTextArea
                                fullWidth
                                control={control}
                                name={`topics[${index}].description`}
                                error={hasError('description')}
                                helperText={getErrorMessage('description')}
                                rules={{
                                    required: required ? 'Description must have some content.' : false,
                                    validate: (value) => !required || value.replace(/<[^>]*>?/gm, '').length > 0 || 'Description must have some content.',
                                }}
                                label="Description*"
                                defaultValue={description}
                                infoBoxText={infoBoxText}
                                readOnly={readOnly}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Button startIcon={<CheckCircleIcon />} variant="contained">
                                Mark as Complete
                            </Button>
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {showRemove ? (
                <div className={classes.removeSection}>
                    <IconButton aria-label="remove" onClick={() => remove(index)}>
                        <RemoveCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                </div>
            ) : null}
        </div>
    );
};

export interface TopicItemProps {
    mode: FormMode;
    index: number;
    id?: number | null;
    importedID?: number | null;
    name?: string;
    categories?: number[] | null;
    description?: string;
    forceExpanded: boolean;
    expand: boolean;
    showRemove: boolean;
    remove: (index: number) => void;
}

export default TopicItem;
