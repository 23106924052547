import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Toolbar, Typography } from '@material-ui/core';
import { NavLink, useParams } from 'react-router-dom';
import {
    PageWrapper,
    PageHeader,
    PageBody,
    SubCyclesTabPanel,
    EvaluatorsTabPanel,
    AcquisitionCycleTabs,
    ErrorMessage,
    ResponseManagementTabPanel,
    LoadingMessage,
    usePermissions,
    DownSelectionTabPanel,
    AwardManagementTabPanel,
    DownSelectionProvider,
} from '../../components';
import { ApolloError, useQuery } from '@apollo/client';
import { GET_CYCLE_TITLE } from '../../apollo/queries';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router';
import { GetCycleTitle, GetCycleTitle_cycle, GetCycleTitleVariables } from '../../apollo/generated/types/GetCycleTitle';
import { AwardManagementProvider } from '../../components/Providers/AwardManagementProvider';
import { CycleDataProvider } from '../../components/Providers/CycleDataProvider';
import { CycleTab } from '../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
        padding: 0,
    },
    backButton: {
        background: '#F0F0F0',
    },
    title: {
        height: '44px',
        marginLeft: '15px',
        alignItems: 'center',
        display: 'flex',
    },
    tabText: {
        marginLeft: '5px',
    },
});

interface URLParams {
    id: string;
    tab: string;
}

const AcquisitionCyclePage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id, tab } = useParams<URLParams>();
    const cycleId = Number(id);
    const [cycle, setCycle] = useState<GetCycleTitle_cycle>();
    const { userLoaded } = usePermissions();

    const { error } = useQuery<GetCycleTitle, GetCycleTitleVariables>(GET_CYCLE_TITLE, {
        variables: {
            id: cycleId,
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetCycleTitle) => {
            if (data.cycle) {
                setCycle(data.cycle);
            } else {
                history.push('/acquisition-cycles');
            }
        },
        onError: (err: ApolloError) => {
            if (err.graphQLErrors) {
                const hasAuthenticationError = err.graphQLErrors.some(
                    (e) => e.extensions?.code.toUpperCase() === 'AUTHORIZATION' || e.extensions?.code.toUpperCase() === 'AUTHENTICATION',
                );
                if (hasAuthenticationError) {
                    history.push('/acquisition-cycles');
                }
            }
            console.error(err);
        },
    });

    let tabPanel;
    switch (tab) {
        case 'sub-cycles':
            tabPanel = <SubCyclesTabPanel />;
            break;
        case 'evaluators':
            tabPanel = <>{!userLoaded ? <LoadingMessage /> : <EvaluatorsTabPanel index={CycleTab.Evaluator} value={CycleTab.Evaluator} />}</>;
            break;
        case 'response':
            tabPanel = <ResponseManagementTabPanel index={CycleTab.Response} value={CycleTab.Response} />;
            break;
        case 'down-select':
            tabPanel = (
                <DownSelectionProvider>
                    <DownSelectionTabPanel index={CycleTab.DownSelection} value={CycleTab.DownSelection} />
                </DownSelectionProvider>
            );
            break;
        case 'award':
            tabPanel = (
                <AwardManagementProvider>
                    <AwardManagementTabPanel index={CycleTab.Award} value={CycleTab.Award} />
                </AwardManagementProvider>
            );
            break;
        default:
            history.push(`/acquisition-cycle/${id}/${CycleTab.SubCycle}`);
            break;
    }

    return (
        <PageWrapper>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <div>
                        <div className={classes.title}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link component={NavLink} to="/acquisition-cycles" color="inherit">
                                    Acquisition Cycles
                                </Link>
                                <Typography variant="h2">{cycle?.title}</Typography>
                            </Breadcrumbs>
                        </div>
                        <div>
                            <AcquisitionCycleTabs id={cycleId} tab={tab} />
                        </div>
                    </div>
                </Toolbar>
            </PageHeader>
            <PageBody>
                <CycleDataProvider id={cycleId}>{error ? <ErrorMessage error={error} /> : tabPanel}</CycleDataProvider>
            </PageBody>
        </PageWrapper>
    );
};

export default AcquisitionCyclePage;
