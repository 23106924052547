import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { ConfirmDialog } from '../../index';
import WarningIcon from '@material-ui/icons/Warning';

const UnSavedChangesDialog: React.FC = () => {
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [nextPage, setNextPage] = useState<any>();
    const [confirmedNavigation, setConfirmedNavigation] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const { formState } = useFormContext();
    const { isDirty } = formState;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePageLeave = (location: any): boolean => {
        if (!confirmedNavigation) {
            setNextPage(location);
            setShow(true);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && nextPage) {
            history.push(nextPage);
        }
    }, [nextPage, confirmedNavigation, history]);

    return (
        <>
            <Prompt when={isDirty} message={handlePageLeave} />
            <ConfirmDialog
                open={show}
                icon={<WarningIcon style={{ color: '#F0A100', fontSize: 91 }} />}
                title="Are you sure you want to leave this page?"
                message={
                    <>
                        You currently have unsaved changes!
                        <br />
                        Press Continue to leave, or Cancel to stay on the current page.
                    </>
                }
                primaryText="Continue"
                secondaryText="Cancel"
                onPrimaryClick={handleConfirmNavigationClick}
                onSecondaryClick={() => setShow(false)}
                onClose={() => setShow(false)}
            />
        </>
    );
};

export default UnSavedChangesDialog;
