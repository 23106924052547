import React, { useCallback, useEffect } from 'react';
import { Button, Menu } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import CycleMenuOptions from './MenuOptions/CycleMenuOptions';
import SubCycleMenuOptions from './MenuOptions/SubCycleMenuOptions';
import { CycleStatus, SubCycleStatus, SuccessFactorStatus } from '../../apollo/generated/types/globalTypes';
import SuccessFactorMenuOptions from './MenuOptions/SuccessFactorMenuOptions';
import { getCycleStatusDisplayName, getSubCycleStatusDisplayName, getSuccessFactorStatusDisplayName } from '../../utils/Enums';

const useStyles = makeStyles({
    ButtonText: {
        flex: '1',
    },
    ButtonDraft: {
        'backgroundColor': '#1976D2',
        '&:hover': {
            backgroundColor: '#1F8BF3',
        },
    },
    ButtonPending: {
        'backgroundColor': '#ff9800',
        '&:hover': {
            backgroundColor: '#FFB14C',
        },
    },
    ButtonApproved: {
        'backgroundColor': '#4caf50',
        '&:hover': {
            backgroundColor: '#38C63C',
        },
    },
    ButtonArchived: {
        'backgroundColor': '#BDBDBD',
        '&:hover': {
            backgroundColor: '#7F7F7F',
        },
    },
});

const StatusDropdown: React.FC<StatusDropdownProps> = (props) => {
    const { name, type, readOnly = false } = props;
    const classes = useStyles();
    const { setValue, watch, register } = useFormContext();
    const [buttonText, setButtonText] = React.useState<string>('');
    const [buttonIcon, setButtonIcon] = React.useState<React.ReactNode>(null);
    const [menu, setMenu] = React.useState<React.ReactNode>(null);
    const [buttonStyle, setButtonStyle] = React.useState<string>('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const defaultState = (): string => {
        switch (type) {
            case StatusType.Cycle:
                return CycleStatus.DRAFT;
            case StatusType.SubCycle:
                return SubCycleStatus.DRAFT;
            case StatusType.SuccessFactor:
                return SuccessFactorStatus.DRAFT;
            default:
                return '';
        }
    };
    const watchStatus = watch('status', defaultState());

    const setStatus = useCallback(
        (newStatus: string) => {
            setValue(name, newStatus);
            setAnchorEl(null);
        },
        [name, setValue],
    );

    useEffect(() => {
        /**
         *
         * @param status
         */
        const renderStyle = (status: string): string => {
            switch (type) {
                case StatusType.Cycle:
                    switch (status) {
                        case CycleStatus.DRAFT:
                            return classes.ButtonDraft;
                        case CycleStatus.PENDING_APPROVAL:
                            return classes.ButtonPending;
                        case CycleStatus.ACTIVE:
                            return classes.ButtonApproved;
                        case CycleStatus.ARCHIVED:
                            return classes.ButtonArchived;
                        default:
                            return '';
                    }
                case StatusType.SubCycle:
                    switch (status) {
                        case SubCycleStatus.DRAFT:
                            return classes.ButtonDraft;
                        case SubCycleStatus.PENDING_APPROVAL:
                            return classes.ButtonPending;
                        case SubCycleStatus.OUT_FOR_RESPONSE:
                        case SubCycleStatus.APPROVED:
                        case SubCycleStatus.COMPLETE:
                            return classes.ButtonApproved;
                        default:
                            return '';
                    }
                case StatusType.SuccessFactor:
                    switch (status) {
                        case SuccessFactorStatus.DRAFT:
                            return classes.ButtonDraft;
                        case SuccessFactorStatus.PENDING_APPROVAL:
                            return classes.ButtonPending;
                        case SuccessFactorStatus.APPROVED:
                            return classes.ButtonApproved;
                        default:
                            return '';
                    }
                default:
                    return '';
            }
        };

        /**
         * Renders the display text on button based on Type and Enum
         */
        const renderText = (status: string): string => {
            switch (type) {
                case StatusType.Cycle:
                    return getCycleStatusDisplayName(status);
                case StatusType.SubCycle:
                    return getSubCycleStatusDisplayName(status);
                case StatusType.SuccessFactor:
                    return getSuccessFactorStatusDisplayName(status);
                default:
                    return '';
            }
        };

        /**
         * Renders the menu based on Type
         */
        const renderMenu = (status: string): React.ReactNode => {
            if (readOnly) {
                null;
            }
            switch (type) {
                case StatusType.Cycle:
                    return <CycleMenuOptions status={status} changeStatus={setStatus} />;
                case StatusType.SubCycle:
                    return <SubCycleMenuOptions status={status} changeStatus={setStatus} />;
                case StatusType.SuccessFactor:
                    return <SuccessFactorMenuOptions status={status} changeStatus={setStatus} />;
                default:
                    return null;
            }
        };

        /**
         *
         * @param status
         */
        const renderButtonIcon = (status: string): React.ReactNode => {
            if (readOnly) {
                null;
            }
            switch (type) {
                case StatusType.Cycle:
                    return <ArrowDropDownIcon />;
                case StatusType.SubCycle:
                    return status !== SubCycleStatus.OUT_FOR_RESPONSE && status !== SubCycleStatus.COMPLETE ? <ArrowDropDownIcon /> : null;
                case StatusType.SuccessFactor:
                    return <ArrowDropDownIcon />;
                default:
                    return null;
            }
        };

        setButtonText(renderText(watchStatus));
        setButtonIcon(renderButtonIcon(watchStatus));
        setMenu(renderMenu(watchStatus));
        setButtonStyle(renderStyle(watchStatus));
    }, [classes, readOnly, setStatus, type, watchStatus]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (readOnly) {
            // Read-Only has no menu
            return;
        }

        if (type === StatusType.Cycle && watchStatus === CycleStatus.ARCHIVED) {
            // Doesnt have menu
            return;
        }

        if (type === StatusType.SubCycle && (watchStatus === SubCycleStatus.OUT_FOR_RESPONSE || watchStatus === SubCycleStatus.COMPLETE)) {
            // Doesnt have menu
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <input name={name} type="hidden" ref={register({ required: true })} />
            <Button fullWidth variant="contained" endIcon={buttonIcon} onClick={handleClick} className={buttonStyle} disabled={props.readOnly}>
                <div className={classes.ButtonText}>{buttonText}</div>
            </Button>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        marginTop: '5px',
                        width: 230,
                    },
                }}>
                {menu}
            </Menu>
        </>
    );
};

export interface StatusMenuOption {
    status: string | number;
    changeStatus: (status: string) => void;
}

enum StatusType {
    Cycle = 'Cycle',
    SubCycle = 'SubCycle',
    SuccessFactor = 'SuccessFactor',
}

type StatusTypeAsUnion = keyof typeof StatusType;

export interface StatusDropdownProps {
    name: string;
    readOnly?: boolean;
    type: StatusTypeAsUnion;
}
export default StatusDropdown;

StatusDropdown.defaultProps = {
    readOnly: false,
};
