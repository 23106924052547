import { FC, ReactElement } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ProtectedRoute } from '../components';
import Pages from './';

const PageRoutes: FC<RouteComponentProps> = (): ReactElement => {
    return (
        <Switch>
            <ProtectedRoute exact path="/" component={Pages.DashboardPage} />
            <ProtectedRoute exact path="/dashboard" component={Pages.DashboardPage} />
            <ProtectedRoute exact path="/profile" component={Pages.ProfilePage} />
            <ProtectedRoute exact path="/acquisition-cycles" component={Pages.AcquisitionCyclesPage} />
            <ProtectedRoute exact path="/acquisition-cycle/create" component={Pages.CreateAcquisitionCyclePage} />
            <ProtectedRoute exact path="/acquisition-cycle/:id/edit/" component={Pages.EditAcquisitionCyclePage} />
            <Redirect exact from="/acquisition-cycle/:id" to="/acquisition-cycle/:id/sub-cycles" />
            <ProtectedRoute exact path="/acquisition-cycle/:id/:tab" component={Pages.AcquisitionCyclePage} />
            <ProtectedRoute exact path="/acquisition-cycle/:id/:tab/:subTab" component={Pages.AcquisitionCyclePage} />
            <ProtectedRoute exact path="/acquisition-cycle/:id/:tab/:subTab/:responseId/evaluate" component={Pages.EvaluatorScorePage} />
            <ProtectedRoute exact path="/acquisition-cycle/:id/:tab/:subTab/:responseId/evaluations" component={Pages.TeamLeadScorePage} />
            <ProtectedRoute exact path="/acquisition-cycle/:cycleId/sub-cycle/:subCycleId/form" component={Pages.SubCycleFormPage} />
            <ProtectedRoute exact path="/acquisition-cycle/:cycleId/sub-cycle/:subCycleId/weights" component={Pages.SubCycleSuccessFactorPage} />
            <ProtectedRoute exact path="/evaluator/:id/" component={Pages.EvaluatorProfilePage} />
            <ProtectedRoute exact path="/responders" component={Pages.RespondersPage} />
            <ProtectedRoute exact path="/users" component={Pages.UsersPage} />
            <ProtectedRoute exact path="/user/create" component={Pages.CreateUserPage} />
            <ProtectedRoute exact path="/user/:id/edit/" component={Pages.EditUserPage} />
            <ProtectedRoute exact path="/upload" component={Pages.FileUploadPage} />
            <Route component={Pages.NotFoundPage} />
        </Switch>
    );
};
export default PageRoutes;
