/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { DetailedHTMLProps, useState } from 'react';
import { TabPanel } from '../../../index';
import { Box } from '@material-ui/core';
import DownSelectionContainer from './DownSelectionContainer';
import { SelectionStatus, ResponseStatus } from '../../../../apollo/generated/types/globalTypes';

interface TabPanelProps extends DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    index: number | string;
    value: number | string;
    data: any;
    readOnly: boolean;
}

const TabPanels: React.FC<TabPanelProps> = ({ index, value, data, readOnly, ...other }) => {
    const finalizedData = data.filter((i) => i.assignedEvaluators.some((x) => x.isTeamLead && x.status === ResponseStatus.LOCKED));
    const [tabData, setTabData] = useState(finalizedData);
    const selected = tabData.filter((i) => i.selectedStatus === SelectionStatus.DOWN_SELECTION_SELECTED).length;
    const pending = tabData.filter((i) => i.selectedStatus === SelectionStatus.PENDING).length;
    const rejected = tabData.filter((i) => i.selectedStatus === SelectionStatus.DOWN_SELECTION_REJECTED).length;

    return (
        <TabPanel index={index} value={value} {...other}>
            <Box>
                <DownSelectionContainer
                    title={`Selected`}
                    count={selected}
                    finishedResponses={tabData}
                    setTabData={setTabData}
                    status={SelectionStatus.DOWN_SELECTION_SELECTED}
                    readOnly={readOnly}
                />
                <DownSelectionContainer
                    title={`Pending Disposition`}
                    count={pending}
                    finishedResponses={tabData}
                    setTabData={setTabData}
                    status={SelectionStatus.PENDING}
                    readOnly={readOnly}
                />
                <DownSelectionContainer
                    title={`Rejected`}
                    count={rejected}
                    finishedResponses={tabData}
                    setTabData={setTabData}
                    status={SelectionStatus.DOWN_SELECTION_REJECTED}
                    readOnly={readOnly}
                />
            </Box>
        </TabPanel>
    );
};
export default TabPanels;
