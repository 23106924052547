import { useHistory } from 'react-router';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Paper, IconButton, Toolbar, Typography, Button, DialogTitle, DialogContent, Dialog, DialogContentText, DialogActions } from '@material-ui/core';
import { ApolloError, useMutation } from '@apollo/client';
import { CREATE_USER } from '../../apollo/mutations';
import { LoadingButton, UserForm, PageWrapper, PageHeader, PageBody } from '../../components';
import { useForm, FormProvider } from 'react-hook-form';
import { UserInputType } from '../../apollo/generated/types/globalTypes';
import { CreateUser, CreateUserVariables } from '../../apollo/generated/types/CreateUser';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        flex: 1,
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    dialogCreated: {
        textDecoration: 'underline',
        fontWeight: 900,
    },
    dialogDescription: {
        color: '#1A2A3D',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
    },
});

const CreateUserPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const methods = useForm<UserInputType>();
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const [createdEmail, setCreatedEmail] = useState<string>('');
    const [apiError, setAPIError] = useState<ApolloError | null>();

    const [createUser, { loading: mutationLoading }] = useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
        onCompleted: (data: CreateUser) => {
            setAPIError(null);
            setCreatedEmail(data.createUser?.email);
            setOpenSuccess(true);
        },
        onError: (error: ApolloError) => {
            console.error(error.message);
            setAPIError(error);
        },
    });

    const resetForm = () => {
        setAPIError(null);
        methods.reset({ email: '', firstName: '', lastName: '', phoneNumber: '', externalRoleId: '', externalRoleName: '' });
        methods.clearErrors();
    };

    const handleDialogCreateNew = () => {
        setOpenSuccess(false);
        resetForm();
    };

    const handleDialogViewUsers = () => {
        setOpenSuccess(false);
        history.push('/users');
    };

    const onSubmitUser = (data: UserInputType) => {
        createUser({
            variables: {
                user: data,
            },
        });
    };

    return (
        <PageWrapper permission={'create:users'}>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <IconButton onClick={history.goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1" className={classes.title}>
                        Creating a User
                    </Typography>
                    <LoadingButton pending={mutationLoading} form="user-form" type="submit" variant="contained">
                        Submit User
                    </LoadingButton>
                </Toolbar>
            </PageHeader>
            <PageBody>
                <div className={classes.root}>
                    <Paper className={classes.paper} elevation={0}>
                        <FormProvider {...methods}>
                            <UserForm onSubmit={onSubmitUser} apiError={apiError} />
                        </FormProvider>
                    </Paper>

                    <Dialog
                        open={openSuccess}
                        disableBackdropClick
                        disableEscapeKeyDown
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">
                            <div className={classes.centerItems}>
                                <CheckCircleIcon style={{ color: '#0EAE79', fontSize: 80 }} />
                                <Typography variant="h1">
                                    You've successfully created <span className={classes.dialogCreated}>{createdEmail}</span>
                                </Typography>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" className={classes.dialogDescription}>
                                You can create another new user, or we can take you back to Users.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className={classes.centerItems}>
                            <Button onClick={handleDialogCreateNew} autoFocus variant="contained">
                                Create Another User
                            </Button>
                            <Button onClick={handleDialogViewUsers} variant="text">
                                View Users
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </PageBody>
        </PageWrapper>
    );
};

export default CreateUserPage;
