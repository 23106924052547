import React from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_PROFILE } from '../../apollo/queries';
import { ProfileForm, PageWrapper, PageHeader, PageBody, LoadingMessage, ErrorMessage } from '../../components';
import { FormProvider, useForm } from 'react-hook-form';
import { UserProfileInputType } from '../../apollo/generated/types/globalTypes';
import { FormMode } from '../../utils/Enums';
import { useParams } from 'react-router-dom';
import { GetProfile, GetProfileVariables } from '../../apollo/generated/types/GetProfile';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '15px',
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    paper: {
        flex: 1,
    },
});

interface URLParams {
    id: string;
}

const ProfilePage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams<URLParams>();
    const methods = useForm<UserProfileInputType>();

    const { loading, error, data } = useQuery<GetProfile, GetProfileVariables>(GET_PROFILE, {
        variables: {
            userId: Number(id),
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (result) => {
            methods.reset({ ...result.user });
        },
    });

    return (
        <PageWrapper permission={'read:evaluators'}>
            <PageHeader>
                <Toolbar className={classes.header}>
                    <IconButton onClick={history.goBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1" className={classes.title}>
                        Profile
                    </Typography>
                </Toolbar>
            </PageHeader>
            <PageBody>
                {loading ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : (
                    <div className={classes.root}>
                        <FormProvider {...methods}>
                            <ProfileForm
                                onSubmit={() => null}
                                mode={FormMode.View}
                                profileComplete={data?.user?.isProfileComplete ?? false}
                                teamAssignments={data?.user?.cycleAssignments}
                            />
                        </FormProvider>
                    </div>
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default ProfilePage;
