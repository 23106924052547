import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        height: '92px',
        width: '100%',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 4px 8px #00000005',
        color: '#1A2A3D',
    },
});

const TopBar: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <AppBar position="relative" className={classes.root}>
            {children}
        </AppBar>
    );
};

export default TopBar;
