import * as React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { KeyboardTimePicker } from '@material-ui/pickers';

const ReactHookFormTime: React.FC<ReactHookFormTimeProps> = ({
    name,
    label,
    mask,
    helperText,
    control,
    defaultValue,
    readOnly,
    onTimeChange,
    error,
    rules,
    className,
}) => {
    return (
        <Controller
            render={({ ref, onChange, ...rest }, { invalid }) => (
                <KeyboardTimePicker
                    margin="normal"
                    className={className}
                    label={label}
                    mask={mask}
                    readOnly={readOnly}
                    onChange={(newValue) => {
                        onChange(newValue);
                        if (onTimeChange) onTimeChange(newValue);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={invalid}
                    helperText={helperText}
                    inputProps={{ readOnly: readOnly }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    {...rest}
                />
            )}
            rules={rules}
            error={error}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
};
export interface ReactHookFormTimeProps {
    label: string;
    name: string;
    mask: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control?: any;
    defaultValue?: Date;
    helperText?: string;
    readOnly?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onTimeChange?: React.Dispatch<any>;
    error?: boolean;
    rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    className?: string;
}

export default ReactHookFormTime;
