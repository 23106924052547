import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { LoadingIconButton } from '../Buttons';

export interface TableActionMenuProps {
    id: number;
    index: number;
    busy?: boolean;
    viewText?: string;
    showView?: boolean;
    onViewClick?: (id: number, index: number) => void;
    editText?: string;
    showEdit?: boolean;
    onEditClick?: (id: number, index: number) => void;
    deleteText?: string;
    showDelete?: boolean;
    onDeleteClick?: (id: number, index: number) => void;
    assignText?: string;
    showAssign?: boolean;
    onAssignClick?: (id: number, index: number) => void;
    unassignText?: string;
    showUnassign?: boolean;
    onUnassignClick?: (id: number, index: number) => void;
    evaluatorScoreText?: string;
    showEvaluatorScore?: boolean;
    onEvaluatorScoreClick?: (id: number, index: number) => void;
}

const TableActionMenu: React.FC<TableActionMenuProps> = ({
    id,
    index,
    busy = false,
    viewText = 'View',
    showView = false,
    onViewClick = () => null,
    editText = 'Edit',
    showEdit = false,
    onEditClick = () => null,
    deleteText = 'Delete',
    showDelete = false,
    onDeleteClick = () => null,
    assignText = 'Assign Evaluators',
    showAssign = false,
    onAssignClick = () => null,
    unassignText = 'Unassign Evaluators',
    showUnassign = false,
    onUnassignClick = () => null,
    evaluatorScoreText = 'Evaluate Response',
    showEvaluatorScore = false,
    onEvaluatorScoreClick = () => null,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onViewClick(id, index);
    };

    const handleEditClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onEditClick(id, index);
    };

    const handleDeleteClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onDeleteClick(id, index);
    };

    const handleAssignClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onAssignClick(id, index);
    };

    const handleUnassignClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onUnassignClick(id, index);
    };

    const handleEvaluatorScoreClick = (e: React.MouseEvent<HTMLLIElement>) => {
        e.stopPropagation();
        handleClose();
        onEvaluatorScoreClick(id, index);
    };

    return (
        <div>
            {showView || showEdit || showDelete || showAssign || showUnassign || showEvaluatorScore ? (
                <>
                    <LoadingIconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} pending={busy}>
                        <MoreVertIcon />
                    </LoadingIconButton>
                    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        {showView ? <MenuItem onClick={handleViewClick}>{viewText}</MenuItem> : null}
                        {showEdit ? <MenuItem onClick={handleEditClick}>{editText}</MenuItem> : null}
                        {showDelete ? <MenuItem onClick={handleDeleteClick}>{deleteText}</MenuItem> : null}
                        {showAssign ? <MenuItem onClick={handleAssignClick}>{assignText}</MenuItem> : null}
                        {showUnassign ? <MenuItem onClick={handleUnassignClick}>{unassignText}</MenuItem> : null}
                        {showEvaluatorScore ? <MenuItem onClick={handleEvaluatorScoreClick}>{evaluatorScoreText}</MenuItem> : null}
                    </Menu>
                </>
            ) : null}
        </div>
    );
};

export default TableActionMenu;
