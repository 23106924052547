/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import { CategoryFields } from '../../../apollo/generated/types/CategoryFields';
import { GET_RESPONSE_SCORE_TABLE } from '../../../apollo/queries';
import { UPDATE_RESPONSE_SELECTION, UPDATE_RDCATEGORY_COMPLETED } from '../../../apollo/mutations';
import { GetResponseScoreTable } from '../../../apollo/generated/types/GetResponseScoreTable';
import { UpdateResponseSelection, UpdateResponseSelectionVariables } from '../../../apollo/generated/types/UpdateResponseSelection';
import { UpdateRDCategoryCompleted, UpdateRDCategoryCompletedVariables } from '../../../apollo/generated/types/UpdateRDCategoryCompleted';
import { useParams } from 'react-router-dom';
import { SelectionStatus, ResponseStatus } from '../../../apollo/generated/types/globalTypes';

interface AwardManagementContextReturn {
    responsesLoading: boolean;
    error?: ApolloError;
    categories?: CategoryFields[];
    tabs?: any;
    researchDevelopmentCategories?: any;
    items?: any;
    pending: number;
    selected: number;
    rejected: number;
    subCycleId: number;
    cycleId: number;
    handleSetCheckBox: (e, index, id) => void;
    initalizeCheckbox?: any;
    checkBox?: any;
    handleCount: (items) => void;
    handleResponseChange: (section: SelectionStatus, id) => void;
}

const AwardManagementContext = createContext<AwardManagementContextReturn | undefined>(undefined);

const AwardManagementProvider: React.FC = ({ children }) => {
    const { id } = useParams<any>();
    const cycleId = Number(id);

    const { loading: responsesLoading, data } = useQuery<GetResponseScoreTable>(GET_RESPONSE_SCORE_TABLE, {
        fetchPolicy: 'cache-and-network',
        variables: {
            cycleId: cycleId,
        },
    });

    const [items, setItems] = useState<any>(data);
    const [checkBox, setCheckBox] = useState<any>([]);

    const [selected, setSelected] = useState(0);
    const [rejected, setRejected] = useState(0);
    const [pending, setPending] = useState(0);
    const [subCycleId, setSubCycleId] = useState(-1);

    const handleCount = (items) => {
        if (items !== undefined) {
            const finalized = items.filter((i) => i.assignedEvaluators.some((x) => x.isTeamLead && x.status === ResponseStatus.LOCKED));
            setSelected(finalized.filter((i) => i.selectedStatus === SelectionStatus.AWARD_SELECTED).length); // 0
            setPending(finalized.filter((i) => i.selectedStatus === SelectionStatus.PENDING).length); // 1
            setRejected(finalized.filter((i) => i.selectedStatus === SelectionStatus.AWARD_REJECTED).length); // 2
        }
    };

    // Holds array of researchDevelopmentCategories id
    const [tabs, setTabs] = useState<number[]>();

    const [updateResponseSelection] = useMutation<UpdateResponseSelection, UpdateResponseSelectionVariables>(UPDATE_RESPONSE_SELECTION);
    const [UpdateRDCategoryCompleted] = useMutation<UpdateRDCategoryCompleted, UpdateRDCategoryCompletedVariables>(UPDATE_RDCATEGORY_COMPLETED);
    const [researchDevelopmentCategories, setResearchDevelopmentCategories] = useState<any>();

    // use id and cycle id for mutation to update saved status
    const initalizeCheckbox = (e: boolean) => {
        setCheckBox((prevState) => {
            const temp = prevState;
            temp.push(e);
            return [...temp];
        });
    };

    const handleSetCheckBox = (e: boolean, index, id) => {
        const updateResult = UpdateRDCategoryCompleted({
            variables: {
                categoryId: id,
                selectionCompleted: e,
            },
        });

        setCheckBox((prevState) => {
            prevState[index] = e;
            return [...prevState];
        });
    };

    const handleResponseChange = (status: SelectionStatus, id) => {
        let temp: any[] = [];

        const updateResult = updateResponseSelection({
            variables: {
                responseId: id,
                selectedStatus: status,
            },
        });

        setItems((prevState) => {
            const isSection = (element) => {
                return element.id === id;
            };
            const index = prevState!.findIndex(isSection);
            const items = [...prevState!];
            const item = { ...items[index] };
            item.selectedStatus = status;
            items[index] = item;
            temp = items;
            return items;
        });
        // re-count with updated data
        handleCount(temp);
    };

    useEffect(() => {
        if (!responsesLoading) {
            if (data && data.responses && data.cycle && data.cycle.subCycles.length > 0) {
                const activeResponses = data.responses.filter((x) => x.topic?.subCycle.id === data.cycle?.subCycles[0].id);
                setItems(activeResponses);
                setSubCycleId(data.cycle?.subCycles[0].id);
                setResearchDevelopmentCategories(data.cycle?.subCycles[0].form?.researchDevelopmentCategories);

                const tabs: number[] = [];

                // only add tabs for researchDevelopmentCategories if a response has it selected
                activeResponses.map((i) => {
                    if (i.researchCategoryId) {
                        if (tabs.indexOf(i.researchCategoryId) === -1) {
                            tabs.push(i.researchCategoryId);
                        }
                    }
                });
                setTabs(tabs);
                handleCount(activeResponses);
            } else {
                setItems([]);
            }
        }
    }, [responsesLoading, data]);

    return (
        <AwardManagementContext.Provider
            value={{
                tabs,
                researchDevelopmentCategories,
                responsesLoading,
                items,
                pending,
                selected,
                rejected,
                subCycleId,
                cycleId,
                handleSetCheckBox,
                initalizeCheckbox,
                checkBox,
                handleCount,
                handleResponseChange,
            }}>
            {children}
        </AwardManagementContext.Provider>
    );
};

const useAwardManagement = (): AwardManagementContextReturn => {
    const context = React.useContext(AwardManagementContext);
    if (context === undefined) {
        throw new Error('AwardManagementContext must be used within a AwardManagementProvider');
    }
    return context;
};

export { AwardManagementProvider, useAwardManagement };
