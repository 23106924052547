import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Toolbar, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import MUIDataTable, { MUIDataTableMeta } from 'mui-datatables';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import {
    LoadingButton,
    PageWrapper,
    PageHeader,
    PageBody,
    TableActionMenu,
    usePermissions,
    LoadingMessage,
    ErrorMessage,
    HasPermission,
} from '../../components';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { GET_USERS } from '../../apollo/queries';
import { useHistory } from 'react-router';
import { DELETE_USER } from '../../apollo/mutations';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { GetUsers } from '../../apollo/generated/types/GetUsers';
import { DeleteUser, DeleteUserVariables } from '../../apollo/generated/types/DeleteUser';
import { UserDisplayFields } from '../../apollo/generated/types/UserDisplayFields';

const useStyles = makeStyles({
    header: {
        minHeight: '92px',
    },
    title: {
        flexGrow: 1,
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    centerActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    dialogCreated: {
        textDecoration: 'underline',
        fontWeight: 900,
    },
    dialogDescription: {
        color: '#1A2A3D',
        fontSize: '16px',
        lineHeight: '19px',
        textAlign: 'center',
    },
    table: {
        '& .MuiTableCell-head:last-child': {
            width: '50px',
        },
    },
});

const UsersPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { hasPermission } = usePermissions();
    const [apiError, setAPIError] = useState<ApolloError | null>();
    const [users, setUsers] = useState<Array<UserDisplayFields>>([]);
    const [selectedUser, setSelectedUser] = useState<UserDisplayFields | undefined>();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [success, setSuccess] = useState(false);
    const { loading, error } = useQuery<GetUsers>(GET_USERS, {
        fetchPolicy: 'cache-and-network',
        onCompleted: (data: GetUsers) => {
            if (data.users) {
                setUsers(data.users);
            }
        },
    });
    const [deleteUser, { loading: mutationLoading }] = useMutation<DeleteUser, DeleteUserVariables>(DELETE_USER, {
        onCompleted: (data: DeleteUser) => {
            const newList = users.filter((user) => user.id !== data.deleteUser);
            setUsers(newList);
            setSuccess(true);
            setAPIError(null);
        },
        onError: (error: ApolloError) => {
            console.error(error.message);
            setAPIError(error);
            setSuccess(false);
        },
    });

    /**
     * Handler for closing popup
     */
    const handleClose = () => {
        setAPIError(null);
        setSuccess(false);
        setConfirmDelete(false);
    };

    /**
     * Click event for Confirming delete
     */
    const handleDeleteConfirm = () => {
        if (selectedUser) {
            deleteUser({
                variables: {
                    userId: selectedUser?.id,
                },
            });
        }
    };

    /**
     * handler for Editing User
     * @param id
     */
    const handleEditClick = (id: number) => {
        history.push(`/user/${id}/edit`);
    };

    /**
     * Handler for Deleting User
     * @param id
     */
    const handleDeleteClick = (id: number) => {
        const user = users.find((u) => u.id === id);
        if (user) {
            setSelectedUser(user);
            setConfirmDelete(true);
        }
    };

    const options = {
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        selectableRows: 'none' as const,
    };

    const columns = [
        {
            name: 'fullName',
            label: 'Name',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'email',
            label: 'Email Address',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'phoneNumber',
            label: 'Phone Number',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'externalRoleName',
            label: 'Role',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: number, tableMeta: MUIDataTableMeta) => (
                    <TableActionMenu
                        id={value}
                        index={tableMeta.rowIndex}
                        showEdit={hasPermission('update:users')}
                        showDelete={hasPermission('delete:users')}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                    />
                ),
            },
        },
    ];

    return (
        <PageWrapper permission="read:users">
            <PageHeader>
                <Toolbar className={classes.header}>
                    <Typography variant="h1" className={classes.title}>
                        Users
                    </Typography>
                    <HasPermission permission="create:users">
                        <Button startIcon={<AddCircleIcon />} component={NavLink} to="/user/create" variant="contained">
                            Create New
                        </Button>
                    </HasPermission>
                </Toolbar>
            </PageHeader>
            <PageBody>
                {loading ? (
                    <LoadingMessage />
                ) : error ? (
                    <ErrorMessage error={error} />
                ) : (
                    <div className={classes.table}>
                        <MUIDataTable title={'Contact List'} data={users} columns={columns} options={options} />
                        <Dialog
                            open={confirmDelete}
                            onClose={handleClose}
                            aria-labelledby="confirm-delete-dialog-title"
                            aria-describedby="confirm-delete-dialog-description">
                            {apiError ? (
                                <>
                                    <DialogTitle id="confirm-delete-dialog-title">
                                        <div className={classes.centerItems}>
                                            <ErrorIcon style={{ color: '#F63832', fontSize: 80 }} />
                                            <Typography variant="h1">Something went wrong</Typography>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="confirm-delete-dialog-description" className={classes.dialogDescription}>
                                            {apiError?.message}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.centerItems}>
                                        <Button onClick={handleClose} variant="contained">
                                            OK
                                        </Button>
                                    </DialogActions>
                                </>
                            ) : success ? (
                                <>
                                    <DialogTitle id="confirm-delete-dialog-title">
                                        <div className={classes.centerItems}>
                                            <CheckCircleIcon style={{ color: '#0EAE79', fontSize: 80 }} />
                                            <Typography variant="h1">
                                                You've successfully deleted <span className={classes.dialogCreated}>{selectedUser?.email}</span>
                                            </Typography>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="confirm-delete-dialog-description" className={classes.dialogDescription}>
                                            The user will no longer be available.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.centerItems}>
                                        <LoadingButton onClick={handleClose} variant="contained">
                                            OK
                                        </LoadingButton>
                                    </DialogActions>
                                </>
                            ) : (
                                <>
                                    <DialogTitle id="confirm-delete-dialog-title">
                                        <div className={classes.centerItems}>
                                            <WarningIcon style={{ color: '#F0A100', fontSize: 80 }} />
                                            <Typography variant="h1">
                                                Delete user <span className={classes.dialogCreated}>{selectedUser?.email}</span>?
                                            </Typography>
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="confirm-delete-dialog-description" className={classes.dialogDescription}>
                                            Clicking Yes will delete the user from the system.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions className={classes.centerItems}>
                                        <LoadingButton pending={mutationLoading} onClick={handleDeleteConfirm} variant="contained">
                                            Yes, delete
                                        </LoadingButton>
                                        <Button onClick={handleClose} variant="text">
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </>
                            )}
                        </Dialog>
                    </div>
                )}
            </PageBody>
        </PageWrapper>
    );
};

export default UsersPage;
