import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Select } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import * as React from 'react';
import { ChangeEvent } from 'react';

const RoleSelect: React.FC<RoleSelectProps> = ({
    index,

    children,
}) => {
    const key = `evaluators[${index}].cycleRoleId`;
    const { watch, setValue, errors } = useFormContext();
    const watchRole = watch(key);
    const role = watchRole || '';

    const getErrorMessage = (index: number): string => {
        return !!errors && !!errors.evaluators && !!errors.evaluators[index] ? errors?.evaluators[index]?.cycleRoleId?.message || '' : '';
    };
    return (
        <FormControl key={index} size="small" style={{ width: '30%' }}>
            <Select
                style={{ background: '#ffffff' }}
                variant="outlined"
                value={role}
                onChange={(e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                    setValue(key, e.target.value, { shouldValidate: true });
                }}
                error={errors && errors.evaluators && index in errors.evaluators}>
                {children}
            </Select>
            <FormHelperText error={errors && errors.evaluators && index in errors.evaluators}>{getErrorMessage(index)}</FormHelperText>
        </FormControl>
    );
};
export interface RoleSelectProps {
    index: number;
}

export default RoleSelect;
