import React, { useCallback, useRef, useState } from 'react';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Button, Grid, IconButton, Paper, Step, StepButton, StepLabel, Stepper, Typography } from '@material-ui/core';
import { SuccessFactorStepIcon, StepperConnector, SuccessFactorBriefStepIcon } from '../../Stepper';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormMode } from '../../../utils/Enums';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Link from '@material-ui/core/Link';
import { NavLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { StatusDropdown, UnSavedChangesDialog, usePermissions } from '../../';
import { FormProvider, useForm } from 'react-hook-form';
import { GetSubCycleSuccessFactorForm_subCycle } from '../../../apollo/generated/types/GetSubCycleSuccessFactorForm';
import { UPDATE_SUB_CYCLE_SUCCESS_FACTOR_FORM } from '../../../apollo/mutations';
import { GET_CYCLE_TOPICS } from '../../../apollo/queries';
import { GetCycleTopics } from '../../../apollo/generated/types/GetCycleTopics';
import { UpdateSubCycleSuccessFactorForm, UpdateSubCycleSuccessFactorFormVariables } from '../../../apollo/generated/types/UpdateSubCycleSuccessFactorForm';
import {
    SubCycleSuccessFactorFormInputType,
    TopicSuccessFactorsInputType,
    SuccessFactorInputType,
    TopicRiskCategoryInputType,
    SuccessFactorStatus,
    SubCycleForm,
    FundamentalPrimeMeasurement,
    FpmCategory,
} from '../../../apollo/generated/types/globalTypes';
import { LoadingButton } from '../../index';
import { Tab, Tabs } from '@material-ui/core';
import TopicSuccessFactorPanelForm from './TopicSuccessFactorPanelForm';
import { TopicWithSuccessFactorsFields } from '../../../apollo/generated/types/TopicWithSuccessFactorsFields';
import { SuccessFactorsFields } from '../../../apollo/generated/types/SuccessFactorsFields';
import { SuccessFactorFormProvider } from '../../Providers/SuccessFactorFormProvider';
import FormDivider from '../FormDivider';
import ExportPDFModal from './ExportPDFModal';
import { useCycleData } from '../../Providers/CycleDataProvider';
import { CycleTab } from '../../../utils/Enums/TabEnum';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
    },
    stickyHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 2,
    },
    formHeader: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '25px',
        padding: '15px',
    },
    breadcrumbs: {
        marginLeft: '25px',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    leftSide: {
        width: '320px',
        paddingLeft: '38px',
        paddingRight: '37px',
    },
    stepperLabel: {
        '& .MuiStepLabel-label': {
            color: '#1A2A3D',
        },
        '& .MuiStepLabel-label.MuiStepLabel-active': {
            color: '#1A2A3D',
        },
    },
    sticky: {
        position: 'sticky',
        top: '95px',
    },
    rightSide: {
        flex: 1,
        maxWidth: '1000px',
        zIndex: 1,
    },
    tabs: {
        marginBottom: '-2px',
    },
});

const getSteps = (hasBriefResponse: boolean) =>
    hasBriefResponse ? ['Product / Technology', 'SF Collaboration'] : ['Product / Technology', 'Organization', 'Application', 'SF Collaboration'];

const schema = yup.object().shape({
    subCycleId: yup.number().required(),
    status: yup.string().required(),
    topics: yup.array().of(
        yup.object().shape({
            id: yup.number().required(),
            successFactors: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    name: yup.string().test('is-name-required', 'Name is required', function test(value) {
                        return (
                            (value !== undefined && value !== '') ||
                            (this.options.context !== undefined &&
                                this.options.context.status !== undefined &&
                                (this.options.context.status === SuccessFactorStatus.DRAFT ||
                                    (this.options.context.isBrief &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.APPEAL &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.VALUE &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.RELIABILITY)))
                        );
                    }),
                    definition: yup.string().test('is-definition-required', 'Definition is required', function test(value) {
                        return (
                            (value !== undefined && value !== '') ||
                            (this.options.context !== undefined &&
                                this.options.context.status !== undefined &&
                                (this.options.context.status === SuccessFactorStatus.DRAFT ||
                                    (this.options.context.isBrief &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.APPEAL &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.VALUE &&
                                        this.parent.fpmCategory !== FundamentalPrimeMeasurement.RELIABILITY)))
                        );
                    }),
                    weight: yup
                        .number()
                        .transform((value) => (isNaN(value) ? undefined : value))
                        .typeError('you must specify a number')
                        .min(1, 'Weight must be a number between 1 and 5')
                        .max(5, 'Weight must be a number between 1 and 5')
                        .test('is-weight-required', 'Weight is required', function test(value) {
                            return (
                                value !== undefined ||
                                (this.options.context !== undefined &&
                                    this.options.context.status !== undefined &&
                                    (this.options.context.status === SuccessFactorStatus.DRAFT ||
                                        (this.options.context.isBrief &&
                                            this.parent.fpmCategory !== FundamentalPrimeMeasurement.APPEAL &&
                                            this.parent.fpmCategory !== FundamentalPrimeMeasurement.VALUE &&
                                            this.parent.fpmCategory !== FundamentalPrimeMeasurement.RELIABILITY)))
                            );
                        }),
                    fpmCategory: yup.mixed<FundamentalPrimeMeasurement>().oneOf(Object.values(FundamentalPrimeMeasurement)).required(),
                }),
            ),
        }),
    ),
});

interface ValidationContext {
    hasBriefResponse: boolean;
}

const SubCycleSuccessFactorForm: React.FC<SubCycleSuccessFactorFormProps> = ({
    cycleId,
    subCycleId,
    subCycleName,
    defaults = {},
    apiError,
    parentRef,
    subCycleOrder,
}) => {
    const { isEvaluator, isTeamLead, isSystemProgramManagement } = usePermissions();
    const { topics, successFactorStatus, formType, form } = defaults;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [savedStatus, setSavedStatus] = useState<SuccessFactorStatus>(successFactorStatus || SuccessFactorStatus.DRAFT);
    const { readOnly: cycleReadOnly } = useCycleData();
    const getMode = () =>
        (isSystemProgramManagement() || isTeamLead(cycleId)) && !cycleReadOnly ? (defaults ? FormMode.Edit : FormMode.Create) : FormMode.View;
    const [mode, setMode] = useState<FormMode>(getMode());
    const readOnly = mode === FormMode.View;

    const [activeStep, setActiveStep] = useState<number>(0);
    const organizationSectionRef = useRef<null | HTMLDivElement>(null);
    const applicationSectionRef = useRef<null | HTMLDivElement>(null);
    const collaborationSectionRef = useRef<null | HTMLDivElement>(null);

    const FpmCategories = [FpmCategory.PRODUCT_TECHNOLOGY, FpmCategory.ORGANIZATION, FpmCategory.APPLICATION];

    let hasBriefResponse = false;
    if (form && formType === SubCycleForm.REQUEST_FOR_INFORMATION && form.__typename === 'RequestForInformationFormType') {
        hasBriefResponse = form.hasBriefResponse;
    }
    const steps = getSteps(hasBriefResponse);
    const methods = useForm<SubCycleSuccessFactorFormInputType>({
        defaultValues: {
            subCycleId: subCycleId,
            status: successFactorStatus || SuccessFactorStatus.DRAFT,
            topics: topics
                ? topics.map(
                      (topic: TopicWithSuccessFactorsFields) =>
                          (({
                              id: topic.id,
                              successFactors:
                                  topic.successFactors.map(
                                      (successFactor: SuccessFactorsFields) =>
                                          ({
                                              id: successFactor.id,
                                              name: successFactor.name,
                                              definition: successFactor.definition,
                                              weight: successFactor.weight,
                                              fpmCategory: successFactor.fpmCategory,
                                              imported: successFactor.imported,
                                          } as SuccessFactorInputType),
                                  ) || [],
                              riskCategories: FpmCategories.map(
                                  (fpmCat) =>
                                      ({
                                          fpmCategory: fpmCat,
                                          risks: topic.risks ? topic.risks.filter((x) => x.fpmCategory === fpmCat).map((risk) => risk.riskId) : [],
                                      } as TopicRiskCategoryInputType),
                              ),
                          } as TopicSuccessFactorsInputType) || []),
                  )
                : [],
        },
        shouldUnregister: false,
        mode: 'onChange',
        context: { hasBriefResponse } as ValidationContext,
        resolver: async (data: SubCycleSuccessFactorFormInputType, context) => {
            const { hasBriefResponse } = context as ValidationContext;
            let values = {};
            let errors = {};
            try {
                values = await schema.validate(data, {
                    context: {
                        status: data.status,
                        isBrief: hasBriefResponse,
                    },
                    abortEarly: false,
                });
            } catch (errs) {
                errors = errs.inner.reduce(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (allErrors: any, currentError: any) => ({
                        ...allErrors,
                        [currentError.path]: {
                            type: currentError.type ?? 'validation',
                            message: currentError.message,
                        },
                    }),
                    {},
                );
            }

            if (data.status !== SuccessFactorStatus.DRAFT) {
                data.topics.map((topic: TopicSuccessFactorsInputType, topicIndex) => {
                    let categories = {} as { [key: string]: SuccessFactorInputType[] };

                    Object.keys(FundamentalPrimeMeasurement).map((key) => {
                        const value = FundamentalPrimeMeasurement[key as keyof typeof FundamentalPrimeMeasurement];
                        categories[value] = [] as SuccessFactorInputType[];
                    });

                    if (topic.successFactors) {
                        categories = topic.successFactors.reduce((acc: { [key: string]: SuccessFactorInputType[] }, obj: SuccessFactorInputType) => {
                            if (!acc[obj.fpmCategory]) {
                                acc[obj.fpmCategory] = [];
                            }
                            acc[obj.fpmCategory].push(obj);
                            return acc;
                        }, categories);

                        for (const key in categories) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const successFactors = categories[key as FundamentalPrimeMeasurement] as SuccessFactorInputType[];
                            if (
                                successFactors.length < 3 &&
                                (!hasBriefResponse ||
                                    key === FundamentalPrimeMeasurement.APPEAL ||
                                    key === FundamentalPrimeMeasurement.VALUE ||
                                    key === FundamentalPrimeMeasurement.RELIABILITY)
                            ) {
                                errors = {
                                    ...errors,
                                    [`topics[${topicIndex}].category[${key}]`]: {
                                        type: 'validation',
                                        message: 'The minimum number of success factors has not been selected',
                                    },
                                };
                                values = {};
                            }
                        }
                    }
                });
            }
            return {
                values,
                errors,
            };
        },
    });

    const [updateForm, { loading }] = useMutation<UpdateSubCycleSuccessFactorForm, UpdateSubCycleSuccessFactorFormVariables>(
        UPDATE_SUB_CYCLE_SUCCESS_FACTOR_FORM,
    );

    const { data } = useQuery<GetCycleTopics>(GET_CYCLE_TOPICS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            cycleId: cycleId,
        },
    });

    const previousSubCycle = data?.cycle?.subCycles.filter((x) => x.order === subCycleOrder - 1)[0] ?? null;
    const previousFormType = previousSubCycle?.formType ?? null;

    const handleStep = useCallback(
        (step: number) => () => {
            setActiveStep(step);
            if (!parentRef || !parentRef.current) {
                return;
            }
            const topOffset = 205;
            if (hasBriefResponse) {
                switch (step) {
                    case 0:
                        parentRef.current.scrollTop = 0;
                        break;
                    case 1:
                        if (collaborationSectionRef && collaborationSectionRef.current) {
                            parentRef.current.scrollTop = collaborationSectionRef.current.offsetTop - topOffset;
                        }
                        break;
                }
            } else {
                switch (step) {
                    case 0:
                        parentRef.current.scrollTop = 0;
                        break;
                    case 1:
                        if (organizationSectionRef && organizationSectionRef.current) {
                            parentRef.current.scrollTop = organizationSectionRef.current.offsetTop - topOffset;
                        }
                        break;
                    case 2:
                        if (applicationSectionRef && applicationSectionRef.current) {
                            parentRef.current.scrollTop = applicationSectionRef.current.offsetTop - topOffset;
                        }
                        break;
                    case 3:
                        if (collaborationSectionRef && collaborationSectionRef.current) {
                            parentRef.current.scrollTop = collaborationSectionRef.current.offsetTop - topOffset;
                        }
                        break;
                }
            }
        },
        [hasBriefResponse, parentRef],
    );

    const onBuildForm = async (data: SubCycleSuccessFactorFormInputType) => {
        try {
            const result = await updateForm({
                variables: {
                    input: data,
                },
            });

            if (result && result.data) {
                enqueueSnackbar('Form Saved!', {
                    variant: 'success',
                });
                const form = result.data.updateSubCycleSuccessFactorForm;
                setSavedStatus(form.successFactorStatus);
                methods.reset({
                    subCycleId: form.id,
                    status: form.successFactorStatus,
                    topics: form.topics.map(
                        (topic: TopicWithSuccessFactorsFields) =>
                            ({
                                id: topic.id,
                                successFactors: topic.successFactors.map(
                                    (successFactor: SuccessFactorsFields) =>
                                        ({
                                            id: successFactor.id,
                                            name: successFactor.name,
                                            definition: successFactor.definition,
                                            weight: successFactor.weight,
                                            fpmCategory: successFactor.fpmCategory,
                                            imported: successFactor.imported,
                                        } as SuccessFactorInputType),
                                ),
                                riskCategories: FpmCategories.map(
                                    (fpmCat) =>
                                        ({
                                            fpmCategory: fpmCat,
                                            risks: topic.risks ? topic.risks.filter((x) => x.fpmCategory === fpmCat).map((risk) => risk.riskId) : [],
                                        } as TopicRiskCategoryInputType),
                                ),
                            } as TopicSuccessFactorsInputType),
                    ),
                });
                setMode(FormMode.Edit);
            } else {
                enqueueSnackbar('Error Saving Form!', {
                    variant: 'error',
                });
            }
        } catch (e) {
            enqueueSnackbar('Error Saving Form!', {
                variant: 'error',
            });
            console.log(e);
        }
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (_: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <FormProvider {...methods}>
            <SuccessFactorFormProvider>
                <form id="acquisition-form">
                    <Grid container className={classes.stickyHeader}>
                        <Grid item xs={12}>
                            <Paper elevation={1}>
                                <div className={classes.formHeader}>
                                    <IconButton component={NavLink} to={`/acquisition-cycle/${cycleId}/sub-cycle/${subCycleId}/form`}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <div className={classes.breadcrumbs}>
                                        <Typography variant="h2">Sub-Cycle Success Factors {subCycleName ? ' - ' + subCycleName : ''}</Typography>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link component={NavLink} to={`/acquisition-cycle/${cycleId}/${CycleTab.SubCycle}/`}>
                                                <Typography variant="h3">Sub-Cycles</Typography>
                                            </Link>
                                            <Link component={NavLink} to={`/acquisition-cycle/${cycleId}/sub-cycle/${subCycleId}/form`}>
                                                <Typography variant="h3"> Sub-Cycle Form</Typography>
                                            </Link>
                                            <Typography variant="h3" style={{ color: '#1976D2' }}>
                                                Success Factors
                                            </Typography>
                                        </Breadcrumbs>
                                    </div>
                                    <Button
                                        style={{ marginRight: '10px' }}
                                        onClick={() => setOpenExport(true)}
                                        disabled={savedStatus === SuccessFactorStatus.DRAFT || savedStatus === SuccessFactorStatus.PENDING_APPROVAL}>
                                        Export
                                    </Button>
                                    <LoadingButton
                                        pending={loading}
                                        disabled={readOnly || isEvaluator(cycleId)}
                                        onClick={methods.handleSubmit(onBuildForm)}
                                        variant="contained">
                                        Save
                                    </LoadingButton>
                                    <ExportPDFModal
                                        open={openExport}
                                        onClose={() => setOpenExport(false)}
                                        subCycleId={subCycleId}
                                        topics={topics || []}
                                        hasBriefResponse={hasBriefResponse}
                                    />
                                </div>
                            </Paper>
                            {apiError ? (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        <AlertTitle>Error</AlertTitle>
                                        {apiError?.message}
                                    </Alert>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                    <div className={classes.root}>
                        <div className={classes.leftSide}>
                            <Grid container spacing={2} className={classes.sticky}>
                                <Grid item xs={12}>
                                    <StatusDropdown name="status" type="SuccessFactor" readOnly={readOnly} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stepper
                                        activeStep={activeStep}
                                        orientation="vertical"
                                        nonLinear
                                        connector={<StepperConnector />}
                                        style={{ background: 'transparent' }}>
                                        {steps.map((label, index) => (
                                            <Step key={label}>
                                                <StepButton onClick={handleStep(index)}>
                                                    <StepLabel
                                                        className={classes.stepperLabel}
                                                        StepIconComponent={hasBriefResponse ? SuccessFactorBriefStepIcon : SuccessFactorStepIcon}>
                                                        {label}
                                                    </StepLabel>
                                                </StepButton>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.rightSide}>
                            <Grid item xs={12}>
                                <Paper elevation={0}>
                                    <input name="subCycleId" type="hidden" ref={methods.register()} value={subCycleId} />
                                    <Tabs value={value} onChange={handleChange} scrollButtons="auto" variant="scrollable" className={classes.tabs}>
                                        {topics && topics.map((topic) => <Tab label={topic.name} key={topic.id} />)}
                                    </Tabs>
                                </Paper>
                            </Grid>
                            <FormDivider />
                            <TopicSuccessFactorPanelForm
                                value={value}
                                mode={mode}
                                formType={formType}
                                form={form}
                                topicInfo={topics}
                                organizationSectionRef={organizationSectionRef}
                                applicationSectionRef={applicationSectionRef}
                                collaborationSectionRef={collaborationSectionRef}
                                previousFormType={previousFormType}
                                subCycleOrder={subCycleOrder}
                            />
                        </div>
                    </div>
                </form>
            </SuccessFactorFormProvider>
            {/* Prevent modal from opening for readOnly status */}
            {!readOnly && <UnSavedChangesDialog />}
        </FormProvider>
    );
};

export default SubCycleSuccessFactorForm;

export interface SubCycleSuccessFactorFormProps {
    cycleId: number;
    subCycleId: number;
    apiError?: ApolloError | null;
    parentRef: React.MutableRefObject<HTMLDivElement | null>;
    defaults: GetSubCycleSuccessFactorForm_subCycle;
    subCycleName?: string | null;
    subCycleOrder: number;
}
