import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwt from 'jsonwebtoken';
import { REGISTER_USER } from '../../../apollo/mutations';
import { ApolloError, useMutation } from '@apollo/client';
import { RegisterUser, RegisterUserVariables } from '../../../apollo/generated/types/RegisterUser';
import { UserDisplayFields } from '../../../apollo/generated/types/UserDisplayFields';
import { CurrentUserFields } from '../../../apollo/generated/types/CurrentUserFields';
import { Role } from '../../../apollo/generated/types/globalTypes';

interface PermissionContextProps {
    userLoaded: boolean;
    authenticationLoading: boolean;
    error?: string | ApolloError;
    token?: string;
    hasPermission: (permission: string) => boolean;
    hasCycleRole: (cycleId: number, role: Role) => boolean;
    updateUserInfo: () => void;
    user?: UserDisplayFields;
    isEvaluator: (cycleId: number) => boolean;
    isTeamLead: (cycleId: number) => boolean;
    isSystemProgramManagement: () => boolean;
}

const PermissionContext = createContext<PermissionContextProps | undefined>(undefined);

const PermissionProvider: React.FC = ({ children }) => {
    const [token, setToken] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [user, setUser] = useState<CurrentUserFields>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [claims, setClaims] = useState<any | undefined>();
    const { isLoading: authenticationLoading, isAuthenticated, getAccessTokenSilently, user: auth0User } = useAuth0();
    const [register] = useMutation<RegisterUser, RegisterUserVariables>(REGISTER_USER);

    const updateUserInfo = () => {
        fetchAccessToken(true);
    };

    const fetchAccessToken = async (willIgnoreCache = false) => {
        const auth0Token = await getAccessTokenSilently({ ignoreCache: willIgnoreCache });
        setToken(auth0Token);

        const decoded = jwt.decode(auth0Token, { complete: true });
        setClaims(decoded);

        try {
            const results = await register({
                variables: {
                    email: auth0User.email,
                    auth0Id: auth0User.sub,
                },
            });
            if (results?.data?.register) {
                setUser(results.data.register);
            } else {
                setError('User failed to register');
            }
        } catch (e) {
            setError(e);
        }
    };

    /**
     * Check if token has permission
     * @param permission
     */
    const hasPermission = (permission: string): boolean => {
        const permissions = claims?.payload?.permissions || [];
        return permissions.includes(permission);
    };

    const hasCycleRole = (cycleId: number, role: Role) => {
        if (!user) {
            return false;
        }

        const assignment = user.cycleAssignments?.find((cycle) => cycle.cycleId === cycleId);
        if (!assignment) {
            return false;
        }
        return assignment.cycleRole.roleType === role;
    };

    // Permissions
    const isEvaluator = (cycleId: number): boolean => hasCycleRole(cycleId, Role.EVALUATOR);
    const isTeamLead = (cycleId: number) => hasCycleRole(cycleId, Role.TEAM_LEAD) || hasCycleRole(cycleId, Role.CO_LEAD);
    const isSystemProgramManagement = () => hasPermission('update:cycles');

    useEffect(() => {
        const init = async () => {
            if (!authenticationLoading && isAuthenticated) {
                fetchAccessToken(false);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, authenticationLoading, isAuthenticated, register, auth0User]);

    return (
        <PermissionContext.Provider
            value={{
                userLoaded: !!user,
                authenticationLoading,
                token,
                user,
                error,
                hasPermission,
                hasCycleRole,
                updateUserInfo,
                isEvaluator,
                isTeamLead,
                isSystemProgramManagement,
            }}>
            {children}
        </PermissionContext.Provider>
    );
};

const usePermissions = (): PermissionContextProps => {
    const context = React.useContext(PermissionContext);
    if (context === undefined) {
        throw new Error('usePermissions must be used within a PermissionProvider');
    }
    return context;
};

export { PermissionProvider, usePermissions };
