/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { FundamentalPrimeMeasurement } from '../../../apollo/generated/types/globalTypes';

export const getFPMOrder = (hasBriefResponse = false) => {
    if (hasBriefResponse) {
        return [FundamentalPrimeMeasurement.APPEAL, FundamentalPrimeMeasurement.VALUE, FundamentalPrimeMeasurement.RELIABILITY];
    } else {
        return [
            FundamentalPrimeMeasurement.APPEAL,
            FundamentalPrimeMeasurement.VALUE,
            FundamentalPrimeMeasurement.RELIABILITY,
            FundamentalPrimeMeasurement.PERSONNEL,
            FundamentalPrimeMeasurement.PROCESSES,
            FundamentalPrimeMeasurement.FINANCES,
            FundamentalPrimeMeasurement.APPLICATION_SIZE,
            FundamentalPrimeMeasurement.DEMAND,
            FundamentalPrimeMeasurement.DELIVERY,
        ];
    }
};

export const getBriefRespnseFPMOrder = () => [FundamentalPrimeMeasurement.APPEAL, FundamentalPrimeMeasurement.VALUE, FundamentalPrimeMeasurement.RELIABILITY];

export const getFPMLabels = () => [
    ['Appeal', 'Value', 'Reliability'],
    ['People', 'Planning / Processes', 'Finances'],
    ['Size / Scope', 'Market Demand', 'Delivery (Market Access)'],
];

export const getFPMLabelsList = () => [
    'Appeal',
    'Value',
    'Reliability',
    'People',
    'Planning / Processes',
    'Finances',
    'Size / Scope',
    'Market Demand',
    'Delivery (Market Access)',
];
