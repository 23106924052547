import * as React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { FieldError, useForm } from 'react-hook-form';
import { ReactHookFormCheckbox, ReactHookFormSelect } from '../../ReactHookForm';
import { TopicNameFields } from '../../../apollo/generated/types/TopicNameFields';
import { ApolloError, useMutation } from '@apollo/client';
import { Export_SuccessFactors_PDF } from '../../../apollo/mutations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import download from 'downloadjs';
import { ExportSuccessFactorsPDF, ExportSuccessFactorsPDFVariables } from '../../../apollo/generated/types/ExportSuccessFactorsPDF';
import { LoadingButton } from '../../index';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ExportSuccessFactorsPdfInputType } from '../../../apollo/generated/types/globalTypes';

export interface PDFInput extends ExportSuccessFactorsPdfInputType {
    fileName: string;
}

const ExportPDFModal: React.FC<ExportPDFModalProps> = ({ open, onClose, subCycleId, topics, hasBriefResponse }) => {
    const [error, setError] = useState<ApolloError>();
    const defaultInput = useMemo(
        () => ({
            subCycleId,
            topics: [],
            showApplicationRisk: false,
            showOrganizationRisk: false,
            showProductTechnologyRisk: false,
        }),
        [subCycleId],
    );
    const { register, errors, handleSubmit, control } = useForm<PDFInput>({
        defaultValues: { fileName: '', ...defaultInput },
    });
    const [exportPDF, { loading }] = useMutation<ExportSuccessFactorsPDF, ExportSuccessFactorsPDFVariables>(Export_SuccessFactors_PDF);

    useEffect(() => {
        setError(undefined);
    }, [open]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleExport = useCallback(
        async (data: PDFInput) => {
            const { fileName, ...inputValues } = data;
            try {
                setError(undefined);
                const result = await exportPDF({
                    variables: {
                        input: { ...defaultInput, ...inputValues },
                    },
                });

                if (result && result.data) {
                    download(new Uint8Array(result.data?.exportSuccessFactors), `${fileName}.pdf`, 'application/pdf');
                    onClose();
                }
            } catch (e) {
                setError(e);
            }
        },
        [defaultInput, exportPDF, onClose],
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogTitle disableTypography>
                <Typography variant="h1">Export Success Factors</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleSubmit(handleExport)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <input name="subCycleId" type="hidden" ref={register({ required: true, valueAsNumber: true })} value={subCycleId} />
                            <TextField
                                fullWidth
                                label="File Name"
                                name="fileName"
                                inputRef={register({
                                    required: 'File Name is required',
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors.fileName}
                                helperText={errors.fileName?.message}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                fullWidth
                                control={control}
                                label="Topics"
                                name="topics"
                                defaultValue={[]}
                                error={!!errors.topics}
                                helperText={(errors.topics as FieldError | undefined)?.message}
                                selectProps={{
                                    multiple: true,
                                    MenuProps: {
                                        getContentAnchorEl: null,
                                    },
                                }}
                                rules={{
                                    required: 'At least one Topic is required',
                                    validate: (value) => value.length > 0 || 'At least one Topic is required',
                                }}>
                                {topics.map((topic: TopicNameFields) => (
                                    <MenuItem key={topic.id} value={topic.id}>
                                        {topic.name}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h3" style={{ opacity: '0.6' }}>
                                Select to show risks on the export
                            </Typography>
                            <div>
                                <ReactHookFormCheckbox label="Product / Technology" name="showProductTechnologyRisk" control={control} />
                            </div>
                            {!hasBriefResponse ? (
                                <>
                                    <div>
                                        <ReactHookFormCheckbox label="Organization" name="showOrganizationRisk" control={control} />
                                    </div>
                                    <div>
                                        <ReactHookFormCheckbox label="Application " name="showApplicationRisk" control={control} />
                                    </div>
                                </>
                            ) : null}
                        </Grid>
                        {error ? (
                            <Grid item xs={12}>
                                <Alert severity="error">
                                    <AlertTitle>Error</AlertTitle>
                                    {error?.message}
                                </Alert>
                            </Grid>
                        ) : null}
                        <Grid container item justify="flex-end" xs={12}>
                            <Button onClick={onClose}>Cancel</Button>
                            <LoadingButton pending={loading} variant="contained" type="submit">
                                Export
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export interface ExportPDFModalProps {
    open: boolean;
    onClose: () => void;
    subCycleId: number;
    topics: TopicNameFields[];
    hasBriefResponse: boolean;
}

export default ExportPDFModal;
