import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import FormSection from '../../FormSection';
import FormDivider from '../../FormDivider';
import { FormMode } from '../../../../utils/Enums';
import { ReactHookFormAutocomplete, ReactHookFormCheckbox, ReactHookFormDate } from '../../../ReactHookForm';
import { useRFIForm } from '../../../Providers/RFIFormProvider';
import { OptionFields } from '../../../../apollo/generated/types/OptionFields';
import { SubCycleStatus } from '../../../../apollo/generated/types/globalTypes';

const DatesAndBudgetsForm: React.FC<DatesAndBudgetsFormProps> = ({ mode }) => {
    const { control, errors, watch } = useFormContext();
    const { addFundingSource, fundingSources, loading } = useRFIForm();
    const status = watch('status', SubCycleStatus.DRAFT);
    const required = status !== SubCycleStatus.DRAFT;
    const readOnly = mode === FormMode.View;

    return (
        <>
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h1">Dates & Budgets</Typography>
                </Grid>
            </FormSection>
            <FormDivider />
            <FormSection>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ opacity: '0.6' }}>
                        Dates
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Posted Date*"
                        name="postedDate"
                        defaultValue={null}
                        rules={{ required: required ? 'Posted Date is required' : false }}
                        error={!!errors.postedDate}
                        helperText={errors.postedDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Original Closing Date*"
                        name="originalClosingDate"
                        defaultValue={null}
                        rules={{ required: required ? 'Original Closing Date' : false }}
                        error={!!errors.originalClosingDate}
                        helperText={errors.originalClosingDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Original Archive Date"
                        name="originalArchiveDate"
                        defaultValue={null}
                        error={!!errors.originalArchiveDate}
                        helperText={errors.originalArchiveDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Last Updated Date"
                        name="lastUpdatedDate"
                        defaultValue={null}
                        error={!!errors.lastUpdatedDate}
                        helperText={errors.lastUpdatedDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Current Closing Date"
                        name="currentClosingDate"
                        defaultValue={null}
                        error={!!errors.currentClosingDate}
                        helperText={errors.currentClosingDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ReactHookFormDate
                        control={control}
                        format="MM/dd/yyyy"
                        label="Archive Date"
                        name="archiveDate"
                        defaultValue={null}
                        error={!!errors.archiveDate}
                        helperText={errors.archiveDate?.message}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" style={{ opacity: '0.6' }}>
                        Budgets
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormAutocomplete
                        label="Funding Source*"
                        name="fundingSourceId"
                        defaultValue=""
                        loading={loading}
                        rules={{ required: required ? 'Funding Source is required' : false }}
                        error={!!errors.fundingSourceId}
                        helperText={errors.fundingSourceId?.message}
                        options={fundingSources}
                        addOption={addFundingSource}
                        getOptionSelected={(option: OptionFields, value?: number | OptionFields) => {
                            if (!value) {
                                return false;
                            }
                            if (typeof value === 'number') {
                                return option.id === value;
                            } else {
                                return option.id === value.id;
                            }
                        }}
                        getOptionValue={(option: OptionFields | null) => (option ? option?.id : null)}
                        getOptionLabel={(option: OptionFields) => option?.name || ''}
                        renderOption={(option: OptionFields) => option?.name}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactHookFormCheckbox
                        control={control}
                        name="isCostSharing"
                        label="Cost Sharing / Matching Funds Required"
                        defaultValue={false}
                        readOnly={readOnly}
                    />
                </Grid>
            </FormSection>
        </>
    );
};

export interface DatesAndBudgetsFormProps {
    mode: FormMode;
}

export default DatesAndBudgetsForm;
