/* eslint-disable @typescript-eslint/no-explicit-any */

import { FpmCategory, FundamentalPrimeMeasurement } from '../../apollo/generated/types/globalTypes';

// FPMs
export const fpmLabels: string[][] = [
    ['Appeal', 'Value', 'Reliability'],
    ['People', 'Planning / Processes', 'Finances'],
    ['Size / Scope', 'Market Demand', 'Delivery (Market Access)'],
];

export const fpmsOrdered: FundamentalPrimeMeasurement[] = [
    FundamentalPrimeMeasurement.APPEAL,
    FundamentalPrimeMeasurement.VALUE,
    FundamentalPrimeMeasurement.RELIABILITY,
    FundamentalPrimeMeasurement.PERSONNEL,
    FundamentalPrimeMeasurement.PROCESSES,
    FundamentalPrimeMeasurement.FINANCES,
    FundamentalPrimeMeasurement.APPLICATION_SIZE,
    FundamentalPrimeMeasurement.DEMAND,
    FundamentalPrimeMeasurement.DELIVERY,
];

// FPM Categories
export enum ScoreSectionsType {
    PRODUCT_TECHNOLOGY = 0,
    ORGANIZATION = 1,
    APPLICATION = 2,
}

export const getScoreSectionsTypeDisplayName = (value: ScoreSectionsType): string => {
    switch (value) {
        case ScoreSectionsType.PRODUCT_TECHNOLOGY:
            return 'Product / Technology';
        case ScoreSectionsType.ORGANIZATION:
            return 'Organization';
        case ScoreSectionsType.APPLICATION:
            return 'Application';
        default:
            return 'None';
    }
};

export const getScoreSectionsTypeFromDisplayName = (value: string): ScoreSectionsType | number => {
    switch (value) {
        case 'Product / Technology':
            return ScoreSectionsType.PRODUCT_TECHNOLOGY;
        case 'Organization':
            return ScoreSectionsType.ORGANIZATION;
        case 'Application':
            return ScoreSectionsType.APPLICATION;
        default:
            return 0;
    }
};

export const getFpmCategoryFromScoreSection = (value: ScoreSectionsType): FpmCategory | null => {
    switch (value) {
        case ScoreSectionsType.PRODUCT_TECHNOLOGY:
            return FpmCategory.PRODUCT_TECHNOLOGY;
        case ScoreSectionsType.ORGANIZATION:
            return FpmCategory.ORGANIZATION;
        case ScoreSectionsType.APPLICATION:
            return FpmCategory.APPLICATION;
        default:
            return null;
    }
};

export const getFpmFromDisplayName = (value: string): FundamentalPrimeMeasurement | null => {
    switch (value) {
        case 'Appeal':
            return FundamentalPrimeMeasurement.APPEAL;
        case 'Value':
            return FundamentalPrimeMeasurement.VALUE;
        case 'Reliability':
            return FundamentalPrimeMeasurement.RELIABILITY;
        case 'People':
            return FundamentalPrimeMeasurement.PERSONNEL;
        case 'Planning / Processes':
            return FundamentalPrimeMeasurement.PROCESSES;
        case 'Finances':
            return FundamentalPrimeMeasurement.FINANCES;
        case 'Size / Scope':
            return FundamentalPrimeMeasurement.APPLICATION_SIZE;
        case 'Market Demand':
            return FundamentalPrimeMeasurement.DEMAND;
        case 'Delivery (Market Access)':
            return FundamentalPrimeMeasurement.DELIVERY;
        default:
            return null;
    }
};

// Generic Types for filterAndMapScores
interface ScoreProps {
    __typename: 'EvaluatorScoreType';
    id: number;
    responseUserId: number;
    successFactor: SuccessFactorProps;
    score: any | null;
    comment: string | null;
}

interface SuccessFactorProps {
    __typename: 'SuccessFactorType';
    id: number | null;
    name: string;
    definition: string;
    fpmCategory: FundamentalPrimeMeasurement;
    weight: any | null;
}

interface ScoreInputProps {
    id?: number | null;
    responseUserId: number;
    successFactorId: number;
    score?: any | null;
    comment?: string | null;
}

// Filters scores by FPMs and isolates important fields
export const filterAndMapScores = (scores: ScoreProps[] | null, fpm: FundamentalPrimeMeasurement): ScoreInputProps[] => {
    return (scores ?? [])
        .filter((score) => score.successFactor.fpmCategory === fpm)
        .map((score) => {
            return {
                id: score.id,
                responseUserId: score.responseUserId,
                successFactorId: score.successFactor.id,
                score: score.score ?? null,
                comment: score.comment,
            } as ScoreInputProps;
        });
};

export const findFPMSection = (fpmCategory: string): ScoreSectionsType => {
    if (fpmCategory === 'Appeal' || fpmCategory === 'Value' || fpmCategory === 'Reliability') {
        return ScoreSectionsType.PRODUCT_TECHNOLOGY;
    } else if (fpmCategory === 'People' || fpmCategory === 'Planning / Processes' || fpmCategory === 'Finances') {
        return ScoreSectionsType.ORGANIZATION;
    } else {
        return ScoreSectionsType.APPLICATION;
    }
};
